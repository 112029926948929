import { Box } from '@mui/material';
import range from 'lodash/range';
import React from 'react';
import { HorizontalBarRoot } from './Loader.styles';

interface OwnProps {
  count?: number;
}

const HorizontalBarLoader: React.FC<OwnProps> = ({ count = 1 }) => {
  return (
    <Box margin={0} width="100%">
      {range(count).map((val) => (
        <Box key={val} mb={1}>
          <HorizontalBarRoot />
        </Box>
      ))}
    </Box>
  );
};

export default HorizontalBarLoader;
