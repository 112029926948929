import { MailIcon } from '@fyeo-di-frontend/shared';
import { Close } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import startCase from 'lodash/startCase';
import React, { memo } from 'react';
import { Email, EmailType } from '../Assets.types';
import * as StyledComponent from './AssetTypes.styles';

interface OwnProps {
  isThreatActor?: boolean;
  data?: Email[];
  onChange?: (data: Email) => void;
  onAdd?: () => void;
  onDelete?: (data: Email) => void;
  disabled?: boolean;
  readOnly?: boolean;
}

const EmailRender: React.FC<OwnProps> = (props) => {
  const onChangeType = (item: Email) => (evt: SelectChangeEvent<EmailType>) => {
    props.onChange?.({ ...item, tag: evt.target.value as EmailType });
  };

  const onChangeValue =
    (item: Email) => (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange?.({ ...item, value: event.target.value });
    };

  const onDelete = (item: Email) => () => {
    props.onDelete?.(item);
  };

  const color = props.isThreatActor ? 'secondary' : 'primary';

  return (
    <>
      <Box display="flex" width="100%" mt={2}>
        <Box mt={-1} ml={-1} mb={-1}>
          <StyledComponent.CursorBox
            onClick={!props.readOnly ? props.onAdd : undefined}
            fontSize={40}
          >
            <MailIcon fontSize="inherit" color={color} />
          </StyledComponent.CursorBox>
        </Box>
      </Box>
      {props?.data?.map?.((data) => (
        <Grid container spacing={1} key={data?._id}>
          <Grid item xs={4}>
            <Select<EmailType>
              disabled={props.disabled}
              onChange={onChangeType(data)}
              size="small"
              fullWidth
              value={data?.tag}
              color={color}
              readOnly={props.readOnly}
            >
              <MenuItem value={EmailType.Work} dense>
                {startCase(EmailType.Work)}
              </MenuItem>
              <MenuItem value={EmailType.Private} dense>
                {startCase(EmailType.Private)}
              </MenuItem>
              <MenuItem value={EmailType.Other} dense>
                {startCase(EmailType.Other)}
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={8}>
            <TextField
              disabled={props.disabled}
              value={data?.value || ''}
              onChange={onChangeValue(data)}
              color={color}
              InputProps={{
                readOnly: props.readOnly,
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={onDelete?.(data)}>
                      <Close fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default memo(EmailRender);
