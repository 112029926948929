import React from 'react';
import { useEditTrendsContext } from './EditTrends.context';
import * as StyledComponent from './EditTrends.styles';
import { Box, Typography } from '@mui/material';
import EditTrendsTableData from './EditTrends.tableData.component';
import { TrendsEditor } from '@fyeo-di-frontend/shared';

const EditTrends = () => {
  const {
    trends,
    isLoading,
    handleLoadMore,
    listingRef,
    value,
    setValue,
    titleValue,
    setTitleValue,
    onTrendClick,
    isPublished,
    onCopyTrend,
    id,
    onSave,
    onPublish,
    isSaving,
    isPublishing,
  } = useEditTrendsContext();

  return (
    <StyledComponent.Root>
      <StyledComponent.Wrapper>
        <Box flex={1} height="100%" display="flex" flexDirection="column">
          <TrendsEditor
            setValue={setValue}
            state={{ value, titleValue }}
            setTitleValue={setTitleValue}
            isPublished={isPublished}
            id={id}
            onSave={onSave}
            onPublish={onPublish}
            isSaving={isSaving}
            isPublishing={isPublishing}
          />
        </Box>
        <Box flex={1} height="100%" display="flex" flexDirection="column">
          <Box mb={1} ml={2}>
            <Typography variant="subtitle1">Previous posts</Typography>
          </Box>
          <StyledComponent.TableContainer ref={listingRef}>
            <EditTrendsTableData
              data={trends}
              loading={isLoading}
              onLoadMore={handleLoadMore}
              onRowClick={onTrendClick}
              onCopyClick={onCopyTrend}
            />
          </StyledComponent.TableContainer>
        </Box>
      </StyledComponent.Wrapper>
    </StyledComponent.Root>
  );
};

export default EditTrends;
