import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const UrlIcon2: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M15.5317 0.629883V6.29695H21.1988L15.5317 0.629883Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.531 0C15.8788 0 16.1607 0.281915 16.1607 0.629674V5.66706H21.1981C21.5459 5.66706 21.8278 5.94898 21.8278 6.29674C21.8278 6.6445 21.5459 6.92641 21.1981 6.92641H15.531C15.1833 6.92641 14.9014 6.6445 14.9014 6.29674V0.629674C14.9014 0.281915 15.1833 0 15.531 0Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.56738 0.629674C3.56738 0.281915 3.8493 0 4.19706 0H15.5312C15.6982 0 15.8583 0.0663404 15.9764 0.184427L21.6435 5.85149C21.7616 5.96958 21.8279 6.12974 21.8279 6.29674V21.4089C21.8279 21.7567 21.546 22.0386 21.1982 22.0386H4.19706C3.8493 22.0386 3.56738 21.7567 3.56738 21.4089V0.629674ZM4.82673 1.25935V20.7792H20.5686V6.55756L15.2704 1.25935H4.82673Z"
    />
    <rect x="1.6792" y="8.39648" width="13.433" height="8.39565" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.17149 8.18652C0.524492 8.18652 0 8.83938 0 9.64472V15.9635C0 16.7689 0.524492 17.4217 1.17149 17.4217H15.6198C16.2668 17.4217 16.7913 16.7689 16.7913 15.9635V9.64471C16.7913 8.83938 16.2668 8.18652 15.6198 8.18652H1.17149ZM5.41556 14.83C6.26959 14.83 6.94944 14.3075 6.94944 13.3299V10.7565H6.17407V13.2737C6.17407 13.7962 5.88752 14.0715 5.41556 14.0715C4.95483 14.0715 4.66266 13.7962 4.66266 13.2737V10.7565H3.88729V13.3299C3.88729 14.3075 4.56714 14.83 5.41556 14.83ZM9.78887 14.7401H10.6541L9.8001 13.1107C10.3226 12.959 10.6485 12.5376 10.6485 11.9814C10.6485 11.2903 10.1541 10.7565 9.37871 10.7565H7.82235V14.7401H8.60334V13.2063H9.00788L9.78887 14.7401ZM8.60334 12.5376V11.4308H9.23262C9.62593 11.4308 9.85629 11.6499 9.85629 11.987C9.85629 12.3129 9.62593 12.5376 9.23262 12.5376H8.60334ZM13.8848 13.9985V14.7401H11.334V10.7565H12.1094V13.9985H13.8848Z"
    />
  </SvgIcon>
);

export default UrlIcon2;
