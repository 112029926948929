import React, { memo } from 'react';
import { Box } from '@mui/material';
import { HorizontalFullHeightLoader } from '@fyeo-di-frontend/shared';

const IncidentsEventLoader = () => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box height={150}>
        <HorizontalFullHeightLoader />
      </Box>
      <Box height={150}>
        <HorizontalFullHeightLoader />
      </Box>
      <Box height={150}>
        <HorizontalFullHeightLoader />
      </Box>
      <Box height={150}>
        <HorizontalFullHeightLoader />
      </Box>
    </Box>
  );
};

export default memo(IncidentsEventLoader);
