import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { Severity } from '@fyeo-di-frontend/shared';
import React, { useMemo } from 'react';
import { Descripted } from '@root/incidents/Incidents.types';
import { enumToDescriptedArray } from '@root/incidents/Incidents.helpers';

interface OwnProps {
  value?: Descripted<typeof Severity>[];
  onChangeSeverity?: (reported: Descripted<typeof Severity>[]) => void;
}

const SeverityFilterComponent: React.FC<OwnProps> = (props) => {
  const option = useMemo(() => {
    return enumToDescriptedArray(Severity).filter(
      (array) => array.id !== Severity.None,
    );
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="subtitle2">Severity</Typography>
      <Box maxWidth={300} width="100%">
        <Autocomplete
          onChange={(event, newValue) => {
            props?.onChangeSeverity?.(newValue || []);
          }}
          options={option}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          multiple
          value={props.value}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </Box>
    </Box>
  );
};

export default SeverityFilterComponent;
