import { Box, Switch, Typography } from '@mui/material';
import React, { memo, useMemo } from 'react';
import styled from 'styled-components';

interface Value {
  isThreatActor?: boolean;
  active?: boolean;
}

interface OwnProps {
  value?: Value;
  disabled?: boolean;
  onChange?: (key: keyof Value, value: boolean) => void;
  disableActive?: boolean;
  disableThreatActor?: boolean;
  exceedsCount?: boolean;
}

const HiddenText = styled(Typography)({
  visibility: 'hidden',
});

const BooleanRender = (props: OwnProps) => {
  const activeColor = useMemo(() => {
    if (props?.value?.isThreatActor) {
      return 'secondary';
    }

    if (props?.value?.active) {
      return 'primary';
    }

    return '#CFD0D1';
  }, [props.value]);

  return (
    <Box width="100%">
      <Box width={105} display="flex" flexDirection="column" alignItems="right">
        <Box display="flex">
          <Typography
            variant="body1"
            color={!props?.value?.active ? undefined : '#CFD0D1'}
          >
            Inactive
          </Typography>
          <Box mt={-1}>
            <Switch
              checked={props.disableActive ? false : props.value?.active}
              value={props?.value?.active}
              onChange={(_, checked) => props?.onChange?.('active', checked)}
              inputProps={{ 'aria-label': 'controlled' }}
              disabled={
                props.disableActive ||
                props.disabled ||
                (props.exceedsCount && !props?.value?.active)
              }
              color={props?.value?.isThreatActor ? 'secondary' : 'primary'}
            />
          </Box>
          <Typography variant="body1" color={activeColor}>
            Active
          </Typography>
        </Box>
        <Box display="flex">
          <HiddenText
            variant="body1"
            color={!props?.value?.isThreatActor ? undefined : '#CFD0D1'}
          >
            Inactive
          </HiddenText>
          <Box mt={-1}>
            <Switch
              disabled={props?.disableThreatActor}
              checked={props?.value?.isThreatActor}
              value={props?.value?.isThreatActor}
              onChange={(_, checked) =>
                props?.onChange?.('isThreatActor', checked)
              }
              inputProps={{ 'aria-label': 'controlled' }}
              color={props?.value?.isThreatActor ? 'secondary' : 'primary'}
            />
          </Box>
          <Typography
            variant="body1"
            color={props?.value?.isThreatActor ? 'secondary' : '#CFD0D1'}
            whiteSpace="nowrap"
          >
            Threat actor
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(BooleanRender);
