import * as StyledComponents from './SettingsSidebar.styles';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SettingsRoutes } from '@root/common/Routes';
import { useAppContext } from '@root/App.context';
import { isStaff } from '@root/common/Utils.helpers';

const SettingsListItem = memo(
  ({ route, label }: { route: string; label: string }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    return (
      <ListItem disablePadding dense disableGutters>
        <StyledComponents.ListItemButtonStyled
          dense
          selected={pathname === route}
          onClick={() => navigate({ pathname: route })}
        >
          <ListItemText
            primary={label}
            primaryTypographyProps={{
              color: pathname === route ? 'primary' : 'initial',
              noWrap: true,
              variant: 'subtitle2',
              fontSize: 12,
              paddingLeft: 1.125,
            }}
          />
        </StyledComponents.ListItemButtonStyled>
      </ListItem>
    );
  },
);
SettingsListItem.displayName = 'SettingsListItem';

const defaultMenu = [
  { route: SettingsRoutes.Root, label: 'Account' },
  {
    route: SettingsRoutes.Root + '/' + SettingsRoutes.ApiAccess,
    label: 'API access',
  },
];

const SettingsSidebar = () => {
  const { idToken } = useAppContext();
  const [menus, setMenus] = useState([...defaultMenu]);

  useEffect(() => {
    if (isStaff(idToken)) {
      setMenus([
        ...defaultMenu,
        {
          route: SettingsRoutes.Root + '/' + SettingsRoutes.EditTrends,
          label: 'Edit general trends',
        },
      ]);
    }
  }, [idToken]);
  return (
    <>
      <StyledComponents.DrawerStyled anchor="left" open variant="persistent">
        <Box ml={1.5} mb={1} display="flex">
          <Typography variant="h6">User settings</Typography>
        </Box>
        <List dense disablePadding>
          {menus.map(({ route, label }) => (
            <SettingsListItem route={route} label={label} key={route} />
          ))}
        </List>
      </StyledComponents.DrawerStyled>
    </>
  );
};

export default SettingsSidebar;
