import styled from 'styled-components';
import { Box, FormControlLabel, Grid, Typography } from '@mui/material';

export const Root = styled.div({
  height: 'calc(100vh - 70px)',
  backgroundColor: '#f8f9fb',
  paddingTop: 30,
  overflowY: 'auto',
  overflowX: 'hidden',
});

export const Wrapper = styled(Box)({
  borderRadius: 4,
  marginRight: 27,
  backgroundColor: '#fff',
  maxHeight: 'calc(100vh - 480px)',
  overflowY: 'auto',
  marginLeft: 8,
  flexDirection: 'column',
  display: 'flex',
  position: 'relative',
});

export const Block = styled(Box)({
  borderRadius: 4,
  marginRight: 27,
  marginLeft: 8,
  marginTop: 14,
  display: 'flex',
  backgroundColor: '#fff',
  flexDirection: 'column',
  padding: 16,
});

export const RightBorderGrid = styled(Grid)({
  border: '1px solid #D5D8DF',
  borderTop: 'none',
  borderLeft: 'none',
  borderBottom: 'none',
});

export const TextBox = styled(Box)({
  width: '100%',
  paddingRight: 8,
  paddingLeft: 8,
  backgroundColor: '#F1F3F6',
  borderRadius: 2,
  minHeight: 39,
  display: 'flex',
  alignItems: 'center',
});

export const APIKeyBox = styled(Box)({
  marginTop: 16,
  width: '100%',
  padding: 16,
  backgroundColor: '#F1F3F6',
  borderRadius: 2,
  gap: 10,
  fontSize: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const HorizontalRule = styled.hr({
  border: '1px solid #D5D8DF',
  borderTop: 'none',
  width: '100%',
});

export const ReportedFormControlLabel = styled(FormControlLabel)({
  width: '100%',
  marginLeft: 0,
});

export const EllipsisBox = styled(Grid)({
  overflowX: 'hidden',
});

export const EllipsisText = styled(Typography)({
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const CopyIconButton = styled(Box)({
  'svg:hover': {
    cursor: 'pointer',
  },
});

export const HiddenEl = styled.div({
  visibility: 'hidden',
});
