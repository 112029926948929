import { HorizontalFullHeightLoader } from '@fyeo-di-frontend/shared';
import { Box, Grid } from '@mui/material';
import range from 'lodash/range';
import React, { memo } from 'react';

const CasesDetailsLoader = () => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Grid container spacing={2}>
        {range(0, 3).map((val) => (
          <Grid key={val} item xs={4}>
            <Box height={40}>
              <HorizontalFullHeightLoader />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box height={150}>
        <HorizontalFullHeightLoader />
      </Box>
      <Grid container spacing={2}>
        {range(0, 6).map((val) => (
          <Grid key={val} item xs={6}>
            <Box height={40}>
              <HorizontalFullHeightLoader />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default memo(CasesDetailsLoader);
