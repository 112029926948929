import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const MobileIcon2: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M13.6271 0H0.610169C0.448342 0 0.293143 0.0642855 0.178714 0.178715C0.0642854 0.293143 0 0.448342 0 0.610169V23.3898C0 23.5517 0.0642854 23.7069 0.178714 23.8213C0.293143 23.9357 0.448342 24 0.610169 24H13.6271C13.7889 24 13.9441 23.9357 14.0586 23.8213C14.173 23.7069 14.2373 23.5517 14.2373 23.3898V0.610169C14.2373 0.448342 14.173 0.293143 14.0586 0.178715C13.9441 0.0642855 13.7889 0 13.6271 0ZM13.0169 1.22034V17.8983H1.22034V1.22034H13.0169ZM1.22034 22.7797V19.1186H13.0169V22.7797H1.22034ZM7.11864 21.7631C7.56795 21.7631 7.9322 21.3988 7.9322 20.9495C7.9322 20.5002 7.56795 20.1359 7.11864 20.1359C6.66932 20.1359 6.30508 20.5002 6.30508 20.9495C6.30508 21.3988 6.66932 21.7631 7.11864 21.7631ZM5.89819 4.27137H8.33886C8.44675 4.27137 8.55021 4.22851 8.6265 4.15223C8.70279 4.07594 8.74564 3.97248 8.74564 3.86459C8.74564 3.75671 8.70279 3.65324 8.6265 3.57696C8.55021 3.50067 8.44675 3.45781 8.33886 3.45781H5.89819C5.7903 3.45781 5.68684 3.50067 5.61055 3.57696C5.53426 3.65324 5.49141 3.75671 5.49141 3.86459C5.49141 3.97248 5.53426 4.07594 5.61055 4.15223C5.68684 4.22851 5.7903 4.27137 5.89819 4.27137Z" />
  </SvgIcon>
);

export default MobileIcon2;
