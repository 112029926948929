import { useCallback, useState } from 'react';
import { useReportsContext } from './Reports.context';
import { Domain, ReportsTypes } from './Reports.types';

const useReportsHook = () => {
  const { setDateRange, setReportType, setDomain } = useReportsContext();
  const [search, setSearch] = useState<string | undefined>();

  const onChangeDateRange = useCallback(
    (from?: Date, to?: Date) => {
      setDateRange?.(() => ({ from, to }));
    },
    [setDateRange],
  );

  const onChangeReportType = useCallback(
    (value: ReportsTypes) => {
      setReportType?.(() => value);
    },
    [setReportType],
  );

  const onDomainChange = useCallback(
    (val: Domain | null) => {
      if (!val) {
        setDomain?.(() => undefined);
      } else {
        setDomain?.(() => val);
      }
    },
    [setDomain],
  );

  return {
    onChangeDateRange,
    onChangeReportType,
    onDomainChange,
    search,
    setSearch,
  };
};

export default useReportsHook;
