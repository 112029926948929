import {
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { defaults } from 'react-chartjs-2';

const initializeChartJS = () => {
  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  );
  defaults.font.family = 'Circular Pro Book';
  defaults.color = '#363636';
  defaults.backgroundColor = '#f8f9fb';
  return;
};

export default initializeChartJS;
