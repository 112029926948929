import { Box, ListItemIcon, Paper, Typography } from '@mui/material';
import styled from 'styled-components';
import { CloseIcon } from '../icons';

export const ImageWrapper = styled(Box)({
  width: 73,
  height: 104,
  background: '#F8F9FB',
  marginRight: 8,
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

export const DetailContainer = styled(Box)({
  width: 'calc(100% - 81px)',
});

export const ListContainer = styled(Paper)({
  backgroundColor: '#F1F3F6',
  padding: 20,
  '@media print': {
    'page-break-inside': 'avoid',
    position: 'relative',
    marginTop: 20,
    marginBottom: 20,
    top: 15,
  },
});

export const HorizontalLine = styled.hr({
  height: 1,
  backgroundColor: '#D5D8DF',
});

export const ListTitle = styled(Typography)({
  wordWrap: 'break-word',
  width: '80%',
  lineHeight: 1,
});

export const ListBox = styled.div({
  width: 20,
  height: 20,
  backgroundColor: '#C4C4C4',
});

export const ReportedItemIcon = styled(ListItemIcon)({
  minWidth: 'auto',
});

export const BreakWord = styled(Typography)({
  wordWrap: 'break-word',
  fontFamily: '"Circular Pro Book", "Noto Sans"',
});

export const EllipsisText = styled(Typography)({
  fontFamily: '"Circular Pro Book", "Noto Sans"',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const HighlightText = styled.span({
  color: '#EE9D00',
});

export const HighlightSecondaryText = styled.span({
  color: '#BA0C00',
});

export const CutWrapper = styled(Box)({
  backgroundColor: 'white',
  marginTop: 16,
  borderRadius: 2,
});

export const StyledCloseIcon = styled(CloseIcon)({
  display: 'inline-flex',
  textAlign: 'center',
  marginLeft: 10,
  marginTop: 5,
  height: '100%',
  ':hover': {
    cursor: 'pointer',
  },
});
