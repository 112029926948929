import styled from 'styled-components';
import { Grid } from '@mui/material';

export const CustomGrid = styled(Grid)({
  borderTop: '1px solid #D5D8DF',
  height: 40,
  display: 'flex',
  alignItems: 'center',
});

export const LeftGrid = styled(CustomGrid)({
  borderRight: '1px solid #D5D8DF',
});

export const SpaceGrid = styled(CustomGrid)({
  height: 20,
});
