import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const OptionIcon = (props: SvgIconProps) => (
  <SvgIcon width="13" height="4" viewBox="0 0 13 4" {...props}>
    <circle
      cx="11.375"
      cy="1.625"
      r="1.625"
      transform="rotate(90 11.375 1.625)"
      fill="#31AD34"
    />
    <circle
      cx="6.5"
      cy="1.625"
      r="1.625"
      transform="rotate(90 6.5 1.625)"
      fill="#31AD34"
    />
    <circle
      cx="1.625"
      cy="1.625"
      r="1.625"
      transform="rotate(90 1.625 1.625)"
      fill="#31AD34"
    />
  </SvgIcon>
);

export default OptionIcon;
