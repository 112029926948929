import { HorizontalFullHeightLoader } from '@fyeo-di-frontend/shared';
import { Box, Grid, Typography } from '@mui/material';
import chroma from 'chroma-js';
import React, { memo, useMemo } from 'react';
import * as StyledComponent from './Dashboard.styles';

interface OwnProps {
  data?: Record<string, Record<string, number>>;
  columns?: string[];
  isLoading: boolean;
  bg?: boolean;
}

const chromaScale = chroma.scale(['#006704', '#FFD700']);
const colorGenerator = (value: number) => chromaScale(value).hex();
const scaleDataForHeatMap = (data: number[], value: number) => {
  const max = Math.max(...data);
  const scaledValue = value / max;
  return scaledValue || 0;
};
const getTextColor = (hc: string) => {
  hc = hc.replace('#', '');
  const [r, g, b] = [0, 2, 4].map((p) => parseInt(hc.substring(p, p + 2), 16));
  return (r * 299 + g * 587 + b * 114) / 1000 >= 128;
};

interface IValue {
  actualValue: number;
  scaledValue: number;
}
const DashboardHeatMap: React.FC<OwnProps> = (props) => {
  const rowData = useMemo(() => {
    return Object.keys(props.data ?? {});
  }, [props.data]);

  const scaledData = useMemo(() => {
    const dataValues = Object.values(props.data ?? {});
    return dataValues.map((item) => {
      const obj: Record<string, IValue> = {};
      props?.columns?.forEach((c) => {
        obj[c] = {
          actualValue: item[c],
          scaledValue: scaleDataForHeatMap(
            dataValues.map((d) => Object.values(d)).flat(),
            item[c],
          ),
        };
      });
      return {
        ...obj,
      };
    });
  }, [props.data, props.columns]);

  return (
    <StyledComponent.WrappedPaper elevation={0} bg={props.bg}>
      {props.isLoading ? (
        <Box width="100%" height={400}>
          <HorizontalFullHeightLoader />
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          pt={2}
          pb={2}
          pl={4}
          pr={4}
          gap={0.2}
        >
          <Typography variant="h6" mb={6}>
            Number of incidents per type and case
          </Typography>
          <Grid container gap={0.625} height={120}>
            <Grid item xs={1.44} />
            {props?.columns?.map((column) => (
              <StyledComponent.TextGrid
                item
                xs={0.57}
                key={column}
                height="100%"
              >
                <StyledComponent.HeatMapHeaderText
                  variant="body1"
                  textAlign="center"
                >
                  {column}
                </StyledComponent.HeatMapHeaderText>
              </StyledComponent.TextGrid>
            ))}
            <Grid item xs={1.0} />
          </Grid>
          {scaledData.map((item, i) => (
            <Grid container key={i} gap={0.625} height={50}>
              <Grid item xs={1.44}>
                <Box
                  height="100%"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  pr={1}
                >
                  <StyledComponent.MultiLineTruncText
                    variant="body1"
                    textAlign="right"
                    title={rowData[i]}
                  >
                    {rowData[i]}
                  </StyledComponent.MultiLineTruncText>
                </Box>
              </Grid>
              {props?.columns?.map((column) => (
                <Grid item xs={0.57} key={column} height="100%">
                  <Box
                    bgcolor={colorGenerator(item[column].scaledValue)}
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    color={
                      getTextColor(colorGenerator(item[column].scaledValue))
                        ? 'black'
                        : 'white'
                    }
                  >
                    {item[column].actualValue.toFixed(0)}
                  </Box>
                </Grid>
              ))}
              <Grid item xs={1.44} />
            </Grid>
          ))}
        </Box>
      )}
    </StyledComponent.WrappedPaper>
  );
};

export default memo(DashboardHeatMap);
