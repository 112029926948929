import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import ReleasePost from './Release.md';
import React, { useEffect, useState } from 'react';
import { retrieveIdHash, storeIdHash } from '@fyeo-di-frontend/shared';
import { useAppContext } from '@root/App.context';
import styled from 'styled-components';
import MDEditor from '@uiw/react-md-editor';
import axios from 'axios';

const Markdown = styled(MDEditor.Markdown)({
  '&.wmde-markdown': {
    fontFamily: 'Circular Pro Book,Noto Sans',
  },
});

const ReleaseNotes = () => {
  const [open, setOpen] = useState(false);
  const { isUserAuthenticated } = useAppContext();

  useEffect(() => {
    if (isUserAuthenticated) {
      axios.get('/').then((res) => {
        const body = res.data as string;
        const hash = retrieveIdHash();
        const regex = /\/main\.(\w+)\.chunk\.js/;
        const match = body.match(regex);

        if (match) {
          const currentHash = match[1];
          if (hash !== currentHash) {
            storeIdHash(currentHash);
            setOpen(true);
          }
        }
      });
    }
  }, [isUserAuthenticated]);

  return open ? (
    <Dialog
      open={open}
      onClose={() => setOpen(() => false)}
      scroll="body"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box position="absolute" right={8} top={8} color="black">
          <IconButton
            onClick={() => setOpen(() => false)}
            color="inherit"
            data-cy="close-release"
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <div className="container" data-color-mode="light">
          <Markdown source={ReleasePost} />
        </div>
      </DialogContent>
    </Dialog>
  ) : null;
};

export default ReleaseNotes;
