import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Option } from '@root/common/Common.types';

interface OwnProps {
  value?: Option[];
  loading?: boolean;
  options?: Option[];
  onChange?: (value: Option[]) => void;
  label: string;
  disableDefaultFiltering?: boolean;
  setInputValue?: (value: string) => void;
  loadMoreResults?: () => void;
}

const IncidentMultiSelectLazyListComponent = (props: OwnProps) => {
  const [position, setPosition] = useState(0);
  const listElem = useRef<HTMLUListElement | null>(null);
  const mounted = useRef<boolean>();

  useEffect(() => {
    if (!mounted.current) mounted.current = true;
    else if (position && listElem.current)
      listElem.current.scrollTop = position - listElem.current.offsetHeight;
  });

  const handleScroll: React.UIEventHandler<HTMLUListElement> = (event) => {
    const listboxNode = event.currentTarget;

    const pos = listboxNode.scrollTop + listboxNode.clientHeight;
    setPosition(pos);
    if (listboxNode.scrollHeight - pos <= 1) {
      props.loadMoreResults?.();
    }
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" gap={1}>
      <Typography variant="subtitle2">{props.label}</Typography>
      <Box maxWidth={300}>
        <Autocomplete
          ListboxProps={
            {
              ref: listElem,
              onScroll: handleScroll,
            } as React.HTMLAttributes<HTMLUListElement>
          }
          onChange={(event, newValue) => {
            const typedValue = newValue as Option[];
            // computation to get unique array of objects based on a key(id in this case)
            const filtered = typedValue.filter(
              (
                (s) => (o) =>
                  ((k) => !s.has(k) && s.add(k))(o.id)
              )(new Set()),
            );

            //handle value change with the new array unique values
            props?.onChange?.(filtered as Option[]);
          }}
          onInputChange={(event, newInputValue) => {
            props?.setInputValue?.(newInputValue);
          }}
          options={props.loading ? [] : props?.options || []}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          loading={props.loading}
          multiple
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            );
          }}
          value={props.value}
          filterSelectedOptions={props.disableDefaultFiltering || undefined}
        />
      </Box>
    </Box>
  );
};

export default memo(IncidentMultiSelectLazyListComponent);
