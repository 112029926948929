import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const AgentIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 31 31" {...props}>
    <path
      d="M25.7378 0.466797H5.34812C2.697 0.466797 0.542969 2.62083 0.542969 5.27195V25.6616C0.542969 28.3128 2.697 30.4668 5.34812 30.4668H25.7378C28.3889 30.4668 30.543 28.3128 30.543 25.6616V5.27195C30.543 2.62083 28.3889 0.466797 25.7378 0.466797ZM5.34812 29.0676C3.47025 29.0676 1.94217 27.5395 1.94217 25.6616V5.27195C1.94217 4.55394 2.1723 3.88195 2.54972 3.33884L27.6801 28.4693C27.1278 28.8467 26.465 29.0768 25.747 29.0768H5.35733L5.34812 29.0676ZM29.1438 25.6616C29.1438 26.3152 28.9505 26.9228 28.6283 27.4475L3.57151 2.37229C4.087 2.0501 4.69455 1.85679 5.35733 1.85679H25.747C27.6249 1.85679 29.153 3.38487 29.153 5.26275V25.6616H29.1438Z"
      fill="#32AE34"
    />
    <path
      d="M19.3679 10.3643V15.6758L20.9236 17.2314V10.3367C21.8993 9.98689 22.5529 9.06636 22.5529 8.02617C22.5529 6.67299 21.4575 5.56836 20.1043 5.56836C18.7511 5.56836 17.6465 6.66379 17.6465 8.01696C17.6465 9.09398 18.3461 10.0513 19.3771 10.3643H19.3679ZM20.0859 6.54412C20.8959 6.54412 21.5587 7.19769 21.5587 8.01696C21.5587 8.83623 20.8959 9.48981 20.0859 9.48981C19.2758 9.48981 18.613 8.83623 18.613 8.01696C18.613 7.2069 19.2666 6.54412 20.0859 6.54412Z"
      fill="#32AE34"
    />
    <path
      d="M18.7505 21.952C18.1429 23.1763 17.0475 24.1061 15.2709 24.1061C12.767 24.1061 11.018 22.1914 10.8984 19.4298H12.4909L11.3034 17.377L10.1343 15.3242L8.95603 17.377L7.76855 19.4298H9.36106C9.47153 23.0474 11.9201 25.6525 15.2709 25.6525C17.2868 25.6525 18.9069 24.6952 19.8827 23.1027L18.7412 21.9612L18.7505 21.952Z"
      fill="#32AE34"
    />
  </SvgIcon>
);

export default AgentIcon;
