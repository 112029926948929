import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DeleteOrCancelComponent from './AssetTypes.deleteOrCancel.component';
import React, { memo } from 'react';
import { AssetDetails } from '@root/assets/Assets.types';

interface OwnProps {
  id?: string;
  onSave?: () => void;
  onClose?: () => void;
  onDeleteAsset?: () => void;
  isSaving?: boolean;
  isDeleting?: boolean;
  data?: AssetDetails;
  isThreatActor?: boolean;
}
const ButtonGroupComponent = (props: OwnProps) => {
  return (
    <>
      <Box
        pt={5}
        pb={6}
        pl={3}
        pr={3}
        gap={2}
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <Box width={150}>
            {!props.id ? (
              <LoadingButton
                variant="contained"
                fullWidth
                size="small"
                onClick={() => props?.onSave?.()}
                loading={props?.isSaving}
                disabled={props?.isDeleting}
                color={props?.isThreatActor ? 'secondary' : 'primary'}
              >
                Save
              </LoadingButton>
            ) : (
              <DeleteOrCancelComponent
                id={props.id}
                onDeleteAsset={props.onDeleteAsset}
                isSaving={props.isSaving}
                isDeleting={props.isDeleting}
                onClose={props.onClose}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default memo(ButtonGroupComponent);
