import {
  deleteIdToken,
  deleteRefreshToken,
  deleteRememberLogin,
} from '@fyeo-di-frontend/shared';
import { useAppContext } from '@root/App.context';
import { useCallback } from 'react';

const useLogoutHook = () => {
  const { setIdToken } = useAppContext();
  const handleLogout = useCallback(() => {
    setIdToken(null);
    deleteIdToken();
    deleteRefreshToken();
    deleteRememberLogin();
  }, [setIdToken]);
  return {
    handleLogout,
  };
};

export default useLogoutHook;
