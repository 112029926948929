import styled from 'styled-components';
import { Drawer, ListItemButton } from '@mui/material';

export const DrawerStyled = styled(Drawer)({
  '> .MuiPaper-root': {
    borderRight: 'none',
    marginTop: 64,
    paddingTop: 30,
    paddingBottom: 64,
    width: 176,
  },
});

export const ListItemButtonStyled = styled(ListItemButton)({
  width: '100%',
  '&.Mui-selected': {
    backgroundColor: '#F8F9FB',
  },
  paddingLeft: 0,
  paddingRight: 1,
  marginRight: 3,
  marginLeft: 1,
});
