import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import React, { memo } from 'react';
import { Option } from '@root/common/Common.types';

interface OwnProps {
  value?: Option[];
  loading?: boolean;
  options?: Option[];
  onChange?: (value: Option[]) => void;
  label: string;
  disableDefaultFiltering?: boolean;
  setInputValue?: (value: string) => void;
}

const IncidentMultiSelectComponent = (props: OwnProps) => {
  return (
    <Box width="100%" display="flex" flexDirection="column" gap={1}>
      <Typography variant="subtitle2">{props.label}</Typography>
      <Box maxWidth={300}>
        <Autocomplete
          onChange={(event, newValue) => {
            const typedValue = newValue as Option[];
            // computation to get unique array of objects based on a key(id in this case)
            const filtered = typedValue.filter(
              (
                (s) => (o) =>
                  ((k) => !s.has(k) && s.add(k))(o.id)
              )(new Set()),
            );

            //handle value change with the new array unique values
            props?.onChange?.(filtered as Option[]);
          }}
          onInputChange={(event, newInputValue) => {
            props?.setInputValue?.(newInputValue);
          }}
          options={props.loading ? [] : props?.options || []}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          loading={props.loading}
          multiple
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            );
          }}
          value={props.value}
          filterSelectedOptions={props.disableDefaultFiltering || undefined}
        />
      </Box>
    </Box>
  );
};

export default memo(IncidentMultiSelectComponent);
