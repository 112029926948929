import { Box, Button, FormControl } from '@mui/material';
import styled from 'styled-components';
import AutoSaveIcon from '@fyeo-di-frontend/shared/src/icons/AutoSave.icon';

export const AssetTypesRoot = styled.div({
  maxWidth: 400,
  overflowY: 'auto',
  overflowX: 'hidden',
  height: 'calc(100vh - 64px)',
  paddingBottom: 16,
});

export const AssetTypesContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 8,
  paddingLeft: 24,
  paddingRight: 24,
});

export const Banner = styled(Box)({
  width: '100%',
  height: 35,
  minHeight: 35,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#6D6D6D',
});

export const EndBadge = styled(Button)({
  height: 20,
});

export const CursorBox = styled(Box)({
  cursor: 'pointer',
});

export const WrappedBox = styled(Box)({
  '.MuiInputBase-input': {
    paddingTop: 5,
    paddingBottom: 5,
    height: 28,
    maxHeight: 28,
  },
});

export const DivWrapper = styled(Box)({
  backgroundColor: '#F1F3F6',
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 4,
  borderColor: '#F1F3F6',
  border: 1,
});

export const ItemWrapper = styled(Box)({
  display: 'flex',
  gap: 8,
  textAlign: 'left',
  alignItems: 'center',
});

export const AutoSaveStill = styled(AutoSaveIcon)({
  height: 26,
  width: 56,
});

export const CustomSelect = styled(Box)({
  '.MuiInputBase-inputSizeSmall': {
    width: 200,
  },
});

export const CustomEm = styled.em({
  color: '#7F8998',
  opacity: 0.5,
});

export const BorderedFormControlWrapper = styled(FormControl)({
  '.MuiOutlinedInput-root fieldset': {
    borderStyle: 'solid',
  },
});

export const CaseText = styled(Box)({
  position: 'absolute',
  right: 30,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
