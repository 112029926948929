import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { LoginRoutes } from '@root/common/Routes';
import React, { useMemo, useState } from 'react';
import { Link as RLink } from 'react-router-dom';
import * as StyledComponents from '../login/Login.styles';
import Root from '../Root.component';
import {
  SignupContextProvider,
  useSignupContext,
  useSignupContextModel,
} from './Signup.context';
import useSignupHook from './Signup.hooks';
import validator from 'validator';
import zxcvbn from 'zxcvbn';
import AuthPasswordComponent from '@root/auth/Auth.password.component';

const Signup = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const {
    firstname,
    lastname,
    email,
    password,
    confirmPassword,
    onChangePassword,
    onChangeFirstname,
    onChangeLastname,
    onChangeEmail,
    onChangeConfirmPassword,
    readTermsAndConditions,
    onChangeTermsAndConditions,
  } = useSignupContext();
  const {
    isSignupInProgress,
    onSignup,
    onCheckEmail,
    isEmailCheckInProgress,
    validEmail,
  } = useSignupHook();

  const strength = useMemo(() => {
    return zxcvbn(password).score;
  }, [password]);

  const disableSignup =
    !validEmail ||
    !firstname.trim?.() ||
    !lastname.trim?.() ||
    !email.trim?.() ||
    !validator.isEmail(email?.trim?.()) ||
    !password?.trim?.() ||
    !confirmPassword.trim?.() ||
    password?.trim?.() !== confirmPassword?.trim?.() ||
    strength < 3 ||
    !readTermsAndConditions ||
    isSignupInProgress;

  return (
    <Root>
      <StyledComponents.Root>
        <Box textAlign="center">
          <Typography variant="h6">Sign up</Typography>
        </Box>
        <Box display="flex" gap={2} justifyContent="center">
          <Typography variant="subtitle2">Already have an account?</Typography>
          <Link
            component={RLink}
            to={LoginRoutes.Root}
            underline="none"
            variant="subtitle2"
          >
            Login
          </Link>
        </Box>
        <Box textAlign="center" ml={15} mr={15}>
          <TextField
            placeholder="Work email"
            onChange={(evt) => onChangeEmail(evt?.target?.value)}
            onBlur={onCheckEmail}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {isEmailCheckInProgress && <CircularProgress size={20} />}
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box textAlign="center" ml={15} mr={15}>
          <TextField
            placeholder="First name"
            onChange={(evt) => onChangeFirstname(evt?.target?.value)}
          />
        </Box>
        <Box textAlign="center" ml={15} mr={15}>
          <TextField
            placeholder="Last name"
            onChange={(evt) => onChangeLastname(evt?.target?.value)}
          />
        </Box>
        <Box textAlign="center" ml={15} mr={15}>
          <TextField
            placeholder="Password"
            type={showPassword ? 'text' : 'password'}
            onChange={(evt) => onChangePassword(evt?.target?.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={() => setShowPassword((old) => !old)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box textAlign="center" ml={15} mr={15}>
          <TextField
            placeholder="Confirm password"
            type={showConfirmPassword ? 'text' : 'password'}
            error={password?.trim?.() !== confirmPassword?.trim?.()}
            helperText={
              password?.trim?.() !== confirmPassword?.trim?.()
                ? "Passwords don't match!"
                : undefined
            }
            onChange={(evt) => onChangeConfirmPassword(evt?.target?.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => setShowConfirmPassword((old) => !old)}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box textAlign="center" ml={15} mr={15} mb={3.75}>
          <AuthPasswordComponent strength={strength} />
        </Box>
        <Box ml={15} mr={15} mb={-1.25}>
          <FormControlLabel
            control={
              <Checkbox
                checked={readTermsAndConditions}
                value={readTermsAndConditions}
                onChange={(_, checked) => onChangeTermsAndConditions(checked)}
              />
            }
            label={
              <Typography variant="subtitle2">
                I have read and agree to the{' '}
                <Link
                  underline="none"
                  href="https://www.gofyeo.com/terms-and-conditions"
                  target="_blank"
                >
                  terms of use.
                </Link>
              </Typography>
            }
            disableTypography
            labelPlacement="end"
          ></FormControlLabel>
        </Box>
        <Box textAlign="center" ml={15} mr={15}>
          <LoadingButton
            variant="contained"
            fullWidth
            size="medium"
            disabled={disableSignup}
            onClick={onSignup}
            loading={isSignupInProgress}
          >
            Sign up
          </LoadingButton>
        </Box>
      </StyledComponents.Root>
    </Root>
  );
};

const SignupWithContext = () => {
  const contextData = useSignupContextModel();
  return (
    <SignupContextProvider value={contextData}>
      <Signup />
    </SignupContextProvider>
  );
};

export default SignupWithContext;
