import { LoadingButton } from '@mui/lab';
import { Box, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as StyledComponents from '../login/Login.styles';
import useResetPasswordHook from './ResetPassword.hooks';
import Root from '../Root.component';

const Login = () => {
  const { email, onChangeEmail, isError, isLoading, onReset } =
    useResetPasswordHook();
  const navigate = useNavigate();
  const disableLogin = !email?.trim?.() || isLoading;
  return (
    <Root>
      <StyledComponents.Root>
        <Box textAlign="center">
          <Typography variant="h6">Reset password</Typography>
        </Box>
        {isError && (
          <Box textAlign="left" ml={15} mr={15}>
            <Typography variant="caption" color="error">
              Failed to reset password
            </Typography>
          </Box>
        )}
        <Box textAlign="center" ml={15} mr={15}>
          <TextField
            placeholder="Work email"
            onChange={(evt) => onChangeEmail(evt.target.value)}
          />
        </Box>
        <Box textAlign="center" ml={15} mr={15}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <LoadingButton
                variant="contained"
                fullWidth
                size="medium"
                color="secondary"
                onClick={() => navigate(-1)}
              >
                Back
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <LoadingButton
                variant="contained"
                fullWidth
                size="medium"
                disabled={disableLogin}
                onClick={onReset}
                loading={isLoading}
              >
                Next
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </StyledComponents.Root>
    </Root>
  );
};

export default Login;
