import { HorizontalFullHeightLoader } from '@fyeo-di-frontend/shared';
import { Box, Link } from '@mui/material';
import range from 'lodash/range';
import React, { memo } from 'react';
import * as StyledComponents from './Events.styles';

const EventsListLoaderComponent = React.memo(
  ({ count = 3 }: { count?: number }) => {
    return (
      <>
        {range(0, count).map((e, i) => (
          <StyledComponents.ListContainer elevation={0} key={i}>
            <StyledComponents.ImageWrapper>
              <Box height="100%" width="100%">
                <HorizontalFullHeightLoader />
              </Box>
            </StyledComponents.ImageWrapper>
            <Box>
              <Box
                display="flex"
                flexWrap="nowrap"
                gap={0.5}
                alignItems="center"
                justifyContent="space-between"
                mb={2}
              >
                <Box height={50} width={100}>
                  <HorizontalFullHeightLoader />
                </Box>
                <Box height={50} width={100}>
                  <HorizontalFullHeightLoader />
                </Box>
              </Box>
              <Link
                typography={{
                  variant: 'subtitle1',
                }}
              >
                <Box height={50}>
                  <HorizontalFullHeightLoader />
                </Box>
              </Link>
              <Box display="flex" gap={0.7}>
                <Link
                  typography={{
                    variant: 'subtitle1',
                  }}
                >
                  <Box height={50}>
                    <HorizontalFullHeightLoader />
                  </Box>
                </Link>
              </Box>
              <Box height={50}>
                <HorizontalFullHeightLoader />
              </Box>
            </Box>
          </StyledComponents.ListContainer>
        ))}
      </>
    );
  },
);

EventsListLoaderComponent.displayName = 'EventsListLoaderComponent';

export default memo(EventsListLoaderComponent);
