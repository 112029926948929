import { CloseIcon } from '@fyeo-di-frontend/shared';
import { Box, ListItemButton, ListItemIcon } from '@mui/material';
import styled from 'styled-components';

export const Root = styled.div({
  paddingTop: 70,
  paddingLeft: 400,
  paddingRight: 400,
  display: 'flex',
  marginBottom: 20,
});

export const EntryRoot = styled.div({
  paddingLeft: 400,
  paddingRight: 400,
  marginTop: 10,
  marginBottom: 20,
});

export const Container = styled.div`
  overflow-y: scroll;
  height: 100vh;
`;

export const Header = styled(Box)({
  height: 468,
  position: 'relative',
  paddingTop: 30,
  paddingLeft: 36,
  width: '100%',
  background: 'linear-gradient(89.78deg, #32AE34 0%, #FAC705 100%);',
});

export const Background = styled(Box)({
  position: 'absolute',
  top: 74,
  right: 0,
});

export const ReportDetailsWrapper = styled(Box)({
  display: 'flex',
  paddingLeft: 364,
  paddingRight: 400,
  paddingTop: 80,
  color: 'white',
  flexDirection: 'column',
  '.MuiTypography-subtitle1': {
    lineHeight: 1.3,
  },
});

export const IncidentsWrapper = styled(Box)({
  background: '#F8F9FB',
  borderRadius: 7,
  padding: 16,
  width: '100%',
});

export const ReportedItemIcon = styled(ListItemIcon)({
  minWidth: 'auto',
  marginRight: '12px',
});

export const CustomListItemButton = styled(ListItemButton)({
  ':hover': {
    cursor: 'default',
    backgroundColor: 'white',
  },
});

export const CursorBox = styled(Box)({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const IncidentTextGroup = styled(Box)({
  '.MuiTypography-subtitle1': {
    lineHeight: 1.3,
  },
});

export const SeverityBox = styled(Box)<{ severityColor: string[] }>(
  (props) => ({
    border: `1px solid ${props.severityColor[0]}`,
    boxSizing: 'border-box',
    borderRadius: 2,
    color: `${props.severityColor[0]}`,
    backgroundColor: props.severityColor[1],
    paddingRight: 5,
    paddingLeft: 5,
  }),
);

export const StyledCloseIcon = styled(CloseIcon)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '-1px',
});
