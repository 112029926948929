import { useAppContext } from '@root/App.context';
import useLogoutHook from '@root/common/Logout.hooks';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useCurrentURL from '../common/Storage.hooks';
import { tokenToJson } from '@root/common/Jwt';
import HeaderComponent from '@root/header/Header.component';

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isUserAuthenticated } = useAppContext();
  const { handleLogout } = useLogoutHook();
  const shouldDeleteStoredURL = useCurrentURL();
  const { idToken } = useAppContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [name, setName] = useState('');
  const [openSupport, setOpenSupport] = useState(false);
  useEffect(() => {
    if (idToken) {
      const { given_name, family_name } = tokenToJson(idToken);
      setName(() => `${given_name} ${family_name}`);
    }
  }, [idToken]);

  if (shouldDeleteStoredURL) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <HeaderComponent
      open={open}
      name={name}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      handleLogout={handleLogout}
      isUserAuthenticated={isUserAuthenticated}
      openSupport={openSupport}
      setOpenSupport={setOpenSupport}
      pathname={pathname}
      push={navigate}
    />
  );
};

export default Header;
