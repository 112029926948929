type TEnv = 'dev' | 'stage' | 'prod' | 'localhost' | undefined;

export const getEnvironment = (): TEnv => {
  const hostName = window?.location?.hostname?.trim?.()?.toLowerCase() || '';
  let env: TEnv = undefined;
  if (
    hostName.includes('dev.fyeo-di.gofyeo.com') ||
    process.env.REACT_APP_STAGE === 'dev'
  ) {
    env = 'dev';
  } else if (
    hostName.includes('stage.fyeo-di.gofyeo.com') ||
    process.env.REACT_APP_STAGE === 'stage'
  ) {
    env = 'stage';
  } else if (hostName.includes('fyeo-di.gofyeo.com')) {
    env = 'prod';
  } else if (hostName.includes('localhost')) {
    env = 'localhost';
  }
  return env;
};

/**
 * process.env.NODE_ENV can only have two values - development or production
 * However we have different types of env - dev, stage, prod
 */
export const isLocalHost = () => getEnvironment() === 'localhost';
export const isStaging = () => getEnvironment() === 'stage';
export const isProd = () => getEnvironment() === 'prod';
export const isDev = () => getEnvironment() === 'dev';

export const getApiEndpoint = () => {
  // let endpoint = "http://localhost:3001/dev";
  if (isProd()) {
    return '/prod';
  } else if (isStaging()) {
    return '/stage';
  }
  return '/dev';
};
