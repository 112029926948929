import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const Elevated: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 59 23" {...props}>
    <path
      d="M0 2C0 0.89543 0.895431 0 2 0H57C58.1046 0 59 0.895431 59 2V21C59 22.1046 58.1046 23 57 23H2C0.89543 23 0 22.1046 0 21V2Z"
      fill="#F9F2C9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57 1H2C1.44771 1 1 1.44772 1 2V21C1 21.5523 1.44772 22 2 22H57C57.5523 22 58 21.5523 58 21V2C58 1.44771 57.5523 1 57 1ZM2 0C0.895431 0 0 0.89543 0 2V21C0 22.1046 0.89543 23 2 23H57C58.1046 23 59 22.1046 59 21V2C59 0.895431 58.1046 0 57 0H2Z"
      fill="#E8BD15"
    />
    <path
      d="M11.8229 15.9995V14.6555H8.00689V12.3995H11.4629V11.0915H8.00689V8.83552H11.8229V7.49152H6.56689V15.9995H11.8229Z"
      fill="#E8BD15"
    />
    <path
      d="M14.8953 15.9995V7.31152H13.5033V15.9995H14.8953Z"
      fill="#E8BD15"
    />
    <path
      d="M17.7348 12.4355C17.7828 11.8115 18.2868 11.1395 19.1628 11.1395C20.1228 11.1395 20.5788 11.7515 20.6028 12.4355H17.7348ZM20.7588 13.9595C20.5668 14.5355 20.1348 14.9795 19.2948 14.9795C18.4308 14.9795 17.7348 14.3435 17.6988 13.4555H21.9708C21.9828 13.4315 22.0068 13.2395 22.0068 13.0115C22.0068 11.1635 20.9508 9.97552 19.1508 9.97552C17.6748 9.97552 16.3068 11.1995 16.3068 13.0475C16.3068 15.0275 17.7108 16.1795 19.2948 16.1795C20.6988 16.1795 21.6228 15.3515 21.9348 14.3315L20.7588 13.9595Z"
      fill="#E8BD15"
    />
    <path
      d="M28.5718 10.1555H27.1078L25.5958 14.3315L24.0478 10.1555H22.5118L24.8878 15.9995H26.2798L28.5718 10.1555Z"
      fill="#E8BD15"
    />
    <path
      d="M29.2733 14.4155C29.2733 15.3395 30.0293 16.1795 31.2533 16.1795C32.2013 16.1795 32.7773 15.6995 33.0533 15.2555C33.0533 15.7235 33.1013 15.9635 33.1133 15.9995H34.4093C34.3973 15.9395 34.3373 15.5915 34.3373 15.0635V12.1595C34.3373 10.9955 33.6533 9.97552 31.8533 9.97552C30.4133 9.97552 29.5013 10.8755 29.3933 11.8835L30.6653 12.1715C30.7253 11.5835 31.1333 11.1155 31.8653 11.1155C32.6333 11.1155 32.9693 11.5115 32.9693 12.0035C32.9693 12.2075 32.8733 12.3755 32.5373 12.4235L31.0373 12.6515C30.0413 12.7955 29.2733 13.3715 29.2733 14.4155ZM31.5173 15.0875C30.9653 15.0875 30.6653 14.7275 30.6653 14.3315C30.6653 13.8515 31.0133 13.6115 31.4453 13.5395L32.9693 13.3115V13.5755C32.9693 14.7155 32.2973 15.0875 31.5173 15.0875Z"
      fill="#E8BD15"
    />
    <path
      d="M37.8326 8.36752H36.5726V9.24352C36.5726 9.75952 36.2966 10.1555 35.6726 10.1555H35.3726V11.3915H36.4526V14.3315C36.4526 15.4235 37.1246 16.0715 38.2046 16.0715C38.7086 16.0715 38.9726 15.9755 39.0566 15.9395V14.7875C38.9966 14.7995 38.7686 14.8355 38.5766 14.8355C38.0606 14.8355 37.8326 14.6195 37.8326 14.1035V11.3915H39.0446V10.1555H37.8326V8.36752Z"
      fill="#E8BD15"
    />
    <path
      d="M41.4603 12.4355C41.5083 11.8115 42.0123 11.1395 42.8883 11.1395C43.8483 11.1395 44.3043 11.7515 44.3283 12.4355H41.4603ZM44.4843 13.9595C44.2923 14.5355 43.8603 14.9795 43.0203 14.9795C42.1563 14.9795 41.4603 14.3435 41.4243 13.4555H45.6963C45.7083 13.4315 45.7323 13.2395 45.7323 13.0115C45.7323 11.1635 44.6763 9.97552 42.8763 9.97552C41.4003 9.97552 40.0323 11.1995 40.0323 13.0475C40.0323 15.0275 41.4363 16.1795 43.0203 16.1795C44.4243 16.1795 45.3483 15.3515 45.6603 14.3315L44.4843 13.9595Z"
      fill="#E8BD15"
    />
    <path
      d="M48.0705 13.0595C48.0705 11.9435 48.7425 11.2355 49.6545 11.2355C50.5665 11.2355 51.1905 11.9315 51.1905 13.0475C51.1905 14.1635 50.5545 14.9195 49.6425 14.9195C48.6945 14.9195 48.0705 14.1755 48.0705 13.0595ZM52.5345 7.31152H51.1785V10.8395C50.9985 10.4675 50.4825 9.99952 49.4625 9.99952C47.8185 9.99952 46.6785 11.3795 46.6785 13.0595C46.6785 14.8235 47.8065 16.1435 49.4865 16.1435C50.3145 16.1435 50.9265 15.7355 51.2025 15.2435C51.2025 15.4835 51.2385 15.8675 51.2625 15.9995H52.5945C52.5825 15.9035 52.5345 15.4595 52.5345 14.9315V7.31152Z"
      fill="#E8BD15"
    />
  </SvgIcon>
);

export default Elevated;
