import * as StyledComponents from './ApiAccess.styles';
import { Box, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { CopyIcon } from '@fyeo-di-frontend/shared';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import ConfirmDialog from './ApiAccess.confirmDialog.component';

interface OwnProps {
  url?: string;
  token?: string;
  apiKey?: string;
  isCopied: boolean;
  handleCopyClick: (text: string) => void;
  isGenerating: boolean;
  onGenerate: () => void;
  onCreateWebhook: (value: { url: string; token: string }) => void;
  isCreatingWebhook: boolean;
  createdWebhook: boolean;
  setCreatedWebhook: (value: boolean) => void;
  selectedCases: string[];
}
const WebhookApiKey = ({
  url,
  token,
  apiKey,
  isCopied,
  handleCopyClick,
  isGenerating,
  onGenerate,
  selectedCases,
  onCreateWebhook,
  isCreatingWebhook,
  createdWebhook,
  setCreatedWebhook,
}: OwnProps) => {
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [webhook, setWebhook] = useState({
    url: '',
    token: '',
  });

  useEffect(() => {
    if (url && token) {
      setWebhook({
        url: url,
        token: token,
      });
    }
  }, [url, token]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (createdWebhook) {
      timeout = setTimeout(() => {
        setCreatedWebhook(false);
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [createdWebhook, setCreatedWebhook]);

  const disabled = useMemo(() => {
    return (
      !webhook.url.trim() ||
      !webhook.token.trim() ||
      ((url || '') === webhook.url && (token || '') === webhook.token)
    );
  }, [url, webhook, token]);

  return (
    <StyledComponents.Block>
      <Grid container spacing={0}>
        <StyledComponents.RightBorderGrid
          item
          xs={7}
          pl={1.125}
          pr={3.1255}
          py={1}
        >
          <Typography variant="h6">Webhook</Typography>
          <Typography variant="subtitle2">
            Webhooks enable you to send notifications to web applications in
            response to events in a group or projects.{' '}
          </Typography>
          <Typography mt={2}>URL</Typography>
          <TextField
            fullWidth
            onChange={(evt) => {
              setWebhook((prev) => ({ ...prev, url: evt.target.value }));
            }}
            value={webhook.url}
          />
          <Typography mt={2}>Secret token</Typography>
          <TextField
            fullWidth
            onChange={(evt) => {
              setWebhook((prev) => ({ ...prev, token: evt.target.value }));
            }}
            value={webhook.token}
          />
          <Box display="flex" gap={2} alignItems="center" mt={2}>
            <Box width={200}>
              <LoadingButton
                variant="contained"
                fullWidth
                size="medium"
                loading={isCreatingWebhook}
                onClick={() =>
                  onCreateWebhook({ url: webhook.url, token: webhook.token })
                }
                data-cy="create-webhook-btn"
                disabled={disabled}
              >
                Create webhook
              </LoadingButton>
            </Box>
            {createdWebhook && (
              <Typography variant="subtitle2">Webhook created!</Typography>
            )}
          </Box>
        </StyledComponents.RightBorderGrid>
        <Grid item xs={5} pl={3.1255} pr={1.125} py={1}>
          <Typography variant="h6">API Key</Typography>
          {apiKey ? (
            <StyledComponents.APIKeyBox>
              <Typography
                variant="subtitle2"
                fontSize={18}
                data-cy="api-key-visible"
              >
                {apiKey}
              </Typography>
              <Tooltip
                title={!isCopied ? 'Copy' : 'Copied to clipboard!'}
                placement="top"
              >
                <StyledComponents.CopyIconButton>
                  <CopyIcon
                    fontSize="inherit"
                    onClick={() => handleCopyClick(apiKey)}
                  />
                </StyledComponents.CopyIconButton>
              </Tooltip>
            </StyledComponents.APIKeyBox>
          ) : (
            <Typography variant="subtitle2" mt={2}>
              You don’t have an API key yet. Generate an API key for this
              account.
            </Typography>
          )}

          <StyledComponents.HiddenEl data-cy="api-key">
            {apiKey}
          </StyledComponents.HiddenEl>
          <Box width={200}>
            <LoadingButton
              variant="contained"
              fullWidth
              size="medium"
              loading={isGenerating}
              onClick={() => setConfirmation(true)}
              data-cy="generate-key-btn"
              disabled={!selectedCases.length}
            >
              {apiKey ? 'Generate another' : 'Generate'}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
      <ConfirmDialog
        confirmation={confirmation}
        setConfirmation={setConfirmation}
        onGenerate={onGenerate}
      />
    </StyledComponents.Block>
  );
};

export default WebhookApiKey;
