import {
  AssetTypeRender,
  CloseIcon,
  determineAssetNameFromResponse,
} from '@fyeo-di-frontend/shared';
import {
  Box,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import * as StyledComponents from './Periodic.styles';

interface DefaultProps<K> {
  assetType?: K | null;
  isThreatActor?: boolean | null;
  _id?: string;
}

interface OwnProps<T> {
  items: T[];
  label: string;
  origin?: string;
}
function PeriodicReportTargetSourceComponent<T extends DefaultProps<K>, K>(
  props: OwnProps<T>,
) {
  return (
    <>
      <>
        <Typography variant="subtitle1" fontSize={14}>
          {props.label}:{' '}
        </Typography>
        <Box bgcolor="#FFF" borderRadius={0.375} minHeight={30}>
          {props.items.map((item, i) => (
            <List dense key={i} disablePadding>
              <ListItem disablePadding>
                <StyledComponents.CustomListItemButton>
                  <Box display="flex" alignItems="center" width="100%">
                    <StyledComponents.ReportedItemIcon>
                      <AssetTypeRender<K>
                        assetType={item?.assetType}
                        size="large"
                        isThreatActor={item?.isThreatActor || false}
                      />
                    </StyledComponents.ReportedItemIcon>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <ListItemText
                        primary={determineAssetNameFromResponse<T, K>(item)}
                        primaryTypographyProps={{
                          variant: 'body1',
                        }}
                      />
                      <Link
                        href={
                          props.origin
                            ? `${props.origin}/assets/${item?._id}`
                            : `/assets/${item?._id}`
                        }
                        target="_blank"
                      >
                        <StyledComponents.CursorBox fontSize={20}>
                          <CloseIcon color="primary" fontSize="inherit" />
                        </StyledComponents.CursorBox>
                      </Link>
                    </Box>
                  </Box>
                </StyledComponents.CustomListItemButton>
              </ListItem>
            </List>
          ))}
        </Box>
      </>
    </>
  );
}

export default PeriodicReportTargetSourceComponent;
