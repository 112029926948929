import { Box, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';
import { Status } from '@fyeo-di-frontend/shared';

interface OwnProps {
  value?: Status;
  onChangeStatus?: (val?: Status) => void;
}

const StatusFilterComponent = (props: OwnProps) => {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="subtitle2">Status</Typography>
      <Box maxWidth={300} width="100%">
        <Box minWidth={120}>
          <Select<Status>
            onChange={(evt) =>
              props?.onChangeStatus?.(evt.target.value as Status)
            }
            size="small"
            fullWidth
            value={props?.value}
          >
            <MenuItem value={undefined} dense></MenuItem>
            <MenuItem value={Status.Open} dense>
              Open
            </MenuItem>
            <MenuItem value={Status.Closed} dense>
              Close
            </MenuItem>
          </Select>
        </Box>
      </Box>
    </Box>
  );
};

export default StatusFilterComponent;
