import {
  generatePrimaryColor,
  HorizontalFullHeightLoader,
  generateJSX,
  StyledComponentsShared as StyledComponents,
  QuestionIcon,
} from '@fyeo-di-frontend/shared';
import { Box, Typography } from '@mui/material';
import range from 'lodash/range';
import React, { memo } from 'react';
import { Line } from 'react-chartjs-2';
import { RIncidentsByIncidentType } from '@root/dashboard/Dashboard.types';

interface OwnProps {
  data?: RIncidentsByIncidentType[];
  isLoading: boolean;
  onTypeClick?: (incidentType: RIncidentsByIncidentType) => void;
}

const DashboardIncidentTypes = (props: OwnProps) => {
  const generateDataset = (item: RIncidentsByIncidentType) => ({
    data: item?.trendData,
    lineTension: 0.3,
    borderWidth: 2,
    borderColor: generatePrimaryColor(item.severity),
  });

  const dataLessThanTen =
    !props.isLoading && props.data?.length && props.data?.length < 10;
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Typography variant="h6">
        Top incident types by severity (30 days)
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={dataLessThanTen ? 'flex-start' : 'space-between'}
        gap={dataLessThanTen ? 1.5 : undefined}
        mt={2}
        height="100%"
      >
        <StyledComponents.HeaderRow elevation={0}>
          <Box width={192}>
            <Typography variant="subtitle1">
              <strong>Incident Type</strong>
            </Typography>
          </Box>
          <Box textAlign="center" width={55}>
            <Typography variant="subtitle1">
              <strong>Count</strong>
            </Typography>
          </Box>
          <Box textAlign="center" width={99}>
            <Typography variant="subtitle1">
              <strong>Severity</strong>
            </Typography>
          </Box>
          <Box textAlign="center" width={99}>
            <Typography variant="subtitle1">
              <strong>Trend</strong>
            </Typography>
          </Box>
        </StyledComponents.HeaderRow>
        {props.isLoading
          ? range(0, 10).map((i) => (
              <StyledComponents.LoaderRow key={i} elevation={0}>
                <Box width="100%" height={45}>
                  <HorizontalFullHeightLoader />
                </Box>
              </StyledComponents.LoaderRow>
            ))
          : props.data?.map((item) => (
              <StyledComponents.Row
                key={item.id}
                elevation={0}
                onClick={() =>
                  props.onTypeClick?.(item as RIncidentsByIncidentType)
                }
                className={props.onTypeClick && 'active-cursor'}
              >
                <Box width={192} display="flex" alignItems="center" gap={2}>
                  <StyledComponents.CustomTooltip
                    disableFocusListener
                    disableTouchListener
                    title={item.dashboardTip}
                    placement="right-start"
                  >
                    <Box display="flex" alignItems="center" fontSize={12}>
                      <QuestionIcon fontSize="inherit" />
                    </Box>
                  </StyledComponents.CustomTooltip>
                  <Typography variant="subtitle2">
                    {item.incidentType}
                  </Typography>
                </Box>
                <Box textAlign="center" width={55}>
                  <Typography variant="subtitle2">{item.count}</Typography>
                </Box>
                <Box
                  textAlign="center"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width={99}
                >
                  <Box>{generateJSX(item.severity)}</Box>
                </Box>
                <Box width={99} textAlign="center">
                  <Line
                    data={{
                      labels: item?.trendData?.map((_, index) => index),
                      datasets: [generateDataset(item)],
                    }}
                    options={{
                      // responsive: true,
                      elements: {
                        point: {
                          radius: 0,
                        },
                      },
                      plugins: {
                        tooltip: {
                          enabled: false,
                        },
                        legend: {
                          display: false,
                        },
                      },
                      scales: {
                        x: {
                          display: false,
                        },
                        y: {
                          display: false,
                        },
                      },
                    }}
                    height={50}
                  />
                </Box>
              </StyledComponents.Row>
            ))}
      </Box>
    </Box>
  );
};

export default memo(DashboardIncidentTypes);
