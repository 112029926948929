import React from 'react';
import { Pills, Status } from '@fyeo-di-frontend/shared';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import { useIncidentsContext } from '@root/incidents/Incidents.context';
import useIncidentsFiltersHook from '@root/incidents/Incidents.filters.hooks';

interface OwnProps {
  clickAction?: () => void;
}

const PillsContainer = ({ clickAction }: OwnProps) => {
  const {
    dateRange,
    severity,
    incidentSearchText,
    reported,
    statuses,
    incidentsTypes,
    assets,
    assetTypes,
  } = useIncidentsContext();
  const {
    onRemoveSeverity,
    onChangeIncidentSearchText,
    onReportedOrNotReported,
    onOpenOrCloseStatus,
    onRemoveIncidentType,
    onRemoveAsset,
    onRemoveAssetTypes,
  } = useIncidentsFiltersHook();

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      gap={1}
      data-cy="filter-pills"
    >
      <Pills
        title="Date"
        label={`${dayjs(dateRange?.from).format('YYYY-MM-DD')} – ${dayjs(
          dateRange?.to,
        ).format('YYYY-MM-DD')}`}
        onClick={() => {
          clickAction?.();
        }}
      />

      {severity?.map((s) => (
        <Pills
          key={s.id}
          label={s.name}
          severity={s.id}
          onClick={() => onRemoveSeverity(s)}
        />
      ))}
      {incidentSearchText && (
        <Pills
          title="Search"
          label={incidentSearchText}
          onClick={() => onChangeIncidentSearchText('')}
        />
      )}
      {reported?.some((r) => r.id) && (
        <Pills label="Reported" onClick={() => onReportedOrNotReported(true)} />
      )}
      {reported?.some((r) => !r.id) && (
        <Pills
          label="Not Reported"
          onClick={() => onReportedOrNotReported(false)}
        />
      )}
      {statuses === Status.Open && (
        <Pills
          title="Status"
          label="Open"
          onClick={() => onOpenOrCloseStatus()}
        />
      )}
      {statuses === Status.Closed && (
        <Pills
          title="Status"
          label="Close"
          onClick={() => onOpenOrCloseStatus()}
        />
      )}
      {incidentsTypes?.map((i) => (
        <Pills
          key={i.id}
          label={i.name}
          title="Types"
          onClick={() => onRemoveIncidentType(i)}
        />
      ))}
      {assets?.map((i) => (
        <Pills
          key={i.id}
          label={i.name}
          title="Assets"
          onClick={() => onRemoveAsset(i)}
        />
      ))}
      {assetTypes?.map((i) => (
        <Pills
          key={i.id}
          label={i.name}
          title="Asset Types"
          onClick={() => onRemoveAssetTypes(i)}
        />
      ))}
    </Box>
  );
};

export default PillsContainer;
