import { Close } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/lab';
import DateDayjsAdapter from '@mui/lab/AdapterDayjs';
import { Box, IconButton } from '@mui/material';
import { Chart } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import React, { Suspense, useMemo } from 'react';
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { Provider } from 'urql';
import {
  AppContextProvider,
  useAppContext,
  useAppContextModel,
} from './App.context';
import Assets from './assets';
import {
  Account,
  ApiAccess,
  EditTrendsRoot,
  ResetPassword as AccountResetPassword,
  ResetPasswordResponse,
} from './account';
import { Cases, CasesContextProvider, useCasesContextModel } from './cases';
import initializeChartJS from './ChartJS.init';
import Loader from './loader/Loader.container';
import PrivateRoute from './common/PrivateRoute';
import {
  AssetsRoutes,
  BaseRoute,
  DashboardRoutes,
  EventsRoutes,
  IncidentsRoutes,
  LoginRoutes,
  ReportsRoutes,
  SettingsRoutes,
} from './common/Routes';
import Dashboard from './dashboard';
import Events from './events';
import { Header } from './header';
import Incidents, { IncidentsDetailsDialog } from './incidents';
import { Reports, DomainReport, PeriodicReport } from './reports';
import { useClient } from '@root/App.hooks';
import LoggedOutRoute from '@root/common/LoggedOutRoute';
import { SettingsSidebar } from '@root/settings-sidebar';
import { ReleaseNotes } from './release-notes';
import ErrorBoundary from '@root/ErrorBoundary';
import { ErrorPage, NotFoundErrorPage } from '@root/error';
import {
  LoginRoot,
  ResetPassword,
  ResetPasswordConfirm,
  Signup,
  VerifyAccount,
} from './auth';
import { isStaff } from '@root/common/Utils.helpers';

Chart.register(zoomPlugin);

const App = () => {
  const { isUserAuthenticated, invalidUrl, idToken } = useAppContext();
  const { pathname } = useLocation();
  initializeChartJS();

  const sideMargin = useMemo(() => {
    return (
      isUserAuthenticated &&
      !pathname.includes(ReportsRoutes.Periodic) &&
      !pathname.includes(ReportsRoutes.Domain) &&
      !pathname.includes(SettingsRoutes.ResetPassword) &&
      !pathname.includes(SettingsRoutes.ResetPasswordResponse)
    );
  }, [isUserAuthenticated, pathname]);
  const contextData = useCasesContextModel();

  const renderLeftNav = () => {
    return !pathname.includes(SettingsRoutes.Root) ? (
      <Cases />
    ) : (
      <SettingsSidebar />
    );
  };

  return (
    <Suspense fallback={<Loader />}>
      <CasesContextProvider value={contextData}>
        <>
          <Header />
          {sideMargin && !invalidUrl && renderLeftNav()}
          <Box flexGrow={1} ml={sideMargin && !invalidUrl ? 22 : 0}>
            <Routes>
              <Route path="*" element={<NotFoundErrorPage />} />
              <Route element={<LoggedOutRoute />}>
                <Route path={LoginRoutes.Root} element={<LoginRoot />} />
                <Route path={LoginRoutes.SignUp} element={<Signup />} />
                <Route
                  path={LoginRoutes.VerifyAccount}
                  element={<VerifyAccount />}
                />
                <Route
                  path={LoginRoutes.ResetPassword}
                  element={<ResetPassword />}
                />
                <Route
                  path={LoginRoutes.ResetPasswordConfirm}
                  element={<ResetPasswordConfirm />}
                />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route path={DashboardRoutes.Root} element={<Dashboard />} />
                <Route path={IncidentsRoutes.Root} element={<Incidents />}>
                  <Route
                    path={IncidentsRoutes.Details}
                    element={<IncidentsDetailsDialog />}
                  />
                </Route>
                <Route path={AssetsRoutes.Root} element={<Assets />}>
                  <Route path={AssetsRoutes.Details} element={<Assets />} />
                </Route>
                <Route path={SettingsRoutes.Root} element={<Outlet />}>
                  <Route index element={<Account />} />
                  <Route
                    path={SettingsRoutes.ApiAccess}
                    element={<ApiAccess />}
                  />
                  {isStaff(idToken) && (
                    <Route
                      path={SettingsRoutes.EditTrends}
                      element={<EditTrendsRoot />}
                    />
                  )}
                  <Route
                    path={SettingsRoutes.ResetPassword}
                    element={<AccountResetPassword />}
                  />
                  <Route
                    path={SettingsRoutes.ResetPasswordResponse}
                    element={<ResetPasswordResponse />}
                  />
                </Route>
                <Route path={EventsRoutes.Root} element={<Events />} />
                <Route path={ReportsRoutes.Root} element={<Outlet />}>
                  <Route index element={<Reports />} />
                  <Route
                    path={ReportsRoutes.Domain}
                    element={<DomainReport />}
                  />
                  <Route
                    path={ReportsRoutes.Periodic}
                    element={<PeriodicReport />}
                  />
                </Route>
              </Route>
            </Routes>
          </Box>
        </>
      </CasesContextProvider>
      <ReleaseNotes />
    </Suspense>
  );
};

const AppWithClient = () => {
  const { client } = useClient();
  return (
    <Provider value={client}>
      <LocalizationProvider dateAdapter={DateDayjsAdapter}>
        <App />
      </LocalizationProvider>
    </Provider>
  );
};

const AppWithContext = () => {
  const contextData = useAppContextModel();
  return (
    <AppContextProvider value={contextData}>
      <AppWithClient />
    </AppContextProvider>
  );
};

const AppWithSnackBarContext = () => {
  const notistackRef = React.createRef<SnackbarProvider>();
  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef?.current?.closeSnackbar?.(key);
  };
  return (
    <BrowserRouter basename={BaseRoute}>
      <SnackbarProvider
        maxSnack={3}
        ref={notistackRef}
        preventDuplicate
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        action={(key) => (
          <IconButton onClick={onClickDismiss(key)}>
            <Close htmlColor="white" />
          </IconButton>
        )}
      >
        <AppWithContext />
      </SnackbarProvider>
    </BrowserRouter>
  );
};

const AppWithErrorBoundary = () => {
  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <AppWithSnackBarContext />
    </ErrorBoundary>
  );
};

export default AppWithErrorBoundary;
