import React from 'react';
import * as StyledComponent from './Account.styles';

interface OwnProps {
  children?: React.ReactNode;
}

const Root = (props: OwnProps) => {
  return (
    <StyledComponent.RootStyled>
      <StyledComponent.ContainerStyled maxWidth="sm">
        {props.children}
      </StyledComponent.ContainerStyled>
    </StyledComponent.RootStyled>
  );
};

export default Root;
