import React, { useMemo } from 'react';
import * as StyledComponents from './AssetTypes.styles';
import { Typography } from '@mui/material';

interface OwnProps {
  id?: string;
  isLoading?: boolean;
  isVerified?: boolean;
}
const BannerComponent = (props: OwnProps) => {
  const pendingAssetType = useMemo(() => {
    return props.id && !props.isLoading && !props.isVerified;
  }, [props.id, props.isLoading, props.isVerified]);

  return (
    <>
      {pendingAssetType && (
        <StyledComponents.Banner>
          <Typography variant="subtitle2" color="white">
            Pending verification
          </Typography>
        </StyledComponents.Banner>
      )}
    </>
  );
};

export default BannerComponent;
