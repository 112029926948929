import React from 'react';
import { memo } from 'react';
import { Domain, ReportsTypes } from './Reports.types';
import ReportsDomainFieldsComponent from './Reports.domain.component';
import ReportsDateRangeComponent from './Reports.dateRange.component';

interface OwnProps {
  reportType?: ReportsTypes;
  onDomainChange?: (val: Domain | null) => void;
  dateRange?: { from?: Date; to?: Date };
  domain?: Domain;
  domains?: Domain[];
  onChangeDateRange?: (from?: Date, to?: Date) => void;
  setSearch?: (value: string) => void;
  loading?: boolean;
  setDateError?: React.Dispatch<React.SetStateAction<boolean>>;
  loadMoreResults?: () => void;
}
const ReportsRenderFieldComponent = (props: OwnProps) => {
  if (props?.reportType === ReportsTypes.Domain) {
    return (
      <ReportsDomainFieldsComponent
        onDomainChange={props.onDomainChange}
        domain={props.domain}
        onChangeDateRange={props.onChangeDateRange}
        domains={props.domains}
        dateRange={props.dateRange}
        setSearch={props.setSearch}
        loading={props.loading}
        setDateError={props.setDateError}
        loadMoreResults={props.loadMoreResults}
      />
    );
  }

  if (props?.reportType === ReportsTypes.Periodic) {
    return (
      <ReportsDateRangeComponent
        dateRange={props.dateRange}
        onChangeDateRange={props.onChangeDateRange}
        setDateError={props.setDateError}
      />
    );
  }
  return null;
};

export default memo(ReportsRenderFieldComponent);
