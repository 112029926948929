import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const CloseIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 25 20">
    <path
      fillRule="evenodd"
      fill="#31ad34"
      d="M25,10h0a.84.84,0,0,0-.07-.35.81.81,0,0,0-.19-.29h0L21.15,5.72a.88.88,0,0,0-1.27,0,.92.92,0,0,0,0,1.29l2.06,2.08H11.54a.91.91,0,0,0,0,1.82h10.4L19.88,13a.92.92,0,0,0,0,1.29.88.88,0,0,0,1.27,0l3.59-3.64a.81.81,0,0,0,.19-.29A.84.84,0,0,0,25,10Z"
    />
    <path
      fillRule="evenodd"
      fill="#31ad34"
      d="M17.39,0a1,1,0,0,1,1,.91V5.45a1,1,0,0,1-2.06,0V1.82H2.06V18.18h14.3V14.55a1,1,0,0,1,2.06,0v4.54a1,1,0,0,1-1,.91H1a1,1,0,0,1-1-.91V.91A1,1,0,0,1,1,0Z"
    />
  </SvgIcon>
);

export default CloseIcon;
