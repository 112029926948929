import React from 'react';
import { Pills, Severity } from '@fyeo-di-frontend/shared';
import { Box } from '@mui/material';
import startCase from 'lodash/startCase';
import { useEventsContext } from '@root/events/Events.context';
import useEventsFiltersHook from '@root/events/Events.filters.hooks';
import dayjs from 'dayjs';
import { FilterStorageKey } from '@root/events/Events.types';

interface OwnProps {
  clickAction?: () => void;
}

const PillsContainer = ({ clickAction }: OwnProps) => {
  const {
    dateRange,
    eventsSearchText,
    assets,
    incidentsFilter,
    sourceTypes,
    languages,
    fileTypes,
    sites,
    threatLevel,
    matchingScore,
  } = useEventsContext();
  const {
    onChangeEventSearchText,
    clearMatchingScore,
    onRemoveAsset,
    onRemoveThreatLevel,
    onRemoveIncident,
    onRemoveSourceType,
    onRemoveLanguage,
    onRemoveFiletype,
    onChangeSite,
  } = useEventsFiltersHook();

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      gap={1}
      data-cy="filter-pills"
    >
      <Pills
        title="Date"
        label={`${dayjs(dateRange?.from).format('YYYY-MM-DD')} – ${dayjs(
          dateRange?.to,
        ).format('YYYY-MM-DD')}`}
        onClick={() => {
          clickAction?.();
        }}
      />
      {eventsSearchText && (
        <Pills
          title="Search"
          label={eventsSearchText}
          onClick={() => onChangeEventSearchText('')}
        />
      )}
      {matchingScore && (
        <Pills
          title="Matching score"
          label={`${matchingScore.from} - ${matchingScore.to}`}
          onClick={() => clearMatchingScore()}
        />
      )}
      {threatLevel?.map((i) => (
        <Pills
          key={i.id}
          label={Severity[i.id as unknown as Severity]}
          severity={+i.id as unknown as Severity}
          onClick={() => onRemoveThreatLevel(i.id)}
        />
      ))}
      {assets?.map((i) => (
        <Pills
          key={i.id}
          label={i.name}
          title="Asset"
          onClick={() => onRemoveAsset(i.id)}
        />
      ))}
      {incidentsFilter?.map((i) => (
        <Pills
          key={i.id}
          label={startCase(i.name)}
          title="Incident"
          onClick={() => onRemoveIncident(i.id)}
        />
      ))}
      {sourceTypes?.map((i) => (
        <Pills
          key={i.id}
          label={i.name}
          title="Source type"
          onClick={() => onRemoveSourceType(i.id)}
        />
      ))}
      {languages?.map((i) => (
        <Pills
          key={i.id}
          label={startCase(i.name)}
          title="Languages"
          onClick={() => onRemoveLanguage(i.id)}
        />
      ))}
      {fileTypes?.map((i) => (
        <Pills
          key={i.id}
          label={startCase(i.name)}
          title="File type"
          onClick={() => onRemoveFiletype(i.id)}
        />
      ))}
      {sites && (
        <Pills
          label={sites.name}
          title="Site"
          onClick={() => onChangeSite(null, FilterStorageKey.Site)}
        />
      )}
    </Box>
  );
};

export default PillsContainer;
