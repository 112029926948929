import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const QuestionIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon width="11" height="11" viewBox="0 0 11 11" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5166 10.1055C8.27803 10.1055 10.5166 7.86689 10.5166 5.10547C10.5166 2.34404 8.27803 0.105469 5.5166 0.105469C2.75518 0.105469 0.516602 2.34404 0.516602 5.10547C0.516602 7.86689 2.75518 10.1055 5.5166 10.1055Z"
      fill="#31AD34"
    />
    <path
      d="M6.22757 8.30708H4.80344V4.10352H6.22757V8.30708ZM4.68164 2.22644C4.68164 1.76735 5.05641 1.39258 5.50613 1.39258C5.9746 1.39258 6.34937 1.76735 6.34937 2.22644C6.34937 2.68553 5.9746 3.0603 5.50613 3.0603C5.05641 3.0603 4.68164 2.68553 4.68164 2.22644Z"
      fill="white"
    />
  </SvgIcon>
);

export default QuestionIcon;
