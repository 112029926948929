import { TableBody, TableCell, TableRow } from '@mui/material';
import range from 'lodash/range';
import React from 'react';
import styled from 'styled-components';
import HorizontalLoader from './Loader.horizontal.component';

interface OwnProps {
  totalCols: number;
  rowCount?: number;
}

const TableCellStyled = styled(TableCell)({
  paddingLeft: 25,
});

const TableLoaderBodyComponent: React.FC<OwnProps> = (props: OwnProps) => {
  const { rowCount, totalCols } = props;
  return (
    <TableBody>
      {range(rowCount || 10).map((val) => (
        <TableRow key={val}>
          {range(totalCols).map((col) => (
            <TableCellStyled key={col}>
              <HorizontalLoader count={1} />
            </TableCellStyled>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default TableLoaderBodyComponent;
