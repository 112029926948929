import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { LoginRoutes } from '@root/common/Routes';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as StyledComponents from '../login/Login.styles';
import useResetPasswordHook from './ResetPassword.hooks';
import useResetPasswordConfirmHook from './ResetPasswordConfirm.hooks';
import Root from '../Root.component';
import zxcvbn from 'zxcvbn';
import AuthPasswordComponent from '@root/auth/Auth.password.component';

const ResetPasswordConfirm = () => {
  const { state, pathname } = useLocation();
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const {
    isLoading: isVerifyAccountResendInProgress,
    onReset: onResendCodeRequest,
    onChangeEmail,
  } = useResetPasswordHook();
  const {
    newPassword,
    confirmPassword,
    code,
    onChangeNewPassword,
    onChangeConfirmPassword,
    onChangeCode,
    isLoading,
    onReset,
    isError,
    isConfirmPassError,
  } = useResetPasswordConfirmHook();

  const strength = useMemo(() => {
    return zxcvbn(newPassword).score;
  }, [newPassword]);

  const disableLogin =
    !newPassword?.trim?.() ||
    !confirmPassword?.trim?.() ||
    !code?.trim?.() ||
    strength < 3 ||
    isLoading;

  useEffect(() => {
    if (!state?.email) {
      if (pathname !== LoginRoutes.ResetPassword) {
        navigate(LoginRoutes.ResetPassword);
      }
    } else {
      onChangeEmail(state.email);
    }
  }, [state, navigate, onChangeEmail, pathname]);

  return (
    <Root>
      <StyledComponents.Root>
        <Box textAlign="center">
          <Typography variant="h6">Reset password</Typography>
        </Box>
        <Box display="flex" gap={2} justifyContent="center">
          <Typography variant="subtitle2">
            Didn&apos;t receive a code?
          </Typography>
          {!isVerifyAccountResendInProgress && (
            <Link
              variant="subtitle2"
              underline="none"
              color="primary"
              onClick={onResendCodeRequest}
            >
              Resend
            </Link>
          )}
          {isVerifyAccountResendInProgress && <CircularProgress size={20} />}
        </Box>
        {isError && (
          <Box textAlign="left" ml={15} mr={15}>
            <Typography variant="caption" color="error">
              Error resetting password
            </Typography>
          </Box>
        )}
        <Box textAlign="center" ml={15} mr={15}>
          <TextField
            placeholder="New password"
            type={showNewPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={() => setShowNewPassword((old) => !old)}>
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(evt) => onChangeNewPassword(evt.target.value)}
          />
        </Box>
        {isConfirmPassError && (
          <Box
            textAlign="left"
            ml={15}
            mr={15}
            position="relative"
            top={10}
            mt={-2}
          >
            <Typography variant="caption" color="error">
              Passwords don&apos;t match
            </Typography>
          </Box>
        )}
        <Box textAlign="center" ml={15} mr={15}>
          <TextField
            placeholder="Confirm password"
            type={showConfirmPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => setShowConfirmPassword((old) => !old)}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(evt) => onChangeConfirmPassword(evt.target.value)}
          />
        </Box>
        <Box textAlign="center" ml={15} mr={15}>
          <TextField
            placeholder="Verification code"
            onChange={(evt) => onChangeCode(evt.target.value)}
          />
        </Box>
        <Box textAlign="center" ml={15} mr={15} mb={3.75}>
          <AuthPasswordComponent strength={strength} />
        </Box>
        <Box textAlign="center" ml={15} mr={15}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <LoadingButton
                variant="contained"
                fullWidth
                size="medium"
                color="secondary"
                onClick={() => navigate(-1)}
              >
                Back
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <LoadingButton
                variant="contained"
                fullWidth
                size="medium"
                disabled={disableLogin}
                onClick={onReset}
                loading={isLoading}
              >
                Confirm
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </StyledComponents.Root>
    </Root>
  );
};

export default ResetPasswordConfirm;
