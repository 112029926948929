import useListBreachesHook from './DomainReport.listBreaches.hooks';
import useTopPasswordsHook from './DomainReport.topPasswords.hooks';
import useTopSourcesHook from './DomainReport.topSources.hooks';
import useTopUsersHook from './DomainReport.topUsers.hooks';

const useDomainReportHook = () => {
  const { fetchingTopSources } = useTopSourcesHook();
  const { fetchingTopUsers } = useTopUsersHook();
  const { fetchingTopPasswords } = useTopPasswordsHook();
  const { fetchingBreaches } = useListBreachesHook();

  return {
    fetchingTopSources,
    fetchingTopUsers,
    fetchingTopPasswords,
    fetchingBreaches,
  };
};

export default useDomainReportHook;
