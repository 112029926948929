import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  // InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
// import DragAndDrop from '@root/support/Support.dragAndDrop.component';
import React from 'react';
import { LoadingButton } from '@mui/lab';

const supportOptions = ['Generating reports', 'Incidents', 'Assets', 'Events'];

interface OwnProps {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  handleFile: (acceptedFiles: File[]) => void;
  optionalFiles: { id: string; file: File }[];
  handleRemoveFile: (id: string) => void;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  onSubmit: () => void;
  supportDetails: { title: string; description: string };
  onChangeData: (key: string, value: string) => void;
}
const SupportForm = ({
  onClose,
  // handleFile,
  // optionalFiles,
  // handleRemoveFile,
  isSubmitting,
  isDisabled,
  onSubmit,
  supportDetails,
  onChangeData,
}: OwnProps) => {
  return (
    <Box width={600}>
      <DialogTitle>
        <Box margin="auto" textAlign="center" mt={2}>
          Contact support
        </Box>
        <Box position="absolute" right={8} top={8} color="black">
          <IconButton onClick={() => onClose(() => false)} color="inherit">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2.5}>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="subtitle2">
              What do you need help with?
            </Typography>
            <Select<string>
              onChange={(evt) => onChangeData?.('title', evt.target.value)}
              size="small"
              fullWidth
              value={supportDetails.title}
            >
              {supportOptions.map((option) => (
                <MenuItem key={option} value={option} dense>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="subtitle2">
              Explain your problem in detail
            </Typography>
            <TextField
              multiline
              rows={7}
              value={supportDetails.description || ''}
              onChange={(evt) =>
                onChangeData?.('description', evt.target.value)
              }
            />
          </Box>
          {/*<Box display="flex" flexDirection="column" gap={0.5}>*/}
          {/*  <Typography variant="subtitle2">*/}
          {/*    Upload attachments (optional)*/}
          {/*  </Typography>*/}
          {/*  <DragAndDrop handleFile={handleFile} />*/}
          {/*  {optionalFiles?.map((file) => (*/}
          {/*    <TextField*/}
          {/*      key={file.id}*/}
          {/*      value={file.file.name}*/}
          {/*      InputProps={{*/}
          {/*        endAdornment: (*/}
          {/*          <InputAdornment position="start">*/}
          {/*            <IconButton onClick={() => handleRemoveFile(file.id)}>*/}
          {/*              <Close fontSize="small" />*/}
          {/*            </IconButton>*/}
          {/*          </InputAdornment>*/}
          {/*        ),*/}
          {/*        readOnly: true,*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  ))}*/}
          {/*</Box>*/}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box textAlign="right" gap={2} display="flex" mb={3} mx={2}>
          <Box width={126}>
            <LoadingButton
              onClick={() => {
                onSubmit();
              }}
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              loading={isSubmitting}
              disabled={isDisabled}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </DialogActions>
    </Box>
  );
};

export default SupportForm;
