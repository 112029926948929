import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const AutoSaveIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="64" height="65" viewBox="0 0 64 65" fill="none">
    <circle
      cx="32.1405"
      cy="32.3827"
      r="30.7284"
      fill="white"
      stroke="#B6BFC8"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.32715 53.3282C12.4393 45.708 19.0963 40.4635 26.7436 38.6955C21.612 36.5708 18.0019 31.5143 18.0019 25.6147C18.0019 17.7988 24.3379 11.4629 32.1537 11.4629C39.9695 11.4629 46.3054 17.7988 46.3054 25.6147C46.3054 31.4677 42.7522 36.4908 37.6851 38.6446C41.0333 39.379 44.2224 40.7649 47.064 42.7414C50.9319 45.4317 53.998 49.1066 55.9528 53.3736C50.1391 59.9583 41.6345 64.1111 32.1599 64.1111C31.929 64.1111 31.6986 64.1087 31.4687 64.1037C30.7815 64.0868 30.0965 64.0501 29.4146 63.994C21.0294 63.2753 13.5755 59.2953 8.32715 53.3282Z"
      fill="#B6BFC8"
    />
  </SvgIcon>
);

export default AutoSaveIcon;
