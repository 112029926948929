import { createContext, useCallback, useContext, useState } from 'react';

export type TSignupContextModel = ReturnType<typeof useSignupContextModel>;

const SingupContext = createContext<TSignupContextModel>({
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  confirmPassword: '',
  onChangeFirstname: () => {},
  onChangeLastname: () => {},
  onChangeEmail: () => {},
  onChangePassword: () => {},
  onChangeConfirmPassword: () => {},
  readTermsAndConditions: false,
  onChangeTermsAndConditions: () => {},
});
const { Provider } = SingupContext;

export const useSignupContextModel = () => {
  const [firstname, setFirstname] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [readTermsAndConditions, setReadTermsAndConditions] =
    useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const onChangeTermsAndConditions = useCallback(
    (value: boolean) => setReadTermsAndConditions(() => value),
    [setReadTermsAndConditions],
  );
  const onChangeFirstname = useCallback(
    (value: string) => setFirstname(() => value),
    [setFirstname],
  );
  const onChangeLastname = useCallback(
    (value: string) => setLastname(() => value),
    [setLastname],
  );
  const onChangeEmail = useCallback(
    (value: string) => setEmail(() => value),
    [setEmail],
  );
  const onChangePassword = useCallback(
    (newPassword: string) => setPassword(() => newPassword),
    [setPassword],
  );
  const onChangeConfirmPassword = useCallback(
    (value: string) => setConfirmPassword(() => value),
    [setConfirmPassword],
  );

  return {
    firstname,
    lastname,
    email,
    password,
    confirmPassword,
    onChangeFirstname,
    onChangeLastname,
    onChangeEmail,
    onChangePassword,
    onChangeConfirmPassword,
    readTermsAndConditions,
    onChangeTermsAndConditions,
  };
};

export const useSignupContext = () => useContext(SingupContext);

export const SignupContextProvider = Provider;
