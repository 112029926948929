import { createContext, useCallback, useContext, useState } from 'react';

export type TLoginContextModel = ReturnType<typeof useLoginContextModel>;

const LoginContext = createContext<TLoginContextModel>({
  email: '',
  password: '',
  keepMeLoggedIn: false,
  onChangePassword: () => {},
  onChangeEmail: () => {},
  onChangeKeepMeLoggedIn: () => {},
});
const { Provider } = LoginContext;

export const useLoginContextModel = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState<boolean>(false);

  const onChangePassword = useCallback(
    (newPassword: string) => setPassword(() => newPassword),
    [setPassword],
  );
  const onChangeEmail = useCallback(
    (newUsername: string) => setEmail(() => newUsername),
    [setEmail],
  );
  const onChangeKeepMeLoggedIn = useCallback(
    (checked: boolean) => setKeepMeLoggedIn(() => checked),
    [setKeepMeLoggedIn],
  );

  return {
    email,
    password,
    onChangePassword,
    onChangeEmail,
    onChangeKeepMeLoggedIn,
    keepMeLoggedIn,
  };
};

export const useLoginContext = () => useContext(LoginContext);

export const LoginContextProvider = Provider;
