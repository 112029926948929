import { HeaderLogo, ReportImage } from '@fyeo-di-frontend/shared';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import {
  PeriodicContextProvider,
  usePeriodicContext,
  usePeriodicContextModel,
} from './PeriodicReport.context';
import usePeriodicReportHook from './PeriodicReport.hooks';
import PeriodicReportIncidentsSummaryComponent from './PeriodicReport.incidentsSummary.component';
import PeriodicReportReportsSummaryComponent from './PeriodicReport.reportsSummary.component';
import * as StyledComponents from './PeriodicReport.styles';
import PeriodicReportTrendsComponent from '@root/reports/periodic-report/PeriodicReport.trends.component';

const PeriodicReport = () => {
  const {
    incidents,
    severity,
    detections,
    data,
    columns,
    selectedCases,
    dateRange,
    incidentsSummary,
    trend,
    preparedBy,
    preparedFor,
  } = usePeriodicContext();

  const cases = useMemo(() => {
    return selectedCases?.map((c) => c.name);
  }, [selectedCases]);

  const {
    fetchingIncidentsByAssetType,
    fetchingIncidentsBySeverity,
    fetchingIncidentsByDate,
    fetchingIncidentsByCaseAndType,
  } = usePeriodicReportHook();
  return (
    <>
      <StyledComponents.Container>
        <StyledComponents.Header>
          <HeaderLogo />
          <StyledComponents.Background>
            <ReportImage />
          </StyledComponents.Background>
          <StyledComponents.ReportDetailsWrapper>
            <Typography variant="h4" mb={1}>
              Domain Intelligence Report
            </Typography>
            <Typography variant="subtitle1">
              Period: {dayjs(dateRange?.from).format('YYYY-MM-DD')} to{' '}
              {dayjs(dateRange?.to).format('YYYY-MM-DD')}
            </Typography>
            <Typography variant="subtitle1">
              Prepared for: {preparedFor}
            </Typography>
            <Typography variant="subtitle1" mb={2.75}>
              Prepared by: {preparedBy}
            </Typography>
            <Typography variant="h4" mb={1}>
              Cases included
            </Typography>
            <Typography variant="subtitle1">{cases?.join(', ')}</Typography>
          </StyledComponents.ReportDetailsWrapper>
        </StyledComponents.Header>
        <PeriodicReportReportsSummaryComponent
          fetchingIncidentsByAssetType={fetchingIncidentsByAssetType}
          fetchingIncidentsByDate={fetchingIncidentsByDate}
          fetchingIncidentsBySeverity={fetchingIncidentsBySeverity}
          severity={severity}
          incidents={incidents}
          detections={detections}
          data={data}
          columns={columns}
          fetchingIncidentsByCaseAndType={fetchingIncidentsByCaseAndType}
        />
        <PeriodicReportTrendsComponent trend={trend} />
        <PeriodicReportIncidentsSummaryComponent
          incidentsSummary={incidentsSummary}
        />
      </StyledComponents.Container>
    </>
  );
};

const PeriodicReportWithContext = () => {
  const contextData = usePeriodicContextModel();
  return (
    <PeriodicContextProvider value={contextData}>
      <PeriodicReport />
    </PeriodicContextProvider>
  );
};

export default PeriodicReportWithContext;
