import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WalletIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M1.69,7.74A.35.35,0,0,1,1.64,7L14.22,5.29a.37.37,0,0,1,.28.09.32.32,0,0,1,.12.26V7.32a.35.35,0,0,1-.35.35L1.69,7.74ZM13.92,6,7,7l7,0Z" />
    <path d="M15.35,16.58V7.42a.36.36,0,0,0-.12-.27A.39.39,0,0,0,15,7H1.69a.38.38,0,0,0-.38.38v9.16a.38.38,0,0,0,.38.38H15a.39.39,0,0,0,.27-.11A.36.36,0,0,0,15.35,16.58Zm-.77-.38H2.07V7.8H14.58Z" />
    <path d="M17,11.33v1.33h2.31v2.39h1.36V12.66H23V11.33H20.68V9H19.32v2.38Z" />
    <polygon points="4.14 11.73 1.82 11.73 1.82 11.23 3.98 11.23 5.8 9.87 6.1 10.27 4.14 11.73" />
    <path d="M6.69,15.34a1,1,0,1,1,1-1A1,1,0,0,1,6.69,15.34Zm0-1.5a.5.5,0,0,0-.49.5.5.5,0,1,0,1,0A.5.5,0,0,0,6.69,13.84Z" />
    <path d="M9.08,13.36a1,1,0,1,1,1-1A1,1,0,0,1,9.08,13.36Zm0-1.5a.5.5,0,0,0-.5.5.5.5,0,1,0,1,0A.49.49,0,0,0,9.08,11.86Z" />
    <path d="M13.06,13.36a1.14,1.14,0,1,1,1.13-1.13A1.14,1.14,0,0,1,13.06,13.36Zm0-1.77a.64.64,0,1,0,.63.64A.64.64,0,0,0,13.06,11.59Z" />
    <path d="M6.49,10.7a1,1,0,1,1,1-1A1,1,0,0,1,6.49,10.7Zm0-1.5a.5.5,0,0,0-.5.5.5.5,0,1,0,.5-.5Z" />
    <rect x="1.82" y="12.11" width="6.5" height="0.5" />
    <polygon points="6.07 14.17 4.76 13.49 1.82 13.49 1.82 12.99 4.94 13.01 6.31 13.73 6.07 14.17" />
  </SvgIcon>
);

export default WalletIcon;
