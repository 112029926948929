import { ReportsRoutes } from './Routes';
import { useLocation } from 'react-router-dom';

const useCurrentURL = () => {
  const { pathname } = useLocation();

  return (
    pathname?.includes(ReportsRoutes.Periodic) ||
    pathname?.includes(ReportsRoutes.Domain)
  );
};

export default useCurrentURL;
