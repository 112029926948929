import { Box, Typography } from '@mui/material';
import startCase from 'lodash/startCase';
import React from 'react';
import { AssetDetails } from '../Assets.types';
import * as StyledComponent from './AssetTypes.styles';

interface OwnProps {
  data?: AssetDetails;
}

const DomainDetails: React.FC<OwnProps> = (props) => {
  return (
    <>
      <Box width="100%">
        <Box display="flex" gap={1} textAlign="left">
          <Typography variant="subtitle1">Whois</Typography>
        </Box>
        <StyledComponent.DivWrapper>
          {Object.keys(props?.data?.whoIs || {}).map((key) => (
            <StyledComponent.ItemWrapper key={key}>
              <Typography variant="subtitle2" fontWeight="bold">
                {startCase(key)}:{' '}
                <Typography variant="subtitle2" component="span">
                  {props?.data?.whoIs?.[key]}
                </Typography>
              </Typography>
            </StyledComponent.ItemWrapper>
          ))}
        </StyledComponent.DivWrapper>
      </Box>
      <Box width="100%">
        <Box display="flex" gap={1} textAlign="left">
          <Typography variant="subtitle1">Mx</Typography>
        </Box>
        <StyledComponent.DivWrapper>
          {props?.data?.mx?.map((mx, index) => (
            <StyledComponent.ItemWrapper key={mx}>
              <Typography variant="subtitle2" fontWeight="bold">
                {index}:{' '}
                <Typography variant="subtitle2" component="span">
                  {props?.data?.mx?.[index]}
                </Typography>
              </Typography>
            </StyledComponent.ItemWrapper>
          ))}
        </StyledComponent.DivWrapper>
      </Box>
      <Box pb={6} width="100%">
        <Box display="flex" gap={1} textAlign="left">
          <Typography variant="subtitle1">Ns</Typography>
        </Box>
        <StyledComponent.DivWrapper>
          {props?.data?.ns?.map((ns, index) => (
            <StyledComponent.ItemWrapper key={ns}>
              <Typography variant="subtitle2" fontWeight="bold">
                {index}:{' '}
                <Typography variant="subtitle2" component="span">
                  {props?.data?.ns?.[index]}
                </Typography>
              </Typography>
            </StyledComponent.ItemWrapper>
          ))}
        </StyledComponent.DivWrapper>
      </Box>
    </>
  );
};

export default DomainDetails;
