import { Detections } from '../report';

export const generateDetectionFromString = (data: string): Detections => {
  switch (data) {
    case 'new':
      return Detections.New;
    default:
      return Detections.Total;
  }
};

export const generateDetectionPrimaryColor = (detections: Detections) => {
  switch (detections) {
    case Detections.New:
      return '#F9C704';
    case Detections.Total:
      return '#31AD34';
  }
};

export const generateDetectionSecondaryColor = (detections: Detections) => {
  switch (detections) {
    case Detections.New:
      return '#FEF4CD';
    case Detections.Total:
      return '#E8F9E8';
  }
};
