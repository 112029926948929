import React from 'react';
import { Pills } from '@fyeo-di-frontend/shared';
import { Box } from '@mui/material';
import { useAssetsContext } from '@root/assets/Assets.context';
import useAssetsFiltersHook from '@root/assets/Assets.filters.hooks';
import startCase from 'lodash/startCase';

const PillsContainer = () => {
  const {
    assetSearchText,
    assetTypes,
    threatActor,
    active,
    matchingScore,
    totalIncidents,
  } = useAssetsContext();
  const {
    onChangeAssetSearchText,
    clearMatchingScore,
    clearTotalIncidents,
    onToggleThreatActor,
    onToggleActive,
    onRemoveAssetType,
  } = useAssetsFiltersHook();

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      gap={1}
      data-cy="filter-pills"
    >
      {assetSearchText && (
        <Pills
          title="Search"
          label={assetSearchText}
          onClick={() => onChangeAssetSearchText('')}
        />
      )}
      {matchingScore && (
        <Pills
          title="Matching score"
          label={`${matchingScore.from} - ${matchingScore.to}`}
          onClick={() => clearMatchingScore()}
        />
      )}
      {totalIncidents && (
        <Pills
          title="Incidents"
          label={`${totalIncidents.from} - ${totalIncidents.to}`}
          onClick={() => clearTotalIncidents()}
        />
      )}
      {threatActor?.some((r) => r.id) && (
        <Pills
          title="Threat actor"
          label="Yes"
          onClick={() => onToggleThreatActor(true)}
        />
      )}
      {threatActor?.some((r) => !r.id) && (
        <Pills
          title="Threat actor"
          label="No"
          onClick={() => onToggleThreatActor(false)}
        />
      )}
      {active?.some((r) => r.id) && (
        <Pills
          title="Active"
          label="Yes"
          onClick={() => onToggleActive(true)}
        />
      )}
      {active?.some((r) => !r.id) && (
        <Pills
          title="Active"
          label="No"
          onClick={() => onToggleActive(false)}
        />
      )}
      {assetTypes?.map((i) => (
        <Pills
          key={i}
          label={startCase(i)}
          title="Types"
          onClick={() => onRemoveAssetType(i)}
        />
      ))}
    </Box>
  );
};

export default PillsContainer;
