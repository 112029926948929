import * as StyledComponents from './ResetPassword.styles';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Root from '../Root.component';
import React, { useMemo, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AuthPasswordComponent } from '@root/auth';
import zxcvbn from 'zxcvbn';
import {
  useResetPasswordContext,
  useResetPasswordContextModel,
  ResetPasswordContextProvider,
} from './ResetPassword.context';
import { SettingsRoutes } from '@root/common/Routes';
import { useNavigate } from 'react-router-dom';
import useResetPasswordHook from '@root/account/reset-password/ResetPassword.hooks';

const ResetPassword = () => {
  const push = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const {
    oldPassword,
    password,
    confirmPassword,
    onChangeConfirmPassword,
    onChangeOldPassword,
    onChangePassword,
    isError,
  } = useResetPasswordContext();

  const { onResetPassword, isResetPasswordInProgress } = useResetPasswordHook();

  const strength = useMemo(() => {
    return zxcvbn(password).score;
  }, [password]);

  const disableReset =
    !oldPassword.trim?.() ||
    !password?.trim?.() ||
    !confirmPassword.trim?.() ||
    password?.trim?.() !== confirmPassword?.trim?.() ||
    strength < 3;

  return (
    <Root>
      <StyledComponents.Root elevation={0}>
        <Box textAlign="center">
          <Typography variant="h6">Change password</Typography>
        </Box>
        {isError && (
          <Box textAlign="left" ml={15} mr={15}>
            <Typography variant="caption" color="error">
              Wrong current password
            </Typography>
          </Box>
        )}
        <Box textAlign="center" ml={15} mr={15}>
          <TextField
            type={showOldPassword ? 'text' : 'password'}
            placeholder="Current password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={() => setShowOldPassword((old) => !old)}>
                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(evt) => {
              const value = evt?.target?.value;
              value && onChangeOldPassword(value);
            }}
            autoComplete=""
          />
        </Box>
        <Box textAlign="center" ml={15} mr={15}>
          <TextField
            type={showPassword ? 'text' : 'password'}
            placeholder="New password"
            value={password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={() => setShowPassword((old) => !old)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(evt) => {
              const value = evt?.target?.value;
              value && onChangePassword(value);
            }}
            autoComplete=""
          />
        </Box>
        <Box textAlign="center" ml={15} mr={15}>
          <TextField
            type={showConfirmPassword ? 'text' : 'password'}
            placeholder="Confirm new password"
            error={password?.trim?.() !== confirmPassword?.trim?.()}
            helperText={
              password?.trim?.() !== confirmPassword?.trim?.()
                ? "Passwords don't match!"
                : undefined
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => setShowConfirmPassword((old) => !old)}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(evt) => {
              const value = evt?.target?.value;
              value && onChangeConfirmPassword(value);
            }}
            autoComplete=""
          />
        </Box>
        <Box textAlign="center" ml={15} mr={15} mb={3.75}>
          <AuthPasswordComponent strength={strength} />
        </Box>
        <Box textAlign="center" ml={15} mr={15} display="flex" gap={1}>
          <LoadingButton
            variant="contained"
            fullWidth
            size="medium"
            color="secondary"
            onClick={() => push(SettingsRoutes.Root)}
          >
            Back
          </LoadingButton>
          <LoadingButton
            variant="contained"
            fullWidth
            size="medium"
            disabled={disableReset}
            onClick={onResetPassword}
            loading={isResetPasswordInProgress}
          >
            Confirm
          </LoadingButton>
        </Box>
      </StyledComponents.Root>
    </Root>
  );
};
const ResetPasswordWithContext = () => {
  const contextData = useResetPasswordContextModel();
  return (
    <ResetPasswordContextProvider value={contextData}>
      <ResetPassword />
    </ResetPasswordContextProvider>
  );
};

export default ResetPasswordWithContext;
