import { Doughnut } from 'react-chartjs-2';
import * as StyledComponents from './Dashboard.styles';
import { Box, Typography } from '@mui/material';
import React, { useMemo, useRef } from 'react';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import DoughnutLegend from './Dashboard.doughnutLegend.component';
import {
  HorizontalFullHeightLoader,
  Severity,
  SeverityWithoutNone,
} from '@fyeo-di-frontend/shared';
import { startCase } from 'lodash';

interface OwnProps {
  lowCount?: number;
  guardedCount?: number;
  elevatedCount?: number;
  highCount?: number;
  severeCount?: number;
  safeCount?: number;
  isLoading: boolean;
  onBarClick?: (severity: Severity) => void;
  bg?: boolean;
}

const DoughnutChart = (props: OwnProps) => {
  const pieRef = useRef<ChartJSOrUndefined<'doughnut', number[], string>>(null);
  const payload = useMemo(() => {
    const value = [
      {
        count: props.severeCount || 0,
        severity: Severity['Severe'],
        label: startCase(SeverityWithoutNone['Severe']),
        bgColor: '#FF4F00',
      },
      {
        count: props.highCount || 0,
        severity: Severity['High'],
        label: startCase(SeverityWithoutNone['High']),
        bgColor: '#FFB800',
      },
      {
        count: props.elevatedCount || 0,
        severity: Severity['Elevated'],
        label: startCase(SeverityWithoutNone['Elevated']),
        bgColor: '#F0D941',
      },
      {
        count: props.guardedCount || 0,
        severity: Severity['Guarded'],
        label: startCase(SeverityWithoutNone['Guarded']),
        bgColor: '#556DEC',
      },
      {
        count: props.lowCount || 0,
        severity: Severity['Low'],
        label: startCase(SeverityWithoutNone['Low']),
        bgColor: '#7CD85B',
      },
      {
        count: props.safeCount || 0,
        severity: Severity['Safe'],
        label: startCase(SeverityWithoutNone['Safe']),
        bgColor: '#767676',
      },
    ];
    value.sort((a, b) => a.severity - b.severity);
    return value;
  }, [props]);

  const data = {
    labels: payload.map((p) => p.label),
    datasets: [
      {
        label: 'By Severity',
        data: payload.map((p) => p.count),
        backgroundColor: payload.map((p) => p.bgColor),
        borderWidth: 0,
      },
    ],
  };

  const option = {
    cutout: '60%',
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <StyledComponents.PiePaper elevation={0} bg={props.bg}>
      {props.isLoading ? (
        <HorizontalFullHeightLoader />
      ) : (
        <>
          <Box width={150} position="relative" maxWidth="100%">
            <Doughnut data={data} ref={pieRef} options={option} />
            <StyledComponents.ChartInner>
              <Typography variant="subtitle2" fontWeight="bold">
                By severity
              </Typography>
            </StyledComponents.ChartInner>
          </Box>
          <DoughnutLegend legendItems={payload} onBarClick={props.onBarClick} />
        </>
      )}
    </StyledComponents.PiePaper>
  );
};

export default DoughnutChart;
