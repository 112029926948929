import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const PersonIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 31 31" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.5744 24.5014C27.7305 22.1089 29.043 18.9411 29.043 15.4668C29.043 8.01095 22.9988 1.9668 15.543 1.9668C8.08712 1.9668 2.04297 8.01095 2.04297 15.4668C2.04297 18.9411 3.3554 22.1089 5.51154 24.5014C7.47278 21.6001 10.2813 19.5281 13.514 18.909C10.7717 18.0517 8.79297 15.4992 8.79297 12.4668C8.79297 8.72674 11.8029 5.7168 15.543 5.7168C19.283 5.7168 22.293 8.72674 22.293 12.4668C22.293 15.4993 20.3001 18.0499 17.5666 18.908C20.8016 19.5258 23.6121 21.5985 25.5744 24.5014ZM24.4883 25.578C22.3458 22.2682 19.1082 20.2168 15.543 20.2168C11.9777 20.2168 8.74011 22.2682 6.59768 25.578C8.97944 27.6867 12.1117 28.9668 15.543 28.9668C18.9742 28.9668 22.1065 27.6867 24.4883 25.578ZM15.543 30.4668C23.8272 30.4668 30.543 23.7511 30.543 15.4668C30.543 7.18253 23.8272 0.466797 15.543 0.466797C7.2587 0.466797 0.542969 7.18253 0.542969 15.4668C0.542969 23.7511 7.2587 30.4668 15.543 30.4668ZM15.543 7.2168C12.6313 7.2168 10.293 9.55517 10.293 12.4668C10.293 15.3784 12.6313 17.7168 15.543 17.7168C18.434 17.7168 20.793 15.3766 20.793 12.4668C20.793 9.55517 18.4546 7.2168 15.543 7.2168Z"
      fill="#32AE34"
    />
  </SvgIcon>
);

export default PersonIcon;
