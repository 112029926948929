import { DateRange, DesktopDateRangePicker } from '@mui/lab';
import { Box, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import * as StyledComponents from './DateRangeFilter.styles';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const defaultRange = [
  dayjs().subtract(1, 'month'),
  dayjs(),
] as DateRange<dayjs.Dayjs>;

interface OwnProps {
  value?: DateRange<dayjs.Dayjs>;
  onChangeDateRange?: (fromDate?: Date, toDate?: Date) => void;
  label?: string;
  setDateError?: React.Dispatch<React.SetStateAction<boolean>>;
}

const DateRangeFilter: React.FC<OwnProps> = (props) => {
  const [value, setValue] = useState<DateRange<dayjs.Dayjs>>(defaultRange);
  const ref = useRef<HTMLDivElement>(null);
  const startRef = useRef<HTMLInputElement>(null);
  const endRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  const isValidDate = useCallback((date: dayjs.Dayjs) => {
    return dayjs(date.format('YYYY-MM-DD'), 'YYYY-MM-DD', true).isValid();
  }, []);

  useEffect(() => {
    const [from, to] = value;
    if (from && to && isValidDate(from) && isValidDate(to)) {
      props.setDateError?.(() => false);
    } else if (from && to) {
      props.setDateError?.(() => true);
    } else {
      props.setDateError?.(() => false);
    }
  }, [value, props.setDateError, isValidDate]);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {props.label && (
        <Typography variant="subtitle2">{props.label}</Typography>
      )}
      <Box>
        <StyledComponents.DateRangePickerRoot ref={ref} />
        <DesktopDateRangePicker<dayjs.Dayjs>
          calendars={1}
          value={value}
          maxDate={dayjs()}
          onChange={(newValue, keyboardInputValue) => {
            const currentValue = dayjs(keyboardInputValue, 'YYYY-MM-DD', true);
            if (!currentValue.isValid()) {
              if (startRef.current?.contains(document.activeElement)) {
                newValue[0] = currentValue;
              } else if (endRef.current?.contains(document.activeElement)) {
                newValue[1] = currentValue;
              }
            }

            if (newValue?.length === 2) {
              const [from, to] = newValue;
              if (from && to && isValidDate(from) && isValidDate(to)) {
                //if input field activated then date validation here
                props?.onChangeDateRange?.(from.toDate(), to.toDate());
              }
            }

            setValue(newValue);
          }}
          renderInput={(startProps, endProps) => (
            <Box display="flex" gap={1} alignItems="flex-start">
              <StyledComponents.BorderedFormControlWrapper
                error={!(value?.[0] && isValidDate(value?.[0]))}
                fullWidth
              >
                <TextField
                  {...startProps}
                  label=""
                  ref={startRef}
                  placeholder="Start date"
                  error={!(value?.[0] && isValidDate(value?.[0]))}
                  helperText={
                    !(value?.[0] && isValidDate(value?.[0]))
                      ? 'Invalid date'
                      : undefined
                  }
                />
              </StyledComponents.BorderedFormControlWrapper>
              <Box height={39} display="flex" alignItems="center">
                <Box width={10} height={2}>
                  <StyledComponents.DarkDivider
                    orientation="horizontal"
                    flexItem
                  />
                </Box>
              </Box>
              <StyledComponents.BorderedFormControlWrapper
                error={!(value?.[1] && isValidDate(value?.[1]))}
                fullWidth
              >
                <TextField
                  {...endProps}
                  label=""
                  ref={endRef}
                  placeholder="End date"
                  error={!(value?.[1] && isValidDate(value?.[1]))}
                  helperText={
                    !(value?.[1] && isValidDate(value?.[1]))
                      ? 'Invalid date'
                      : undefined
                  }
                />
              </StyledComponents.BorderedFormControlWrapper>
            </Box>
          )}
          inputFormat="YYYY-MM-DD"
          mask="____-__-__"
        />
      </Box>
    </Box>
  );
};

export default memo(DateRangeFilter);
