import { Breaches, IChartData, Detections } from '@fyeo-di-frontend/shared';
import qs from 'qs';
import { createContext, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

export type TDomainContextModel = ReturnType<typeof useDomainContextModel>;

const DomainContext = createContext<Partial<TDomainContextModel>>({});
const { Provider } = DomainContext;

export const useDomainContextModel = () => {
  const location = useLocation();
  const params = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const selectedCases = JSON.parse(params?.['caseIds'] as string) as {
    name: string;
    id: string;
  }[];
  const from = JSON.parse(params?.['from'] as string) as Date;
  const to = JSON.parse(params?.['to'] as string) as Date;
  const preparedFor = JSON.parse(params?.['preparedFor'] as string) as string;
  const preparedBy = JSON.parse(params?.['preparedBy'] as string) as string;

  const [topSources, setTopSources] = useState<
    {
      label: string;
      count: number;
      description: string;
    }[]
  >([]);

  const [chartData, setChartData] = useState<Record<Detections, IChartData[]>>({
    [Detections.New]: [],
    [Detections.Total]: [],
  });

  const [summary, setSummary] = useState<{
    entries: number;
    sources: number;
  }>({
    entries: 0,
    sources: 0,
  });

  const [topUsers, setTopUsers] = useState<
    {
      label: string;
      count: number;
    }[]
  >([]);

  const [topPasswords, setTopPasswords] = useState<
    {
      label: string;
      count: number;
    }[]
  >([]);

  const [listOfBreaches, setListOfBreaches] = useState<Breaches[]>([]);

  const dateRange = { from, to };

  return {
    dateRange,
    selectedCases,
    preparedFor,
    preparedBy,
    topSources,
    setTopSources,
    topUsers,
    setTopUsers,
    topPasswords,
    setTopPasswords,
    listOfBreaches,
    setListOfBreaches,
    summary,
    setSummary,
    chartData,
    setChartData,
  };
};

export const useDomainContext = () => useContext(DomainContext);

export const DomainContextProvider = Provider;
