import React, { useContext } from 'react';
import * as StyledComponent from './Incidents.styles';
import MDEditor, {
  commands,
  ICommand,
  EditorContext,
} from '@uiw/react-md-editor';
import Bold from '../static-assets/Bold.png';
import Strikethrough from '../static-assets/Strikethrough.png';
import Italic from '../static-assets/Italic.png';
import Ordered from '../static-assets/Ordered.png';
import Unordered from '../static-assets/Unordered.png';
import { Typography } from '@mui/material';

const Button = () => {
  const { preview, dispatch } = useContext(EditorContext);
  const click = () => {
    dispatch?.({
      preview: preview === 'edit' ? 'preview' : 'edit',
    });
  };
  if (preview === 'edit') {
    return (
      <StyledComponent.ButtonText>
        <StyledComponent.CommentText
          variant="subtitle2"
          textAlign="center"
          onClick={click}
        >
          Preview
        </StyledComponent.CommentText>
      </StyledComponent.ButtonText>
    );
  }
  return (
    <StyledComponent.ButtonText>
      <StyledComponent.CommentText
        variant="subtitle2"
        textAlign="center"
        onClick={click}
      >
        Write
      </StyledComponent.CommentText>
    </StyledComponent.ButtonText>
  );
};

const codePreview = {
  name: 'preview',
  keyCommand: 'preview',
  value: 'preview',
  icon: <Button />,
};

const bold: ICommand = {
  ...commands.getCommands().find((c) => c.name === 'bold'),
  icon: (
    <StyledComponent.OptionWrapper>
      <img alt="" src={Bold} />
    </StyledComponent.OptionWrapper>
  ),
};

const strikethrough: ICommand = {
  ...commands.getCommands().find((c) => c.name === 'strikethrough'),
  icon: (
    <StyledComponent.OptionWrapper>
      <img alt="" src={Strikethrough} />
    </StyledComponent.OptionWrapper>
  ),
};

const title: ICommand = {
  name: 'title',
  keyCommand: 'title',
  icon: (
    <Typography variant="subtitle1" textAlign="center">
      Write a comment
    </Typography>
  ),
};

const italic: ICommand = {
  ...commands.getCommands().find((c) => c.name === 'italic'),
  icon: (
    <StyledComponent.OptionWrapper>
      <img alt="" src={Italic} />
    </StyledComponent.OptionWrapper>
  ),
};

export const orderedListCommand: ICommand = {
  ...commands.getCommands().find((c) => c.name === 'ordered-list'),
  icon: (
    <StyledComponent.OptionWrapper>
      <img alt="" src={Ordered} />
    </StyledComponent.OptionWrapper>
  ),
};

export const unorderedListCommand: ICommand = {
  ...commands.getCommands().find((c) => c.name === 'unordered-list'),
  icon: (
    <StyledComponent.OptionWrapper>
      <img alt="" src={Unordered} />
    </StyledComponent.OptionWrapper>
  ),
};

interface OwnProps {
  value?: string;
  setValue: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const MarkdownEditor = ({ value, setValue }: OwnProps) => {
  return (
    <StyledComponent.MarkdownWrapper data-color-mode="light">
      <MDEditor
        value={value}
        onChange={setValue}
        preview="edit"
        commands={[title]}
        extraCommands={[
          bold,
          strikethrough,
          italic,
          orderedListCommand,
          unorderedListCommand,
          commands.divider,
          codePreview,
        ]}
      />
      <StyledComponent.EditorFooter>
        <div>
          <Typography variant="subtitle2">Supports Markdown.</Typography>
        </div>
      </StyledComponent.EditorFooter>
    </StyledComponent.MarkdownWrapper>
  );
};

export default MarkdownEditor;
