import {
  generateDetectionFromString,
  generateDetectionPrimaryColor,
  generateDetectionSecondaryColor,
  IChartData,
  Detections,
} from '@fyeo-di-frontend/shared';
import { Box, Typography } from '@mui/material';
import { LegendItem } from 'chart.js';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Line } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import {
  computeTotalDetections,
  generateLabels,
} from './DomainReport.chart.helpers';
import * as StyledComponents from './DomainReport.styles';
import { ILayoutItem } from './DomainReport.types';
import { Chart } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
Chart.register(zoomPlugin);

interface OwnProps {
  data?: Record<Detections, IChartData[]>;
}

const ChartComponent = (props: OwnProps) => {
  const labels = generateLabels('MMM DD', props?.data);

  const newChartData = useMemo(
    () => computeTotalDetections(props?.data),
    [props?.data],
  );

  const generateSeriesData = useCallback(
    (detections: Detections) => ({
      label: startCase(detections),
      fill: true,
      data: newChartData?.[detections].map((m) => m.count),
      lineTension: 0.4,
      borderWidth: 2,
      backgroundColor: generateDetectionSecondaryColor(
        generateDetectionFromString(detections),
      ),
      borderColor: generateDetectionPrimaryColor(
        generateDetectionFromString(detections),
      ),
    }),
    [newChartData],
  );

  const [legendItems, setLegendItems] = useState<LegendItem[]>([]);
  const lineRef = useRef<ChartJSOrUndefined<'line', number[], string>>(null);
  useEffect(() => {
    const line = lineRef.current;

    if (line && generateSeriesData(Detections.New)) {
      const legend: ILayoutItem = line.boxes[0];
      const legendItms = legend.legendItems;
      legendItms && setLegendItems(legendItms);
    }
  }, [lineRef]);

  return (
    <>
      <Box display="flex" gap={1.375} justifyContent="flex-start" ml={7}>
        {legendItems.map((items, i) => (
          <Box display="flex" gap={0.75} alignItems="center" key={i}>
            <StyledComponents.LegendLine
              strokeStyle={items.strokeStyle as string}
            />
            <Typography variant="body2">{items.text}</Typography>
          </Box>
        ))}
      </Box>
      {labels && Detections.New ? (
        <>
          <Line
            data={{
              labels: labels,
              datasets: [
                generateSeriesData(Detections.New),
                generateSeriesData(Detections.Total),
              ],
            }}
            ref={lineRef}
            options={{
              responsive: true,
              scales: {
                y: {
                  beginAtZero: true,
                  min: 0,
                  ticks: {
                    stepSize: 1,
                  },
                },
              },
              elements: {
                point: {
                  radius: 0,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: true,
                  backgroundColor: '#fff',
                  bodyColor: '#323232',
                  borderColor: 'rgba(1, 1, 1, 0.1)',
                  borderWidth: 1,
                  intersect: false,
                  displayColors: false,
                  cornerRadius: 8,
                  padding: 10,
                  callbacks: {
                    title: () => '',
                    label: (context) => {
                      const detection =
                        Detections[
                          context.dataset.label as keyof typeof Detections
                        ];
                      const barData =
                        newChartData?.[detection]?.[context.dataIndex];

                      return [
                        dayjs(barData?.date).format('MMM DD, YYYY'),
                        `${barData?.count} detected entries`,
                      ];
                    },
                  },
                },
                zoom: {
                  zoom: {
                    wheel: {
                      enabled: true,
                    },
                    pinch: {
                      enabled: true,
                    },
                    mode: 'x',
                  },
                  pan: {
                    enabled: true,
                    mode: 'x',
                  },
                },
              },
            }}
          />
        </>
      ) : null}
    </>
  );
};

export default memo(ChartComponent);
