import { SocialIcon } from '@fyeo-di-frontend/shared';
import {
  Close,
  Facebook,
  Instagram,
  LinkedIn,
  Pinterest,
  Reddit,
  Twitter,
  YouTube,
} from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import React, { memo } from 'react';
import { Social, SocialType } from '../Assets.types';
import * as StyledComponent from './AssetTypes.styles';

interface OwnProps {
  isThreatActor?: boolean;
  data?: Social[];
  onChange?: (data: Social) => void;
  onAdd?: () => void;
  onDelete?: (data: Social) => void;
  disabled?: boolean;
}

const renderSocialIcon = (type: SocialType) => {
  switch (type) {
    case SocialType.Facebook:
      return <Facebook />;
    case SocialType.Twitter:
      return <Twitter />;
    case SocialType.Instagram:
      return <Instagram />;
    case SocialType.LinkedIn:
      return <LinkedIn />;
    case SocialType.Pinterest:
      return <Pinterest />;
    case SocialType.Reddit:
      return <Reddit />;
    case SocialType.YouTube:
      return <YouTube />;
  }
  return undefined;
};

const SocialRender: React.FC<OwnProps> = (props) => {
  const onChangeType =
    (item: Social) => (evt: SelectChangeEvent<SocialType>) => {
      props.onChange?.({ ...item, tag: evt.target.value as SocialType });
    };

  const onChangeValue =
    (item: Social) => (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange?.({ ...item, value: event.target.value });
    };

  const onDelete = (item: Social) => () => {
    props.onDelete?.(item);
  };

  const color = props.isThreatActor ? 'secondary' : 'primary';

  return (
    <>
      <Box display="flex" width="100%" mt={2}>
        <Box mt={-1} ml={-1} mb={-1}>
          <StyledComponent.CursorBox onClick={props.onAdd} fontSize={40}>
            <SocialIcon color={color} fontSize="inherit" />
          </StyledComponent.CursorBox>
        </Box>
      </Box>
      {props?.data?.map?.((data) => (
        <Grid container spacing={1} key={data?._id}>
          <Grid item xs={3}>
            <StyledComponent.WrappedBox height={50}>
              <Select<SocialType>
                onChange={onChangeType(data)}
                size="small"
                fullWidth
                value={data?.tag}
                color={color}
                disabled={props.disabled}
              >
                {Object.keys(SocialType).map((key) => (
                  <MenuItem
                    key={key}
                    value={key as SocialType}
                    disabled={
                      ![SocialType.Twitter, SocialType.Reddit].some(
                        (s) => key === s,
                      )
                    }
                  >
                    {renderSocialIcon(key as SocialType)}
                  </MenuItem>
                ))}
              </Select>
            </StyledComponent.WrappedBox>
          </Grid>
          <Grid item xs={9}>
            <Box height={50}>
              <TextField
                disabled={props.disabled}
                value={data?.value || ''}
                onChange={onChangeValue(data)}
                size="small"
                color={color}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={onDelete?.(data)}>
                        <Close fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default memo(SocialRender);
