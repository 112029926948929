import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import { FilterStorageKey } from '@root/events/Events.types';
import { Option } from '@root/common/Common.types';

interface OwnProps {
  selectedValue?: Option;
  loading?: boolean;
  options?: Option[];
  onChange?: (selectedValue: Option | null, key: FilterStorageKey) => void;
  setInputValue?: (value: string) => void;
  loadMoreResults?: () => void;
}
const EventsSiteComponent = (props: OwnProps) => {
  const listElem = useRef<HTMLUListElement | null>(null);
  const mounted = useRef<boolean>();
  const [position, setPosition] = useState(0);

  const handleScroll: React.UIEventHandler<HTMLUListElement> = (event) => {
    const listboxNode = event.currentTarget;

    const pos = listboxNode.scrollTop + listboxNode.clientHeight;
    setPosition(pos);
    if (listboxNode.scrollHeight - pos <= 1) {
      props.loadMoreResults?.();
    }
  };

  useEffect(() => {
    if (!mounted.current) mounted.current = true;
    else if (position && listElem.current)
      listElem.current.scrollTop = position - listElem.current.offsetHeight;
  });

  return (
    <Box width="100%" display="flex" flexDirection="column" gap={1}>
      <Typography variant="subtitle2">Site</Typography>
      <Box maxWidth={300}>
        <Autocomplete
          ListboxProps={
            {
              ref: listElem,
              onScroll: handleScroll,
            } as React.HTMLAttributes<HTMLUListElement>
          }
          onChange={(event, newValue) =>
            props?.onChange?.(newValue, FilterStorageKey.Site)
          }
          onInputChange={(event, newInputValue) => {
            props?.setInputValue?.(newInputValue);
          }}
          options={props.loading ? [] : props?.options || []}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          loading={props.loading}
          value={props.selectedValue}
          filterSelectedOptions
        />
      </Box>
    </Box>
  );
};

export default memo(EventsSiteComponent);
