import {
  Box,
  ClickAwayListener,
  Fade,
  Grid,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import usePlacesAutocomplete from 'use-places-autocomplete';

const StyledPopper = styled(Popper)({
  zIndex: 1200,
});

interface OwnProps {
  address?: string;
  onChangeAddress?: (address: string) => void;
  disabled?: boolean;
  required?: boolean;
  isThreatActor?: boolean;
  readOnly?: boolean;
  placeholder?: string;
}

const LocationRenderer = ({
  address: inputAddress,
  onChangeAddress,
  required,
  disabled,
  isThreatActor,
  readOnly,
  placeholder,
}: OwnProps) => {
  const [address, setAddress] = useState<string>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const ref = useRef<HTMLInputElement>(null);

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const {
    suggestions: { data },
    setValue,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  useEffect(() => {
    setAddress(() => inputAddress);
  }, [inputAddress]);

  useEffect(() => {
    if (address) {
      setValue(address);
    }
  }, [address, setValue]);

  const onSelect = useCallback(
    (description: string) => {
      onChangeAddress?.(description);
      handleClose();
    },
    [onChangeAddress, handleClose],
  );

  const open = Boolean(anchorEl) && data?.length > 0;

  const handleChange = useCallback(
    (text: string) => {
      setAddress(text);
      if (text === '' && !required) onSelect(text);
    },
    [onSelect, required],
  );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" align="left">
              Location
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={address || ''}
              onChange={(evt) => handleChange(evt.target.value)}
              ref={ref}
              onBlur={handleClose}
              onFocus={() => setAnchorEl(ref.current)}
              disabled={disabled}
              color={isThreatActor ? 'secondary' : 'primary'}
              InputProps={{
                readOnly,
              }}
              placeholder={placeholder}
            />
            <StyledPopper
              open={open}
              transition
              anchorEl={anchorEl}
              disablePortal
              placement="bottom-start"
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={300}>
                  <Paper>
                    <Box width={350}>
                      <MenuList>
                        {data?.map(({ place_id, description }) => (
                          <MenuItem
                            onClick={() => onSelect(description)}
                            key={place_id}
                          >
                            <Typography
                              variant="subtitle2"
                              noWrap
                              title={description}
                            >
                              {description}
                            </Typography>
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Box>
                  </Paper>
                </Fade>
              )}
            </StyledPopper>
          </Grid>
        </Grid>
      </Box>
    </ClickAwayListener>
  );
};

export default LocationRenderer;
