import { createContext, useState, useContext } from 'react';
import {
  RIncidentsByDate,
  RIncidentsBySeverity,
  RIncidentsByAssetType,
  RNewOpenClosed,
  RIncidentsByIncidentType,
} from './Dashboard.types';

export type TDashboardContextModel = ReturnType<
  typeof useDashboardContextModel
>;

const DashboardContext = createContext<Partial<TDashboardContextModel>>({});
const { Provider } = DashboardContext;

export const useDashboardContextModel = () => {
  const [incidents, setIncidents] = useState<RIncidentsByDate>({
    closedIncidents: 0,
    newIncidents: 0,
    openIncidents: 0,
  });

  const [severity, setSeverity] = useState<RIncidentsBySeverity>({
    severeCount: 0,
    guardedCount: 0,
    highCount: 0,
    lowCount: 0,
    elevatedCount: 0,
    safeCount: 0,
  });

  const [detections, setDetections] = useState<RIncidentsByAssetType>({
    domainCount: 0,
    locationCount: 0,
    organizationCount: 0,
    personCount: 0,
    agentCount: 0,
  });

  const [newOpenClosed, setNewOpenClosed] = useState<Partial<RNewOpenClosed>>(
    {},
  );

  const [incidentTypes, setIncidentTypes] = useState<
    RIncidentsByIncidentType[]
  >([]);

  const [columns, setColumns] = useState<string[]>([]);
  const [data, setData] = useState<Record<string, Record<string, number>>>({});
  return {
    incidents,
    setIncidents,
    severity,
    setSeverity,
    detections,
    setDetections,
    newOpenClosed,
    setNewOpenClosed,
    incidentTypes,
    setIncidentTypes,
    columns,
    setColumns,
    data,
    setData,
  };
};

export const useDashboardContext = () => useContext(DashboardContext);

export const DashboardContextProvider = Provider;
