import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import ReportsDateRangeComponent from './Reports.dateRange.component';
import { Domain } from '@root/reports/Reports.types';

interface OwnProps {
  onDomainChange?: (val: Domain | null) => void;
  dateRange?: { from?: Date; to?: Date };
  domain?: Domain;
  domains?: Domain[];
  onChangeDateRange?: (from?: Date, to?: Date) => void;
  setSearch?: (value: string) => void;
  loading?: boolean;
  setDateError?: React.Dispatch<React.SetStateAction<boolean>>;
  loadMoreResults?: () => void;
}
const ReportsDomainFields = (props: OwnProps) => {
  const listElem = useRef<HTMLUListElement | null>(null);
  const mounted = useRef<boolean>();
  const [position, setPosition] = useState(0);

  const handleScroll: React.UIEventHandler<HTMLUListElement> = (event) => {
    const listboxNode = event.currentTarget;

    const pos = listboxNode.scrollTop + listboxNode.clientHeight;
    setPosition(pos);
    if (listboxNode.scrollHeight - pos <= 1) {
      props.loadMoreResults?.();
    }
  };

  useEffect(() => {
    if (!mounted.current) mounted.current = true;
    else if (position && listElem.current)
      listElem.current.scrollTop = position - listElem.current.offsetHeight;
  });

  return (
    <>
      <Box display="flex" flexDirection="column" gap={0.5} width={230}>
        <Typography variant="subtitle2">Domain</Typography>
        <Autocomplete
          ListboxProps={
            {
              ref: listElem,
              onScroll: handleScroll,
            } as React.HTMLAttributes<HTMLUListElement>
          }
          onChange={(event, newValue) => props?.onDomainChange?.(newValue)}
          onInputChange={(event, newInputValue) => {
            props?.setSearch?.(newInputValue);
          }}
          options={props.loading ? [] : props?.domains || []}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          loading={props.loading}
          value={props.domain}
          filterSelectedOptions
        />
      </Box>
      <ReportsDateRangeComponent
        dateRange={props.dateRange}
        onChangeDateRange={props.onChangeDateRange}
        setDateError={props.setDateError}
      />
    </>
  );
};

export default memo(ReportsDomainFields);
