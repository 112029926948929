import { createContext, useCallback, useContext, useState } from 'react';

export type TResetPasswordContextModel = ReturnType<
  typeof useResetPasswordContextModel
>;

const ResetPasswordContext = createContext<TResetPasswordContextModel>({
  oldPassword: '',
  password: '',
  confirmPassword: '',
  onChangeOldPassword: () => {},
  onChangePassword: () => {},
  onChangeConfirmPassword: () => {},
  isError: false,
  setIsError: () => {},
});
const { Provider } = ResetPasswordContext;

export const useResetPasswordContextModel = () => {
  const [oldPassword, setOldPassword] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);

  const onChangeOldPassword = useCallback(
    (value: string) => setOldPassword(() => value),
    [setOldPassword],
  );
  const onChangePassword = useCallback(
    (newPassword: string) => setPassword(() => newPassword),
    [setPassword],
  );
  const onChangeConfirmPassword = useCallback(
    (value: string) => setConfirmPassword(() => value),
    [setConfirmPassword],
  );

  return {
    oldPassword,
    password,
    confirmPassword,
    onChangePassword,
    onChangeConfirmPassword,
    onChangeOldPassword,
    isError,
    setIsError,
  };
};

export const useResetPasswordContext = () => useContext(ResetPasswordContext);

export const ResetPasswordContextProvider = Provider;
