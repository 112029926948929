import Root from '../Root.component';
import * as StyledComponents from './ResetPasswordResponse.styles';
import { CheckIcon, InfoIcon } from '@fyeo-di-frontend/shared';
import { Box, Link, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { SettingsRoutes } from '@root/common/Routes';
import { useNavigate, useLocation } from 'react-router-dom';

const ResetPasswordResponse = () => {
  const push = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state?.status) {
      push(SettingsRoutes.ResetPassword);
    }
  }, [state, push]);

  return (
    <Root>
      <StyledComponents.Root elevation={0}>
        <Box
          fontSize={100}
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={3}
        >
          {state?.status === 'success' ? (
            <>
              <CheckIcon fontSize="inherit" />
              <Typography variant="h6">
                Password changed successfully
              </Typography>
              <Box width={137} fontSize={0}>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  size="medium"
                  onClick={() => {
                    push(SettingsRoutes.Root);
                  }}
                >
                  OK
                </LoadingButton>
              </Box>
            </>
          ) : (
            <>
              <InfoIcon fontSize="inherit" />
              <Box>
                <Typography variant="h6" textAlign="center">
                  Password not changed
                </Typography>
                <Typography variant="subtitle2" textAlign="center">
                  Due to a technical error the password could not be changed.
                  <br /> Please try again. If the problem persists{' '}
                  <Link underline="none" href="#" target="_blank">
                    contact FYEO.
                  </Link>
                </Typography>
              </Box>
              <Box display="flex" gap={2} justifyContent="center">
                <Box width={137} fontSize={0}>
                  <LoadingButton
                    variant="contained"
                    fullWidth
                    size="medium"
                    onClick={() => {
                      push(SettingsRoutes.ResetPassword);
                    }}
                  >
                    Retry
                  </LoadingButton>
                </Box>
                <Box width={137} fontSize={0}>
                  <LoadingButton
                    variant="contained"
                    fullWidth
                    size="medium"
                    color="secondary"
                    onClick={() => {
                      push(SettingsRoutes.Root);
                    }}
                  >
                    Cancel
                  </LoadingButton>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </StyledComponents.Root>
    </Root>
  );
};

export default ResetPasswordResponse;
