import React from 'react';
import * as StyledComponents from './General.styles';
import { Typography } from '@mui/material';
import { Severity } from '../helpers';

interface OwnProps {
  label: string;
  severity?: Severity;
  onClick?: () => void;
  title?: string;
}
const PillsComponent = ({ label, severity, onClick, title }: OwnProps) => {
  return (
    <StyledComponents.PillsContainer severity={severity}>
      <Typography variant="subtitle1" fontSize={12}>
        {title ? `${title}: ` : ''}
        {label}
      </Typography>
      <div className="close" onClick={onClick}>
        <div />
        <div className="last-child" />
      </div>
    </StyledComponents.PillsContainer>
  );
};

export default PillsComponent;
