import React from 'react';
import * as StyledComponents from './ApiAccess.styles';
import {
  Box,
  Checkbox,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import useApiAccessHook from './ApiAccess.hooks';
import useGenerateKeyHook from './ApiAccess.generateKey.hooks';
import WebhookApiKey from './ApiAccess.webhookApiKey.component';

const ApiAccess = () => {
  const {
    cases,
    groups,
    searchText,
    setSearchText,
    isCheckedGroup,
    onGroupCheck,
    selectedCases,
    isIndeterminateGroup,
    onChangeSelectedCases,
    isDisabled,
  } = useApiAccessHook();

  const {
    isCopied,
    handleCopyClick,
    apiKey,
    isGenerating,
    onGenerate,
    webhook,
    createdWebhook,
    setCreatedWebhook,
    isCreatingWebhook,
    onCreateWebhook,
  } = useGenerateKeyHook();

  return (
    <StyledComponents.Root>
      <Box ml={2}>
        <Typography variant="h6">Select cases for API access</Typography>
      </Box>
      <StyledComponents.Wrapper>
        <Box position="sticky" top={0} bgcolor="#fff" zIndex={1}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p={2.5}
          >
            <Box display="flex" alignItems="center" gap={1.5}>
              <Typography variant="subtitle1">Case</Typography>
              <Box width={300}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchRoundedIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(evt) => {
                    setSearchText(() => evt.target.value);
                  }}
                  value={searchText}
                />
              </Box>
            </Box>
            <Typography variant="subtitle2">
              <Link
                href="https://fyeo-di-first-party-api-documentation-f-y-e-o-8dc31f71535c58731.gitlab.io"
                target="_blank"
              >
                How do I use FYEOs API?
              </Link>
            </Typography>
          </Box>
          <StyledComponents.HorizontalRule />
        </Box>
        {groups?.map((group) => (
          <React.Fragment key={group}>
            <Box display="flex" p={2.5} flexDirection="column" gap={1.75}>
              <StyledComponents.ReportedFormControlLabel
                control={
                  <Checkbox
                    indeterminate={isIndeterminateGroup(group)}
                    checked={isCheckedGroup(group)}
                    value={isCheckedGroup(group)}
                    disabled={isDisabled}
                    onChange={(_, checked) => {
                      onGroupCheck?.(group, checked);
                    }}
                  />
                }
                label={
                  <Typography variant="subtitle2" fontWeight="bold">
                    {group}
                  </Typography>
                }
                disableTypography
                labelPlacement="end"
              />
              <Grid container spacing={0.5}>
                {cases
                  ?.filter?.((aCase) => aCase?.group === group)
                  ?.map((aCase) => {
                    const selected =
                      (selectedCases || [])?.findIndex?.(
                        (id) => id === aCase.id,
                      ) >= 0;
                    return (
                      <Grid item xs={3} key={aCase.id}>
                        <StyledComponents.ReportedFormControlLabel
                          data-cy="case-item"
                          control={
                            <Checkbox
                              checked={selected}
                              value={selected}
                              disabled={isDisabled}
                              onChange={() => {
                                onChangeSelectedCases?.(aCase, selected);
                              }}
                            />
                          }
                          label={
                            <StyledComponents.EllipsisBox>
                              <Tooltip title={aCase.name}>
                                <StyledComponents.EllipsisText variant="subtitle2">
                                  {aCase.name}
                                </StyledComponents.EllipsisText>
                              </Tooltip>
                            </StyledComponents.EllipsisBox>
                          }
                          disableTypography
                          labelPlacement="end"
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
            <StyledComponents.HorizontalRule />
          </React.Fragment>
        ))}
      </StyledComponents.Wrapper>
      <WebhookApiKey
        isCopied={isCopied}
        handleCopyClick={handleCopyClick}
        isGenerating={isGenerating}
        onGenerate={onGenerate}
        selectedCases={selectedCases}
        apiKey={apiKey}
        url={webhook?.url}
        token={webhook?.token}
        createdWebhook={createdWebhook}
        isCreatingWebhook={isCreatingWebhook}
        setCreatedWebhook={setCreatedWebhook}
        onCreateWebhook={onCreateWebhook}
      />
    </StyledComponents.Root>
  );
};

export default ApiAccess;
