import { Box, Typography } from '@mui/material';
import * as StyledComponents from './Account.styles';
import { LoadingButton } from '@mui/lab';
import React, { ReactNode } from 'react';

interface OwnProps {
  title: string;
  children: ReactNode;
  buttonText: string;
  buttonAction?: () => void;
  buttonLoading?: boolean;
  dataCy?: string;
}
const CallToAction = (props: OwnProps) => {
  return (
    <Box display="flex" flexDirection="column" gap={2.875}>
      <Typography variant="subtitle1">{props.title}</Typography>
      <StyledComponents.BorderBox
        p={2.25}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        {props.children}
        <Box width={200}>
          <LoadingButton
            variant="contained"
            fullWidth
            size="medium"
            loading={props.buttonLoading}
            onClick={props.buttonAction}
            data-cy={props.dataCy}
          >
            {props.buttonText}
          </LoadingButton>
        </Box>
      </StyledComponents.BorderBox>
    </Box>
  );
};

export default CallToAction;
