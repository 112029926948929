import { Case } from '@fyeo-di-frontend/shared';
import { ExpandMore } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { memo, useCallback, useState } from 'react';
import * as StyledComponents from './Cases.styles';
import { EditIconWrapper } from './Cases.styles';

const CasesListItem = memo(
  ({
    aCase,
    onEditCase,
    selected,
    onChangeSelectedCases,
  }: {
    aCase: Case;
    selected: boolean;
    onEditCase?: (aCase: Case) => void;
    onChangeSelectedCases?: (
      aCase: Case,
      isDelete?: boolean | undefined,
    ) => void;
  }) => {
    const [isHover, setIsHover] = useState(false);
    return (
      <ListItem
        disablePadding
        dense
        disableGutters
        onMouseEnter={() => setIsHover(() => true)}
        onMouseLeave={() => setIsHover(() => false)}
        data-cy={`caseId-${selected}-${aCase.id}`}
      >
        <Tooltip title={aCase.name} placement="right-start">
          <StyledComponents.ListItemButtonStyled
            dense
            onClick={() => onChangeSelectedCases?.(aCase, selected)}
            selected={selected}
          >
            <ListItemText
              primary={aCase.name}
              primaryTypographyProps={{
                color: selected ? 'primary' : 'initial',
                noWrap: true,
                variant: 'subtitle2',
                fontSize: 12,
                paddingLeft: 1.125,
              }}
            />
            {isHover && (
              <EditIconWrapper
                onClick={(evt) => {
                  evt.stopPropagation();
                  evt.preventDefault();
                  onEditCase?.(aCase);
                }}
              />
            )}
          </StyledComponents.ListItemButtonStyled>
        </Tooltip>
      </ListItem>
    );
  },
);

CasesListItem.displayName = 'CasesListItem';

const RenderList = ({
  groups,
  cases,
  selectedCases,
  onChangeSelectedCases,
  onEditCase,
  onGroupCheck,
}: {
  groups?: string[];
  cases?: Case[];
  selectedCases?: Case[];
  onChangeSelectedCases?: (aCase: Case, isDelete?: boolean | undefined) => void;
  onEditCase?: (aCase: Case) => void;
  onGroupCheck?: (group: string, checked: boolean) => void;
}) => {
  const isCheckedGroup = useCallback(
    (group: string) => {
      return (
        selectedCases?.filter((c) => c.group === group).length ===
        cases?.filter((c) => c.group === group).length
      );
    },
    [selectedCases, cases],
  );

  const isIndeterminateGroup = useCallback(
    (group: string) => {
      return (
        selectedCases?.some((c) => c.group === group) && !isCheckedGroup(group)
      );
    },
    [selectedCases, isCheckedGroup],
  );

  return (
    <>
      {groups?.map((group, index) => (
        <StyledComponents.AccordionStyled
          defaultExpanded={index === 0}
          disableGutters
          square
          elevation={0}
          key={group}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pl={1.5}
          >
            <StyledComponents.AccordianSummaryStyled
              expandIcon={<ExpandMore />}
            >
              <Tooltip title={group}>
                <Typography
                  variant="subtitle1"
                  fontSize={12}
                  maxWidth={120}
                  noWrap
                >
                  {group}
                </Typography>
              </Tooltip>
            </StyledComponents.AccordianSummaryStyled>
            <Checkbox
              indeterminate={isIndeterminateGroup(group)}
              checked={isCheckedGroup(group)}
              value={isCheckedGroup(group)}
              onChange={(_, checked) => {
                onGroupCheck?.(group, checked);
              }}
            />
          </Box>
          <StyledComponents.CustomAccordionDetails>
            <List dense disablePadding>
              {cases
                ?.filter?.((aCase) => aCase?.group === group)
                ?.map((aCase) => {
                  const selected =
                    (selectedCases || [])?.findIndex?.(
                      (m) => m.id === aCase.id,
                    ) >= 0;
                  return (
                    <CasesListItem
                      key={aCase.id}
                      aCase={aCase}
                      selected={selected}
                      onChangeSelectedCases={onChangeSelectedCases}
                      onEditCase={onEditCase}
                    />
                  );
                })}
            </List>
          </StyledComponents.CustomAccordionDetails>
        </StyledComponents.AccordionStyled>
      ))}
    </>
  );
};

RenderList.displayName = 'RenderList';

export default memo(RenderList);
