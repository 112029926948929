import { LinearProgress } from '@mui/material';
import styled from 'styled-components';

export const HorizontalBarRoot = styled(LinearProgress)`
  && {
    height: 25px;
    .MuiLinearProgress-bar {
      background-color: #D5D8DF;
    }
    &.MuiLinearProgress-colorPrimary {
      background-color: #f5f6fa;
    }
  }
`;

export const HorizontalBarFullHeightRoot = styled(HorizontalBarRoot)`
  && {
    height: 100%;
  }
`;
