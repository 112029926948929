import { useEffect, useState } from 'react';
import { FileTypes } from '@root/events/Events.types';
import { Option } from '@root/common/Common.types';

const useEventsFileTypes = () => {
  const [fileTypes, setFileTypes] = useState<Option[]>([]);

  useEffect(() => {
    const fileModel: {
      name: string;
      id: string;
    }[] = Object.entries(FileTypes).map(([key, value]) => ({
      name: value as string,
      id: key,
    }));

    setFileTypes(() => fileModel);
  }, []);

  return {
    isLoading: false,
    fileTypes,
  };
};

export default useEventsFileTypes;
