import React from 'react';
import {
  ElevatedIcon,
  GuardedIcon,
  HighIcon,
  LowIcon,
  SevereIcon,
  SafeIcon,
} from '../icons';
import * as StyledComponents from './Severity.styles';

export enum SeverityWithoutNone {
  Elevated = 'elevated',
  Guarded = 'guarded',
  High = 'high',
  Low = 'low',
  Severe = 'severe',
  Safe = 'safe',
}

export enum Severity {
  None = -1,
  Safe = 0,
  Low = 1,
  Guarded = 2,
  Elevated = 3,
  High = 4,
  Severe = 5,
}

export const scaleDataForSeverity = (data: number[], value: number) => {
  const max = Math.max(...data);
  const min = Math.min(...data);
  const range = max - min;
  const scaledValue = ((value - min) / range) * 100;
  return scaledValue || 0;
};

export const generatePrimaryColor = (severity?: Severity) => {
  switch (severity) {
    case Severity.Elevated:
      return '#F0D941';
    case Severity.Guarded:
      return '#546DEC';
    case Severity.High:
      return '#FFB800';
    case Severity.Severe:
      return '#FF4F00';
    case Severity.Low:
      return '#7CD85B';
    case Severity.Safe:
      return '#767676';
    default:
      return '#7CD85B';
  }
};

export const generatePrimaryAndSecColor = (severity?: Severity) => {
  switch (severity) {
    case Severity.Elevated:
      return { backgroundColor: '#F9F2C9', color: '#E8BD15' };
    case Severity.Guarded:
      return { backgroundColor: '#D7DDF8', color: '#546DEC' };
    case Severity.High:
      return { backgroundColor: '#F9E9C9', color: '#EE9D00' };
    case Severity.Severe:
      return { backgroundColor: '#F9D9CB', color: '#FF4F00' };
    case Severity.Low:
      return { backgroundColor: '#DFF2DB', color: '#42BD16' };
    case Severity.Safe:
      return { backgroundColor: '#E2E2E2', color: '#767676' };
    default:
      return { backgroundColor: '#DCE0E5', color: '#363636' };
  }
};

export const generateJSX = (severity: Severity) => {
  let rec;
  switch (severity) {
    case Severity.Elevated:
      rec = <ElevatedIcon />;
      break;
    case Severity.Guarded:
      rec = <GuardedIcon />;
      break;
    case Severity.High:
      rec = <HighIcon />;
      break;
    case Severity.Severe:
      rec = <SevereIcon />;
      break;
    case Severity.Low:
      rec = <LowIcon />;
      break;
    case Severity.Safe:
      rec = <SafeIcon />;
      break;
  }

  return (
    <StyledComponents.SeverityWrapper>{rec}</StyledComponents.SeverityWrapper>
  );
};

export const generateSecondaryColor = (severity: Severity) => {
  switch (severity) {
    case Severity.Elevated:
      return '#fff7d4';
    case Severity.Guarded:
      return '#f0f4ff';
    case Severity.High:
      return '#ffecc7';
    case Severity.Severe:
      return '#ffe0d7';
    case Severity.Low:
      return '#f3ffee';
    case Severity.Safe:
      return '#E2E2E2';
  }
};

export const generateSeverityFromString = (data: string): Severity => {
  switch (data) {
    case 'elevated':
      return Severity.Elevated;
    case 'guarded':
      return Severity.Guarded;
    case 'high':
      return Severity.High;
    case 'low':
      return Severity.Low;
    case 'severe':
      return Severity.Severe;
    case 'safe':
      return Severity.Safe;
    default:
      return Severity.None;
  }
};

export const generateStringFromSeverity = (severity: Severity) => {
  switch (severity) {
    case Severity.Elevated:
      return 'Elevated';
    case Severity.Guarded:
      return 'Guarded';
    case Severity.High:
      return 'High';
    case Severity.Low:
      return 'Low';
    case Severity.Severe:
      return 'Severe';
  }
};
