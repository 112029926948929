import { MailIcon } from '@fyeo-di-frontend/shared';
import { Close } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import React, { memo } from 'react';
import * as StyledComponent from './CasesDetails.styles';

interface OwnProps {
  data?: string[];
  onChange?: (index: number, data: string) => void;
  onAdd?: () => void;
  onDelete?: (index: number) => void;
  singleMail?: boolean;
}

const EmailRender: React.FC<OwnProps> = (props) => {
  const onChangeUrlValue =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange?.(index, event.target.value);
    };

  const onDelete = (index: number) => () => {
    props.onDelete?.(index);
  };

  return (
    <>
      <Box display="flex" width="100%" mt={1}>
        <Box mt={-1} ml={0.5} mb={-1}>
          <StyledComponent.CursorBox
            onClick={props.onAdd}
            fontSize={50}
            height={50}
          >
            <MailIcon fontSize="inherit" color="primary" />
          </StyledComponent.CursorBox>
        </Box>
      </Box>
      <Grid container spacing={1}>
        {props?.data?.map?.((data, index) => (
          <Grid item key={index} xs={6}>
            <TextField
              value={data || ''}
              onChange={onChangeUrlValue(index)}
              InputProps={{
                readOnly: props.singleMail,
                endAdornment: !props.singleMail && (
                  <InputAdornment position="start">
                    <IconButton onClick={onDelete?.(index)}>
                      <Close fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default memo(EmailRender);
