import React from 'react';
import { Box, Typography } from '@mui/material';
import * as StyledComponents from './Dashboard.styles';
import { CustomLegend } from './Dashboard.types';
import { Severity } from '@fyeo-di-frontend/shared';

const DoughnutLegend = ({
  legendItems,
  onBarClick,
}: {
  legendItems: CustomLegend[];
  onBarClick?: (severity: Severity) => void;
}) => {
  return (
    <Box display="flex" gap={1.25} flexDirection="column">
      {legendItems.map((items, i) => (
        <StyledComponents.CursorBox
          display="flex"
          gap={0.45}
          alignItems="center"
          key={i}
          onClick={() => onBarClick?.(items.severity)}
          className={onBarClick && 'active-cursor'}
        >
          <StyledComponents.LegendCircle fillStyle={items.bgColor as string} />
          <Typography variant="body2" noWrap>
            {items.label}: {items.count}
          </Typography>
        </StyledComponents.CursorBox>
      ))}
    </Box>
  );
};

export default DoughnutLegend;
