import {
  Avatar,
  Box,
  Divider,
  FormControlLabel,
  ListItemIcon,
  Tabs,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { TabPanel } from '@mui/lab';

export const ReportedFormControlLabel = styled(FormControlLabel)({
  marginLeft: 0,
});

export const RoundedCornerAvatar = styled(Avatar)({
  borderRadius: 4,
});

export const ReportedItemIcon = styled(ListItemIcon)({
  minWidth: 'auto',
  marginRight: 12,
});

export const DataTypography = styled(Typography)({
  wordBreak: 'break-all',
});

export const SwitchWrapper = styled(Box)({
  transform: 'rotate(180deg)',
});

export const CustomDivider = styled(Divider)({
  marginLeft: 20,
  marginRight: 20,
});

export const TabPanelWrapper = styled(TabPanel)({
  padding: 0,
  backgroundColor: '#F1F3F6',
  borderRadius: '0px 4px 4px 4px',
  '.history-data': {
    maxHeight: 800,
    overflowY: 'auto',
  },
});

export const DividerBox = styled(Box)({
  borderBottom: 1,
  borderColor: 'divider',
});

export const TabLabel = styled(Tabs)({
  width: '100%',
  borderBottom: 0,
  marginBottom: -10,
  '.MuiTabs-flexContainer .MuiBox-root': {
    borderBottom: 0,
  },
  '.MuiButtonBase-root': {
    color: '#666',
  },
  button: {
    background: '#f7f9fc',
    marginRight: 5,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    '&.Mui-selected': {
      backgroundColor: '#F1F3F6',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      borderBottom: 0,
      color: '#323232',
    },
  },
});
