import React from 'react';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import {
  ReportsContextProvider,
  useReportsContext,
  useReportsContextModel,
} from './Reports.context';
import useDomainPdf from './Reports.domainPdf.hooks';
import ReportFormComponent from './Reports.form.component';
import useReportsHook from './Reports.hooks';
import usePeriodicPdf from './Reports.periodicPdf.hooks';
import * as StyledComponents from './Reports.styles';
import useReportsDomains from './Reports.domains.hooks';
import useDownloadDomainCSVHook from './Reports.downloadDomainCSV.hooks';

const Reports = () => {
  const {
    reportType,
    domain,
    dateRange,
    computeButtonDisability,
    cases,
    url,
    setDateError,
  } = useReportsContext();
  const {
    onChangeDateRange,
    onChangeReportType,
    onDomainChange,
    search,
    setSearch,
  } = useReportsHook();
  const { domains, isLoading, loadMore } = useReportsDomains(search);
  const data = {
    reportType,
    domain,
    domains,
    dateRange,
    isLoading,
  };
  const { isDownloadingDomainPdf, isGeneratingDomainPdf, onGenerateDomainPdf } =
    useDomainPdf();
  const {
    isDownloadingPeriodicPdf,
    isGeneratingPeriodicPdf,
    onGeneratePeriodicPdf,
  } = usePeriodicPdf();

  const { isDownloading, beginDownloadSequence } = useDownloadDomainCSVHook();

  const buttonActions = useMemo(() => {
    return {
      domain: {
        onDownloadPdf: onGenerateDomainPdf,
        onExportToCsv: beginDownloadSequence,
      },
      periodic: {
        onDownloadPdf: onGeneratePeriodicPdf,
      },
    };
  }, [onGenerateDomainPdf, onGeneratePeriodicPdf]);

  const loading = useMemo(() => {
    return {
      domain: {
        isDownloading: isDownloadingDomainPdf || isGeneratingDomainPdf,
        isExporting: isDownloading,
      },
      periodic: {
        isDownloading: isDownloadingPeriodicPdf || isGeneratingPeriodicPdf,
      },
    };
  }, [
    isGeneratingDomainPdf,
    isDownloadingDomainPdf,
    isDownloadingPeriodicPdf,
    isGeneratingPeriodicPdf,
    isDownloading,
  ]);

  const disabled = computeButtonDisability?.();
  return (
    <StyledComponents.Root>
      <Box ml={2} display="flex" flexDirection="column">
        <Typography variant="h6" fontWeight="bold">
          Generate report
        </Typography>
        <StyledComponents.FormWrapper>
          <ReportFormComponent
            onChangeReportType={onChangeReportType}
            onDomainChange={onDomainChange}
            onChangeDateRange={onChangeDateRange}
            data={data}
            disabled={disabled}
            caseIds={cases}
            url={url}
            buttonActions={buttonActions}
            loading={loading}
            setSearch={setSearch}
            setDateError={setDateError}
            loadMoreResults={loadMore}
          />
        </StyledComponents.FormWrapper>
      </Box>
    </StyledComponents.Root>
  );
};

const ReportsWithContext = () => {
  const contextData = useReportsContextModel();
  return (
    <ReportsContextProvider value={contextData}>
      <Reports />
    </ReportsContextProvider>
  );
};

export default ReportsWithContext;
