import { Typography } from '@mui/material';
import * as StyledComponent from './Root.styles';
import React, { memo } from 'react';
import zxcvbn from 'zxcvbn';

interface OwnProps {
  strength: zxcvbn.ZXCVBNScore;
}
const AuthPasswordComponent = ({ strength }: OwnProps) => {
  return (
    <>
      <Typography variant="body2" textAlign="left" mb={0.5}>
        Password needs to have at least 4 bars of strength.
      </Typography>
      <StyledComponent.StrengthMeter>
        {[...Array(5)].map((e, i) => {
          if (strength < 2) {
            return i <= strength ? (
              <StyledComponent.ActiveLowMeterBar key={i} />
            ) : (
              <StyledComponent.LowMeterBar key={i} />
            );
          } else if (strength >= 2 && strength < 3) {
            return i <= strength ? (
              <StyledComponent.ActiveMediumMeterBar key={i} />
            ) : (
              <StyledComponent.MediumMeterBar key={i} />
            );
          } else
            return i <= strength ? (
              <StyledComponent.ActiveHighMeterBar key={i} />
            ) : (
              <StyledComponent.HighMeterBar key={i} />
            );
        })}
      </StyledComponent.StrengthMeter>
    </>
  );
};

export default memo(AuthPasswordComponent);
