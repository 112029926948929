import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const SocialIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M17,12.32v1.33h2.31V16h1.36V13.65H23V12.32H20.68V9.94H19.32v2.38Z" />
    <path d="M12.91,14.2l-.33.32.13.44.4,1.4L11,15.59l-.24-.09-.25.08a7.47,7.47,0,0,1-2.21.33c-3.42,0-5.84-2.13-5.84-4.37S4.87,7.17,8.29,7.17s5.84,2.13,5.84,4.37A3.67,3.67,0,0,1,12.91,14.2Zm1.34,3.39L14,16.68l-.55-1.93a4.46,4.46,0,0,0,1.45-3.21c0-2.83-3-5.13-6.6-5.13s-6.6,2.3-6.6,5.13,2.95,5.13,6.6,5.13a8.42,8.42,0,0,0,2.44-.36l2.63,1Zm-8.6-5.27a.91.91,0,1,0-.88-.91A.9.9,0,0,0,5.65,12.32Zm3.52-.91a.88.88,0,1,1-.88-.91A.89.89,0,0,1,9.17,11.41Zm1.76.91a.91.91,0,1,0-.88-.91A.89.89,0,0,0,10.93,12.32Z" />
  </SvgIcon>
);

export default SocialIcon;
