import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const UrlIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M17,11.44v1.32h2.3v2.39h1.37V12.76H23V11.44H20.69V9.06H19.32v2.38Z" />
    <path d="M1.2,10.17a.8.8,0,0,1,.71-.88h8.73a.81.81,0,0,1,.71.88V14a.81.81,0,0,1-.71.88H1.91A.81.81,0,0,1,1.2,14Z" />
    <path fillRule="evenodd" d="M14.36,8a.35.35,0,0,0-.08-.13L10.86,4.45a.35.35,0,0,0-.13-.08.32.32,0,0,0-.14,0H3.73a.38.38,0,0,0-.38.38V9.29H1.91a.8.8,0,0,0-.71.88V14a.81.81,0,0,0,.71.88H3.35v2.41a.38.38,0,0,0,.38.38H14a.38.38,0,0,0,.38-.38V8.14A.32.32,0,0,0,14.36,8Zm-.73,8.9H4.12v-2h6.52a.81.81,0,0,0,.71-.88V10.17a.81.81,0,0,0-.71-.88H4.12V5.1h6.09v3a.38.38,0,0,0,.38.38h3Z" />
    <path fill="#fff" d="M4.47,13.3a.86.86,0,0,0,.93-.9V10.84H4.93v1.52a.42.42,0,0,1-.46.48A.42.42,0,0,1,4,12.36V10.84H3.55V12.4A.85.85,0,0,0,4.47,13.3Z" />
    <path fill="#fff" d="M7.11,13.25h.53l-.52-1a.67.67,0,0,0,.51-.68.71.71,0,0,0-.76-.74H5.93v2.41H6.4v-.93h.24ZM6.4,11.92v-.67h.38c.24,0,.38.13.38.33a.34.34,0,0,1-.38.34Z" />
    <path fill="#fff" d="M9.59,13.25V12.8H8.52v-2H8.05v2.41Z" />
  </SvgIcon>
);

export default UrlIcon;
