import { useLocation } from 'react-router-dom';
import { FilterStorageKey } from './Events.types';
import { useCallback, useEffect } from 'react';
import useEventsFiltersHook from './Events.filters.hooks';
import { useEventsContext } from './Events.context';

const useFilterRouterState = () => {
  const { clear } = useEventsContext();
  const {
    onChangeDateRange,
    onChangeEventSearchText,
    onChangeMultiSelect,
    onChangeMatchingScore,
    onChangeSite,
  } = useEventsFiltersHook();
  const { state } = useLocation();

  //restore from router state
  const restoreFromRouter = useCallback(() => {
    clear?.();
    const sRange = state?.dateRange;
    if (sRange?.from) {
      onChangeDateRange(sRange?.from, sRange?.to);
    }

    const sSearchText = state?.eventsSearchText;
    if (sSearchText) {
      onChangeEventSearchText(sSearchText);
    }

    const sAsset = state?.asset;
    if (sAsset) {
      onChangeMultiSelect(sAsset, FilterStorageKey.Asset);
    }

    const sIncidentsFilter = state?.incidents;
    if (sIncidentsFilter) {
      onChangeMultiSelect(sIncidentsFilter, FilterStorageKey.Incidents);
    }

    const sSourceTypes = state?.sourceType;
    if (sSourceTypes) {
      onChangeMultiSelect(sSourceTypes, FilterStorageKey.SourceType);
    }

    const sLanguages = state?.language;
    if (sLanguages) {
      onChangeMultiSelect(sLanguages, FilterStorageKey.Language);
    }

    const sFileTypes = state?.fileType;
    if (sFileTypes) {
      onChangeMultiSelect(sFileTypes, FilterStorageKey.FileType);
    }

    const sSites = state?.site;
    if (sSites) {
      onChangeSite(sSites, FilterStorageKey.Site);
    }

    const sThreatLevel = state?.threatLevel;
    if (typeof sThreatLevel != 'undefined') {
      onChangeMultiSelect(sThreatLevel, FilterStorageKey.ThreatLevel);
    }

    const sMatchingScore = state?.matchingScore;
    if (sMatchingScore) {
      onChangeMatchingScore(sMatchingScore);
    }
  }, [
    clear,
    onChangeDateRange,
    onChangeEventSearchText,
    onChangeMultiSelect,
    onChangeMatchingScore,
    onChangeSite,
    state,
  ]);

  // restore filters from state
  useEffect(() => {
    if (state) {
      restoreFromRouter();
    }
  }, [restoreFromRouter, state]);
};

export default useFilterRouterState;
