import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';

export interface IDToken {
  sub: string;
  'cognito:groups': string[];
  email_verified: boolean;
  iss: string;
  'cognito:username': string;
  given_name: string;
  origin_jti: string;
  aud: string;
  event_id: string;
  token_use: string;
  auth_time: number;
  name: string;
  exp: number;
  'custom:role': string;
  iat: number;
  family_name: string;
  jti: string;
  email: string;
}

export const tokenToJson = (token: string): IDToken =>
  jwtDecode<IDToken>(token);

export const isValidToken = (token?: string | null) => {
  let valid = false;
  if (token?.trim?.()) {
    const decodedJson = jwtDecode<IDToken>(token);
    valid = dayjs.unix(decodedJson.exp).diff(dayjs().add(5, 'm')) > 0;
  }
  return valid;
};
