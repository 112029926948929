import {
  deleteIncidentFilters,
  Incident,
  retrieveIncidentFilters,
  Severity,
  Status,
} from '@fyeo-di-frontend/shared';
import { SortDirection } from '@mui/material';
import dayjs from 'dayjs';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Descripted, FilterStorageKey } from './Incidents.types';
import { Option } from '@root/common/Common.types';
import useIncidentsHook from './Incidents.hooks';

export type TIncidentsContextModel = Partial<
  ReturnType<typeof useIncidentsContextModel>
>;

const IncidentsContext = createContext<TIncidentsContextModel>({});
const { Provider } = IncidentsContext;

export const useIncidentsContextModel = () => {
  const listingRef = useRef<HTMLDivElement>(null);
  const [incidents, setIncidents] = useState<Incident[]>([]);
  const [page, setPage] = useState<number>(1);
  const [shouldResetData, setShouldResetData] = useState<boolean>(true);

  // input fields
  const [assetInput, setAssetInput] = useState('');

  // filters
  const [dateRange, setDateRange] = useState<{ from?: Date; to?: Date }>({
    from: dayjs().subtract(1, 'month').toDate(),
    to: dayjs().toDate(),
  });
  const [incidentSearchText, setIncidentSearchText] = useState<string>('');
  const [incidentsTypes, setIncidentsTypes] = useState<Option[]>([]);

  const [assets, setAssets] = useState<Option[]>([]);
  const [assetTypes, setAssetTypes] = useState<Option[]>([]);
  const [severity, setSeverity] = useState<Descripted<typeof Severity>[]>([]);
  const [reported, setReported] = useState<{ name: string; id: boolean }[]>([]);
  const [statuses, setStatuses] = useState<Status | undefined>(undefined);

  //restore from local storage
  const restoreFromLocalStorage = useCallback(() => {
    const dRange = retrieveIncidentFilters<typeof dateRange>(
      FilterStorageKey.DateRange,
    );
    if (dRange?.from) {
      setDateRange(() => dRange);
    }

    const iSearchText = retrieveIncidentFilters<typeof incidentSearchText>(
      FilterStorageKey.IncidentSearchText,
    );
    if (iSearchText) {
      setIncidentSearchText(() => iSearchText);
    }

    const iATypes = retrieveIncidentFilters<typeof assetTypes>(
      FilterStorageKey.AssetTypes,
    );
    if (iATypes) {
      setAssetTypes(() => iATypes);
    }

    const iTypes = retrieveIncidentFilters<typeof incidentsTypes>(
      FilterStorageKey.IncidentsTypes,
    );
    if (iTypes) {
      setIncidentsTypes(() => iTypes);
    }

    const iAssets = retrieveIncidentFilters<typeof assets>(
      FilterStorageKey.Assets,
    );
    if (iAssets) {
      setAssets(() => iAssets);
    }

    const lReported = retrieveIncidentFilters<typeof reported>(
      FilterStorageKey.Reported,
    );
    if (lReported && lReported.length > 0) {
      setReported(() => lReported);
    }

    const lSeverity = retrieveIncidentFilters<typeof severity>(
      FilterStorageKey.Severity,
    );
    if (lSeverity) {
      setSeverity(() => lSeverity);
    }

    const lStatuses = retrieveIncidentFilters<typeof statuses>(
      FilterStorageKey.Statuses,
    );
    if (lStatuses || lStatuses === Status.Open) {
      setStatuses(() => lStatuses);
    }
  }, [
    setDateRange,
    setIncidentSearchText,
    setIncidentsTypes,
    setReported,
    setSeverity,
    setStatuses,
    setAssets,
    setAssetTypes,
  ]);

  // restore filters
  useEffect(() => {
    restoreFromLocalStorage();
  }, [restoreFromLocalStorage]);

  // sort
  const [sort, setSort] = useState<
    Partial<{
      key: keyof Incident;
      sortDirection?: SortDirection;
    }>
  >({});

  const clear = useCallback(() => {
    setDateRange(() => ({
      from: dayjs().subtract(1, 'month').toDate(),
      to: dayjs().toDate(),
    }));
    setIncidentSearchText(() => '');
    setIncidentsTypes(() => []);
    setAssets(() => []);
    setReported(() => []);
    setSeverity(() => []);
    setStatuses(() => undefined);
    setSort(() => ({}));

    // clear local storage
    deleteIncidentFilters(FilterStorageKey.DateRange);
    deleteIncidentFilters(FilterStorageKey.IncidentSearchText);
    deleteIncidentFilters(FilterStorageKey.IncidentsTypes);
    deleteIncidentFilters(FilterStorageKey.Assets);
    deleteIncidentFilters(FilterStorageKey.Reported);
    deleteIncidentFilters(FilterStorageKey.Severity);
    deleteIncidentFilters(FilterStorageKey.Statuses);
  }, [
    setDateRange,
    setIncidentSearchText,
    setIncidentsTypes,
    setReported,
    setSeverity,
    setStatuses,
    setSort,
    setAssets,
  ]);

  const resetDataAndPage = useCallback(() => {
    setPage(() => 1);
  }, [setPage]);

  const { isLoading, handleLoadMore, onEventClick, refetch } = useIncidentsHook(
    {
      dateRange,
      incidentSearchText,
      incidentsTypes,
      severity,
      statuses,
      reported,
      sort,
      setIncidents,
      page,
      setPage,
      resetDataAndPage,
      assets,
      assetTypes,
      shouldResetData,
      setShouldResetData,
      listingRef,
    },
  );

  return {
    incidents,
    dateRange,
    incidentSearchText,
    incidentsTypes,
    severity,
    reported,
    statuses,
    sort,
    setIncidents,
    setDateRange,
    setIncidentSearchText,
    setIncidentsTypes,
    setSeverity,
    setReported,
    setStatuses,
    setSort,
    clear,
    page,
    setPage,
    resetDataAndPage,
    assetInput,
    setAssetInput,
    assets,
    setAssets,
    setAssetTypes,
    assetTypes,
    isLoading,
    handleLoadMore,
    onEventClick,
    refetch,
    listingRef,
  };
};

export const useIncidentsContext = () => useContext(IncidentsContext);

export const IncidentsContextProvider = Provider;
