import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';

interface OwnProps {
  searchText?: string;
  onChangeSearchText?: (searchText: string) => void;
}

const FilterSearchComponent = (props: OwnProps) => {
  const { searchText: pSearchText, onChangeSearchText } = props;
  const [searchText, setSearchText] = useState<string>();

  useEffect(() => {
    setSearchText(() => pSearchText);
  }, [pSearchText, setSearchText]);

  return (
    <Box display="flex" flexDirection="column" gap={1} width="100%">
      <Typography variant="subtitle2">Free search</Typography>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchRoundedIcon />
            </InputAdornment>
          ),
        }}
        onChange={(evt) => {
          setSearchText(() => evt.target.value);
          onChangeSearchText?.(evt.target.value);
        }}
        value={searchText || ''}
      />
    </Box>
  );
};

export default memo(FilterSearchComponent);
