import {
  Breaches,
  generateJSX,
  Severity,
  TableBodyLoader,
} from '@fyeo-di-frontend/shared';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React, { memo } from 'react';
import * as StyledComponent from './DomainReport.styles';
import NoResultIconUrl from './NoResultIcon.svg';

interface Props {
  loading?: boolean;
  data?: Breaches[];
}

const DomainReportTableData = ({ loading, data }: Props) => {
  return (
    <Box mb={1} mt={1}>
      <Table size="small">
        <StyledComponent.TableHeaderStyled>
          <TableRow>
            <StyledComponent.TableHeaderCell width={100}>
              <Typography variant="subtitle1" noWrap>
                Email
              </Typography>
            </StyledComponent.TableHeaderCell>
            <StyledComponent.TableHeaderCell width="40%">
              <Typography variant="subtitle1" noWrap>
                Source
              </Typography>
            </StyledComponent.TableHeaderCell>
            <StyledComponent.TableHeaderCell width="10%">
              <Typography variant="subtitle1" noWrap>
                Threat level
              </Typography>
            </StyledComponent.TableHeaderCell>
          </TableRow>
        </StyledComponent.TableHeaderStyled>
        <TableBody>
          {data?.map((row, i) => (
            <StyledComponent.TableBodyRow key={i}>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.source}</TableCell>
              <TableCell align="center">
                {generateJSX(row.threatLevel as Severity)}
              </TableCell>
            </StyledComponent.TableBodyRow>
          ))}
        </TableBody>
        {loading && <TableBodyLoader totalCols={3} rowCount={20} />}
      </Table>
      {!data?.length && (
        <StyledComponent.NoResultContainer elevation={0}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            <StyledComponent.NoResultIcon src={NoResultIconUrl} />
            <Typography variant="h5">No results.</Typography>
          </Box>
        </StyledComponent.NoResultContainer>
      )}
    </Box>
  );
};

export default memo(DomainReportTableData);
