import * as StyledComponents from '@root/error/Error.styles';
import NoResultIconUrl from '@root/common/NoResultIcon.svg';
import { Box, Link, Typography } from '@mui/material';
import React from 'react';

interface OwnProps {
  label: string;
  details?: string;
  buttonText: string;
  buttonAction: () => void;
}
const ErrorComponent = ({
  label,
  buttonAction,
  buttonText,
  details,
}: OwnProps) => {
  return (
    <>
      <StyledComponents.Root>
        <StyledComponents.ContainerWrapper maxWidth="sm">
          <StyledComponents.PaperRoot elevation={0}>
            <StyledComponents.NoResultIcon src={NoResultIconUrl} />
            <Typography variant="subtitle1" textAlign="center">
              {label}
            </Typography>
            {details && (
              <Typography variant="subtitle2" textAlign="center" width={400}>
                {details}. If the problem persists{' '}
                <Link
                  href="mailto:hello@gofyeo.com"
                  target="_blank"
                  referrerPolicy="no-referrer"
                  underline="none"
                >
                  contact FYEO.
                </Link>
              </Typography>
            )}
            <Box minWidth={100} mt={2}>
              <StyledComponents.NoWrapButtonText
                variant="contained"
                fullWidth
                size="small"
                onClick={buttonAction}
              >
                {buttonText}
              </StyledComponents.NoWrapButtonText>
            </Box>
          </StyledComponents.PaperRoot>
        </StyledComponents.ContainerWrapper>
      </StyledComponents.Root>
    </>
  );
};

export default ErrorComponent;
