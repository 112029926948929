import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { SortDirection, Typography, Box } from '@mui/material';
import React, { memo } from 'react';

interface OwnProps {
  title: string;
  sortDirection?: SortDirection;
  centered?: boolean;
}

const TableHeaderWithSort: React.FC<OwnProps> = (props) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent={props.centered ? 'center' : undefined}
  >
    <Box mt={0.3}>
      <Typography variant="subtitle1" noWrap>
        {props.title}
      </Typography>
    </Box>
    {props.sortDirection === 'asc' && <KeyboardArrowUp />}
    {props.sortDirection === 'desc' && <KeyboardArrowDown />}
  </Box>
);

export default memo(TableHeaderWithSort);
