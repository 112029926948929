import React from 'react';
import { Box, Typography } from '@mui/material';
import { LegendItem } from 'chart.js';
import * as StyledComponents from './Dashboard.styles';

const LegendComponent: React.FC<{ legendItems: LegendItem[] }> = ({
  legendItems,
}) => {
  return (
    <Box display="flex" gap={1.375} justifyContent="flex-end" mr={2}>
      {legendItems.map((items, i) => (
        <Box display="flex" gap={0.75} alignItems="center" key={i}>
          <StyledComponents.LegendLine
            strokeStyle={items.strokeStyle as string}
          />
          <Typography variant="body2">{items.text}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default LegendComponent;
