import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Close } from '@mui/icons-material';
import React from 'react';

interface OwnProps {
  close: boolean;
  setClose: () => void;
  onSave: () => void;
}
const AssetTypesCloseDialogComponent = ({
  close,
  setClose,
  onSave,
}: OwnProps) => {
  return (
    <Dialog open={close} onClose={setClose} scroll="body">
      <DialogTitle>
        <Box position="absolute" right={8} top={8} color="black">
          <IconButton onClick={() => setClose()} color="inherit">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="subtitle1" fontWeight="bold">
            You have unsaved changes!
          </Typography>
          <Typography variant="subtitle2">
            Do you want to save the new asset?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          margin="auto"
          textAlign="center"
          gap={2}
          display="flex"
          mb={3}
          mx={3}
        >
          <Box width={126}>
            <Button
              onClick={() => {
                onSave();
              }}
              variant="contained"
              color="primary"
              size="small"
              fullWidth
            >
              Save
            </Button>
          </Box>
          <Box width={150}>
            <Button
              onClick={() => setClose()}
              variant="contained"
              color="secondary"
              size="small"
              fullWidth
            >
              Discard changes
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AssetTypesCloseDialogComponent;
