import {
  Case,
  IncidentDetails,
  Severity,
  SourceType,
  Status,
} from '@fyeo-di-frontend/shared';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import * as StyledComponent from './IncidentsDetails.styles';

interface OwnProps {
  data?: Partial<IncidentDetails>;
  onChangeData?: (
    key: keyof IncidentDetails,
    value: string | Severity | Status | Date | boolean | SourceType,
  ) => void;
  onSave?: () => void;
  isSaving?: boolean;
  cases?: Case[];
  refetchIncidents?: () => void;
  isChanged: boolean;
  isNew?: boolean;
  incidentTypeOptions?: { name: string; id: string }[];
}

const IncidentDetailsComponent: React.FC<OwnProps> = (props) => {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box
        display="flex"
        flexDirection="row"
        gap={1}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography variant="h6" fontWeight="bold">
          Title
        </Typography>
        <Typography variant="h6" fontWeight="bold">
          Date: {dayjs(props?.data?.date).format('YYYY-MM-DD')}
        </Typography>
      </Box>
      <Box fontWeight="bold">
        <TextField
          value={props?.data?.details || ''}
          onChange={(evt) => props?.onChangeData?.('details', evt.target.value)}
        />
      </Box>
      <Typography variant="h6">Summary</Typography>
      <TextField
        multiline
        rows={7}
        value={props?.data?.summary || ''}
        onChange={(evt) => props?.onChangeData?.('summary', evt.target.value)}
      />
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Case</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Severity</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Incident type</Typography>
        </Grid>
        <Grid item xs={4}>
          <Select<string>
            onChange={(evt) =>
              props?.onChangeData?.('caseId', evt.target.value)
            }
            size="small"
            fullWidth
            value={props?.data?.caseId || ''}
            defaultValue=""
            disabled
          >
            {props.cases?.map((item) => (
              <MenuItem key={item.id} value={item.id} dense>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Select<Severity>
            onChange={(evt) =>
              props?.onChangeData?.('severity', evt.target.value as Severity)
            }
            size="small"
            fullWidth
            value={props?.data?.severity || Severity.Safe}
            defaultValue={Severity.Safe}
          >
            <MenuItem value={Severity.Safe} dense>
              {Severity[Severity.Safe]}
            </MenuItem>
            <MenuItem value={Severity.Low} dense>
              {Severity[Severity.Low]}
            </MenuItem>
            <MenuItem value={Severity.Guarded} dense>
              {Severity[Severity.Guarded]}
            </MenuItem>
            <MenuItem value={Severity.Elevated} dense>
              {Severity[Severity.Elevated]}
            </MenuItem>
            <MenuItem value={Severity.High} dense>
              {Severity[Severity.High]}
            </MenuItem>
            <MenuItem value={Severity.Severe} dense>
              {Severity[Severity.Severe]}
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Select<string>
            onChange={(evt) =>
              props?.onChangeData?.('incidentType', evt.target.value)
            }
            size="small"
            fullWidth
            value={props?.data?.incidentType || ''}
            defaultValue={''}
          >
            {props.incidentTypeOptions?.map(({ id, name }, index) => (
              <MenuItem value={id} dense key={`${id}-${index}`}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Source</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Source type</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={props?.data?.source || ''}
            onChange={(evt) =>
              props?.onChangeData?.('source', evt.target.value)
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Select<SourceType>
            onChange={(evt) =>
              props?.onChangeData?.(
                'sourceType',
                evt.target.value as SourceType,
              )
            }
            size="small"
            fullWidth
            value={props?.data?.sourceType || SourceType.None}
          >
            {Object.entries(SourceType).map(([key, value]) => {
              if (key === 'None') {
                return (
                  <MenuItem value={value} disabled dense>
                    None
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem value={value} dense>
                    {key}
                  </MenuItem>
                );
              }
            })}
          </Select>
        </Grid>
      </Grid>
      <Typography variant="subtitle1">Recommendation</Typography>
      <TextField
        multiline
        rows={7}
        value={props?.data?.recommendation || ''}
        onChange={(evt) =>
          props?.onChangeData?.('recommendation', evt.target.value)
        }
      />
      <Box display="flex">
        <StyledComponent.ReportedFormControlLabel
          control={
            <Checkbox
              checked={!!props?.data?.reported}
              value={!!props?.data?.reported}
              onChange={(_, checked) =>
                props?.onChangeData?.('reported', checked)
              }
            />
          }
          label={<Typography variant="subtitle2">Reported</Typography>}
          disableTypography
          labelPlacement="start"
        />
      </Box>
      <Typography variant="subtitle1">Status</Typography>
      <Box display="flex">
        <Typography variant="body1">Open</Typography>
        <StyledComponent.SwitchWrapper mt={-1}>
          <Switch
            checked={props?.data?.status === Status.Open}
            value={props?.data?.status === Status.Open}
            onChange={(_, checked) =>
              props?.onChangeData?.(
                'status',
                checked ? Status.Open : Status.Closed,
              )
            }
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </StyledComponent.SwitchWrapper>
        <Typography variant="body1">Closed</Typography>
      </Box>

      <Box margin="auto" width={257}>
        <LoadingButton
          variant="contained"
          onClick={props?.onSave}
          fullWidth
          loading={props?.isSaving}
          disabled={!props?.isChanged}
        >
          Save changes
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default IncidentDetailsComponent;
