import {
  AssetTypeRender,
  HorizontalFullHeightLoader,
} from '@fyeo-di-frontend/shared';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import * as StyledComponent from './Incidents.styles';

interface OwnProps<T> {
  isLoading: boolean;
  items: {
    assetType: T;
    assetName?: string;
    name?: string;
    domainName?: string;
  }[];
  label: string;
  isThreatActor: boolean;
}
function IncidentsThreatTarget<T>(props: OwnProps<T>) {
  return (
    <>
      {!!props.items.length && (
        <>
          <Typography variant="h6">{props.label}</Typography>
          {props.isLoading && (
            <Box height={70}>
              <HorizontalFullHeightLoader />
            </Box>
          )}
          <Box bgcolor="#F1F3F6" borderRadius={2} minHeight={50}>
            <List dense>
              {props.items?.map((item) => (
                <ListItem
                  key={(props.isThreatActor && item.name) || item.assetName}
                  disablePadding
                >
                  <ListItemButton>
                    <StyledComponent.ReportedItemIcon>
                      <AssetTypeRender
                        assetType={item.assetType}
                        size="large"
                        isThreatActor={props.isThreatActor}
                      />
                    </StyledComponent.ReportedItemIcon>
                    <ListItemText
                      primary={
                        (props.isThreatActor && item.name) || item.assetName
                      }
                      primaryTypographyProps={{
                        variant: 'body1',
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </>
      )}
    </>
  );
}

export default IncidentsThreatTarget;
