import { Case } from '@fyeo-di-frontend/shared';
import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppContext } from '../../App.context';
import { tokenToJson } from '../../common/Jwt';
import CaseDetailsComponent from './CasesDetails.component';
import useCasesDetailsHooks from './CasesDetails.hooks';
import CasesDetailsLoaderComponent from './CasesDetails.loader.component';
import { isStaff } from '@root/common/Utils.helpers';

interface OwnProps {
  selectedCaseId: string;
  cases: Case[];
  onClose?: () => void;
}

const CasesDetails: React.FC<OwnProps> = (props) => {
  const [groups, setGroups] = useState<{ value: string; label: string }[]>([]);
  const { idToken } = useAppContext();

  const contextGroups = useMemo(() => {
    return idToken ? tokenToJson(idToken)['cognito:groups'] : [];
  }, [idToken]);

  const userEmail = useMemo(() => {
    return idToken ? tokenToJson(idToken)['email'] : undefined;
  }, [idToken]);

  const {
    details,
    onChangeData,
    isLoading,
    isSaving,
    onSave,
    closeCaseDialog,
    onDeleteEmail,
  } = useCasesDetailsHooks(props.selectedCaseId);

  useEffect(() => {
    setGroups(() =>
      Array.from(new Set(contextGroups)).map((item) => ({
        value: item,
        label: item,
      })),
    );
  }, [contextGroups]);

  useEffect(() => {
    if (closeCaseDialog) {
      props?.onClose?.();
    }
  }, [closeCaseDialog, props]);

  return (
    <Box
      pt={2}
      pb={5}
      pl={20}
      pr={20}
      display="flex"
      flexDirection="column"
      gap={4}
    >
      {isLoading && <CasesDetailsLoaderComponent />}
      {!isLoading && (
        <CaseDetailsComponent
          data={details}
          onChangeData={onChangeData}
          onSave={onSave}
          isSaving={isSaving}
          groupOptions={groups}
          selectedCaseId={props.selectedCaseId}
          isStaff={isStaff(idToken)}
          userEmail={userEmail}
          onDeleteEmail={onDeleteEmail}
        />
      )}
    </Box>
  );
};

export default CasesDetails;
