import {
  deleteIncidentFilters,
  Severity,
  Status,
  storeIncidentFilters,
} from '@fyeo-di-frontend/shared';
import debounce from 'lodash/debounce';
import { useCallback, useMemo } from 'react';
import { useIncidentsContext } from './Incidents.context';
import { Descripted, FilterStorageKey } from './Incidents.types';
import { Option } from '@root/common/Common.types';

let handleOnChangeSearchText: ReturnType<typeof debounce>;

const useIncidentsFiltersHook = () => {
  const {
    setDateRange,
    setIncidentSearchText,
    setIncidentsTypes,
    setSeverity,
    setReported,
    setStatuses,
    setAssetTypes,
    resetDataAndPage,
    reported,
    severity,
    incidentsTypes,
    setAssets,
    assets,
    assetTypes,
  } = useIncidentsContext();

  const onChangeDateRange = useCallback(
    (from?: Date, to?: Date) => {
      storeIncidentFilters(FilterStorageKey.DateRange, { from, to });
      setDateRange?.(() => ({ from, to }));
      resetDataAndPage?.();
    },
    [setDateRange, resetDataAndPage],
  );

  const onChangeIncidentSearchText = useMemo(() => {
    handleOnChangeSearchText?.cancel?.();
    handleOnChangeSearchText = debounce((val: string) => {
      storeIncidentFilters(FilterStorageKey.IncidentSearchText, val);
      setIncidentSearchText?.(() => val);
      resetDataAndPage?.();
    }, 100);
    return handleOnChangeSearchText;
  }, [setIncidentSearchText, resetDataAndPage]);

  const onChangeIncidentTypes = useCallback(
    (val: Option[]) => {
      storeIncidentFilters(FilterStorageKey.IncidentsTypes, val);
      setIncidentsTypes?.(() => val);
      resetDataAndPage?.();
    },
    [setIncidentsTypes, resetDataAndPage],
  );

  const onChangeAssets = useCallback(
    (val: Option[]) => {
      storeIncidentFilters(FilterStorageKey.Assets, val);
      setAssets?.(() => val);
      resetDataAndPage?.();
    },
    [setAssets, resetDataAndPage],
  );

  const onChangeAssetTypes = useCallback(
    (val: Option[]) => {
      storeIncidentFilters(FilterStorageKey.AssetTypes, val);
      setAssetTypes?.(() => val);
      resetDataAndPage?.();
    },
    [setAssetTypes, resetDataAndPage],
  );

  const onRemoveIncidentType = useCallback(
    (val: Option) => {
      const v = incidentsTypes?.filter((i) => i.id !== val.id) || [];
      onChangeIncidentTypes(v);
    },
    [incidentsTypes, onChangeIncidentTypes],
  );

  const onRemoveAsset = useCallback(
    (val: Option) => {
      const v = assets?.filter((i) => i.id !== val.id) || [];
      onChangeAssets(v);
    },
    [assets, onChangeAssets],
  );

  const onRemoveAssetTypes = useCallback(
    (val: Option) => {
      const v = assetTypes?.filter((i) => i.id !== val.id) || [];
      onChangeAssetTypes(v);
    },
    [assetTypes, onChangeAssetTypes],
  );

  const onChangeSeverity = useCallback(
    (val: Descripted<typeof Severity>[]) => {
      storeIncidentFilters(FilterStorageKey.Severity, val);
      setSeverity?.(() => val);
      resetDataAndPage?.();
    },
    [setSeverity, resetDataAndPage],
  );

  const onRemoveSeverity = useCallback(
    (val: Descripted<typeof Severity>) => {
      const v = severity?.filter((i) => i.id !== val.id) || [];
      onChangeSeverity(v);
    },
    [severity, onChangeSeverity],
  );

  const onChangeReported = useCallback(
    (val: { name: string; id: boolean }[]) => {
      storeIncidentFilters(FilterStorageKey.Reported, val);
      setReported?.(() => val);
      resetDataAndPage?.();
    },
    [setReported, resetDataAndPage],
  );

  const onReportedOrNotReported = useCallback(
    (val: boolean) => {
      const v = reported?.filter((e) => e.id !== val) || [];
      onChangeReported(v);
    },
    [reported, onChangeReported],
  );

  const onChangeStatuses = useCallback(
    (val?: Status) => {
      if (val === undefined) deleteIncidentFilters(FilterStorageKey.Statuses);
      else {
        storeIncidentFilters(FilterStorageKey.Statuses, val);
      }
      setStatuses?.(() => val);
      resetDataAndPage?.();
    },
    [setStatuses, resetDataAndPage],
  );

  const onOpenOrCloseStatus = useCallback(() => {
    onChangeStatuses(undefined);
  }, [onChangeStatuses]);

  return {
    onChangeDateRange,
    onChangeIncidentSearchText,
    onChangeIncidentTypes,
    onChangeSeverity,
    onChangeStatuses,
    onChangeReported,
    onReportedOrNotReported,
    onOpenOrCloseStatus,
    onRemoveIncidentType,
    onRemoveSeverity,
    onChangeAssets,
    onRemoveAsset,
    onChangeAssetTypes,
    onRemoveAssetTypes,
  };
};

export default useIncidentsFiltersHook;
