import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const OrganizationIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 31 31" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.543 2.2168C13.1958 2.2168 11.293 4.11959 11.293 6.4668C11.293 8.81401 13.1958 10.7168 15.543 10.7168C17.8902 10.7168 19.793 8.81401 19.793 6.4668C19.793 4.11959 17.8902 2.2168 15.543 2.2168ZM9.79297 6.4668C9.79297 3.29116 12.3673 0.716797 15.543 0.716797C18.7186 0.716797 21.293 3.29116 21.293 6.4668C21.293 8.38406 20.3546 10.0822 18.9121 11.1269L26.9511 22.7387C28.8302 22.9421 30.293 24.5336 30.293 26.4668C30.293 28.5379 28.614 30.2168 26.543 30.2168C24.7288 30.2168 23.2154 28.9285 22.868 27.2168H19.218C18.8705 28.9285 17.3572 30.2168 15.543 30.2168C13.7288 30.2168 12.2154 28.9285 11.868 27.2168H8.21796C7.8705 28.9285 6.35719 30.2168 4.54297 30.2168C2.4719 30.2168 0.792969 28.5379 0.792969 26.4668C0.792969 24.5337 2.2557 22.9422 4.13474 22.7388L12.1738 11.1268C10.7313 10.0821 9.79297 8.38403 9.79297 6.4668ZM19.218 25.7168H22.868C23.1296 24.4282 24.0519 23.3795 25.2659 22.9399L17.5841 11.844C17.1741 11.9997 16.7417 12.1099 16.293 12.1683V22.7918C17.7623 23.0901 18.9197 24.2474 19.218 25.7168ZM14.793 22.7918C13.3236 23.0901 12.1662 24.2474 11.868 25.7168H8.21796C7.95638 24.4282 7.03401 23.3795 5.81993 22.9398L13.5017 11.8439C13.9117 11.9997 14.3442 12.1099 14.793 12.1683V22.7918ZM15.543 24.2168C14.3003 24.2168 13.293 25.2242 13.293 26.4668C13.293 27.7094 14.3003 28.7168 15.543 28.7168C16.7856 28.7168 17.793 27.7094 17.793 26.4668C17.793 25.2242 16.7856 24.2168 15.543 24.2168ZM24.293 26.4668C24.293 25.2242 25.3003 24.2168 26.543 24.2168C27.7856 24.2168 28.793 25.2242 28.793 26.4668C28.793 27.7094 27.7856 28.7168 26.543 28.7168C25.3003 28.7168 24.293 27.7094 24.293 26.4668ZM4.54297 24.2168C3.30033 24.2168 2.29297 25.2242 2.29297 26.4668C2.29297 27.7094 3.30033 28.7168 4.54297 28.7168C5.78561 28.7168 6.79297 27.7094 6.79297 26.4668C6.79297 25.2242 5.78561 24.2168 4.54297 24.2168Z"
      fill="#32AE34"
    />
  </SvgIcon>
);

export default OrganizationIcon;
