import { Box, Button, Typography } from '@mui/material';
import { DateRangeFilter as DateRangeFilterComponent } from '@root/date-range-filter';
import dayjs from 'dayjs';
import React from 'react';
import { useEventsContext } from './Events.context';
import useEventsFiltersHook from './Events.filters.hooks';
import EventsMultiSelectComponent from '@root/events/Events.multiSelect.component';
import { FilterStorageKey } from '@root/events/Events.types';
import useEventsAssets from '@root/events/Events.assets.hooks';
import useEventsThreatLevel from '@root/events/Events.threatLevel.hooks';
import MatchingScoreFilterComponent from '@root/events/Events.matchingScoreFilter.component';
import useEventsSites from '@root/events/Events.sites.hooks';
import EventsSiteComponent from '@root/events/Events.site.component';
import FilterSearchComponent from '@root/events/Events.filterSearch.component';
import useEventsFileTypes from '@root/events/Events.fileType.hooks';
import { ISOLanguages } from '@root/events/Events.constants';
import { useEventIncidentsFiltersHook } from '@root/events/Events.incidents.hooks';
import { enumToDescriptedArray, MultiSelectLazyList } from '@root/incidents';
import { SourceTypeWithOutNone } from '@fyeo-di-frontend/shared';
import { Option } from '@root/common/Common.types';

const FiltersContainer = () => {
  const {
    dateRange,
    clear,
    eventsSearchText,
    assets,
    threatLevel,
    matchingScore,
    sites,
    sourceTypes,
    fileTypes,
    incidentsFilter,
    assetInput,
    setAssetInput,
    setSiteInput,
    setIncidentInput,
    siteInput,
    languages,
  } = useEventsContext();
  const {
    onChangeDateRange,
    onChangeMultiSelect,
    onChangeMatchingScore,
    onChangeSite,
    onChangeEventSearchText,
  } = useEventsFiltersHook();
  const {
    isLoading,
    assets: assetsOptions,
    loadMore,
  } = useEventsAssets(assetInput);
  const { isLoading: isLoadingFileTypes, fileTypes: fileTypesOptions } =
    useEventsFileTypes();
  const {
    isLoading: isLoadingSites,
    sites: sitesOptions,
    loadMore: loadMoreSite,
  } = useEventsSites(siteInput);
  const { isLoading: isLoadingThreatLevel, threatLevel: threatLevelOptions } =
    useEventsThreatLevel();
  const {
    isLoading: isLoadingIncidents,
    incidents,
    loadMore: loadMoreIncidents,
  } = useEventIncidentsFiltersHook();
  return (
    <Box p={4} bgcolor="white">
      <Typography variant="h6" mb={1.5}>
        Filters
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        rowGap={2}
      >
        <Box width="32%">
          <DateRangeFilterComponent
            value={
              dateRange?.from
                ? [dayjs(dateRange?.from), dayjs(dateRange?.to)]
                : undefined
            }
            onChangeDateRange={onChangeDateRange}
            label="Date"
          />
        </Box>
        <Box width="32%">
          <MultiSelectLazyList
            loading={isLoading}
            options={assetsOptions}
            onChange={(value: Option[]) =>
              onChangeMultiSelect(value, FilterStorageKey.Asset)
            }
            value={assets}
            label="Asset"
            setInputValue={setAssetInput}
            disableDefaultFiltering
            loadMoreResults={loadMore}
          />
        </Box>
        <Box width="32%">
          <EventsMultiSelectComponent
            loading={isLoadingThreatLevel}
            options={threatLevelOptions}
            onChange={onChangeMultiSelect}
            selectedValues={threatLevel}
            label="Threat level"
            name={FilterStorageKey.ThreatLevel}
          />
        </Box>
        <Box width="32%">
          <EventsSiteComponent
            loading={isLoadingSites}
            options={sitesOptions}
            onChange={onChangeSite}
            selectedValue={sites}
            setInputValue={setSiteInput}
            loadMoreResults={loadMoreSite}
          />
        </Box>
        <Box width="32%">
          <MatchingScoreFilterComponent
            value={matchingScore}
            onChange={onChangeMatchingScore}
          />
        </Box>
        <Box width="32%">
          <FilterSearchComponent
            searchText={eventsSearchText}
            onChangeSearchText={onChangeEventSearchText}
          />
        </Box>
        <Box width="32%">
          <EventsMultiSelectComponent
            loading={false}
            options={enumToDescriptedArray(SourceTypeWithOutNone)}
            onChange={onChangeMultiSelect}
            selectedValues={sourceTypes}
            label="Source types"
            name={FilterStorageKey.SourceType}
          />
        </Box>
        <Box width="32%">
          <EventsMultiSelectComponent
            loading={false}
            options={ISOLanguages}
            onChange={onChangeMultiSelect}
            selectedValues={languages}
            label="Languages"
            name={FilterStorageKey.Language}
          />
        </Box>
        <Box width="32%">
          <EventsMultiSelectComponent
            loading={isLoadingFileTypes}
            options={fileTypesOptions}
            onChange={onChangeMultiSelect}
            selectedValues={fileTypes}
            label="File types"
            name={FilterStorageKey.FileType}
          />
        </Box>
        <Box width="32%">
          <MultiSelectLazyList
            loading={isLoadingIncidents}
            options={incidents}
            onChange={(value: Option[]) =>
              onChangeMultiSelect(value, FilterStorageKey.Incidents)
            }
            value={incidentsFilter}
            label="Incidents"
            setInputValue={setIncidentInput}
            disableDefaultFiltering
            loadMoreResults={loadMoreIncidents}
          />
        </Box>
        <Box
          width="32%"
          alignSelf="flex-end"
          display="flex"
          justifyContent="flex-end"
        >
          <Box width={90}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              onClick={clear}
            >
              Clear all
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FiltersContainer;
