import React from 'react';
import * as StyledComponent from './Trends.styles';
import MDEditor, { commands, ICommand } from '@uiw/react-md-editor';
import Bold from '../static-assets/Bold.png';
import Strikethrough from '../static-assets/Strikethrough.png';
import Italic from '../static-assets/Italic.png';
import Ordered from '../static-assets/Ordered.png';
import Unordered from '../static-assets/Unordered.png';
import { Box, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const bold: ICommand = {
  ...commands.getCommands().find((c) => c.name === 'bold'),
  icon: (
    <StyledComponent.OptionWrapper>
      <img alt="" src={Bold} />
    </StyledComponent.OptionWrapper>
  ),
};

const strikethrough: ICommand = {
  ...commands.getCommands().find((c) => c.name === 'strikethrough'),
  icon: (
    <StyledComponent.OptionWrapper>
      <img alt="" src={Strikethrough} />
    </StyledComponent.OptionWrapper>
  ),
};

const italic: ICommand = {
  ...commands.getCommands().find((c) => c.name === 'italic'),
  icon: (
    <StyledComponent.OptionWrapper>
      <img alt="" src={Italic} />
    </StyledComponent.OptionWrapper>
  ),
};

export const orderedListCommand: ICommand = {
  ...commands.getCommands().find((c) => c.name === 'ordered-list'),
  icon: (
    <StyledComponent.OptionWrapper>
      <img alt="" src={Ordered} />
    </StyledComponent.OptionWrapper>
  ),
};

export const unorderedListCommand: ICommand = {
  ...commands.getCommands().find((c) => c.name === 'unordered-list'),
  icon: (
    <StyledComponent.OptionWrapper>
      <img alt="" src={Unordered} />
    </StyledComponent.OptionWrapper>
  ),
};

interface OwnProps {
  state?: {
    value?: string;
    titleValue?: string;
  };
  setValue?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setTitleValue?: React.Dispatch<React.SetStateAction<string | undefined>>;
  onSave?: () => void;
  isSaving?: boolean;
  onPublish?: () => void;
  isPublishing?: boolean;
  isPublished?: () => boolean;
  id?: string;
}
const MarkdownEditor = ({
  setValue,
  state,
  setTitleValue,
  isPublishing,
  onPublish,
  onSave,
  isSaving,
  isPublished,
  id,
}: OwnProps) => {
  const titleComp: ICommand = {
    name: 'title',
    keyCommand: 'title',
    render: () => {
      return <Box height={37} />;
    },
  };

  return (
    <Box height={'100%'} display="flex" flexDirection="column" maxHeight="100%">
      <StyledComponent.MarkdownWrapper
        data-color-mode="light"
        data-cy="trend-editor"
      >
        <MDEditor
          value={state?.value}
          onChange={setValue}
          preview="edit"
          commands={[titleComp]}
          height={0}
          textareaProps={{
            placeholder: 'Add general trends here',
          }}
          extraCommands={[
            bold,
            strikethrough,
            italic,
            orderedListCommand,
            unorderedListCommand,
          ]}
        />
        <StyledComponent.AbsoluteTextField>
          <TextField
            value={state?.titleValue}
            onChange={(evt) => {
              setTitleValue?.(evt.target.value);
            }}
            placeholder="Add title here"
          />
        </StyledComponent.AbsoluteTextField>
      </StyledComponent.MarkdownWrapper>
      <StyledComponent.EditorFooter>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={1.5}
          mb={1}
        >
          <Box width={117}>
            <LoadingButton
              color="primary"
              variant="contained"
              fullWidth
              loading={isSaving}
              onClick={() => onSave?.()}
              disabled={!state?.value?.trim() || !state?.titleValue?.trim()}
            >
              {id ? 'Update' : 'Save'}
            </LoadingButton>
          </Box>
          {!isPublished?.() && (
            <Box width={117}>
              <LoadingButton
                color="secondary"
                variant="contained"
                fullWidth
                onClick={() => onPublish?.()}
                loading={isPublishing}
                disabled={!state?.value?.trim() || !state?.titleValue?.trim()}
              >
                Publish
              </LoadingButton>
            </Box>
          )}
        </Box>
      </StyledComponent.EditorFooter>
    </Box>
  );
};

export default MarkdownEditor;
