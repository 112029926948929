import React, { useCallback } from 'react';
import Tab from '@mui/material/Tab';
import * as StyledComponents from './IncidentsDetails.styles';
import { TabList } from '@mui/lab';
import { TabValues } from './IncidentsDetails.types';

interface OwnProps {
  labels?: { label: string; id: TabValues }[];
  onTabChange: (value?: TabValues) => void;
}

const IncidentDetailsTabsLabel = ({ labels, onTabChange }: OwnProps) => {
  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: TabValues) => {
      onTabChange(newValue);
    },
    [onTabChange],
  );

  return (
    <StyledComponents.TabLabel>
      <StyledComponents.DividerBox>
        <TabList onChange={handleChange} aria-label="tab-component">
          {labels?.map((item) => (
            <Tab key={item.id} label={item.label} value={`${item.id}`} />
          ))}
        </TabList>
      </StyledComponents.DividerBox>
    </StyledComponents.TabLabel>
  );
};

export default IncidentDetailsTabsLabel;
