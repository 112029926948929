import { Severity } from '../helpers';

export const generateIndicatorColor = (threatLevel: Severity) => {
  switch (threatLevel) {
    case Severity.Severe:
      return '#FF4F00';
    case Severity.High:
      return '#FFB800';
    case Severity.Elevated:
      return '#F0D941';
    case Severity.Guarded:
      return '#546DEC';
    case Severity.Low:
      return '#7CD85B';
    default:
      return '#999999';
  }
};
