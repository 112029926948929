import React from 'react';
import { Box, Typography } from '@mui/material';
import { DateRangeFilter as DateRangeFilterComponent } from '@root/date-range-filter';
import dayjs from 'dayjs';
import { memo } from 'react';

interface OwnProps {
  dateRange?: { from?: Date; to?: Date };
  onChangeDateRange?: (from?: Date, to?: Date) => void;
  setDateError?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReportsDateRange = ({
  dateRange,
  onChangeDateRange,
  setDateError,
}: OwnProps) => {
  return (
    <Box display="flex" flexDirection="column" gap={0.5} width={250}>
      <Typography variant="subtitle2">Date</Typography>
      <DateRangeFilterComponent
        value={
          dateRange?.from
            ? [dayjs(dateRange?.from), dayjs(dateRange?.to)]
            : undefined
        }
        onChangeDateRange={onChangeDateRange}
        setDateError={setDateError}
      />
    </Box>
  );
};

export default memo(ReportsDateRange);
