import { UrlIcon } from '@fyeo-di-frontend/shared';
import { Close } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import React, { memo } from 'react';
import * as StyledComponent from './AssetTypes.styles';

interface OwnProps {
  isThreatActor?: boolean;
  data?: string[];
  onChange?: (index: number, data: string) => void;
  onAdd?: () => void;
  onDelete?: (index: number) => void;
  disabled?: boolean;
}

const UrlRender = (props: OwnProps) => {
  const onChangeUrlValue =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange?.(index, event.target.value);
    };

  const onDelete = (index: number) => () => {
    props.onDelete?.(index);
  };

  const color = props.isThreatActor ? 'secondary' : 'primary';

  return (
    <>
      <Box display="flex" width="100%" mt={1}>
        <Box mt={-1} ml={-1} mb={-1}>
          <StyledComponent.CursorBox onClick={props.onAdd} fontSize={40}>
            <UrlIcon fontSize="inherit" color={color} />
          </StyledComponent.CursorBox>
        </Box>
      </Box>
      {props?.data?.map?.((data, index) => (
        <Box key={index} display="flex" width="100%" gap={1}>
          <TextField
            value={data}
            disabled={props.disabled}
            onChange={onChangeUrlValue(index)}
            color={color}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={onDelete?.(index)}>
                    <Close fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ))}
    </>
  );
};

export default memo(UrlRender);
