import { Box, Slider, TextField, Typography } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import * as StyledComponents from './Events.styles';

interface Value {
  from: number;
  to: number;
}

interface OwnProps {
  value?: Value;
  onChange?: (value: Value) => void;
}

const MatchingScoreFilterComponent: React.FC<OwnProps> = (props) => {
  const { value: pValue, onChange } = props;
  const [value, setValue] = useState<Value>({
    from: 80,
    to: 100,
  });

  useEffect(() => {
    if (pValue) {
      setValue(() => pValue);
    }
  }, [pValue, setValue]);

  const onChangeWrapped = (newValue: number[]) => {
    const [from, to] = newValue;
    setValue(() => ({ from, to }));
    onChange?.({ from, to });
  };

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="subtitle2" mb={1}>
        Matching score
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        gap={1}
      >
        <Box maxWidth={100}>
          <TextField
            value={value?.from}
            onChange={(evt) =>
              onChangeWrapped([Number(evt.target.value), value.to])
            }
          />
        </Box>
        <Box width={10} pt={2.2}>
          <StyledComponents.DividerStyled />
        </Box>
        <Box maxWidth={100}>
          <TextField
            value={value?.to}
            onChange={(evt) =>
              onChangeWrapped([value.from, Number(evt.target.value)])
            }
          />
        </Box>
      </Box>
      <Box p={0.5} />
      <Slider
        value={[value.from, value.to]}
        valueLabelDisplay="auto"
        onChange={(_, newValue) => onChangeWrapped(newValue as number[])}
        min={80}
        size="small"
      />
    </Box>
  );
};

export default memo(MatchingScoreFilterComponent);
