import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const AutoSaveIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 357.96 202.18">
    <path
      d="M295.38,218H78.12v-.4A74.1,74.1,0,1,1,107.28,73a82.67,82.67,0,0,1,158.23,3.06A74.1,74.1,0,1,1,295.38,218ZM85.93,206H295.38a62.11,62.11,0,1,0-31-115.91L257,94.36l-1.5-8.42a70.67,70.67,0,0,0-138.31-3.75L115.55,89l-6.5-2.65A62.09,62.09,0,1,0,84.25,206Z"
      transform="translate(-11.52 -15.81)"
      fill="#c6c6c6"
    />
    <path
      className="cls-1"
      d="M179.19,179.1a6.47,6.47,0,0,1-4.59-1.9l-34.55-34.54a6.51,6.51,0,0,1,9.2-9.2l29.94,29.95,49.16-49.15a6.5,6.5,0,0,1,9.19,9.19L183.79,177.2A6.47,6.47,0,0,1,179.19,179.1Z"
      transform="translate(-11.52 -15.81)"
      fill="#c6c6c6"
    />
  </SvgIcon>
);

export default AutoSaveIcon;
