import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import MarkdownEditor from './Incident.markdownEditor.component';

interface OwnProps {
  content: string;
  onSave?: (editorState: string) => void;
  isSaving?: boolean;
  onCancel?: () => void;
}
const EditMarkdownEditor = ({
  content,
  onSave,
  isSaving,
  onCancel,
}: OwnProps) => {
  const [editorState, setEditorState] = React.useState<string | undefined>(
    content,
  );
  return (
    <Box width="100%" display="flex" flexDirection="column" gap={2}>
      <MarkdownEditor value={editorState} setValue={setEditorState} />
      <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
        <Box width={150}>
          <LoadingButton
            color="primary"
            variant="contained"
            fullWidth
            loading={isSaving}
            onClick={() => editorState && onSave?.(editorState)}
            disabled={!editorState?.trim()}
          >
            Save comment
          </LoadingButton>
        </Box>
        <Box width={100}>
          <LoadingButton
            color="secondary"
            variant="contained"
            fullWidth
            onClick={onCancel}
          >
            Cancel
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default EditMarkdownEditor;
