import React from 'react';
import {
  EditTrendsContextProvider,
  useEditTrendsContextModel,
} from './EditTrends.context';
import EditTrends from './EditTrends.container';

const EditTrendsRoot = () => {
  const contextData = useEditTrendsContextModel();
  return (
    <EditTrendsContextProvider value={contextData}>
      <EditTrends />
    </EditTrendsContextProvider>
  );
};

export default EditTrendsRoot;
