import {
  AssetType,
  AssetTypeRender,
  getHighestPositiveValue,
  Severity,
} from '@fyeo-di-frontend/shared';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { CaseDetails } from '../Cases.types';
import EmailRenderer from './CasesDetails.emailRender.component';
import * as StyledComponent from './CasesDetails.styles';
import validator from 'validator';

interface OwnProps {
  data?: Partial<CaseDetails>;
  onChangeData?: (
    key: keyof CaseDetails,
    value: string | Severity | string[] | boolean,
  ) => void;
  onSave?: () => void;
  isSaving?: boolean;
  groupOptions?: {
    value: string;
    label: string;
  }[];
  selectedCaseId?: string;
  isStaff?: boolean;
  userEmail?: string;
  onDeleteEmail?: (index: number) => void;
}

const CaseDetailsComponent = (props: OwnProps) => {
  const onChangeEmail = useCallback(
    (index: number, value: string) => {
      if (props?.data?.emails) {
        props?.data?.emails?.splice(index, 1, value);
        props.onChangeData?.('emails', [...props.data.emails]);
      }
    },
    [props],
  );

  const isSingleEmail = useMemo(() => {
    return props?.data?.emails && props?.data?.emails?.length === 1;
  }, [props.data?.emails]);

  const onAddEmail = useCallback(() => {
    const emails = props?.data?.emails;
    if (emails?.length) {
      if (validator.isEmail(emails[emails.length - 1])) {
        emails.push('');
        props.onChangeData?.('emails', [...emails]);
      } else return;
    } else {
      const emails = [];
      emails.push('');
      props.onChangeData?.('emails', [...emails]);
    }
  }, [props]);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box
        display="flex"
        flexDirection="row"
        gap={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" fontWeight="bold">
          {props.selectedCaseId ? 'Edit case settings' : 'New Case'}
        </Typography>
        {props.isStaff && (
          <Box display="flex">
            <Typography
              variant="body1"
              color={!props?.data?.isActive ? undefined : '#CFD0D1'}
            >
              Inactive
            </Typography>
            <Box mt={-1}>
              <Switch
                checked={props?.data?.isActive}
                value={props?.data?.isActive}
                onChange={(_, checked) =>
                  props?.onChangeData?.('isActive', checked)
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Box>
            <Typography
              variant="body1"
              color={props?.data?.isActive ? 'primary' : '#CFD0D1'}
            >
              Active
            </Typography>
          </Box>
        )}
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography variant="subtitle2">Name</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">Group</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={props?.data?.name || ''}
            onChange={(evt) => props?.onChangeData?.('name', evt.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Select<string>
            size="small"
            fullWidth
            value={props?.data?.group || ''}
            onChange={(evt) => props?.onChangeData?.('group', evt.target.value)}
            defaultValue=""
          >
            {props?.groupOptions?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Typography variant="subtitle2">Comments</Typography>
      <TextField
        multiline
        rows={7}
        value={props?.data?.comments || ''}
        onChange={(evt) => props?.onChangeData?.('comments', evt.target.value)}
      />
      <Box display="flex" alignItems="center" gap={5} width="100%">
        <StyledComponent.ReportedFormControlLabel
          control={<Checkbox />}
          label={
            <Typography variant="subtitle2">Send notifications</Typography>
          }
          disableTypography
          labelPlacement="start"
          checked={props?.data?.sendNotification || false}
          value={props?.data?.sendNotification || false}
          onChange={(_, checked) =>
            props?.onChangeData?.('sendNotification', checked)
          }
        />
        {props?.data?.sendNotification && (
          <Box display="flex" alignItems="center" gap={2}>
            <Box>
              <Typography variant="subtitle2">Minimum alert level:</Typography>
            </Box>
            <Box width={150}>
              <Select<Severity>
                onChange={(evt) =>
                  props?.onChangeData?.(
                    'severity',
                    evt.target.value as Severity,
                  )
                }
                size="small"
                fullWidth
                value={props?.data?.severity || Severity.Safe}
                defaultValue={Severity.Safe}
              >
                <MenuItem value={Severity.Safe} dense>
                  {Severity[Severity.Safe]}
                </MenuItem>
                <MenuItem value={Severity.Low} dense>
                  {Severity[Severity.Low]}
                </MenuItem>
                <MenuItem value={Severity.Guarded} dense>
                  {Severity[Severity.Guarded]}
                </MenuItem>
                <MenuItem value={Severity.Elevated} dense>
                  {Severity[Severity.Elevated]}
                </MenuItem>
                <MenuItem value={Severity.High} dense>
                  {Severity[Severity.High]}
                </MenuItem>
                <MenuItem value={Severity.Severe} dense>
                  {Severity[Severity.Severe]}
                </MenuItem>
              </Select>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        gap={1}
        justifyContent="space-between"
        alignItems="flex-start"
        mt={2}
      >
        <Typography variant="h6" fontWeight="bold">
          Case assets used
        </Typography>
      </Box>
      <Grid container spacing={1} mb={3}>
        <Grid item xs={2.4}>
          <StyledComponent.DivWrapper>
            <AssetTypeRender assetType={AssetType.Domain} size="large" />
            <Typography variant="subtitle1" align="center" data-cy="domain">
              {getHighestPositiveValue(props.data?.assetCount?.domain)}/
              {getHighestPositiveValue(props.data?.assetMaxCount?.domain)}
            </Typography>
          </StyledComponent.DivWrapper>
        </Grid>
        <Grid item xs={2.4}>
          <StyledComponent.DivWrapper>
            <AssetTypeRender assetType={AssetType.Person} size="large" />
            <Typography variant="subtitle1" align="center" data-cy="person">
              {getHighestPositiveValue(props.data?.assetCount?.person)}/
              {getHighestPositiveValue(props.data?.assetMaxCount?.person)}
            </Typography>
          </StyledComponent.DivWrapper>
        </Grid>
        <Grid item xs={2.4}>
          <StyledComponent.DivWrapper>
            <AssetTypeRender assetType={AssetType.Location} size="large" />
            <Typography variant="subtitle1" align="center" data-cy="location">
              {getHighestPositiveValue(props.data?.assetCount?.location)}/
              {getHighestPositiveValue(props.data?.assetMaxCount?.location)}
            </Typography>
          </StyledComponent.DivWrapper>
        </Grid>
        <Grid item xs={2.4}>
          <StyledComponent.DivWrapper>
            <AssetTypeRender assetType={AssetType.Organization} size="large" />
            <Typography
              variant="subtitle1"
              align="center"
              data-cy="organization"
            >
              {getHighestPositiveValue(props.data?.assetCount?.organization)}/
              {getHighestPositiveValue(props.data?.assetMaxCount?.organization)}
            </Typography>
          </StyledComponent.DivWrapper>
        </Grid>
        <Grid item xs={2.4}>
          <StyledComponent.DivWrapper>
            <AssetTypeRender assetType={AssetType.Agent} size="large" />
            <Typography
              variant="subtitle1"
              align="center"
              data-cy="organization"
            >
              {getHighestPositiveValue(props.data?.assetCount?.agent)}/
              {getHighestPositiveValue(props.data?.assetMaxCount?.agent)}
            </Typography>
          </StyledComponent.DivWrapper>
        </Grid>
      </Grid>
      <Box
        display="flex"
        flexDirection="row"
        gap={1}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography variant="h6" fontWeight="bold">
          Notification email adresses
        </Typography>
      </Box>
      <EmailRenderer
        data={props?.data?.emails || []}
        onChange={onChangeEmail}
        onAdd={onAddEmail}
        onDelete={props?.onDeleteEmail}
        singleMail={isSingleEmail}
      />
      <Box margin="auto" width={257} mt={6}>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={props?.onSave}
          fullWidth
          loading={props?.isSaving}
        >
          Save changes
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default CaseDetailsComponent;
