import * as StyledComponents from './General.styles';
import React from 'react';

interface OwnProps {
  onClick?: () => void;
}
const FilterButtonComponent = ({ onClick }: OwnProps) => {
  return (
    <StyledComponents.ButtonWrapper
      onClick={onClick}
      size="small"
      variant="contained"
      color="primary"
      data-cy="filter-btn"
    >
      <StyledComponents.FilterOptionsButton>
        <div className="first" />
        <div className="second" />
        <div className="last" />
      </StyledComponents.FilterOptionsButton>
    </StyledComponents.ButtonWrapper>
  );
};

export default FilterButtonComponent;
