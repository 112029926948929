import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const Severe: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 59 23" {...props}>
    <path
      d="M0 2C0 0.89543 0.895431 0 2 0H57C58.1046 0 59 0.895431 59 2V21C59 22.1046 58.1046 23 57 23H2C0.89543 23 0 22.1046 0 21V2Z"
      fill="#F9D9CB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57 1H2C1.44771 1 1 1.44772 1 2V21C1 21.5523 1.44772 22 2 22H57C57.5523 22 58 21.5523 58 21V2C58 1.44771 57.5523 1 57 1ZM2 0C0.895431 0 0 0.89543 0 2V21C0 22.1046 0.89543 23 2 23H57C58.1046 23 59 22.1046 59 21V2C59 0.895431 58.1046 0 57 0H2Z"
      fill="#FF4F00"
    />
    <path
      d="M17.2523 9.48352C17.0603 8.53552 16.2803 7.31152 14.2763 7.31152C12.6923 7.31152 11.3843 8.48752 11.3843 9.91552C11.3843 11.1875 12.2483 12.0395 13.5443 12.3035L14.7443 12.5555C15.4763 12.7115 15.8843 13.1555 15.8843 13.7075C15.8843 14.3675 15.3563 14.8835 14.3363 14.8835C13.1123 14.8835 12.4763 14.0675 12.3923 13.1795L11.0483 13.5635C11.1803 14.7875 12.1763 16.1795 14.3363 16.1795C16.2803 16.1795 17.3483 14.9195 17.3483 13.5995C17.3483 12.3995 16.5443 11.4515 15.0443 11.1515L13.8203 10.8995C13.1603 10.7555 12.8243 10.3475 12.8243 9.80752C12.8243 9.15952 13.4243 8.58352 14.2883 8.58352C15.4283 8.58352 15.8723 9.36352 15.9563 9.89152L17.2523 9.48352Z"
      fill="#FF4F00"
    />
    <path
      d="M19.6859 12.4355C19.7339 11.8115 20.2379 11.1395 21.1139 11.1395C22.0739 11.1395 22.5299 11.7515 22.5539 12.4355H19.6859ZM22.7099 13.9595C22.5179 14.5355 22.0859 14.9795 21.2459 14.9795C20.3819 14.9795 19.6859 14.3435 19.6499 13.4555H23.9219C23.9339 13.4315 23.9579 13.2395 23.9579 13.0115C23.9579 11.1635 22.9019 9.97552 21.1019 9.97552C19.6259 9.97552 18.2579 11.1995 18.2579 13.0475C18.2579 15.0275 19.6619 16.1795 21.2459 16.1795C22.6499 16.1795 23.5739 15.3515 23.8859 14.3315L22.7099 13.9595Z"
      fill="#FF4F00"
    />
    <path
      d="M30.5229 10.1555H29.0589L27.5469 14.3315L25.9989 10.1555H24.4629L26.8389 15.9995H28.2309L30.5229 10.1555Z"
      fill="#FF4F00"
    />
    <path
      d="M32.4158 12.4355C32.4638 11.8115 32.9678 11.1395 33.8438 11.1395C34.8038 11.1395 35.2598 11.7515 35.2838 12.4355H32.4158ZM35.4398 13.9595C35.2478 14.5355 34.8158 14.9795 33.9758 14.9795C33.1118 14.9795 32.4158 14.3435 32.3798 13.4555H36.6518C36.6638 13.4315 36.6878 13.2395 36.6878 13.0115C36.6878 11.1635 35.6318 9.97552 33.8318 9.97552C32.3558 9.97552 30.9878 11.1995 30.9878 13.0475C30.9878 15.0275 32.3918 16.1795 33.9758 16.1795C35.3798 16.1795 36.3038 15.3515 36.6158 14.3315L35.4398 13.9595Z"
      fill="#FF4F00"
    />
    <path
      d="M41.5101 10.1195C41.4501 10.1075 41.3061 10.0835 41.1381 10.0835C40.3701 10.0835 39.7221 10.4555 39.4461 11.0915V10.1555H38.0901V15.9995H39.4821V13.2155C39.4821 12.1235 39.9741 11.4995 41.0541 11.4995C41.1981 11.4995 41.3541 11.5115 41.5101 11.5355V10.1195Z"
      fill="#FF4F00"
    />
    <path
      d="M43.6575 12.4355C43.7055 11.8115 44.2095 11.1395 45.0855 11.1395C46.0455 11.1395 46.5015 11.7515 46.5255 12.4355H43.6575ZM46.6815 13.9595C46.4895 14.5355 46.0575 14.9795 45.2175 14.9795C44.3535 14.9795 43.6575 14.3435 43.6215 13.4555H47.8935C47.9055 13.4315 47.9295 13.2395 47.9295 13.0115C47.9295 11.1635 46.8735 9.97552 45.0735 9.97552C43.5975 9.97552 42.2295 11.1995 42.2295 13.0475C42.2295 15.0275 43.6335 16.1795 45.2175 16.1795C46.6215 16.1795 47.5455 15.3515 47.8575 14.3315L46.6815 13.9595Z"
      fill="#FF4F00"
    />
  </SvgIcon>
);

export default Severe;
