import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import React from 'react';

interface OwnProps {
  redirectUrl: string;
  setRedirectUrl: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const EventsRedirectDialog = ({ redirectUrl, setRedirectUrl }: OwnProps) => {
  return (
    <Dialog
      open={!!redirectUrl}
      onClose={() => setRedirectUrl(() => undefined)}
      scroll="body"
    >
      <DialogTitle>
        <Box margin="auto" textAlign="center">
          Are you sure?
        </Box>
        <Box position="absolute" right={8} top={8} color="black">
          <IconButton
            onClick={() => setRedirectUrl(() => undefined)}
            color="inherit"
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        You are about to leave FYEO DI portal to an external site
      </DialogContent>
      <DialogActions>
        <Box margin="auto" textAlign="center" gap={2} display="flex" mb={2}>
          <Box width={126}>
            <Link
              href={redirectUrl}
              target="_blank"
              rel="noreferrer"
              underline="none"
            >
              <Button
                onClick={() => {
                  setRedirectUrl(() => undefined);
                }}
                variant="contained"
                color="primary"
                size="small"
                fullWidth
              >
                Yes, continue.
              </Button>
            </Link>
          </Box>
          <Box width={126}>
            <Button
              onClick={() => setRedirectUrl(() => undefined)}
              variant="contained"
              color="secondary"
              size="small"
              fullWidth
            >
              No, cancel.
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EventsRedirectDialog;
