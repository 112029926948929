import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import React, { useMemo } from 'react';

interface OwnProps {
  value?: { name: string; id: boolean }[];
  onChange?: (key: { name: string; id: boolean }[]) => void;
  label: string;
}

const YesNoSelectComponent = (props: OwnProps) => {
  const option = useMemo(
    () => [
      {
        name: 'Yes',
        id: true,
      },
      {
        name: 'No',
        id: false,
      },
    ],
    [],
  );
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="subtitle2" noWrap>
        {props.label}
      </Typography>
      <Box width="100%">
        <Autocomplete
          onChange={(event, newValue) => {
            props?.onChange?.(newValue || []);
          }}
          options={option}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          multiple
          value={props.value}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </Box>
    </Box>
  );
};

export default YesNoSelectComponent;
