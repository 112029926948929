import { LoadingButton } from '@mui/lab';
import React, { memo } from 'react';

interface OwnProps {
  id?: string;
  onDeleteAsset?: () => void;
  isDeleting?: boolean;
  isSaving?: boolean;
  onClose?: () => void;
}
const DeleteOrCancel: React.FC<OwnProps> = (props) => {
  return (
    <>
      {props.id ? (
        <LoadingButton
          variant="contained"
          color="secondary"
          fullWidth
          size="small"
          onClick={props?.onDeleteAsset}
          loading={props?.isDeleting}
          disabled={props?.isSaving}
        >
          Delete Asset
        </LoadingButton>
      ) : (
        <LoadingButton
          variant="contained"
          color="secondary"
          fullWidth
          size="small"
          onClick={props?.onClose}
          disabled={props?.isSaving}
        >
          Cancel
        </LoadingButton>
      )}
    </>
  );
};

export default memo(DeleteOrCancel);
