import { AssetType } from './AssetType.render.component';
import { Asset } from './Asset.types';

export const determineAssetName = <T>(row: Asset<T>) => {
  switch (row.assetType) {
    case AssetType.Person:
    case AssetType.Agent:
      return `${row?.firstName || ''} ${row?.lastName || ''}`;
    default:
      return row.name || '';
  }
};

interface DefaultProps<K> {
  assetType?: K | null;
  name?: {
    common?: string | null;
    first?: string | null;
    last?: string | null;
  } | null;
}

export const determineAssetNameFromResponse = <T extends DefaultProps<K>, K>(
  row?: T,
) => {
  switch (row?.assetType) {
    case AssetType.Person:
    case AssetType.Agent:
      return `${row?.name?.first || ''} ${row?.name?.last || ''}`;
    default:
      return row?.name?.common || '';
  }
};
