import { Box, Grid } from '@mui/material';
import { HorizontalFullHeightLoader } from '@fyeo-di-frontend/shared';
import React, { memo } from 'react';

const DomainTypeLoader = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={4}
      width="100%"
    >
      <Box height={80} width={80}>
        <HorizontalFullHeightLoader />
      </Box>
      <Box height={40} width="100%">
        <HorizontalFullHeightLoader />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box height={40}>
            <HorizontalFullHeightLoader />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box height={40}>
            <HorizontalFullHeightLoader />
          </Box>
        </Grid>
      </Grid>
      <Box height={40} width="100%">
        <HorizontalFullHeightLoader />
      </Box>
      <Box height={40} width="100%">
        <HorizontalFullHeightLoader />
      </Box>
      <Box height={70} width="100%">
        <HorizontalFullHeightLoader />
      </Box>
      <Box height={70} width="100%">
        <HorizontalFullHeightLoader />
      </Box>
    </Box>
  );
};

export default memo(DomainTypeLoader);
