import { Case, retrieveIdToken } from '@fyeo-di-frontend/shared';
import { createContext, useContext, useEffect, useState } from 'react';

export type TAppContextModel = ReturnType<typeof useAppContextModel>;

const AppContext = createContext<TAppContextModel>({
  idToken: '',
  setIdToken: () => {},
  isUserAuthenticated: false,
  invalidUrl: false,
  selectedCases: [],
  setSelectedCases: () => {},
  setInvalidUrl: () => {},
  allCases: [],
  setAllCases: () => {},
});

const { Provider } = AppContext;

export const useAppContextModel = () => {
  const [idToken, setIdToken] = useState<string | null>(retrieveIdToken());
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState<boolean>(
    !!idToken,
  );
  const [selectedCases, setSelectedCases] = useState<Case[]>([]);
  const [allCases, setAllCases] = useState<Case[]>([]);

  useEffect(() => {
    setIsUserAuthenticated(() => !!idToken);
  }, [idToken]);

  return {
    idToken,
    setIdToken,
    isUserAuthenticated,
    setSelectedCases,
    selectedCases,
    allCases,
    setAllCases,
    invalidUrl,
    setInvalidUrl,
  };
};

export const AppContextProvider = Provider;

export const useAppContext = () => useContext(AppContext);
