import styled from 'styled-components';
import { Box, Button } from '@mui/material';
import { generatePrimaryAndSecColor, Severity } from '../helpers';

export const PillsContainer = styled(Box)<{ severity?: Severity }>(
  ({ severity }) => {
    const colorGroup = generatePrimaryAndSecColor(severity);
    return {
      border: severity ? `1.5px solid ${colorGroup.color}` : 'none',
      backgroundColor: colorGroup.backgroundColor,
      paddingLeft: 10,
      width: 'fit-content',
      paddingRight: 5,
      paddingTop: 1,
      paddingBottom: 1,
      display: 'flex',
      borderRadius: 50,
      alignItems: 'center',
      gap: 10,
      justifyContent: 'space-between',
      '.MuiTypography-root': {
        color: colorGroup.color,
        fontSize: 12,
      },
      '.close': {
        borderRadius: '50%',
        borderColor: 'transparent',
        border: `1.5px solid ${colorGroup.color}`,
        position: 'relative',
        height: 18,
        width: 18,

        div: {
          width: '80%',
          borderBottom: `1.5px solid ${colorGroup.color}`,
          transform: 'rotate(-45deg)',
          position: 'absolute',
          top: '48%',
          left: 1.6,
        },
        '.last-child': {
          transform: 'rotate(45deg)',
        },
        '&:hover': {
          cursor: 'pointer',
        },
      },
    };
  },
);

export const ButtonWrapper = styled(Button)({
  width: 45,
  height: 35,
  color: 'white',
  backgroundColor: '#4B5B4E',
  ':hover': {
    backgroundColor: '#31AD34',
    opacity: 0.9,
  },
});
export const FilterOptionsButton = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 4,
  div: {
    height: 3,
    backgroundColor: 'white',
    borderRadius: 50,
    border: 'none',
  },
  '.first': {
    width: 19,
  },
  '.second': {
    width: 14,
  },
  '.last': {
    width: 8,
  },
});
