import React from 'react';
import * as StyledComponents from './Events.styles';
import { v4 } from 'uuid';
import { HighlightSecondaryText } from './Events.styles';
export const highlightText = (
  cut: string,
  start: number,
  end: number,
  isThreatActor?: boolean,
) => {
  const firstPart = cut.slice(0, start);
  const highlightText = cut?.slice(start, end + 1);
  const endPart = cut.slice(end + 1, cut.length);

  // Return in array of JSX elements
  return [
    firstPart,
    isThreatActor ? (
      <StyledComponents.HighlightSecondaryText key={v4()}>
        {highlightText}
      </StyledComponents.HighlightSecondaryText>
    ) : (
      <StyledComponents.HighlightText key={v4()}>
        {highlightText}
      </StyledComponents.HighlightText>
    ),
    endPart,
  ];
};

interface Props {
  cut: string;
  matches: { startPos: number; endPos: number }[];
  isThreatActor?: boolean;
}
const eventsFormatCut = ({ cut, matches, isThreatActor }: Props) => {
  let remainingCut = cut;
  let offset = 0;
  const returnText: (string | JSX.Element)[][] = [];
  const sortedMatches = [...matches].sort((a, b) => {
    return a.endPos - b.startPos;
  });

  sortedMatches.map((match) => {
    const res = highlightText(
      remainingCut,
      match.startPos - offset,
      match.endPos - offset,
      isThreatActor,
    );
    returnText.push(res.slice(0, 2));
    remainingCut = res[2] as string;
    offset = match.endPos + 1;
  });

  return (
    <StyledComponents.BreakWord variant="body2">
      {returnText.flat().map((text, i) => (
        <React.Fragment key={i}>{text}</React.Fragment>
      ))}
      {remainingCut}
    </StyledComponents.BreakWord>
  );
};

export { eventsFormatCut };
