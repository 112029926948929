import Papa from 'papaparse';
import { Incident, Severity, Status } from '@fyeo-di-frontend/shared';
import dayjs from 'dayjs';

export const transformDataToCSVDetails = (incidents: Incident[]) => {
  const data = incidents.map((row, i) => [
    i + 1,
    dayjs(row.date).format('YYYY-MM-DD'),
    row.details,
    row.case,
    Severity[row.severity],
    row.eventCount,
    row.incidentType,
    row.reported ? 'Yes' : 'No',
    Status[row.status],
  ]);

  return Papa.unparse({
    fields: [
      '',
      'Date',
      'Incident',
      'Case',
      'Severity',
      'Events',
      'Incident Type',
      'Reported',
      'Status',
    ],
    data: data,
  });
};
