export interface Case {
  name: string;
  id: string;
  group: string;
  isActive: boolean;
  assetCount: {
    person: number;
    location: number;
    organization: number;
    domain: number;
    agent: number;
  };
  assetMaxCount: {
    person: number;
    location: number;
    organization: number;
    domain: number;
    agent: number;
  };
}

export enum ApiAccessType {
  API_KEY = 'API_KEY',
  COGNITO_USER = 'COGNITO_USER',
}

export interface User {
  userPoolId?: string;
  apiAccessKey?: string;
  ApiAccessCount?: number;
  creatorEmail?: string;
  webhook?: {
    url?: string;
    token?: string;
  };
  caseIdsForApi?: string[];
}
