import { useSnackbar, VariantType } from 'notistack';
import { useCallback } from 'react';
import useLogoutHook from './Logout.hooks';
import { ErrorCodes } from '@root/common/ErrorCodes.enum';

export const useErrorHook = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleLogout } = useLogoutHook();

  const handleError = useCallback(
    (error: Error, disableAction?: boolean) => {
      if (error.message === ErrorCodes['_2480']) {
        enqueueSnackbar('Session timeout', {
          variant: 'info',
        });
        handleLogout();
      } else {
        enqueueSnackbar(error.message, {
          variant: 'error',
          action: disableAction ? () => null : undefined,
        });
      }
    },
    [enqueueSnackbar, handleLogout],
  );
  return {
    handleError,
  };
};

export const useNonErrorHook = () => {
  const { enqueueSnackbar } = useSnackbar();
  const handleMessage = useCallback(
    (message: string, variant: VariantType) => {
      enqueueSnackbar(message, { variant });
    },
    [enqueueSnackbar],
  );
  return {
    handleMessage,
  };
};

export default useErrorHook;
