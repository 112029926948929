import {
  HorizontalBarChartComponent,
  HorizontalFullHeightLoader,
} from '@fyeo-di-frontend/shared';
import { Box } from '@mui/material';
import React from 'react';
import * as StyledComponents from './DomainReport.styles';

interface OwnProps {
  isLoading?: boolean;
  data?: {
    label: string;
    count: number;
  }[];
}

const TopUsers = (props: OwnProps) => {
  if (!props?.data?.length && !props.isLoading) {
    return null;
  }

  return (
    <StyledComponents.EntryRoot>
      <StyledComponents.NoBreakTitle variant="h5" fontWeight={'bold'} mb={2}>
        Top users
      </StyledComponents.NoBreakTitle>
      <StyledComponents.ChartPaper elevation={0}>
        {props.isLoading ? (
          <Box height={368}>
            <HorizontalFullHeightLoader />
          </Box>
        ) : (
          <HorizontalBarChartComponent data={props?.data} />
        )}
      </StyledComponents.ChartPaper>
    </StyledComponents.EntryRoot>
  );
};

export default TopUsers;
