import { Divider, FormControl } from '@mui/material';
import styled from 'styled-components';

export const DarkDivider = styled(Divider)({
  backgroundColor: '#323232',
  height: '100%',
});

export const DateRangePickerRoot = styled.div({
  '.MuiPaper-root': {
    '> div > div': {
      height: 350,
    },
    backgroundColor: '#E5E7EA',
    borderRadius: 16,
    '.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
      backgroundColor: 'rgb(212, 233, 217)',
    },
  },
});

export const BorderedFormControlWrapper = styled(FormControl)({
  '.MuiOutlinedInput-root fieldset': {
    border: 'none',
    legend: {
      display: 'none',
    },
  },
  '.Mui-error.Mui-focused:focus-within': {
    border: 'none',
  },
  '.Mui-error.MuiInputBase-formControl:hover': {
    border: 'none',
  },
  '.Mui-error': {
    '.MuiInputBase-root:hover': {
      border: 'none',
    },
    fieldset: {
      top: 0,
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderStyle: 'solid',
    },
  },
});
