import {
  Box,
  Button,
  Divider,
  Paper,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';

export const Root = styled.div({
  height: 'calc(100vh - 70px)',
  backgroundColor: '#f8f9fb',
  overflowY: 'auto',
  overflowX: 'hidden',
});

export const TableHeaderCell = styled(TableCell)({
  top: 60,
  height: 50,
  cursor: 'pointer',
});

export const FirstEdge = styled(TableHeaderCell)({
  borderTopLeftRadius: 10,
});

export const TableHeaderCenteredCell = styled(TableHeaderCell)({
  textAlign: 'center',
  '> div': {
    justifyContent: 'center',
  },
});

export const SecondEdge = styled(TableHeaderCenteredCell)({
  borderTopRightRadius: 10,
});

export const TableBodyRow = styled(TableRow)({
  backgroundColor: '#fff',
  cursor: 'pointer',
});

export const TableHeaderRow = styled(TableRow)({
  backgroundColor: '#fff',
});

export const TableBodyCell = styled(TableCell)({
  textAlign: 'center',
});

export const WrapText = styled(Typography)({
  wordBreak: 'break-word',
});

export const TableHeaderStyled = styled(TableHead)({
  paddingTop: 60,
  position: 'sticky',
  top: 0,
  backgroundColor: '#f8f9fb',
  zIndex: 1,
});

export const Favicon = styled(Box)({
  display: 'flex',
  height: 40,
  width: 40,
  justifyContent: 'center',
  alignItems: 'center',
  img: {
    objectFit: 'contain',
    height: 26,
    width: 26,
  },
});

export const AssetFavicon = styled(Favicon)({
  img: {
    objectFit: 'contain',
    width: 60,
    height: 60,
  },
  width: 80,
  height: 80,
});

export const DividerStyled = styled(Divider)({
  height: 2,
  backgroundColor: '#000',
});

export const NoResultContainer = styled(Paper)({
  marginTop: 10,
  paddingTop: 40,
  paddingBottom: 62,
});

export const NoResultIcon = styled.img({
  width: 103,
});

export const AddIcon = styled(Button)({
  paddingTop: 2.4,
  paddingBottom: 2.4,
});

export const DownloadIcon = styled(LoadingButton)({
  paddingTop: 2.4,
  paddingBottom: 2.4,
  borderWidth: 1.5,
  minWidth: 103,
  minHeight: 37,
  ':hover': {
    borderWidth: 1.5,
  },
});
