import { HeaderComponent } from '@root/header';
import React from 'react';
import ErrorComponent from '@root/error/Error.component';

const Error = () => {
  return (
    <>
      <HeaderComponent isErrorPage />
      <ErrorComponent
        label="Oops! Something went wrong."
        buttonText="Refresh"
        buttonAction={() => location.reload()}
        details="The page could not load. Please try refreshing the page"
      />
    </>
  );
};

export default Error;
