export enum Detections {
  New = 'new',
  Total = 'total',
}

export interface Breaches {
  email: string;
  source: string;
  threatLevel: number;
  handle?: string;
  dumpDate?: string;
  passwordPlain?: string;
  passwordHash?: string;
  image?: string;
  industry?: string;
  description?: string;
  title?: string;
  localPart?: string;
  emailDomain?: string;
  country?: string;
  ip?: string;
  phone?: string;
  imported?: string;
  id?: string;
}
