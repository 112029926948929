import {
  DashboardDetections,
  DashboardHeatMapComponent,
  DashboardIncidents,
  DashboardSeverityChart,
  RIncidentsByAssetType,
  RIncidentsByDate,
  RIncidentsBySeverity,
} from '@fyeo-di-frontend/shared';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import * as StyledComponents from './PeriodicReport.styles';

interface OwnProps {
  incidents?: RIncidentsByDate;
  fetchingIncidentsByDate: boolean;
  fetchingIncidentsBySeverity: boolean;
  fetchingIncidentsByAssetType: boolean;
  fetchingIncidentsByCaseAndType: boolean;
  severity?: RIncidentsBySeverity;
  detections?: RIncidentsByAssetType;
  data?: Record<string, Record<string, number>>;
  columns?: string[];
}
const PeriodicReportReportsSummaryComponent = (props: OwnProps) => {
  const {
    incidents,
    fetchingIncidentsByDate,
    severity,
    fetchingIncidentsBySeverity,
    detections,
    fetchingIncidentsByAssetType,
    fetchingIncidentsByCaseAndType,
    data,
    columns,
  } = props;
  return (
    <StyledComponents.Root>
      <Box width="100%">
        <Typography variant="h5" fontWeight={'bold'} mb={4.375}>
          Report Summary
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" gap={1}>
              <DashboardIncidents
                newIncidents={incidents?.newIncidents}
                openIncidents={incidents?.openIncidents}
                closedIncidents={incidents?.closedIncidents}
                isLoading={fetchingIncidentsByDate}
                bg
                monthly
              />
              <Grid container spacing={1}>
                <Grid item xs={5.5}>
                  <DashboardSeverityChart
                    {...severity}
                    isLoading={fetchingIncidentsBySeverity}
                    bg
                  />
                </Grid>
                <Grid item xs={6.5}>
                  <DashboardDetections
                    {...detections}
                    isLoading={fetchingIncidentsByAssetType}
                    bg
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <DashboardHeatMapComponent
              data={data}
              columns={columns}
              isLoading={fetchingIncidentsByCaseAndType}
              bg
            />
          </Grid>
        </Grid>
      </Box>
    </StyledComponents.Root>
  );
};

export default PeriodicReportReportsSummaryComponent;
