import { Box } from '@mui/material';
import styled from 'styled-components';

export const SeverityWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  'svg.MuiSvgIcon-root': {
    height: 23,
    width: 58,
  },
});
