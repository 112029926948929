import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import ReportsRenderFieldComponent from './Reports.renderField.component';
import ReportsTypeFieldComponent from './Reports.reportType.component';
import { Domain, ReportsTypes } from './Reports.types';

interface OwnProps {
  onChangeReportType?: (value: ReportsTypes) => void;
  onDomainChange?: (val: Domain | null) => void;
  onChangeDateRange?: (from?: Date, to?: Date) => void;
  setDateError?: React.Dispatch<React.SetStateAction<boolean>>;
  data?: {
    reportType?: ReportsTypes;
    domain?: Domain;
    domains?: Domain[];
    dateRange?: { from?: Date; to?: Date };
    isLoading?: boolean;
  };
  buttonActions?: {
    domain?: {
      onDownloadPdf?: () => void;
      onExportToCsv?: () => void;
    };
    periodic?: {
      onDownloadPdf?: () => void;
      onExportToCsv?: () => void;
    };
  };
  loading?: {
    domain?: {
      isDownloading?: boolean;
      isExporting?: boolean;
    };
    periodic?: {
      isDownloading?: boolean;
      isExporting?: boolean;
    };
    isShowing?: boolean;
  };
  disabled?: boolean;
  caseIds?: { name: string; id: string }[];
  url?: string;
  setSearch?: (value: string) => void;
  loadMoreResults?: () => void;
}

const ReportsForm = (props: OwnProps) => {
  const {
    reportType,
    domain,
    domains,
    dateRange,
    isLoading: loading,
  } = props.data ?? {};
  const { onDomainChange, onChangeDateRange, setSearch } = props;

  const onClickAction = useMemo(() => {
    switch (reportType) {
      case ReportsTypes.Domain:
        return props?.buttonActions?.domain?.onDownloadPdf;
      case ReportsTypes.Periodic:
        return props?.buttonActions?.periodic?.onDownloadPdf;
      case ReportsTypes.None:
        return undefined;
    }
  }, [reportType, props]);

  const isLoading = useMemo(() => {
    switch (reportType) {
      case ReportsTypes.Domain:
        return props?.loading?.domain?.isDownloading;
      case ReportsTypes.Periodic:
        return props?.loading?.periodic?.isDownloading;
      case ReportsTypes.None:
        return false;
    }
  }, [reportType, props]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      gap={4.75}
    >
      <Box display="flex" gap={1.5}>
        <Box display="flex" flexDirection="column" gap={1.5}>
          <Typography variant="subtitle2">Case selection</Typography>
          <Typography variant="subtitle2">
            Select one or several cases from left side menu.{' '}
          </Typography>
        </Box>
        <ReportsTypeFieldComponent
          reportType={props.data?.reportType}
          onChangeReportType={props.onChangeReportType}
        />
        <ReportsRenderFieldComponent
          onDomainChange={onDomainChange}
          domain={domain}
          onChangeDateRange={onChangeDateRange}
          domains={domains}
          dateRange={dateRange}
          reportType={reportType}
          setSearch={setSearch}
          loading={loading}
          setDateError={props.setDateError}
          loadMoreResults={props.loadMoreResults}
        />
      </Box>
      {reportType && (
        <Box display="flex" gap={2.5} alignItems="center">
          {reportType === ReportsTypes.Domain && (
            <Box width={165}>
              <LoadingButton
                color="primary"
                variant="contained"
                onClick={props?.buttonActions?.domain?.onExportToCsv}
                fullWidth
                disabled={props?.disabled}
                loading={props?.loading?.domain?.isExporting}
              >
                Download CSV
              </LoadingButton>
            </Box>
          )}
          <Box width={165}>
            <LoadingButton
              color="primary"
              variant="contained"
              onClick={onClickAction}
              fullWidth
              disabled={props?.disabled}
              loading={isLoading}
            >
              Download PDF
            </LoadingButton>
          </Box>
          <Box width={165}>
            <LoadingButton
              color="primary"
              variant="contained"
              fullWidth
              href={props?.url || '#'}
              target="_blank"
              disabled={props?.disabled}
              loading={props?.loading?.isShowing}
            >
              Show in browser
            </LoadingButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ReportsForm;
