import { Trend } from '@fyeo-di-frontend/shared';
import { Box, Typography } from '@mui/material';
import React from 'react';
import * as StyledComponents from './PeriodicReport.styles';

interface OwnProps {
  trend?: Trend;
}
const PeriodicReportTrendsComponent = (props: OwnProps) => {
  return (
    <StyledComponents.EntryRoot>
      <Box width="100%">
        <Typography variant="h5" fontWeight="bold" mb={3}>
          Trends
        </Typography>
        {!props?.trend?.title && !props?.trend?.content && (
          <Typography variant="subtitle1">No trends report</Typography>
        )}
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="subtitle1" data-color-mode="light">
            <StyledComponents.Markdown source={props?.trend?.title} />
          </Typography>
          <StyledComponents.Body variant="body1" data-color-mode="light">
            <StyledComponents.Markdown source={props?.trend?.content} />
          </StyledComponents.Body>
        </Box>
      </Box>
    </StyledComponents.EntryRoot>
  );
};

export default PeriodicReportTrendsComponent;
