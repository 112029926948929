import Papa from 'papaparse';
import { Breaches } from '@fyeo-di-frontend/shared';
import dayjs from 'dayjs';

export const transformDomainDataToCSVDetails = (domains?: Breaches[]) => {
  const data =
    domains?.map((row) => [
      row.email,
      row?.handle,
      row?.ip,
      row?.phone,
      row?.source,
      row?.imported ? dayjs(row.imported).format('YYYY-MM-DD') : '',
      row?.dumpDate ? dayjs(row.dumpDate).format('YYYY-MM-DD') : '',
      row?.passwordPlain,
    ]) || [];

  return Papa.unparse({
    fields: [
      'Email',
      'Handle',
      'IP',
      'Phone',
      'Database',
      'Imported date',
      'Leaked date',
      'Plaintext',
    ],
    data: data,
  });
};
