import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const DownloadIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 25 24">
    <g clipPath="url(#clip0_10994_17020)">
      <path
        d="M24.7801 10.832C24.4281 8.73601 22.8441 7.00001 20.7961 6.44001C19.9001 6.20001 19.0281 6.18401 18.2201 6.35201C17.5961 3.96801 15.4361 2.20801 12.8601 2.20801C10.2841 2.20801 8.12414 3.96801 7.50014 6.35201C6.68414 6.19201 5.81215 6.20001 4.90815 6.44801C2.86015 7.01601 1.27615 8.76001 0.932145 10.856C0.364145 14.328 3.02815 17.336 6.39614 17.336H7.60414C7.93214 17.336 8.20414 17.064 8.20414 16.736C8.20414 16.408 7.93214 16.136 7.60414 16.136H6.58815C4.32415 16.136 2.29215 14.48 2.06815 12.224C1.81215 9.63201 3.85215 7.44801 6.38814 7.44801C6.67615 7.44801 6.96415 7.48001 7.25215 7.53601L8.36414 7.76001L8.62014 6.78401C9.03614 5.19201 10.2681 3.88801 11.8761 3.52801C14.2361 3.00001 16.4681 4.44801 17.0521 6.66401L17.3401 7.76001L18.4521 7.53601C18.7401 7.48001 19.0281 7.44801 19.3161 7.44801C21.8521 7.44801 23.8921 9.64001 23.6361 12.224C23.4121 14.48 21.3881 16.136 19.1161 16.136H18.0521C17.7241 16.136 17.4521 16.408 17.4521 16.736C17.4521 17.064 17.7241 17.336 18.0521 17.336H19.3081C22.6841 17.336 25.3561 14.32 24.7721 10.832H24.7801Z"
        fill="#31AD34"
      />
      <path
        d="M14.6768 17.7037H13.4368V10.7357C13.4368 10.4077 13.1648 10.1357 12.8368 10.1357C12.5088 10.1357 12.2368 10.4077 12.2368 10.7357V17.6957H11.0448C10.6848 17.6957 10.4528 18.0877 10.6368 18.4077L12.4528 21.5517C12.6368 21.8637 13.0928 21.8637 13.2688 21.5517L15.0848 18.4077C15.2688 18.0957 15.0368 17.6957 14.6768 17.6957V17.7037Z"
        fill="#31AD34"
      />
    </g>
    <defs>
      <clipPath id="clip0_10994_17020">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.835938)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default DownloadIcon;
