import styled from 'styled-components';
import { Box } from '@mui/material';

export const Root = styled.div({
  height: 'calc(100vh - 70px)',
  backgroundColor: '#f8f9fb',
  paddingTop: 30,
  overflowY: 'auto',
  overflowX: 'hidden',
});

export const FormWrapper = styled(Box)({
  borderRadius: '4px 4px 0px 0px',
  marginTop: 13,
  marginRight: 27,
  backgroundColor: '#fff',
  display: 'flex',
  paddingTop: 18,
  paddingLeft: 18,
  paddingBottom: 18,
});
