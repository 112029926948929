import {
  Box,
  Typography,
  CircularProgress,
  Link,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { LoginRoutes } from '@root/common/Routes';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as StyledComponents from '../login/Login.styles';
import Root from '../Root.component';
import {
  useVerifyAccountHook,
  useVerifyAccountResendCodeHook,
} from './VerifyAccount.hooks';

const VerifyAccount = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { code, isVerifyAccountInProgress, onVerifyAccount, onChangeCode } =
    useVerifyAccountHook();
  const disableVerifyAccount = !code?.trim?.() || isVerifyAccountInProgress;
  const { isVerifyAccountResendInProgress, onResendCodeRequest } =
    useVerifyAccountResendCodeHook();

  useEffect(() => {
    if (!state?.email || !state?.password) {
      navigate(LoginRoutes.SignUp);
    }
  }, [state, navigate]);

  return (
    <Root>
      <StyledComponents.Root>
        <Box textAlign="center">
          <Typography variant="h6">Verify account</Typography>
        </Box>
        <Box textAlign="center" display="flex" flexDirection="column">
          <Typography variant="caption">
            We&apos;ve sent you an email with a verification code.
          </Typography>
          <Typography variant="caption">
            Please enter the code below.
          </Typography>
        </Box>
        <Box display="flex" gap={2} justifyContent="center">
          <Typography variant="subtitle2">
            Didn&apos;t receive a code?
          </Typography>
          {!isVerifyAccountResendInProgress && (
            <Link
              variant="subtitle2"
              underline="none"
              color="primary"
              onClick={onResendCodeRequest}
            >
              Resend
            </Link>
          )}
          {isVerifyAccountResendInProgress && <CircularProgress size={20} />}
        </Box>
        <Box textAlign="center" ml={15} mr={15}>
          <TextField
            placeholder="Code"
            onChange={(evt) => onChangeCode(evt?.target?.value)}
          />
        </Box>
        <Box textAlign="center" ml={15} mr={15}>
          <LoadingButton
            variant="contained"
            fullWidth
            size="large"
            disabled={disableVerifyAccount}
            onClick={onVerifyAccount}
            loading={isVerifyAccountInProgress}
          >
            Verify
          </LoadingButton>
        </Box>
      </StyledComponents.Root>
    </Root>
  );
};

export default VerifyAccount;
