import { Descripted } from '@root/incidents/Incidents.types';

/**
 * Helper to produce an array of enum descriptors.
 * @param enumeration Enumeration object.
 * @param separatorRegex Regex that would catch the separator in your enum key.
 */
export function enumToDescriptedArray<T extends Record<string, unknown>>(
  enumeration: T,
  separatorRegex = /_/g,
): Descripted<T>[] {
  return (Object.keys(enumeration) as Array<keyof T>)
    .filter((key) => isNaN(Number(key)))
    .filter(
      (key) =>
        typeof enumeration[key] === 'number' ||
        typeof enumeration[key] === 'string',
    )
    .map((key) => ({
      id: enumeration[key],
      name: String(key).replace(separatorRegex, ' '),
    }));
}
