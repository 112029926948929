import { Paper } from '@mui/material';
import styled from 'styled-components';

export const Root = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  paddingTop: 40,
  paddingBottom: 80,
});
