import React from 'react';
import DomainIcon from '../icons/Domain.icon';
import LocationIcon from '../icons/Location.icon';
import OrganizationIcon from '../icons/Organization.icon';
import PersonIcon from '../icons/Person.icon';
import { SvgIconProps } from '@mui/material';
import AgentIcon from '../icons/Agent.icon';

export enum AssetType {
  Person = 'person',
  Domain = 'domain',
  Location = 'location',
  Organization = 'organization',
  Agent = 'agent',
}

interface AssetTypeRenderProps<T> {
  assetType?: T | null;
  isThreatActor?: boolean;
  size?: 'small' | 'medium' | 'large' | 'inherit';
}

function AssetTypeRender<T>({
  assetType,
  isThreatActor,
  size,
  ...rest
}: AssetTypeRenderProps<T> & SvgIconProps) {
  let ret: JSX.Element | null = null;
  switch (assetType) {
    case AssetType.Domain:
      ret = (
        <DomainIcon
          color={isThreatActor ? 'secondary' : 'primary'}
          fontSize={size}
          {...rest}
        />
      );
      break;
    case AssetType.Location:
      ret = (
        <LocationIcon
          color={isThreatActor ? 'secondary' : 'primary'}
          fontSize={size}
          {...rest}
        />
      );
      break;
    case AssetType.Organization:
      ret = (
        <OrganizationIcon
          color={isThreatActor ? 'secondary' : 'primary'}
          fontSize={size}
          {...rest}
        />
      );
      break;
    case AssetType.Person:
      ret = (
        <PersonIcon
          color={isThreatActor ? 'secondary' : 'primary'}
          fontSize={size}
          {...rest}
        />
      );
      break;

    case AssetType.Agent:
      ret = (
        <AgentIcon
          color={isThreatActor ? 'secondary' : 'primary'}
          fontSize={size}
          {...rest}
        />
      );
      break;
  }
  return ret;
}

export default AssetTypeRender;
