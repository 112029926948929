import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface OwnProps {
  searchText?: string;
  onChangeSearchText?: (searchText: string) => void;
}

const AssetTextFilterComponent: React.FC<OwnProps> = (props) => {
  const { searchText: pSearchText, onChangeSearchText } = props;
  const [searchText, setSearchText] = useState<string>();

  useEffect(() => {
    if (pSearchText !== undefined) {
      setSearchText(() => pSearchText);
    }
  }, [pSearchText, setSearchText]);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="subtitle2">Asset</Typography>
      <Box width="100%">
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRoundedIcon />
              </InputAdornment>
            ),
          }}
          onChange={(evt) => {
            setSearchText(() => evt.target.value);
            onChangeSearchText?.(evt.target.value);
          }}
          value={searchText}
        />
      </Box>
    </Box>
  );
};

export default AssetTextFilterComponent;
