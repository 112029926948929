import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useAssetsContext } from './Assets.context';
import AssetTypeFilterComponent from './Assets.filterAssetType.component';
import useAssetsFiltersHook from './Assets.filters.hooks';
import AssetTextFilterComponent from './Assets.filterText.component';
import MatchingScoreFilterComponent from './Assets.matchingScoreFilter.component';
import TotalIncidentsFilterComponent from './Assets.totalIncidentsFilter.component';
import YesNoSelectComponent from './Assets.yesNoSelect.component';

const FiltersComponent = () => {
  const {
    onChangeAssetType,
    onChangeAssetSearchText,
    onChangeThreatActor,
    onChangeActive,
    onChangeVerified,
    onChangeMatchingScore,
    onChangeTotalIncidents,
    maxIncidentCount,
    maxIncidentCountLoading,
  } = useAssetsFiltersHook();
  const {
    assetTypes,
    assetSearchText,
    threatActor,
    active,
    verified,
    matchingScore,
    totalIncidents,
    clear,
  } = useAssetsContext();

  return (
    <Box p={4} bgcolor="white">
      <Typography variant="h6" mb={1.5}>
        Filters
      </Typography>
      <Box display="flex" gap={2} flexDirection="column">
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          rowGap={2}
        >
          <Box width="32%">
            <AssetTextFilterComponent
              onChangeSearchText={onChangeAssetSearchText}
              searchText={assetSearchText}
            />
          </Box>
          <Box width="32%">
            <AssetTypeFilterComponent
              onChange={onChangeAssetType}
              value={assetTypes}
            />
          </Box>
          <Box width="32%">
            <YesNoSelectComponent
              label="Threat actor"
              onChange={onChangeThreatActor}
              value={threatActor}
            />
          </Box>
          <Box width="32%">
            <YesNoSelectComponent
              label="Active"
              onChange={onChangeActive}
              value={active}
            />
          </Box>
          <Box width="32%">
            <MatchingScoreFilterComponent
              onChange={onChangeMatchingScore}
              value={matchingScore}
            />
          </Box>
          <Box width="32%">
            <TotalIncidentsFilterComponent
              onChange={onChangeTotalIncidents}
              value={totalIncidents}
              maxIncidentValue={maxIncidentCount}
            />
          </Box>
          <Box width="32%">
            <YesNoSelectComponent
              label="Verified"
              onChange={onChangeVerified}
              value={verified}
            />
          </Box>
          <Box
            width="32%"
            alignSelf="flex-end"
            display="flex"
            justifyContent="flex-end"
          >
            <Box width={90}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                onClick={clear}
                disabled={maxIncidentCountLoading}
              >
                Clear all
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FiltersComponent;
