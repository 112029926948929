export const BaseRoute = process.env.PUBLIC_URL || '';

export const LoginRoutes = {
  Root: '/',
  SignUp: '/signup',
  ResetPassword: '/resetpassword',
  ResetPasswordConfirm: '/resetpasswordconfirm',
  VerifyAccount: '/verifyaccount',
};

export const DashboardRoutes = {
  Root: '/dashboard',
};

export const ReportsRoutes = {
  Root: '/reports',
  Periodic: 'periodic',
  Domain: 'domain',
};

export const SettingsRoutes = {
  Root: '/settings',
  ApiAccess: 'api-access',
  EditTrends: 'edit-general-trends',
  ResetPassword: 'reset-password',
  ResetPasswordResponse: 'reset-password/response',
};

export const IncidentsRoutes = {
  Root: '/incidents',
  Details: ':id',
};

export const AssetsRoutes = {
  Root: '/assets',
  Details: ':id',
};

export const EventsRoutes = {
  Root: '/events',
};
