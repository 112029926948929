import {
  TableHeaderWithSort,
  TableBodyLoader,
  Incident,
  Status,
  generateJSX,
} from '@fyeo-di-frontend/shared';
import { FiberManualRecord } from '@mui/icons-material';
import {
  Box,
  SortDirection,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';

import React, { memo, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import * as StyledComponent from './Incidents.styles';
import NoResultIconUrl from '../common/NoResultIcon.svg';

interface Props {
  loading?: boolean;
  data?: Incident[];
  onLoadMore?: () => void;
  onSort?: (key: keyof Incident, sortDirection?: SortDirection) => void;
  onRowClick?: (row: Incident) => void;
  onEventClick?: (incident: Incident) => void;
}

const IncidentTableData = ({
  loading,
  data,
  onLoadMore,
  onSort,
  onRowClick,
  onEventClick,
}: Props) => {
  const [sortState, setSortState] = useState<
    Partial<Record<keyof Incident, SortDirection>>
  >({});

  const handleSort = (key: keyof Incident) => () => {
    const sortDir = sortState?.[key];
    let newSortDirection: SortDirection = 'asc';
    if (sortDir === 'asc') {
      newSortDirection = 'desc';
    } else if (sortDir === 'desc') {
      newSortDirection = false;
    }
    setSortState(() => ({ [key]: newSortDirection }));
    onSort?.(key, newSortDirection);
  };

  return (
    <Box mb={1}>
      <Table size="small">
        <StyledComponent.TableHeaderStyled>
          <TableRow>
            <StyledComponent.TableHeaderCell
              width={100}
              onClick={handleSort('date')}
            >
              <TableHeaderWithSort
                title="Date"
                sortDirection={sortState['date']}
              />
            </StyledComponent.TableHeaderCell>
            <StyledComponent.TableHeaderCell
              width="40%"
              onClick={handleSort('details')}
            >
              <TableHeaderWithSort
                title="Incident"
                sortDirection={sortState['details']}
              />
            </StyledComponent.TableHeaderCell>
            <StyledComponent.TableHeaderCell
              width="10%"
              onClick={handleSort('case')}
            >
              <TableHeaderWithSort
                title="Case"
                sortDirection={sortState['case']}
              />
            </StyledComponent.TableHeaderCell>
            <StyledComponent.TableHeaderCell
              width="10%"
              onClick={handleSort('severity')}
            >
              <TableHeaderWithSort
                title="Severity"
                sortDirection={sortState['severity']}
              />
            </StyledComponent.TableHeaderCell>
            <StyledComponent.TableHeaderCell
              width="5%"
              onClick={handleSort('eventCount')}
            >
              <TableHeaderWithSort
                title="Events"
                sortDirection={sortState['eventCount']}
                centered
              />
            </StyledComponent.TableHeaderCell>
            <StyledComponent.TableHeaderCell
              width="10%"
              onClick={handleSort('incidentType')}
            >
              <TableHeaderWithSort
                title="Incident Type"
                sortDirection={sortState['incidentType']}
              />
            </StyledComponent.TableHeaderCell>
            <StyledComponent.TableHeaderCell
              width="5%"
              onClick={handleSort('reported')}
            >
              <TableHeaderWithSort
                title="Reported"
                centered
                sortDirection={sortState['reported']}
              />
            </StyledComponent.TableHeaderCell>
            <StyledComponent.TableHeaderCell
              width="10%"
              onClick={handleSort('status')}
            >
              <TableHeaderWithSort
                title="Status"
                sortDirection={sortState['status']}
              />
            </StyledComponent.TableHeaderCell>
          </TableRow>
        </StyledComponent.TableHeaderStyled>
        <TableBody>
          {data?.map((row) => (
            <StyledComponent.TableBodyRow
              key={row?.id}
              hover
              data-cy={`incident-list-${row.id}`}
            >
              <TableCell onClick={() => onRowClick?.(row)}>
                {dayjs(row.date).format('YYYY-MM-DD')}
              </TableCell>
              <TableCell onClick={() => onRowClick?.(row)}>
                {row.details}
              </TableCell>
              <TableCell onClick={() => onRowClick?.(row)}>
                {row.case}
              </TableCell>
              <TableCell onClick={() => onRowClick?.(row)}>
                <Box display="flex">{generateJSX(row.severity)}</Box>
              </TableCell>
              <StyledComponent.TableBodyCenteredCell
                onClick={() => onEventClick?.(row)}
              >
                {row.eventCount}
              </StyledComponent.TableBodyCenteredCell>
              <TableCell onClick={() => onRowClick?.(row)}>
                {row.incidentType}
              </TableCell>
              <StyledComponent.TableBodyCenteredCell
                onClick={() => onRowClick?.(row)}
              >
                {row.reported ? (
                  <FiberManualRecord color="primary" />
                ) : (
                  <FiberManualRecord htmlColor="#DCE0E5" />
                )}
              </StyledComponent.TableBodyCenteredCell>
              <TableCell onClick={() => onRowClick?.(row)}>
                {Status[row.status]}
              </TableCell>
            </StyledComponent.TableBodyRow>
          ))}
        </TableBody>
        {loading && <TableBodyLoader totalCols={8} rowCount={20} />}
      </Table>
      {!loading && !!data?.length && <Waypoint onEnter={onLoadMore} />}
      {!data?.length && (
        <StyledComponent.NoResultContainer elevation={0}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            <StyledComponent.NoResultIcon src={NoResultIconUrl} />
            <Typography variant="h5">No results.</Typography>
            <Typography variant="body1">
              Please adjust your filters or select more cases from the left side
              menu.
            </Typography>
          </Box>
        </StyledComponent.NoResultContainer>
      )}
    </Box>
  );
};

export default memo(IncidentTableData);
