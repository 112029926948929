import React, { useEffect } from 'react';
import ErrorComponent from './Error.component';
import { useNavigate } from 'react-router-dom';
import { DashboardRoutes } from '@root/common/Routes';
import { useAppContext } from '@root/App.context';

const NotFoundError = () => {
  const navigate = useNavigate();
  const { setInvalidUrl } = useAppContext();

  useEffect(() => {
    setInvalidUrl(true);

    return () => {
      setInvalidUrl(false);
    };
  }, [setInvalidUrl]);

  return (
    <>
      <ErrorComponent
        label="Page not found."
        buttonText="Return to dashboard"
        buttonAction={() => navigate(DashboardRoutes.Root)}
      />
    </>
  );
};

export default NotFoundError;
