import useErrorHook from '@root/common/GlobalMessage.hooks';
import { AssetDetails } from '@root/assets';
import { useCallback } from 'react';
import { validateInputs } from '@root/assets/asset-types/AssetTypes.helpers';

const useValidator = () => {
  const { handleError } = useErrorHook();

  const performValidation = useCallback(
    (details: AssetDetails, autoSave?: boolean) => {
      return Object.entries(details).some(([key, value]) => {
        if (!value) return false;
        const inputValidation = validateInputs(
          key as keyof AssetDetails,
          value,
        );
        if (typeof inputValidation === 'string') {
          const check = ['social', 'email', 'contact', 'wallet'].some((key) =>
            inputValidation.includes(key),
          );

          if (check && !autoSave) {
            handleError(new Error(inputValidation));
          } else if (!check) {
            handleError(new Error(inputValidation));
          }

          return true;
        }

        return false;
      });
    },
    [handleError],
  );

  return {
    performValidation,
  };
};

export default useValidator;
