import styled from 'styled-components';
import { Box, Link, Paper, TableCell, TableRow } from '@mui/material';
import { CopyIcon } from '@fyeo-di-frontend/shared';

export const Root = styled.div({
  height: 'calc(100vh - 70px)',
  backgroundColor: '#f8f9fb',
  paddingTop: 30,
  overflowY: 'hidden',
  overflowX: 'hidden',
});

export const Wrapper = styled(Box)({
  borderRadius: 4,
  marginRight: 27,
  marginLeft: 27,
  marginBottom: 30,
  backgroundColor: '#fff',
  height: 'calc(100% - 70px)',
  padding: 30,
  display: 'flex',
  gap: 30,
});

export const NoResultContainer = styled(Paper)({
  marginTop: 10,
  paddingTop: 40,
  paddingBottom: 62,
});

export const NoResultIcon = styled.img({
  width: 103,
});

export const TableBodyRow = styled(TableRow)({
  backgroundColor: '#fff',
});

export const TableContainer = styled(Box)({
  overflowY: 'auto',
  height: '100%',
});

export const TableCellWithoutBorder = styled(TableCell)({
  border: 'none',
});

export const TitleLink = styled(Link)({
  color: 'unset',
  '&:hover': {
    color: '#31AD34',
  },
});

export const CursorCopyIcon = styled(CopyIcon)({
  cursor: 'pointer',
});
