export enum FilterStorageKey {
  DateRange = 'dateRange',
  EventsSearchText = 'eventsSearchText',
  Asset = 'asset',
  ThreatLevel = 'threatLevel',
  Site = 'site',
  SourceType = 'sourceType',
  Language = 'language',
  FileType = 'fileType',
  MatchingScore = 'matchingScore',
  Incidents = 'incidents',
}

export enum FileTypes {
  Pdf = 'Pdf',
  Text = 'Text',
  Ppt = 'Ppt',
  Doc = 'Doc',
  Xml = 'Xml',
  Json = 'Json',
  OctetStream = 'OctetStream',
  Html = 'Html',
}

export type EventIncident = {
  label: string;
  id: string;
};
