import {
  HorizontalFullHeightLoader,
  SeverityWithoutNone,
} from '@fyeo-di-frontend/shared';
import { Box, Paper, Tab, Tabs } from '@mui/material';
import React, { memo, useState } from 'react';
import ChartComponent from './Dashboard.chart.component';
import { TabRoot } from './Dashboard.styles';
import { IChartData } from './Dashboard.types';

interface OwnProps {
  newData?: Record<SeverityWithoutNone, IChartData[]>;
  openData?: Record<SeverityWithoutNone, IChartData[]>;
  closedData?: Record<SeverityWithoutNone, IChartData[]>;
  isLoading: boolean;
}
const DashboardChart = (props: OwnProps) => {
  const [selectedTab, setSelectedTab] = useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Paper elevation={0}>
      {props.isLoading ? (
        <Box height={368}>
          <HorizontalFullHeightLoader />
        </Box>
      ) : (
        <Box p={1}>
          <TabRoot>
            <Tabs value={selectedTab} onChange={handleChange}>
              <Tab label="New" />
              <Tab label="Open" />
              <Tab label="Closed" />
            </Tabs>
          </TabRoot>
          {selectedTab === 0 && <ChartComponent data={props?.newData} />}
          {selectedTab === 1 && <ChartComponent data={props?.openData} />}
          {selectedTab === 2 && <ChartComponent data={props?.closedData} />}
        </Box>
      )}
    </Paper>
  );
};

export default memo(DashboardChart);
