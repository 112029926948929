import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const MobileIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M10.51,4H2.35a.36.36,0,0,0-.27.12A.37.37,0,0,0,2,4.43V18.7a.38.38,0,0,0,.38.38h8.16a.38.38,0,0,0,.38-.38V4.43a.37.37,0,0,0-.11-.27A.36.36,0,0,0,10.51,4Zm-.39.77V15.26H2.73V4.81ZM2.73,18.32V16h7.39v2.3Zm3.7-.64a.51.51,0,1,0-.51-.51A.51.51,0,0,0,6.43,17.68Zm-.77-11H7.19a.27.27,0,0,0,.26-.26.26.26,0,0,0-.26-.25H5.66a.24.24,0,0,0-.25.25.26.26,0,0,0,.25.26Z" />
    <path d="M17.09,11.87v1.32h2.28v2.35h1.34V13.19H23V11.87H20.71V9.53H19.37v2.34Z" />
  </SvgIcon>
);

export default MobileIcon;
