import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
} from '@mui/material';
import { IncidentsRoutes } from '@root/common/Routes';
import { Close } from '@mui/icons-material';
import { IncidentsDetails } from './details';

const IncidentsDetailsDialog = () => {
  const push = useNavigate();
  return (
    <Dialog
      open={true}
      onClose={() => push(IncidentsRoutes.Root)}
      scroll="body"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Box position="absolute" right={8} top={8} color="black">
          <IconButton
            onClick={() => push(IncidentsRoutes.Root)}
            color="inherit"
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <IncidentsDetails />
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(IncidentsDetailsDialog);
