import { AResponsePayload } from '../assets';
import { Severity } from '../helpers';
import dayjs from 'dayjs';

export interface Events<T, K> {
  id: string;
  title?: string;
  url?: string;
  cut?: string;
  createdAt?: Date | string;
  image?: string;
  name?: string;
  pdfUrl: string;
  caseId?: string;
  case?: string;
  classified?: boolean;
  classifiedAt?: Date | string;
  sourceType?: string;
  largeImage?: string;
  threatLevel?: Severity;
  confidenceScore?: number;
  documentData?: {
    contentLanguage: string;
  };
  incidentObject?: {
    id: string;
    title: string;
  };
  site?: string;
  asset?: {
    assetType: T;
    assetName: string;
    id: string;
  };
  assetDetails?: K;
  mentions?: Mention[];
  threatActorMentions?: Mention[];
}

export interface Mention {
  cut?: string;
  startPos?: number;
  endPos?: number;
  translatedCut?: string;
  matches?: {
    assetId?: string;
    assetName?: string;
    matched?: string;
    keywordName?: string;
    startPos?: number;
    endPos?: number;
  }[];
}

export interface EventResponse {
  _id: string;
  createdAt: string | number | Date | dayjs.Dayjs | null | undefined;
  title: string;
  url: string;
  mentions: {
    cut: string;
    start_pos: number;
    end_pos: number;
    matches: {
      matched: string;
      keywordName: string;
      startPos: string;
      endPos: string;
    }[];
  }[];
  imageIconUrl: string;
  pdfUrl: string;
  asset: AResponsePayload;
  isClassified: string;
  classifiedAt: string;
  probability: string;
  sourceType: string;
  imageUrl: string;
  threatLevel: Severity;
  site: string;
  incidentObject?: {
    _id: string;
    title: string;
  };
}

export enum ThreatLevel {
  Safe = 0,
  Low = 1,
  Moderate = 2,
  Critical = 3,
  High = 4,
  Severe = 5,
  None = -1,
}
