import {
  Box,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material';
import React from 'react';

const NoGroup = () => {
  return (
    <Box width={444}>
      <DialogTitle>
        <Box margin="auto" textAlign="center" mt={2}>
          No account group found
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          fontSize={80}
          alignItems="center"
          justifyContent="center"
          gap={2}
        >
          <Typography variant="subtitle2" textAlign="center">
            There is no user group associated with this email address. Please
            contact support at{' '}
            <Link
              href="mailto:support@gofyeo.com"
              underline="none"
              variant="subtitle2"
              referrerPolicy="no-referrer"
              target="_blank"
            >
              support@gofyeo.com.
            </Link>
          </Typography>
        </Box>
      </DialogContent>
    </Box>
  );
};

export default NoGroup;
