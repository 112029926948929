import { useLocation } from 'react-router-dom';
import { Status } from '@fyeo-di-frontend/shared';
import { useCallback, useEffect } from 'react';
import useIncidentsFiltersHook from './Incidents.filters.hooks';
import { useIncidentsContext } from './Incidents.context';

const useFilterRouterState = () => {
  const { clear } = useIncidentsContext();
  const {
    onChangeDateRange,
    onChangeIncidentSearchText,
    onChangeIncidentTypes,
    onChangeAssets,
    onChangeSeverity,
    onChangeStatuses,
    onChangeReported,
    onChangeAssetTypes,
  } = useIncidentsFiltersHook();
  const { state } = useLocation();

  //restore from router state
  const restoreFromRouter = useCallback(() => {
    clear?.();
    const sRange = state?.dateRange;
    if (sRange?.from) {
      onChangeDateRange(sRange?.from, sRange?.to);
    }

    const sSearchText = state?.incidentSearchText;
    if (sSearchText) {
      onChangeIncidentSearchText(sSearchText);
    }

    const sTypes = state?.incidentsTypes;
    if (sTypes) {
      onChangeIncidentTypes(sTypes);
    }

    const sATypes = state?.assetTypes;
    if (sATypes) {
      onChangeAssetTypes(sATypes);
    }

    const sAssets = state?.assets;
    if (sAssets) {
      onChangeAssets(sAssets);
    }

    const sReported = state?.reported;
    if (sReported && sReported?.length > 0) {
      onChangeReported(sReported);
    }

    const sSeverity = state?.severity;
    if (typeof sSeverity != 'undefined') {
      onChangeSeverity(sSeverity);
    }

    const sStatus = state?.statuses;
    if (sStatus || sStatus === Status.Open) {
      onChangeStatuses(sStatus);
    }
  }, [
    clear,
    onChangeStatuses,
    onChangeSeverity,
    onChangeReported,
    onChangeIncidentTypes,
    onChangeIncidentSearchText,
    onChangeDateRange,
    state,
    onChangeAssets,
    onChangeAssetTypes,
  ]);

  // restore filters from state
  useEffect(() => {
    if (state) {
      restoreFromRouter();
    }
  }, [restoreFromRouter, state]);
};

export default useFilterRouterState;
