import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const InfoIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    {...props}
  >
    <circle
      cx="52.1885"
      cy="52.4785"
      r="50"
      fill="#FFEECC"
      stroke="#FFA800"
      strokeWidth="3"
    />
    <path
      d="M58.6885 23.9785H45.6885L48.2723 63.8586H56.1046L58.6885 23.9785ZM45.85 75.588C45.85 79.1473 48.6761 81.9785 52.1481 81.9785C55.7009 81.9785 58.6077 79.1473 58.6077 75.588C58.6077 72.0287 55.7009 69.1166 52.1481 69.1166C48.6761 69.1166 45.85 72.0287 45.85 75.588Z"
      fill="#FFA800"
    />
  </SvgIcon>
);

export default InfoIcon;
