import { CombinedError } from 'urql';
import { ErrorCodes } from './ErrorCodes.enum';
import { tokenToJson } from './Jwt';

export const formatErrorCode = (error?: CombinedError) => {
  const originalError = error?.graphQLErrors[0]?.originalError as {
    errorCode?: number;
    message?: string;
  };
  const errorCode = `_${originalError?.errorCode}`;
  if (isValidError(errorCode)) {
    return `_${originalError?.errorCode}` as keyof typeof ErrorCodes;
  } else {
    return 'None';
  }
};

// predicate here
const isValidError = (error: string): error is keyof typeof ErrorCodes => {
  return error in ErrorCodes;
};

export const isStaff = (token?: string | null) => {
  const IDToken = token ? tokenToJson(token) : undefined;
  return IDToken?.['cognito:groups']?.includes('staff');
};

export const totalCounts = () => {
  return {
    domain: 3,
    person: 5,
    location: 3,
    organization: 2,
  };
};

export const downloadCSV = (data: string, title: string, id?: string) => {
  const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
  let csvURL;
  if (navigator.msSaveBlob) {
    csvURL = navigator.msSaveBlob(csvData, `${title}${id ? `-${id}` : ''}`);
  } else {
    csvURL = window.URL.createObjectURL(csvData);
  }

  const tempLink = document.createElement('a');
  if (typeof csvURL !== 'boolean') {
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `${title}${id ? `-${id}` : ''}`);
    tempLink.click();
  }
};

const allowedTags: string[] = []; // Defines the allowed HTML tags

export const removeHTMLTagsFromString = (text?: string) => {
  const htmlTagPresent = text
    ? /<([a-z]+[a-z\d]*)(?:[^>]+)?>/i.test(text)
    : false;
  return {
    newText: text?.replace(/<[^>]+>/g, (tag) => {
      const tagMatch = tag.match(/<\/?(\w+)/);
      const tagName = tagMatch ? tagMatch[1] : '';
      return allowedTags.includes(tagName) ? tag : '';
    }),
    htmlTagPresent,
  };
};
