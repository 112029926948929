import * as StyledComponents from './Incidents.styles';
import { CommentType, History } from './Incidents.types';
import dayjs from 'dayjs';
import { MenuItem } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { OptionIcon } from '../icons';
import EditMarkdownEditor from './Incident.editMarkdownEditor.component';

interface OwnProps {
  incident?: History;
  isLast: boolean;
  user?: string;
  onEdit?: (
    id: string,
    newValue: { content: string; commentator: string },
    callback?: () => void,
  ) => void;
  onDelete?: (id: string) => void;
  updatingComments?: string[];
  incidentId?: string;
  setRef?: (id: string, ref: HTMLDivElement | null) => void;
}
const IncidentsHistoryComponent = ({
  incident,
  isLast,
  user,
  onDelete,
  onEdit,
  updatingComments,
  incidentId,
  setRef,
}: OwnProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [edit, setEdit] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const copyTextToClipboard = async (text: string) => {
    return await navigator.clipboard.writeText(text);
  };

  const isActiveUser = useMemo(() => {
    return (
      user &&
      incident?.changedKey === 'comment' &&
      user === (incident?.newValue as CommentType)?.commentator
    );
  }, [user, incident?.changedKey, incident?.newValue]);
  return (
    <>
      <StyledComponents.IncidentHistory
        id={`comment_${incident?._id}`}
        ref={(r) => {
          setRef?.(`comment_${incident?._id}`, r);
        }}
      >
        {edit ? (
          <EditMarkdownEditor
            content={(incident?.newValue as CommentType)?.content}
            onCancel={() => setEdit(() => false)}
            isSaving={
              (incident?._id && updatingComments?.includes(incident?._id)) ||
              false
            }
            onSave={(editorState) => {
              incident?._id &&
                onEdit?.(
                  incident?._id,
                  {
                    content: editorState,
                    commentator: (incident.newValue as CommentType).commentator,
                  },
                  () => {
                    setEdit(() => false);
                  },
                );
            }}
          />
        ) : (
          <>
            {!isLast && <StyledComponents.Line />}
            <StyledComponents.MenuWrapper onClick={handleClick}>
              <OptionIcon />
            </StyledComponents.MenuWrapper>
            <StyledComponents.IncidentWrapperWithWidth>
              {incident?.createdAt &&
                dayjs(new Date(incident?.createdAt)).format('YYYY-MM-DD')}
            </StyledComponents.IncidentWrapperWithWidth>
            <StyledComponents.IncidentWrapper>
              <StyledComponents.IncidentText>
                {incident?.changedKey === 'recommendation' ||
                incident?.changedKey === 'sourceType' ? (
                  <>
                    <span className="bold transform">
                      {incident?.changedKey}
                    </span>{' '}
                    updated by {incident?.updatedBy}
                  </>
                ) : incident?.changedKey === 'comment' ? (
                  <>
                    <span className="bold">
                      {(incident?.newValue as CommentType)?.commentator}
                    </span>
                    <span>
                      <div className="container" data-color-mode="light">
                        <StyledComponents.Markdown
                          source={(incident?.newValue as CommentType)?.content}
                        />
                      </div>
                    </span>
                  </>
                ) : (
                  <>
                    <span className="bold transform">
                      {incident?.changedKey}
                    </span>{' '}
                    changed from {`"${incident?.oldValue}"`} to
                    {`"${incident?.newValue}"`} by
                    {`"${incident?.updatedBy}"`}
                  </>
                )}
              </StyledComponents.IncidentText>
            </StyledComponents.IncidentWrapper>
          </>
        )}
        <StyledComponents.MenuStyled
          id="menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'menu-button',
          }}
        >
          <MenuItem
            onClick={() => {
              copyTextToClipboard(
                `${window.location.origin}/incidents/${incidentId}#comment_${incident?._id}`,
              ).then(handleClose);
            }}
          >
            Copy link
          </MenuItem>
          {isActiveUser && [
            <MenuItem
              key="menu-1"
              onClick={() => {
                handleClose();
                setEdit(() => true);
              }}
            >
              Edit comment
            </MenuItem>,
            <MenuItem
              key="menu-2"
              onClick={() => {
                incident?._id && onDelete?.(incident?._id);
                handleClose();
              }}
            >
              Delete comment
            </MenuItem>,
          ]}
        </StyledComponents.MenuStyled>
      </StyledComponents.IncidentHistory>
    </>
  );
};

export default IncidentsHistoryComponent;
