import { HorizontalFullHeightLoader } from '@fyeo-di-frontend/shared';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import * as StyledComponents from './DomainReport.styles';

interface OwnProps {
  data?: {
    sources: number;
    entries: number;
  };
  isLoading?: boolean;
}
0;
const DomainReportCardSummaryComponent = ({ isLoading, data }: OwnProps) => {
  return (
    <StyledComponents.EntryRoot>
      <Grid container spacing={1} mt={8}>
        <Grid item xs={6}>
          <StyledComponents.CardPaper elevation={0}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height={150}
            >
              {isLoading ? (
                <HorizontalFullHeightLoader />
              ) : (
                <>
                  <Typography variant="h4">{data?.entries || 0}</Typography>
                  <Typography variant="body1">
                    Total detected entries
                  </Typography>
                </>
              )}
            </Box>
          </StyledComponents.CardPaper>
        </Grid>
        <Grid item xs={6}>
          <StyledComponents.CardPaper elevation={0}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height={150}
            >
              {isLoading ? (
                <HorizontalFullHeightLoader />
              ) : (
                <>
                  <Typography variant="h4">{data?.sources || 0}</Typography>
                  <Typography variant="body1">
                    Total detected sources
                  </Typography>
                </>
              )}
            </Box>
          </StyledComponents.CardPaper>
        </Grid>
      </Grid>
    </StyledComponents.EntryRoot>
  );
};

export default DomainReportCardSummaryComponent;
