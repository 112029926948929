import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const LocationIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 31 31" {...props}>
    <g clipPath="url(#clip0_12383_18524)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.79297 12.098C3.79297 5.78721 9.07011 0.716797 15.5317 0.716797H15.5543C22.0158 0.716797 27.293 5.78721 27.293 12.098C27.293 14.8471 25.8468 17.3554 23.5242 20.6059C21.6353 23.248 19.7874 25.5859 18.4122 27.2627C17.7243 28.1014 17.1541 28.7756 16.7553 29.2407C16.5559 29.4732 16.3993 29.6536 16.2922 29.7761C16.2387 29.8374 16.1975 29.8842 16.1696 29.9159L16.1377 29.952L16.1272 29.9639L16.1264 29.9648C16.1263 29.9649 16.1263 29.9649 15.5656 29.4668L16.1264 29.9648C15.9906 30.1176 15.7982 30.2085 15.5939 30.2163C15.5769 30.2169 15.5599 30.217 15.543 30.2165C15.5259 30.217 15.5087 30.2169 15.4915 30.2162C15.287 30.2084 15.0947 30.1173 14.959 29.9642L15.5204 29.4668C14.959 29.9642 14.959 29.9642 14.959 29.9642L14.9581 29.9631L14.9476 29.9513L14.9157 29.9151C14.8954 29.8919 14.8679 29.8606 14.8338 29.8215C14.8212 29.8071 14.8076 29.7916 14.7932 29.775C14.6862 29.6522 14.5296 29.4715 14.3302 29.2386C13.9315 28.7728 13.3613 28.0977 12.6735 27.2584C11.2985 25.5804 9.45092 23.243 7.56227 20.6067C5.2385 17.3654 3.79297 14.8469 3.79297 12.098ZM15.5436 28.3493C15.5672 28.3218 15.5916 28.2934 15.6166 28.2642C16.0092 27.8065 16.5723 27.1408 17.2524 26.3115C18.613 24.6524 20.4394 22.3417 22.3039 19.7337C24.639 16.4656 25.793 14.3145 25.793 12.098C25.793 6.66201 21.2345 2.2168 15.5543 2.2168H15.5317C9.85139 2.2168 5.29297 6.66201 5.29297 12.098C5.29297 14.3148 6.44758 16.4775 8.78149 19.7329C10.6462 22.3357 12.4729 24.6469 13.8338 26.3076C14.5139 27.1377 15.0771 27.8045 15.4698 28.2632C15.4951 28.2928 15.5197 28.3215 15.5436 28.3493Z"
        fill="#32AE34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.543 8.2168C13.748 8.2168 12.293 9.67187 12.293 11.4668C12.293 13.2617 13.748 14.7168 15.543 14.7168C17.3379 14.7168 18.793 13.2617 18.793 11.4668C18.793 9.67187 17.3379 8.2168 15.543 8.2168ZM10.793 11.4668C10.793 8.84344 12.9196 6.7168 15.543 6.7168C18.1663 6.7168 20.293 8.84344 20.293 11.4668C20.293 14.0901 18.1663 16.2168 15.543 16.2168C12.9196 16.2168 10.793 14.0901 10.793 11.4668Z"
        fill="#32AE34"
      />
    </g>
    <defs>
      <clipPath id="clip0_12383_18524">
        <rect
          width="30"
          height="30"
          fill="white"
          transform="translate(0.542969 0.466797)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default LocationIcon;
