import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const SocialIcon2: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M19.4282 13.4407L18.7828 14.0647L19.0278 14.9283L19.6298 17.0511L16.2568 15.8239L15.7833 15.6517L15.3018 15.8002C14.1336 16.1606 12.8508 16.3614 11.5 16.3614C5.60213 16.3614 1.5 12.7037 1.5 8.9307C1.5 5.15765 5.60213 1.5 11.5 1.5C17.3979 1.5 21.5 5.15765 21.5 8.9307C21.5 10.5738 20.7689 12.1444 19.4282 13.4407ZM21.8732 19.4634L21.3684 17.6836L20.4709 14.519C22.0532 12.9891 23 11.0457 23 8.9307C23 3.99841 17.8513 0 11.5 0C5.14873 0 0 3.99841 0 8.9307C0 13.863 5.14873 17.8614 11.5 17.8614C12.9989 17.8614 14.4309 17.6387 15.744 17.2336L20.1346 18.8309L21.8732 19.4634ZM6.89963 10.2836C7.74646 10.2836 8.43296 9.57545 8.43296 8.70187C8.43296 7.82829 7.74646 7.12012 6.89963 7.12012C6.05279 7.12012 5.36629 7.82829 5.36629 8.70187C5.36629 9.57545 6.05279 10.2836 6.89963 10.2836ZM13.0337 8.70187C13.0337 9.57545 12.3472 10.2836 11.5004 10.2836C10.6535 10.2836 9.96704 9.57545 9.96704 8.70187C9.96704 7.82829 10.6535 7.12012 11.5004 7.12012C12.3472 7.12012 13.0337 7.82829 13.0337 8.70187ZM16.1002 10.2836C16.947 10.2836 17.6335 9.57545 17.6335 8.70187C17.6335 7.82829 16.947 7.12012 16.1002 7.12012C15.2534 7.12012 14.5669 7.82829 14.5669 8.70187C14.5669 9.57545 15.2534 10.2836 16.1002 10.2836Z" />
  </SvgIcon>
);

export default SocialIcon2;
