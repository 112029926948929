import { useEventsContext } from './Events.context';
import debounce from 'lodash/debounce';
import { useCallback, useMemo } from 'react';
import {
  deleteEventFilters,
  storeEventFilters,
} from '@fyeo-di-frontend/shared';
import { FilterStorageKey } from './Events.types';
import { Option } from '@root/common/Common.types';

let handleOnChangeSearchText: ReturnType<typeof debounce>;
let handleOnChangeMatchingScore: ReturnType<typeof debounce>;

const useEventsFiltersHook = () => {
  const {
    setDateRange,
    resetDataAndPage,
    setEventsSearchText,
    setAssets,
    setThreatLevel,
    setMatchingScore,
    setSites,
    setSourceTypes,
    setFileTypes,
    setLanguages,
    setIncidentsFilter,
    assets,
    threatLevel,
    incidentsFilter,
    sourceTypes,
    languages,
    fileTypes,
  } = useEventsContext();

  const onChangeDateRange = useCallback(
    (from?: Date, to?: Date) => {
      storeEventFilters(FilterStorageKey.DateRange, { from, to });
      setDateRange?.(() => ({ from, to }));
      resetDataAndPage?.();
    },
    [setDateRange, resetDataAndPage],
  );

  const onChangeEventSearchText = useMemo(() => {
    handleOnChangeSearchText?.cancel?.();
    handleOnChangeSearchText = debounce((val: string) => {
      storeEventFilters(FilterStorageKey.EventsSearchText, val);
      setEventsSearchText?.(() => val);
      resetDataAndPage?.();
    }, 100);
    return handleOnChangeSearchText;
  }, [setEventsSearchText, resetDataAndPage]);

  const onChangeMultiSelect = useCallback(
    (val: { name: string; id: string }[], key: FilterStorageKey) => {
      storeEventFilters(key, val);
      switch (key) {
        case FilterStorageKey.Asset:
          setAssets?.(() => val);
          break;
        case FilterStorageKey.Incidents:
          setIncidentsFilter?.(() => val);
          break;
        case FilterStorageKey.ThreatLevel:
          setThreatLevel?.(() => val);
          break;
        case FilterStorageKey.SourceType:
          setSourceTypes?.(() => val);
          break;
        case FilterStorageKey.FileType:
          setFileTypes?.(() => val);
          break;
        case FilterStorageKey.Language:
          setLanguages?.(() => val);
          break;
      }
      resetDataAndPage?.();
    },
    [
      setAssets,
      resetDataAndPage,
      setSourceTypes,
      setThreatLevel,
      setFileTypes,
      setLanguages,
      setIncidentsFilter,
    ],
  );

  const onChangeSite = useCallback(
    (val: Option | null, key: FilterStorageKey) => {
      if (!val) {
        deleteEventFilters(key);
        setSites?.(() => undefined);
      } else {
        storeEventFilters(key, val);
        setSites?.(() => val as { name: string; id: string });
      }
      resetDataAndPage?.();
    },
    [setSites, resetDataAndPage],
  );

  const onChangeMatchingScore = useMemo(() => {
    handleOnChangeMatchingScore?.cancel?.();
    handleOnChangeMatchingScore = debounce(
      (val?: { from: number; to: number }) => {
        storeEventFilters(FilterStorageKey.MatchingScore, val);
        setMatchingScore?.(() => val);
        resetDataAndPage?.();
      },
      100,
    );
    return handleOnChangeMatchingScore;
  }, [setMatchingScore, resetDataAndPage]);

  const clearMatchingScore = useCallback(() => {
    setMatchingScore?.(undefined);
    deleteEventFilters(FilterStorageKey.MatchingScore);
  }, [setMatchingScore]);

  const onRemoveAsset = useCallback(
    (val: string) => {
      const v = assets?.filter((i) => i.id !== val) || [];
      onChangeMultiSelect(v, FilterStorageKey.Asset);
    },
    [assets, onChangeMultiSelect],
  );

  const onRemoveThreatLevel = useCallback(
    (val: string) => {
      const v = threatLevel?.filter((i) => i.id !== val) || [];
      onChangeMultiSelect(v, FilterStorageKey.ThreatLevel);
    },
    [threatLevel, onChangeMultiSelect],
  );

  const onRemoveIncident = useCallback(
    (val: string) => {
      const v = incidentsFilter?.filter((i) => i.id !== val) || [];
      onChangeMultiSelect(v, FilterStorageKey.Incidents);
    },
    [incidentsFilter, onChangeMultiSelect],
  );

  const onRemoveSourceType = useCallback(
    (val: string) => {
      const v = sourceTypes?.filter((i) => i.id !== val) || [];
      onChangeMultiSelect(v, FilterStorageKey.SourceType);
    },
    [sourceTypes, onChangeMultiSelect],
  );

  const onRemoveLanguage = useCallback(
    (val: string) => {
      const v = languages?.filter((i) => i.id !== val) || [];
      onChangeMultiSelect(v, FilterStorageKey.Language);
    },
    [languages, onChangeMultiSelect],
  );

  const onRemoveFiletype = useCallback(
    (val: string) => {
      const v = fileTypes?.filter((i) => i.id !== val) || [];
      onChangeMultiSelect(v, FilterStorageKey.FileType);
    },
    [fileTypes, onChangeMultiSelect],
  );
  return {
    onChangeDateRange,
    onChangeEventSearchText,
    onChangeMultiSelect,
    onChangeMatchingScore,
    onChangeSite,
    clearMatchingScore,
    onRemoveAsset,
    onRemoveThreatLevel,
    onRemoveIncident,
    onRemoveSourceType,
    onRemoveLanguage,
    onRemoveFiletype,
  };
};

export default useEventsFiltersHook;
