import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import React from 'react';

interface OwnProps {
  confirmation: boolean;
  setConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  onGenerate: () => void;
}
const ConfirmDialog = ({
  confirmation,
  setConfirmation,
  onGenerate,
}: OwnProps) => {
  if (confirmation) {
    return (
      <Dialog
        open={confirmation}
        onClose={() => setConfirmation(() => false)}
        scroll="body"
      >
        <DialogTitle>
          <Box margin="auto" textAlign="center" mt={2}>
            Please confirm!
          </Box>
          <Box position="absolute" right={8} top={8} color="black">
            <IconButton
              onClick={() => setConfirmation(() => false)}
              color="inherit"
            >
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <Typography variant="subtitle2" textAlign="center">
              When you generate a new API key it
              <br />
              will replace the current one.
            </Typography>
            <Typography variant="subtitle2">
              Are you sure you want to continue?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            margin="auto"
            textAlign="center"
            gap={2}
            display="flex"
            mb={3}
            mx={3}
          >
            <Box width={126}>
              <Button
                onClick={() => {
                  onGenerate();
                  setConfirmation(() => false);
                }}
                variant="contained"
                color="primary"
                size="small"
                fullWidth
              >
                Yes, continue.
              </Button>
            </Box>
            <Box width={126}>
              <Button
                onClick={() => setConfirmation(() => false)}
                variant="contained"
                color="secondary"
                size="small"
                fullWidth
              >
                No, cancel.
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    );
  }

  return null;
};

export default ConfirmDialog;
