import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import React from 'react';
import { CheckIcon } from '@fyeo-di-frontend/shared';

interface OwnProps {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}
const SupportSuccess = ({ onClose }: OwnProps) => {
  return (
    <Box width={444}>
      <DialogTitle>
        <Box margin="auto" textAlign="center" mt={2}>
          Support ticket created!
        </Box>
        <Box position="absolute" right={8} top={8} color="black">
          <IconButton onClick={() => onClose(() => false)} color="inherit">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          fontSize={80}
          alignItems="center"
          justifyContent="center"
          gap={2}
        >
          <Typography variant="subtitle2">
            We’ll get back to you via email as soon as possible.
          </Typography>
          <CheckIcon fontSize="inherit" />
        </Box>
      </DialogContent>
    </Box>
  );
};

export default SupportSuccess;
