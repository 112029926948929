import dayjs from 'dayjs';
import { useMemo } from 'react';
import { usePeriodicContext } from './PeriodicReport.context';

const usePeriodicDashboardQuery = (fromDate?: Date, toDate?: Date) => {
  const { selectedCases } = usePeriodicContext();

  const from = useMemo(() => {
    return dayjs(fromDate);
  }, [fromDate]);

  const to = useMemo(() => {
    return dayjs(toDate);
  }, [toDate]);

  const pause =
    (selectedCases?.length && selectedCases?.length <= 0) ||
    !fromDate ||
    !toDate;

  const caseIds = useMemo(
    () => selectedCases?.map((c) => c.id) || [],
    [selectedCases],
  );

  return {
    from,
    to,
    caseIds,
    pause,
  };
};

export default usePeriodicDashboardQuery;
