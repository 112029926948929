import {
  Box,
  Grid,
  Paper,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import React from 'react';

export const PaperWithFullHeight = styled(Paper)<{ bg?: boolean }>((props) => ({
  height: '100%',
  backgroundColor: props.bg ? '#f8f9fb' : undefined,
}));

export const CardPaper = styled(Paper)<{ bg?: boolean }>((props) => ({
  backgroundColor: props.bg ? '#f8f9fb' : undefined,
  '&.active-cursor': {
    cursor: 'pointer',
  },
}));

export const CursorBox = styled(Box)({
  '&.active-cursor': {
    cursor: 'pointer',
  },
});
export const HeaderRow = styled(Paper)({
  display: 'flex',
  backgroundColor: 'white',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px  14px',
});

export const Row = styled(HeaderRow)({
  padding: '5px 14px',
  height: '8%',
  '&.active-cursor': {
    cursor: 'pointer',
  },
});

export const LoaderRow = styled(HeaderRow)({
  padding: 0,
});

export const TextGrid = styled(Grid)({});

export const HeatMapHeaderText = styled(Typography)`
  transform: rotate(-60deg);
  white-space: nowrap;
  position: relative;
  top: 75%;
  left: 30%;
  ${(props) => props.theme.breakpoints.down(1920)} {
    top: 70%;
    left: 10%;
  }
  ${(props) => props.theme.breakpoints.up(2600)} {
    top: 50%;
    left: 30%;
  }
`;

export const WrappedPaper = styled(Paper)<{ bg?: boolean }>((props) => ({
  backgroundColor: props.bg ? '#f8f9fb' : undefined,
}));

export const AssetTypeWrapper = styled(Box)({
  ':hover': {
    cursor: 'pointer',
  },
});

export const LegendCircle = styled(Box)<{ fillStyle?: string }>`
  width: 12px;
  height: 12px;
  background-color: ${({ fillStyle }) => `${fillStyle}`};
  border-radius: 50%;
`;

export const PiePaper = styled(Paper)<{ bg?: boolean }>((props) => ({
  height: '100%',
  backgroundColor: props.bg ? '#f8f9fb' : 'fff',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  padding: 10,
}));

export const ChartInner = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'absolute',
  top: '22%',
  right: '20%',
  bottom: '20%',
  left: '27%',
  borderRadius: '50%',
  padding: '1.25em 0',
  lineHeight: '120%',
  zIndex: 0 /* Allow tooltips on top */,
  overflow: 'hidden',
});

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    color: '#323232',
    boxShadow: '0px 0px 4.22142px 2px rgba(0, 0, 0, 0.1)',
    fontSize: 11,
    borderRadius: 3.16606,
    maxWidth: 155,
  },
  [`& .${tooltipClasses.tooltipPlacementRight}`]: {
    marginLeft: '2px !important',
  },
}));

export const MultiLineTruncText = styled(Typography)({
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
});
