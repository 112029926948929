import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const CopyIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <g clipPath="url(#clip0_10764_16888)">
      <path
        d="M17.5543 19.857H8.5293C7.89941 19.857 7.29532 19.6068 6.84992 19.1614C6.40452 18.716 6.1543 18.1119 6.1543 17.482V13.0487H7.73763V17.482C7.73763 17.692 7.82104 17.8934 7.9695 18.0418C8.11797 18.1903 8.31933 18.2737 8.5293 18.2737H17.5543C17.7643 18.2737 17.9656 18.1903 18.1141 18.0418C18.2626 17.8934 18.346 17.692 18.346 17.482V8.45703C18.346 8.24707 18.2626 8.0457 18.1141 7.89724C17.9656 7.74877 17.7643 7.66537 17.5543 7.66537H13.121V6.08203H17.5543C18.1842 6.08203 18.7883 6.33225 19.2337 6.77765C19.6791 7.22305 19.9293 7.82714 19.9293 8.45703V17.482C19.9293 18.1119 19.6791 18.716 19.2337 19.1614C18.7883 19.6068 18.1842 19.857 17.5543 19.857V19.857Z"
        fill="#31AD34"
      />
      <path
        d="M12.3297 14.6324H3.30469C2.6748 14.6324 2.07071 14.3822 1.62531 13.9368C1.17991 13.4914 0.929688 12.8873 0.929688 12.2574V3.23242C0.929688 2.60253 1.17991 1.99844 1.62531 1.55304C2.07071 1.10764 2.6748 0.857422 3.30469 0.857422H12.3297C12.9596 0.857422 13.5637 1.10764 14.0091 1.55304C14.4545 1.99844 14.7047 2.60253 14.7047 3.23242V12.2574C14.7047 12.8873 14.4545 13.4914 14.0091 13.9368C13.5637 14.3822 12.9596 14.6324 12.3297 14.6324ZM3.30469 2.44076C3.09472 2.44076 2.89336 2.52416 2.74489 2.67263C2.59643 2.8211 2.51302 3.02246 2.51302 3.23242V12.2574C2.51302 12.4674 2.59643 12.6687 2.74489 12.8172C2.89336 12.9657 3.09472 13.0491 3.30469 13.0491H12.3297C12.5397 13.0491 12.741 12.9657 12.8895 12.8172C13.0379 12.6687 13.1214 12.4674 13.1214 12.2574V3.23242C13.1214 3.02246 13.0379 2.8211 12.8895 2.67263C12.741 2.52416 12.5397 2.44076 12.3297 2.44076H3.30469Z"
        fill="#31AD34"
      />
    </g>
    <defs>
      <clipPath id="clip0_10764_16888">
        <rect
          width="19"
          height="19"
          fill="white"
          transform="translate(0.929688 0.857422)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default CopyIcon;
