import { TableBodyLoader, TrendItem } from '@fyeo-di-frontend/shared';
import { Box, Table, TableBody, Typography } from '@mui/material';
import dayjs from 'dayjs';

import React, { memo } from 'react';
import { Waypoint } from 'react-waypoint';
import * as StyledComponent from './EditTrends.styles';
import NoResultIconUrl from '@root/common/NoResultIcon.svg';

interface Props {
  loading?: boolean;
  data?: TrendItem[];
  onLoadMore?: () => void;
  onRowClick?: (row: TrendItem) => void;
  onCopyClick?: (row: TrendItem) => void;
}

const EditTrendsTableData = ({
  loading,
  data,
  onLoadMore,
  onRowClick,
  onCopyClick,
}: Props) => {
  return (
    <Box mb={1}>
      <Table size="small">
        <TableBody>
          {data?.map((row) => (
            <StyledComponent.TableBodyRow
              key={row?.id}
              data-cy={`trend-list-${row.id}`}
            >
              <StyledComponent.TableCellWithoutBorder>
                <StyledComponent.TitleLink
                  typography={{
                    variant: 'subtitle2',
                  }}
                  onClick={() => onRowClick?.(row)}
                  underline="hover"
                  data-cy={`title-${row.title || ''}`}
                >
                  {row.title}
                </StyledComponent.TitleLink>
              </StyledComponent.TableCellWithoutBorder>
              <StyledComponent.TableCellWithoutBorder>
                {dayjs(row.createdAt).format('MMM DD, YYYY')}
              </StyledComponent.TableCellWithoutBorder>
              <StyledComponent.TableCellWithoutBorder
                data-cy={row.published ? 'published' : 'saved'}
              >
                {row.published ? 'Published' : 'Saved'}
              </StyledComponent.TableCellWithoutBorder>
              <StyledComponent.TableCellWithoutBorder>
                <StyledComponent.CursorCopyIcon
                  onClick={() => onCopyClick?.(row)}
                />
              </StyledComponent.TableCellWithoutBorder>
            </StyledComponent.TableBodyRow>
          ))}
        </TableBody>
        {loading && <TableBodyLoader totalCols={4} rowCount={20} />}
      </Table>
      {!loading && !!data?.length && <Waypoint onEnter={onLoadMore} />}
      {!data?.length && (
        <StyledComponent.NoResultContainer elevation={0}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            <StyledComponent.NoResultIcon src={NoResultIconUrl} />
            <Typography variant="h5">No results.</Typography>
          </Box>
        </StyledComponent.NoResultContainer>
      )}
    </Box>
  );
};

export default memo(EditTrendsTableData);
