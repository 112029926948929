import React from 'react';
import { Dialog } from '@mui/material';
import SupportForm from '@root/support/Support.form.component';
import SupportSuccess from '@root/support/Support.success.component';
import useSupportHook from '@root/support/Support.hooks';

interface OwnProps {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
}
const Support = ({ onClose, open }: OwnProps) => {
  const {
    submitted,
    onSubmit,
    onChangeData,
    isSubmitting,
    supportDetails,
    handleRemoveFile,
    handleFile,
    optionalFiles,
    disabledSubmitBtn,
  } = useSupportHook();

  return (
    <Dialog open={open} onClose={() => onClose(() => false)} scroll="body">
      {submitted ? (
        <SupportSuccess onClose={onClose} />
      ) : (
        <SupportForm
          onClose={onClose}
          handleFile={handleFile}
          optionalFiles={optionalFiles}
          handleRemoveFile={handleRemoveFile}
          isSubmitting={isSubmitting}
          isDisabled={disabledSubmitBtn}
          onSubmit={onSubmit}
          supportDetails={supportDetails}
          onChangeData={onChangeData}
        />
      )}
    </Dialog>
  );
};

export default Support;
