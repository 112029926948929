import styled from 'styled-components';
import { ListItemIcon, Avatar, Typography, Box, Menu } from '@mui/material';
import MDEditor from '@uiw/react-md-editor';

export const ReportedItemIcon = styled(ListItemIcon)({
  minWidth: 'auto',
  marginRight: 12,
});

export const RoundedCornerAvatar = styled(Avatar)({
  borderRadius: 4,
});

export const DataTypography = styled(Typography)({
  wordBreak: 'break-all',
});

export const IncidentText = styled.div({
  '.bold': {
    fontWeight: 'bold',
  },
  '.transform': {
    textTransform: 'capitalize',
  },
  span: {
    p: {
      margin: 0,
    },
    '.wmde-markdown p': {
      margin: 0,
    },
  },
});

export const IncidentHistory = styled.div({
  borderRadius: 4,
  backgroundColor: '#fff',
  borderColor: '#ccc',
  borderWidth: '1',
  borderStyle: 'solid',
  marginBottom: 10,
  padding: 10,
  display: 'flex',
  position: 'relative',
  '&div': {
    backgroundColor: '#fff',
    marginRight: 10,
  },
  '@media print': {
    'page-break-inside': 'avoid',
    position: 'relative',
    marginTop: 20,
    marginBottom: 20,
    top: 15,
  },
});

export const Line = styled.div({
  width: 10,
  height: 2,
  background: '#9D9D9D',
  transform: 'rotate(90deg)',
  position: 'absolute',
  left: 12,
  bottom: -7.5,
});

export const IncidentWrapper = styled(Box)({
  display: 'flex',
  flexWrap: 'nowrap',
  gap: 4,
  backgroundColor: '#fff',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  marginBottom: 8,
});

export const MenuWrapper = styled(Box)({
  position: 'absolute',
  top: 0,
  right: 6,
  '&:hover': {
    cursor: 'pointer',
  },
});

export const MenuStyled = styled(Menu)({
  position: 'absolute',
  bottom: 0,
  ul: {
    minWidth: 100,
  },
  '.MuiPopover-paper': {
    marginTop: 0,
  },
});

export const MarkdownWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 4,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  border: 'none',
  width: '100%',
  // height: 500,
  minHeight: 'calc(100% - 62px) !important',
  position: 'relative',
  background: '#F1F3F6',
  '.w-md-editor': {
    width: '100%',
    border: 'none',
    boxShadow: 'none',
    minHeight: 'calc(100% - 62px) !important',
    background: '#F1F3F6',
  },
  '.w-md-editor-aree .w-md-editor-input': {
    minHeight: 'calc(100% - 62px) !important',
  },
  '.w-md-editor-text': {
    minHeight: 'calc(100% - 62px) !important',
    paddingLeft: 18,
  },
  '.w-md-editor-preview': {
    position: 'relative',
  },
  '.w-md-editor-bar': {
    display: 'none',
  },
  '.w-md-editor-toolbar': {
    borderBottom: '1px solid #C9C9C9',
    background: '#F1F3F6',
    padding: 0,
    'ul:first-child': {
      margin: 0,
      width: 500,
      marginBlockStart: 0,
      marginBlockEnd: 0,
      li: {
        width: '100%',
        padding: 4,
        borderRight: '1px solid #C9C9C9',
      },
      '.MuiOutlinedInput-root:hover': {
        border: 'none',
      },
      '.MuiOutlinedInput-root': {
        border: 'none',
      },
      '.MuiOutlinedInput-root:focus': {
        border: 'none',
      },
      '.MuiOutlinedInput-root:focus-within': {
        border: 'none',
      },
    },
    'ul:last-child': {
      marginRight: 10,
    },
    li: {
      '&>button:hover': {
        backgroundColor: 'transparent',
        color: 'transparent',
        boxShadow: '1px 1px 0px #bfbdbd',
        cursor: 'pointer',
      },
      '&>button:hover:disabled': {
        boxShadow: 'none',
        cursor: 'not-allowed',
      },
      '&>button:disabled': {
        opacity: 0.5,
      },
      '&>button': {
        height: 'auto',
        transition: 'box-shadow 0.2s ease-in-out',
      },
    },
  },
});

export const OptionWrapper = styled(Box)({
  border: 'none',
  padding: 5,
  minWidth: 25,
  height: 20,
  borderRadius: 2,
  margin: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'capitalize',
  transition: 'box-shadow 0.2s ease-in-out',
  img: {
    height: 12,
  },
  '&:active': {
    boxShadow: '1px 1px 0px #bfbdbd inset',
  },
});

export const ButtonText = styled(Box)({
  transition: 'font-weight 0.2s ease-in-out',
  paddingRight: 8,
  '&:hover': {
    cursor: 'pointer',
    '.MuiTypography-subtitle2': {
      fontWeight: 'bold',
    },
  },
});

export const CommentText = styled(Typography)({
  marginLeft: 4,
});

export const EditorFooter = styled(Box)({
  border: 'none',
  '> div': {
    padding: 7,
    marginLeft: 7,
    marginRight: 7,
  },
  borderBottomRightRadius: 4,
  borderBottomLeftRadius: 4,
  background: '#F1F3F6',
  height: 60,
});

export const Markdown = styled(MDEditor.Markdown)({
  '&.wmde-markdown': {
    fontFamily: 'Circular Pro Book,Noto Sans',
  },
});

export const AbsoluteTextField = styled(Box)({
  width: 499,
  position: 'absolute',
  left: 0,
  top: 2,
  zIndex: 1,
  padding: 4,
  '.MuiOutlinedInput-root:hover': {
    border: 'none',
  },
  '.MuiOutlinedInput-root': {
    border: 'none',
  },
  '.MuiOutlinedInput-root:focus': {
    border: 'none',
  },
  '.MuiOutlinedInput-root:focus-within': {
    border: 'none',
  },
});
