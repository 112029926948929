import { AccordianLoader, Case } from '@fyeo-di-frontend/shared';
import { Add, Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useAppContext } from '@root/App.context';
import React, { useState } from 'react';
import { isStaff } from '../common/Utils.helpers';
import { useCasesContext } from './Cases.context';
import RenderList from './Cases.renderList.component';
import * as StyledComponents from './Cases.styles';
import { CasesDetails } from './details';
import NoGroup from '@root/cases/Cases.noGroup.component';

const Cases = () => {
  const { selectedCases, idToken, allCases: cases } = useAppContext();
  const {
    groups,
    onChangeSelectedCases,
    isLoading,
    onGroupCheck,
    openNoGroup,
  } = useCasesContext();
  const [editingCase, setEditingCase] = useState<Case>();

  return (
    <>
      <StyledComponents.DrawerStyled anchor="left" open variant="persistent">
        <Box ml={1.5} mb={1} display="flex">
          <Typography variant="h6">Cases</Typography>
          {isStaff(idToken) && (
            <Box mt={-1} ml={-1}>
              <IconButton
                size="large"
                onClick={() => {
                  setEditingCase(() => ({
                    id: '',
                    group: '',
                    name: '',
                    isActive: true,
                    assetCount: {
                      person: 0,
                      domain: 0,
                      organization: 0,
                      location: 0,
                      agent: 0,
                    },
                    assetMaxCount: {
                      person: 0,
                      domain: 0,
                      organization: 0,
                      location: 0,
                      agent: 0,
                    },
                  }));
                }}
                color="primary"
              >
                <Add fontSize="medium" />
              </IconButton>
            </Box>
          )}
        </Box>
        {!isLoading && (
          <RenderList
            groups={groups}
            cases={cases}
            selectedCases={selectedCases}
            onChangeSelectedCases={onChangeSelectedCases}
            onEditCase={setEditingCase}
            onGroupCheck={onGroupCheck}
          />
        )}
        {isLoading && (
          <Box m={1}>
            <AccordianLoader />
          </Box>
        )}
      </StyledComponents.DrawerStyled>
      {editingCase && (
        <Dialog
          open={!!editingCase}
          onClose={() => setEditingCase(() => undefined)}
          scroll="body"
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>
            <Box position="absolute" right={8} top={8} color="black">
              <IconButton
                onClick={() => setEditingCase(() => undefined)}
                color="inherit"
                data-cy="close-modal"
              >
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <CasesDetails
              selectedCaseId={editingCase?.id}
              cases={cases}
              onClose={() => setEditingCase(() => undefined)}
            />
          </DialogContent>
        </Dialog>
      )}
      {openNoGroup && (
        <Dialog open={openNoGroup} scroll="body">
          <NoGroup />
        </Dialog>
      )}
    </>
  );
};

export default Cases;
