import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const High: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 59 23" {...props}>
    <path
      d="M0 2C0 0.89543 0.895431 0 2 0H57C58.1046 0 59 0.895431 59 2V21C59 22.1046 58.1046 23 57 23H2C0.89543 23 0 22.1046 0 21V2Z"
      fill="#F9E9C9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57 1H2C1.44771 1 1 1.44772 1 2V21C1 21.5523 1.44772 22 2 22H57C57.5523 22 58 21.5523 58 21V2C58 1.44771 57.5523 1 57 1ZM2 0C0.895431 0 0 0.89543 0 2V21C0 22.1046 0.89543 23 2 23H57C58.1046 23 59 22.1046 59 21V2C59 0.895431 58.1046 0 57 0H2Z"
      fill="#EE9D00"
    />
    <path
      d="M24.2689 16.0004V7.49238H22.8169V11.0084H18.8209V7.49238H17.3809V16.0004H18.8209V12.3644H22.8169V16.0004H24.2689Z"
      fill="#EE9D00"
    />
    <path
      d="M27.6425 16.0004V10.1564H26.2625V16.0004H27.6425ZM26.0465 8.10438C26.0465 8.60838 26.4545 9.00438 26.9465 9.00438C27.4505 9.00438 27.8585 8.60838 27.8585 8.10438C27.8585 7.60038 27.4505 7.19238 26.9465 7.19238C26.4545 7.19238 26.0465 7.60038 26.0465 8.10438Z"
      fill="#EE9D00"
    />
    <path
      d="M29.0903 16.3244C29.2463 17.5004 30.3263 18.4604 31.8743 18.4604C34.0703 18.4604 34.8743 17.0084 34.8743 15.4484V10.1564H33.5303V10.9004C33.2783 10.4204 32.7143 10.0484 31.8023 10.0484C30.1943 10.0484 29.1023 11.3324 29.1023 12.8924C29.1023 14.5364 30.2423 15.7364 31.8023 15.7364C32.6543 15.7364 33.2423 15.3404 33.4943 14.8844V15.4964C33.4943 16.6844 32.9423 17.2484 31.8383 17.2484C31.0343 17.2484 30.4703 16.7084 30.3743 15.9884L29.0903 16.3244ZM32.0303 14.5484C31.1183 14.5484 30.5063 13.9124 30.5063 12.8924C30.5063 11.8964 31.1423 11.2484 32.0303 11.2484C32.8943 11.2484 33.5303 11.8964 33.5303 12.8924C33.5303 13.9004 32.9183 14.5484 32.0303 14.5484Z"
      fill="#EE9D00"
    />
    <path
      d="M38.0407 12.5684C38.0647 11.8244 38.4847 11.2484 39.2527 11.2484C40.1287 11.2484 40.4527 11.8244 40.4527 12.5444V16.0004H41.8447V12.3044C41.8447 11.0204 41.1487 9.98838 39.7087 9.98838C39.0967 9.98838 38.4367 10.2044 38.0407 10.7204V7.31238H36.6487V16.0004H38.0407V12.5684Z"
      fill="#EE9D00"
    />
  </SvgIcon>
);

export default High;
