import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const Guarded: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 59 23" {...props}>
    <path
      d="M0 2C0 0.89543 0.895431 0 2 0H57C58.1046 0 59 0.895431 59 2V21C59 22.1046 58.1046 23 57 23H2C0.89543 23 0 22.1046 0 21V2Z"
      fill="#D7DDF8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57 1H2C1.44771 1 1 1.44772 1 2V21C1 21.5523 1.44772 22 2 22H57C57.5523 22 58 21.5523 58 21V2C58 1.44771 57.5523 1 57 1ZM2 0C0.895431 0 0 0.89543 0 2V21C0 22.1046 0.89543 23 2 23H57C58.1046 23 59 22.1046 59 21V2C59 0.895431 58.1046 0 57 0H2Z"
      fill="#556DEC"
    />
    <path
      d="M13.7994 15.9995V11.6915H9.63535V12.9515H12.4434C12.3714 13.6835 11.7234 14.8595 10.0074 14.8595C8.54335 14.8595 7.19935 13.8155 7.19935 11.7395C7.19935 9.62752 8.63935 8.64352 10.0194 8.64352C11.3754 8.64352 12.2154 9.45952 12.5034 10.4195L13.8594 9.90352C13.4034 8.49952 12.0834 7.31152 10.0194 7.31152C7.81135 7.31152 5.73535 8.94352 5.73535 11.7395C5.73535 14.5475 7.70335 16.1795 9.92335 16.1795C11.2794 16.1795 12.1554 15.5435 12.5274 14.9195L12.6354 15.9995H13.7994Z"
      fill="#546DEC"
    />
    <path
      d="M19.2652 15.3515C19.2652 15.5915 19.2892 15.8675 19.3132 15.9995H20.6452C20.6212 15.7835 20.5852 15.4115 20.5852 14.9315V10.1555H19.1932V13.6115C19.1932 14.3795 18.7732 14.9195 17.9692 14.9195C17.1292 14.9195 16.7932 14.3075 16.7932 13.5875V10.1555H15.4012V13.8395C15.4012 15.1355 16.2172 16.1675 17.6092 16.1675C18.2932 16.1675 18.9772 15.8795 19.2652 15.3515Z"
      fill="#546DEC"
    />
    <path
      d="M22.0989 14.4155C22.0989 15.3395 22.8549 16.1795 24.0789 16.1795C25.0269 16.1795 25.6029 15.6995 25.8789 15.2555C25.8789 15.7235 25.9269 15.9635 25.9389 15.9995H27.2349C27.2229 15.9395 27.1629 15.5915 27.1629 15.0635V12.1595C27.1629 10.9955 26.4789 9.97552 24.6789 9.97552C23.2389 9.97552 22.3269 10.8755 22.2189 11.8835L23.4909 12.1715C23.5509 11.5835 23.9589 11.1155 24.6909 11.1155C25.4589 11.1155 25.7949 11.5115 25.7949 12.0035C25.7949 12.2075 25.6989 12.3755 25.3629 12.4235L23.8629 12.6515C22.8669 12.7955 22.0989 13.3715 22.0989 14.4155ZM24.3429 15.0875C23.7909 15.0875 23.4909 14.7275 23.4909 14.3315C23.4909 13.8515 23.8389 13.6115 24.2709 13.5395L25.7949 13.3115V13.5755C25.7949 14.7155 25.1229 15.0875 24.3429 15.0875Z"
      fill="#546DEC"
    />
    <path
      d="M32.3377 10.1195C32.2777 10.1075 32.1337 10.0835 31.9657 10.0835C31.1977 10.0835 30.5497 10.4555 30.2737 11.0915V10.1555H28.9177V15.9995H30.3097V13.2155C30.3097 12.1235 30.8017 11.4995 31.8817 11.4995C32.0257 11.4995 32.1817 11.5115 32.3377 11.5355V10.1195Z"
      fill="#546DEC"
    />
    <path
      d="M34.4491 13.0595C34.4491 11.9435 35.1211 11.2355 36.0331 11.2355C36.9451 11.2355 37.5691 11.9315 37.5691 13.0475C37.5691 14.1635 36.9331 14.9195 36.0211 14.9195C35.0731 14.9195 34.4491 14.1755 34.4491 13.0595ZM38.9131 7.31152H37.5571V10.8395C37.3771 10.4675 36.8611 9.99952 35.8411 9.99952C34.1971 9.99952 33.0571 11.3795 33.0571 13.0595C33.0571 14.8235 34.1851 16.1435 35.8651 16.1435C36.6931 16.1435 37.3051 15.7355 37.5811 15.2435C37.5811 15.4835 37.6171 15.8675 37.6411 15.9995H38.9731C38.9611 15.9035 38.9131 15.4595 38.9131 14.9315V7.31152Z"
      fill="#546DEC"
    />
    <path
      d="M41.7759 12.4355C41.8239 11.8115 42.3278 11.1395 43.2038 11.1395C44.1638 11.1395 44.6199 11.7515 44.6439 12.4355H41.7759ZM44.7999 13.9595C44.6079 14.5355 44.1758 14.9795 43.3358 14.9795C42.4718 14.9795 41.7758 14.3435 41.7398 13.4555H46.0119C46.0239 13.4315 46.0479 13.2395 46.0479 13.0115C46.0479 11.1635 44.9919 9.97552 43.1919 9.97552C41.7159 9.97552 40.3479 11.1995 40.3479 13.0475C40.3479 15.0275 41.7518 16.1795 43.3358 16.1795C44.7398 16.1795 45.6639 15.3515 45.9759 14.3315L44.7999 13.9595Z"
      fill="#546DEC"
    />
    <path
      d="M48.3861 13.0595C48.3861 11.9435 49.0581 11.2355 49.9701 11.2355C50.8821 11.2355 51.5061 11.9315 51.5061 13.0475C51.5061 14.1635 50.8701 14.9195 49.9581 14.9195C49.0101 14.9195 48.3861 14.1755 48.3861 13.0595ZM52.8501 7.31152H51.4941V10.8395C51.3141 10.4675 50.7981 9.99952 49.7781 9.99952C48.1341 9.99952 46.9941 11.3795 46.9941 13.0595C46.9941 14.8235 48.1221 16.1435 49.8021 16.1435C50.6301 16.1435 51.2421 15.7355 51.5181 15.2435C51.5181 15.4835 51.5541 15.8675 51.5781 15.9995H52.9101C52.8981 15.9035 52.8501 15.4595 52.8501 14.9315V7.31152Z"
      fill="#546DEC"
    />
  </SvgIcon>
);

export default Guarded;
