export enum ReportsTypes {
  None = '',
  Domain = 'domain',
  Periodic = 'periodic',
}

export interface Response {
  name: {
    common: string;
  };
}

export interface Domain {
  name: string;
  id: string;
}
