import * as StyledComponents from './Header.styles';
import { Box, Link, MenuItem, Stack } from '@mui/material';
import LogoUrl from '@root/header/Logo.svg';
import {
  AssetsRoutes,
  DashboardRoutes,
  EventsRoutes,
  IncidentsRoutes,
  ReportsRoutes,
  SettingsRoutes,
} from '@root/common/Routes';
import { Support } from '@root/support';
import React from 'react';

interface OwnProps {
  isErrorPage?: boolean;
  isUserAuthenticated?: boolean;
  open?: boolean;
  pathname?: string;
  name?: string;
  anchorEl?: HTMLElement | null;
  handleClick?: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose?: () => void;
  setOpenSupport?: React.Dispatch<React.SetStateAction<boolean>>;
  openSupport?: boolean;
  push?: (location: string) => void;
  handleLogout?: () => void;
}
const HeaderComponent = ({
  isErrorPage,
  isUserAuthenticated,
  pathname,
  open,
  name,
  anchorEl,
  handleClick,
  handleClose,
  setOpenSupport,
  openSupport,
  push,
  handleLogout,
}: OwnProps) => {
  return (
    <StyledComponents.AppBarStyled position="sticky" elevation={0}>
      <StyledComponents.ToolbarStyled>
        <Box display="flex" gap={6} flexGrow={1}>
          <img src={LogoUrl} alt="Logo" />
          {!isErrorPage && isUserAuthenticated && (
            <Box display="flex" gap={5} pl={2.5}>
              <Box>
                <StyledComponents.LinkStyled
                  to={DashboardRoutes.Root}
                  selected={pathname?.includes(DashboardRoutes.Root)}
                >
                  Dashboard
                </StyledComponents.LinkStyled>
              </Box>
              <Box>
                <StyledComponents.LinkStyled
                  to={IncidentsRoutes.Root}
                  selected={pathname?.includes(IncidentsRoutes.Root)}
                >
                  Incidents
                </StyledComponents.LinkStyled>
              </Box>
              <Box>
                <StyledComponents.LinkStyled
                  to={AssetsRoutes.Root}
                  selected={pathname?.includes(AssetsRoutes.Root)}
                >
                  Assets
                </StyledComponents.LinkStyled>
              </Box>
              <Box>
                <StyledComponents.LinkStyled
                  to={EventsRoutes.Root}
                  selected={pathname?.includes(EventsRoutes.Root)}
                >
                  Events
                </StyledComponents.LinkStyled>
              </Box>
              <Box>
                <StyledComponents.LinkStyled
                  to={ReportsRoutes.Root}
                  selected={pathname?.includes(ReportsRoutes.Root)}
                >
                  Reports
                </StyledComponents.LinkStyled>
              </Box>
            </Box>
          )}
        </Box>
        {!isErrorPage && isUserAuthenticated && (
          <>
            <Stack direction="row" spacing={1}>
              {/*<StyledComponents.AvatarIconStyled data-cy="notification-avatar">*/}
              {/*  <NotificationIcon fontSize="large" />*/}
              {/*</StyledComponents.AvatarIconStyled>*/}
              <StyledComponents.AvatarStyled
                id="menu-button"
                aria-controls={open ? 'menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                data-cy="user-avatar"
              >
                {name ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : ''}
              </StyledComponents.AvatarStyled>
            </Stack>
            <StyledComponents.MenuStyled
              id="menu"
              anchorEl={anchorEl}
              open={!!open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'menu-button',
              }}
            >
              <MenuItem
                onClick={() => {
                  push?.(SettingsRoutes.Root);
                  handleClose?.();
                }}
              >
                User settings
              </MenuItem>
              <Link
                href="https://gofyeo.zendesk.com/hc/en-us/categories/7018566436381-FYEO-Domain-Intelligence"
                underline="none"
                color="black"
                target="_blank"
                referrerPolicy="no-referrer"
              >
                <MenuItem onClick={handleClose}>FAQ</MenuItem>
              </Link>
              <MenuItem
                onClick={() => {
                  handleClose?.();
                  setOpenSupport?.(true);
                }}
              >
                Support
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleLogout?.();
                  handleClose?.();
                }}
              >
                Sign out
              </MenuItem>
            </StyledComponents.MenuStyled>
          </>
        )}
        {openSupport && setOpenSupport && (
          <Support onClose={setOpenSupport} open={openSupport} />
        )}
      </StyledComponents.ToolbarStyled>
    </StyledComponents.AppBarStyled>
  );
};

export default HeaderComponent;
