import { Box, Button, Typography } from '@mui/material';
import { DateRangeFilter as DateRangeFilterComponent } from '@root/date-range-filter';
import dayjs from 'dayjs';
import React from 'react';
import { useIncidentsContext } from './Incidents.context';
import useIncidentsFiltersHook from './Incidents.filters.hooks';
import IncidentsTextAndTypeFilterComponent from './Incidents.filterTextAndType.component';
import ReportedFilterComponent from './Incidents.reportedFilter.component';
import SeverityFilterComponent from './Incidents.severityFilter.component';
import StatusFilterComponent from './Incidents.statusFilter.component';
import useIncidentsType from './Incidents.type.hooks';
import MultiSelectComponent from './Incidents.multiSelect.component';
import useIncidentsAssets from '@root/incidents/Incidents.assets.hooks';
import { enumToDescriptedArray } from '@root/incidents/Incidents.helpers';
import { AssetType } from '@fyeo-di-frontend/shared';
import IncidentMultiSelectLazyListComponent from './Incidents.multiSelectLazyList.component';

const FiltersContainer = () => {
  const {
    isLoading: isIncidentsTypeLoading,
    incidentsTypes: incidentTypeOptions,
  } = useIncidentsType();
  const {
    onChangeDateRange,
    onChangeIncidentSearchText,
    onChangeIncidentTypes,
    onChangeSeverity,
    onChangeStatuses,
    onChangeReported,
    onChangeAssets,
    onChangeAssetTypes,
  } = useIncidentsFiltersHook();
  const { clear } = useIncidentsContext();

  const {
    dateRange,
    incidentSearchText,
    incidentsTypes,
    severity,
    statuses,
    reported,
    assetInput,
    setAssetInput,
    assets,
    assetTypes,
  } = useIncidentsContext();
  const {
    isLoading: isAssetLoading,
    assets: assetsOptions,
    loadMore,
  } = useIncidentsAssets(assetInput);

  return (
    <Box p={4} bgcolor="white">
      <Typography variant="h6" mb={1.5}>
        Filters
      </Typography>
      <Box display="flex" gap={2} flexDirection="column">
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          rowGap={2}
        >
          <Box width="32%">
            <DateRangeFilterComponent
              value={
                dateRange?.from
                  ? [dayjs(dateRange?.from), dayjs(dateRange?.to)]
                  : undefined
              }
              onChangeDateRange={onChangeDateRange}
              label="Date"
            />
          </Box>
          <Box width="32%">
            <IncidentsTextAndTypeFilterComponent
              onChangeSearchText={onChangeIncidentSearchText}
              searchText={incidentSearchText}
            />
          </Box>
          <Box width="32%">
            <MultiSelectComponent
              loading={isIncidentsTypeLoading}
              options={incidentTypeOptions}
              onChange={onChangeIncidentTypes}
              value={incidentsTypes}
              label="Incident Types"
            />
          </Box>
          <Box width="32%">
            <SeverityFilterComponent
              onChangeSeverity={onChangeSeverity}
              value={severity}
            />
          </Box>
          <Box width="32%">
            <ReportedFilterComponent
              onChangeReported={onChangeReported}
              value={reported}
            />
          </Box>
          <Box width="32%">
            <StatusFilterComponent
              onChangeStatus={onChangeStatuses}
              value={statuses}
            />
          </Box>
          <Box width="32%">
            <IncidentMultiSelectLazyListComponent
              loading={isAssetLoading}
              options={assetsOptions}
              onChange={onChangeAssets}
              value={assets}
              label="Asset"
              setInputValue={setAssetInput}
              disableDefaultFiltering
              loadMoreResults={loadMore}
            />
          </Box>
          <Box width="32%">
            <MultiSelectComponent
              options={enumToDescriptedArray(AssetType)}
              onChange={onChangeAssetTypes}
              value={assetTypes}
              label="Asset Types"
            />
          </Box>
          <Box
            width="32%"
            alignSelf="flex-end"
            display="flex"
            justifyContent="flex-end"
          >
            <Box width={90}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                onClick={clear}
              >
                Clear all
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FiltersContainer;
