import { Box, Grid } from '@mui/material';
import { HorizontalFullHeightLoader } from '@fyeo-di-frontend/shared';
import React, { memo } from 'react';

const IncidentsDetailsLoader = () => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box height={40}>
        <HorizontalFullHeightLoader />
      </Box>
      <Box height={150}>
        <HorizontalFullHeightLoader />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box height={40}>
            <HorizontalFullHeightLoader />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box height={40}>
            <HorizontalFullHeightLoader />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box height={40}>
            <HorizontalFullHeightLoader />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box height={40}>
            <HorizontalFullHeightLoader />
          </Box>
        </Grid>
      </Grid>
      <Box height={150}>
        <HorizontalFullHeightLoader />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" my={5}>
        <Box height={40} width={200}>
          <HorizontalFullHeightLoader />
        </Box>
      </Box>
      <Box height={150}>
        <HorizontalFullHeightLoader />
      </Box>
    </Box>
  );
};

export default memo(IncidentsDetailsLoader);
