import React from 'react';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { memo } from 'react';
import { ReportsTypes } from './Reports.types';

interface OwnProps {
  reportType?: ReportsTypes;
  onChangeReportType?: (value: ReportsTypes) => void;
}

const ReportTypeComponent = (props: OwnProps) => {
  return (
    <Box display="flex" flexDirection="column" gap={0.5} width={150}>
      <Typography variant="subtitle2">Report type</Typography>
      <Select<ReportsTypes>
        onChange={(evt) =>
          props?.onChangeReportType?.(evt.target.value as ReportsTypes)
        }
        size="small"
        fullWidth
        value={props?.reportType || ReportsTypes.None}
        defaultValue={ReportsTypes.None}
      >
        <MenuItem value={ReportsTypes.None} dense>
          {ReportsTypes.None}
        </MenuItem>
        <MenuItem value={ReportsTypes.Domain} dense>
          Domain report
        </MenuItem>
        <MenuItem value={ReportsTypes.Periodic} dense>
          Periodic report
        </MenuItem>
      </Select>
    </Box>
  );
};

export default memo(ReportTypeComponent);
