import { useAppContext } from '@root/App.context';
import dayjs from 'dayjs';
import { useMemo } from 'react';

const useDashboardQuery = () => {
  const { selectedCases } = useAppContext();

  const from = useMemo(() => {
    return dayjs().subtract(1, 'month');
  }, []);

  const to = useMemo(() => {
    return dayjs();
  }, []);

  const caseIds = useMemo(
    () => selectedCases.map((c) => c.id) || [],
    [selectedCases],
  );

  return {
    from,
    to,
    caseIds,
    pause: selectedCases.length <= 0,
  };
};

export default useDashboardQuery;
