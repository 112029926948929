import {
  generatePrimaryColor,
  generateSecondaryColor,
  generateSeverityFromString,
  Severity,
  SeverityWithoutNone,
} from '@fyeo-di-frontend/shared';
import { LegendItem } from 'chart.js';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { formatDataFromLabel, generateLabels } from './Dashboard.chart.helpers';
import LegendComponent from './Dashboard.legend.component';
import { IChartData, ILayoutItem } from './Dashboard.types';

interface OwnProps {
  data?: Record<SeverityWithoutNone, IChartData[]>;
}

const ChartComponent = (props: OwnProps) => {
  const labels = useMemo(
    () => generateLabels('MMM,DD', props?.data),
    [props?.data],
  );

  const newChartData = useMemo(
    () => formatDataFromLabel(props?.data),
    [props?.data],
  );

  const generateSeriesData = (severity: SeverityWithoutNone) => ({
    fill: true,
    label: startCase(severity),
    data: [
      ...[...(newChartData?.[severity] || [])].map?.((m) => m.count),
      Math.max(...[...(newChartData?.[severity] || [])].map?.((m) => m.count)) +
        1,
    ],
    lineTension: 0.3,
    borderWidth: 2,
    backgroundColor: generateSecondaryColor(
      generateSeverityFromString(severity),
    ),
    borderColor: generatePrimaryColor(generateSeverityFromString(severity)),
  });

  const [legendItems, setLegendItems] = useState<LegendItem[]>([]);
  const lineRef = useRef<ChartJSOrUndefined<'line', number[], string>>(null);
  useEffect(() => {
    const line = lineRef.current;
    if (line) {
      const legend: ILayoutItem = line.boxes[0];
      const legendItms = legend.legendItems;
      legendItms && setLegendItems(legendItms);
    }
  }, [lineRef]);

  return (
    <>
      <LegendComponent legendItems={legendItems} />
      <Line
        data={{
          labels: labels,
          datasets: [
            generateSeriesData(SeverityWithoutNone.Safe),
            generateSeriesData(SeverityWithoutNone.Low),
            generateSeriesData(SeverityWithoutNone.Guarded),
            generateSeriesData(SeverityWithoutNone.Elevated),
            generateSeriesData(SeverityWithoutNone.High),
            generateSeriesData(SeverityWithoutNone.Severe),
          ],
        }}
        ref={lineRef}
        options={{
          responsive: true,
          scales: {
            y: {
              stacked: true,
            },
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              backgroundColor: '#fff',
              bodyColor: '#323232',
              borderColor: 'rgba(1, 1, 1, 0.1)',
              borderWidth: 1,
              intersect: false,
              displayColors: false,
              cornerRadius: 8,
              padding: 10,
              callbacks: {
                title: () => '',
                label: (context) => {
                  const severity =
                    SeverityWithoutNone[
                      context.dataset.label as keyof typeof SeverityWithoutNone
                    ];
                  const barData = newChartData?.[severity]?.[context.dataIndex];
                  return [
                    dayjs(barData?.date).format('MMM DD, YYYY'),
                    `${barData?.count} ${
                      Severity[generateSeverityFromString(severity)]
                    } severity incidents`,
                  ];
                },
              },
            },
            zoom: {
              zoom: {
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true,
                },
                mode: 'x',
              },
              pan: {
                enabled: true,
                mode: 'x',
              },
            },
          },
        }}
      />
    </>
  );
};

export default memo(ChartComponent);
