import { MobileIcon } from '@fyeo-di-frontend/shared';
import { Close } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { validatePhone } from './AssetTypes.helpers';

import startCase from 'lodash/startCase';
import React, { memo } from 'react';
import { Contact, ContactType } from '../Assets.types';
import * as StyledComponent from './AssetTypes.styles';

interface OwnProps {
  isThreatActor?: boolean;
  data?: Contact[];
  onChange?: (data: Contact) => void;
  onAdd?: () => void;
  onDelete?: (data: Contact) => void;
  disabled?: boolean;
  readOnly?: boolean;
}

const ContactRender: React.FC<OwnProps> = (props) => {
  const onChangeType =
    (item: Contact) => (evt: SelectChangeEvent<ContactType>) => {
      props.onChange?.({ ...item, tag: evt.target.value as ContactType });
    };

  const onChangeValue =
    (item: Contact) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const formatNumber = event.target.value.replace(/ /gi, '');
      props.onChange?.({ ...item, value: validatePhone(formatNumber) });
    };

  const onDelete = (item: Contact) => () => {
    props.onDelete?.(item);
  };

  const color = props.isThreatActor ? 'secondary' : 'primary';

  return (
    <>
      <Box display="flex" width="100%" mt={1}>
        <Box mt={-1} ml={-1} mb={-1}>
          <StyledComponent.CursorBox
            onClick={!props.readOnly ? props.onAdd : undefined}
            fontSize={40}
          >
            <MobileIcon fontSize="inherit" color={color} />
          </StyledComponent.CursorBox>
        </Box>
      </Box>
      {props?.data?.map?.((data) => (
        <Grid container spacing={1} key={data?._id}>
          <Grid item xs={4}>
            <Select<ContactType>
              onChange={onChangeType(data)}
              size="small"
              fullWidth
              value={data?.tag}
              color={color}
              disabled={props.disabled}
              readOnly={props.readOnly}
            >
              <MenuItem value={ContactType.Home} dense>
                {startCase(ContactType.Home)}
              </MenuItem>
              <MenuItem value={ContactType.Mobile} dense>
                {startCase(ContactType.Mobile)}
              </MenuItem>
              <MenuItem value={ContactType.Office} dense>
                {startCase(ContactType.Office)}
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={8}>
            <TextField
              value={data?.value || ''}
              onChange={onChangeValue(data)}
              placeholder="eg: + 1 415 555 2671"
              color={color}
              InputProps={{
                readOnly: props.readOnly,
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={onDelete?.(data)}>
                      <Close fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default memo(ContactRender);
