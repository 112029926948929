import React from 'react';
import { createTheme } from '@mui/material';
import CircularProBold from './fonts/CircularPro-Bold.otf';
import CircularProRegular from './fonts/CircularPro-Book.otf';
import CircularProMedium from './fonts/CircularPro-Medium.otf';
import NotoSans from './fonts/NotoSans-Regular.otf';
import TickIcon from './icons/Tick.icon';

const theme = createTheme({
  palette: {
    primary: {
      main: '#31AD34',
      contrastText: '#fff',
      light: '#D4E9D9',
    },
    secondary: {
      main: '#FFA800',
      contrastText: '#fff',
      light: '#FFF2D9',
    },
    action: {
      disabledBackground: '#C4C4C4',
    },
  },
  typography: {
    fontFamily: 'Circular Pro Book, Noto Sans',
    fontSize: 12,
    h5: {
      fontFamily: 'Circular Pro Medium, Noto Sans',
      fontSize: 24,
    },
    h6: {
      fontFamily: 'Circular Pro Medium, Noto Sans',
      fontSize: 18,
    },
    subtitle1: {
      fontFamily: 'Circular Pro Medium, Noto Sans',
      fontSize: 16,
    },
    subtitle2: {
      fontFamily: 'Circular Pro Book, Noto Sans',
      fontSize: 14,
      fontWeight: 400,
    },
    body2: {
      fontFamily: 'Circular Pro Book, Noto Sans',
      fontSize: 12,
      fontWeight: 400,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Circular Pro Book';
          src: url(${CircularProRegular}) format('opentype');
        }
        @font-face {
          font-family: 'Circular Pro Bold';
          font-weight: bold;
          src:  url(${CircularProBold}) format('opentype');
        }
        @font-face {
          font-family: 'Circular Pro Medium';
          font-weight: bold;
          src:  url(${CircularProMedium}) format('opentype');
        }
        @font-face {
          font-family: 'Noto Sans';
          src:  url(${NotoSans}) format('truetype');
        }
      `,
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          input: {
            filter: 'none',
            '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus':
              {
                '-webkit-box-shadow': '0 0 0px 1000px #F1F3F6 inset;',
              },
            '&::placeholder': {
              color: '#7F8998',
              fontStyle: 'italic',
            },
          },
        },
      },
      defaultProps: {
        size: 'small',
        hiddenLabel: true,
        fullWidth: true,
        variant: 'outlined',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fieldset: {
            border: 'none',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&&': {
            borderRadius: 4,
          },
          backgroundColor: '#F1F3F6',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: '#F1F3F6',
          '&:hover, &:focus, &:focus-within': {
            borderColor: '#31AD34',
          },
          fontSize: 14,
        },
        colorSecondary: {
          '&:hover, &:focus, &:focus-within': {
            borderColor: '#FFA800',
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          textTransform: 'capitalize',
          fontSize: 16,
          fontFamily: 'Circular Pro Medium',
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: <TickIcon />,
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginRight: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '.MuiButtonBase-root': {
            textTransform: 'capitalize',
            color: '#323232',
            fontSize: 15,
            fontFamily: 'Circular Pro Medium, Noto Sans',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&:not(.MuiPaper-elevation0)': {
            boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
  },
});

export default theme;
