import {
  Box,
  ListItemIcon,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import styled from 'styled-components';

export const Root = styled.div({
  marginLeft: 'auto',
  marginTop: 10,
  marginBottom: 20,
  marginRight: 'auto',
  display: 'flex',
});

export const EntryRoot = styled.div({
  marginLeft: 'auto',
  marginTop: 10,
  marginBottom: 20,
  marginRight: 'auto',
  width: 921,
});

export const Container = styled.div`
  overflow-y: scroll;
  height: 100vh;
`;

export const Header = styled(Box)({
  minHeight: 468,
  position: 'relative',
  paddingTop: 30,
  paddingLeft: 36,
  paddingBottom: 30,
  width: '100%',
  background: 'linear-gradient(89.78deg, #32AE34 0%, #FAC705 100%);',
});

export const Background = styled(Box)({
  position: 'absolute',
  bottom: -10,
  right: 0,
  img: {
    width: 426,
    height: 394,
  },
});

export const ReportDetailsWrapper = styled(Box)({
  display: 'flex',
  paddingLeft: 364,
  paddingRight: 400,
  paddingTop: 80,
  color: 'white',
  flexDirection: 'column',
  '.MuiTypography-subtitle1': {
    lineHeight: 1.3,
  },
});

export const IncidentsWrapper = styled(Box)({
  background: '#F8F9FB',
  borderRadius: 7,
  padding: 16,
  width: '100%',
});

export const ReportedItemIcon = styled(ListItemIcon)({
  minWidth: 'auto',
  marginRight: '12px',
});

export const CursorBox = styled(Box)({
  cursor: 'pointer',
});

export const IncidentTextGroup = styled(Box)({
  '.MuiTypography-subtitle1': {
    lineHeight: 1.3,
  },
});

export const TableHeaderCell = styled(TableCell)({
  top: 60,
  height: 50,
  cursor: 'pointer',
});

export const TableBodyRow = styled(TableRow)({
  backgroundColor: '#fff',
  cursor: 'pointer',
});

export const TableHeaderStyled = styled(TableHead)({
  paddingTop: 60,
});
