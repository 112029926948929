import styled from 'styled-components';
import { Container } from '@mui/material';

export const RootStyled = styled.div({
  backgroundColor: '#31AD34',
  height: 'calc(100vh - 64px)',
});

export const RootTopStyledBg = styled.img({
  width: '100%',
  position: 'absolute',
  top: 62,
});

export const ContainerStyled = styled(Container)({
  background: 'transparent',
  position: 'relative',
  top: 40,
});

export const HighMeterBar = styled.div({
  width: 'calc(20% - 3px)',
  background: '#31AD34',
  borderRadius: 6,
  height: 3,
  opacity: 0.2,
});

export const ActiveHighMeterBar = styled(HighMeterBar)({
  opacity: 1,
});

export const LowMeterBar = styled(HighMeterBar)({
  background: 'red',
});

export const ActiveLowMeterBar = styled(LowMeterBar)({
  opacity: 1,
});

export const MediumMeterBar = styled(HighMeterBar)({
  background: 'orange',
});

export const ActiveMediumMeterBar = styled(MediumMeterBar)({
  opacity: 1,
});

export const StrengthMeter = styled.div({
  display: 'flex',
  gap: 6,
});
