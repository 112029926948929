import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const Low: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 59 23" {...props}>
    <path
      d="M0 2C0 0.89543 0.895431 0 2 0H57C58.1046 0 59 0.895431 59 2V21C59 22.1046 58.1046 23 57 23H2C0.89543 23 0 22.1046 0 21V2Z"
      fill="#DFF2DB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57 1H2C1.44771 1 1 1.44772 1 2V21C1 21.5523 1.44772 22 2 22H57C57.5523 22 58 21.5523 58 21V2C58 1.44771 57.5523 1 57 1ZM2 0C0.895431 0 0 0.89543 0 2V21C0 22.1046 0.89543 23 2 23H57C58.1046 23 59 22.1046 59 21V2C59 0.895431 58.1046 0 57 0H2Z"
      fill="#42BD16"
    />
    <path
      d="M24.1808 16.0002V14.6322H20.2808V7.49219H18.8408V16.0002H24.1808Z"
      fill="#42BD16"
    />
    <path
      d="M27.9936 14.9322C27.1416 14.9322 26.3616 14.2842 26.3616 13.0722C26.3616 11.8602 27.1416 11.2362 27.9936 11.2362C28.8576 11.2362 29.6256 11.8602 29.6256 13.0722C29.6256 14.2962 28.8576 14.9322 27.9936 14.9322ZM27.9936 9.97619C26.2536 9.97619 24.9696 11.2842 24.9696 13.0722C24.9696 14.8722 26.2536 16.1802 27.9936 16.1802C29.7456 16.1802 31.0296 14.8722 31.0296 13.0722C31.0296 11.2842 29.7456 9.97619 27.9936 9.97619Z"
      fill="#42BD16"
    />
    <path
      d="M35.6182 10.1562L34.2382 14.2002L33.0502 10.1562H31.5622L33.4582 16.0002H34.8862L36.3022 11.8002L37.7542 16.0002H39.1462L41.0182 10.1562H39.6022L38.4382 14.2002L37.0582 10.1562H35.6182Z"
      fill="#42BD16"
    />
  </SvgIcon>
);

export default Low;
