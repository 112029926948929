import { Box, FormControlLabel } from '@mui/material';
import styled from 'styled-components';

export const ReportedFormControlLabel = styled(FormControlLabel)({
  marginLeft: 0,
});

export const CursorBox = styled(Box)({
  cursor: 'pointer',
});

export const DivWrapper = styled(Box)({
  backgroundColor: '#F1F3F6',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 4,
  borderColor: '#F1F3F6',
  border: 1,
  width: '100%',
  height: 39.13,
  gap: 10,
});

export const SwitchWrapper = styled(Box)({
  transform: 'rotate(180deg)',
});
