import { AppBar, Avatar, Button, Menu, Toolbar } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

export const AppBarStyled = styled(AppBar)({
  background: 'linear-gradient(89.78deg, #32AE34 0%, #FAC705 100%);',
  position: 'relative',
});

export const LinkStyled = styled(Link)<LinkProps & { selected?: boolean }>(
  (props) => ({
    color: 'white',
    textDecoration: 'none',
    verticalAlign: 'sub',
    fontSize: 15,
    [props.selected ? '&' : ':hover']: {
      borderBottomWidth: 1,
      borderBottomColor: 'white',
      borderBottomStyle: 'solid',
      paddingBottom: 2,
    },
  }),
);

export const SignoutButton = styled(Button)({
  color: 'white',
  backgroundColor: '#4B5B4E',
  ':hover': {
    backgroundColor: '#31AD34',
    opacity: 0.9,
  },
  textTransform: 'initial',
  minWidth: 130,
});

export const ToolbarStyled = styled(Toolbar)({
  paddingLeft: 16,
});

export const AvatarS = styled(Avatar)({
  backgroundColor: 'white',
  ':hover': {
    cursor: 'pointer',
  },
});

export const AvatarStyled = styled(AvatarS)({
  color: '#323232',
  fontSize: 15,
  fontWeight: 500,
});

export const AvatarIconStyled = styled(AvatarS)({
  backgroundColor: 'white',
});

export const MenuStyled = styled(Menu)({
  position: 'absolute',
  bottom: 0,
  ul: {
    minWidth: 175,
  },
  '.MuiPopover-paper': {
    marginTop: 12,
  },
});
