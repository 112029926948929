import { Incident } from '@fyeo-di-frontend/shared';
import { SortDirection } from '@mui/material';
import { useCallback } from 'react';
import { useIncidentsContext } from './Incidents.context';

const useIncidentsSortsHook = () => {
  const { sort, setSort, resetDataAndPage } = useIncidentsContext();

  const onChangeSort = useCallback(
    (key: keyof Incident, sortDirection?: SortDirection) => {
      setSort?.(() => ({
        key,
        sortDirection,
      }));
      if (!(key === 'date' && sortDirection === false)) {
        resetDataAndPage?.();
      }
    },
    [setSort, resetDataAndPage],
  );

  return {
    sort,
    onChangeSort,
  };
};

export default useIncidentsSortsHook;
