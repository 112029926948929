import { Box } from '@mui/material';
import range from 'lodash/range';
import React from 'react';
import HorizontalBarLoader from './Loader.horizontal.component';

interface OwnProps {
  noOfAccordians?: number;
  itemCountInAccordian?: number;
}

const AccordianLoader: React.FC<OwnProps> = ({ noOfAccordians, itemCountInAccordian }) => {
  return (
    <Box margin={0} width="100%">
      {range(noOfAccordians || 2).map((val) => (
        <Box key={val} mb={1} width="100%">
          <HorizontalBarLoader count={1} />
          {range(itemCountInAccordian || 5).map(m => (
            <Box key={m} ml={3} mb={1}>
              <HorizontalBarLoader count={1} />
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default AccordianLoader;
