import {
  HorizontalFullHeightLoader,
  IChartData,
  Detections,
} from '@fyeo-di-frontend/shared';
import { Box } from '@mui/material';
import React, { memo } from 'react';
import ChartComponent from './DomainReport.chart.component';
import * as StyledComponents from './DomainReport.styles';

interface OwnProps {
  data?: Record<Detections, IChartData[]>;
  isLoading: boolean;
}

const DomainChart = (props: OwnProps) => {
  return (
    <StyledComponents.CardPaper elevation={0}>
      {props.isLoading ? (
        <Box height={368}>
          <HorizontalFullHeightLoader />
        </Box>
      ) : (
        <Box p={1}>
          <ChartComponent data={props?.data} />
        </Box>
      )}
    </StyledComponents.CardPaper>
  );
};

export default memo(DomainChart);
