export enum ErrorCodes {
  '_1531' = 'Unable to create duplicate asset',
  '_3415' = 'Domain field is required',
  '_7664' = 'Username cannot be of email format, since user pool is configured for email alias.',
  '_1921' = 'User already exists',
  '_1755' = 'Email already used',
  '_2283' = 'Domain is in ignore list',
  '_1339' = 'Weak password',
  '_4683' = 'You must be authorized to access this domain data',
  '_2480' = 'You must be authenticated',
  '_2809' = 'Location field is required',
  '_1856' = 'Invalid domain name',
  '_2261' = 'name.common is required',
  '_2045' = 'HTML tags are not allowed',
  '_7140' = 'You should be assigned to a group before you are allowed to work with case(s)',
  '_2487' = 'Old password is incorrect',
  '_4458' = 'An account with the given email already exists.',
  '_8249' = 'Firstname field is required.',
  '_8133' = 'Lastname field is required.',
  '_7800' = 'Email field is required.',
  '_7675' = 'Email field is required.',
  '_8550' = 'Email field is required.',
  '_8441' = 'Code field is required.',
  '_8038' = 'Password field is required.',
  '_4760' = 'Invalid code provided, please request a code again.',
  // '_3083' = 'Incorrect username or password',
  LowerBound = 'Matching score inputted value is less than the lower bound of 80',
  HigherBound = 'Matching score inputted value is greater than the upper bound of 100',
  None = 'An error occurred',
}
