import { Case } from '@fyeo-di-frontend/shared';

const ID_TOKEN_KEY = '__fyeo__idToken__';
const ID_HASH = '__fyeo__idHash__';
const REMEMBER_LOGIN = '__fyeo__rememberLogin__';
const REFRESH_TOKEN_KEY = '__fyeo__refreshToken__';
const INCIDENT_FILTER_KEY_PREFIX = '__fyeo__incidentFilter__';
const EVENT_FILTER_KEY_PREFIX = '__fyeo__eventFilter__';
const ASSET_FILTER_KEY_PREFIX = '__fyeo__assetFilter__';
const SELECTED_CASES = '__fyeo__selectedCases__';

export const retrieveIdToken = () => localStorage.getItem(ID_TOKEN_KEY);
export const retrieveIdHash = () => localStorage.getItem(ID_HASH);

export const retrieveRememberLogin = () => localStorage.getItem(REMEMBER_LOGIN);

export const storeIdToken = (token: string) =>
  localStorage.setItem(ID_TOKEN_KEY, token);

export const storeIdHash = (hash: string) =>
  localStorage.setItem(ID_HASH, hash);

export const storeRememberLogin = () =>
  localStorage.setItem(REMEMBER_LOGIN, 'true');

export const deleteIdToken = () => localStorage.removeItem(ID_TOKEN_KEY);

export const deleteRememberLogin = () =>
  localStorage.removeItem(REMEMBER_LOGIN);

export const retrieveRefreshToken = () =>
  localStorage.getItem(REFRESH_TOKEN_KEY);

export const storeRefreshToken = (token: string) =>
  localStorage.setItem(REFRESH_TOKEN_KEY, token);

export const deleteRefreshToken = () =>
  localStorage.removeItem(REFRESH_TOKEN_KEY);

export const storeIncidentFilters = <T>(key: string, json: T) =>
  localStorage.setItem(
    `${INCIDENT_FILTER_KEY_PREFIX}${key}`,
    JSON.stringify(json),
  );

export const storeEventFilters = <T>(key: string, json: T) =>
  localStorage.setItem(
    `${EVENT_FILTER_KEY_PREFIX}${key}`,
    JSON.stringify(json),
  );

export const storeSelectedCases = (json: Case[]) =>
  localStorage.setItem(`${SELECTED_CASES}`, JSON.stringify(json));

export const retrieveIncidentFilters = <T>(key: string) =>
  JSON.parse(
    localStorage.getItem(`${INCIDENT_FILTER_KEY_PREFIX}${key}`) as string,
  ) as T;

export const retrieveEventFilters = <T>(key: string) =>
  JSON.parse(
    localStorage.getItem(`${EVENT_FILTER_KEY_PREFIX}${key}`) as string,
  ) as T;

export const retrieveSelectedCases = () =>
  JSON.parse(localStorage.getItem(`${SELECTED_CASES}`) as string) as Case[];

export const deleteIncidentFilters = (key: string) =>
  localStorage.removeItem(`${INCIDENT_FILTER_KEY_PREFIX}${key}`);

export const storeAssetFilters = <T>(key: string, json: T) =>
  localStorage.setItem(
    `${ASSET_FILTER_KEY_PREFIX}${key}`,
    JSON.stringify(json),
  );

export const retrieveAssetFilters = <T>(key: string) =>
  JSON.parse(
    localStorage.getItem(`${ASSET_FILTER_KEY_PREFIX}${key}`) as string,
  ) as T;
export const deleteAssetFilters = (key: string) =>
  localStorage.removeItem(`${ASSET_FILTER_KEY_PREFIX}${key}`);

export const deleteEventFilters = (key: string) =>
  localStorage.removeItem(`${EVENT_FILTER_KEY_PREFIX}${key}`);
