import { useAppContext } from '@root/App.context';
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { LoginRoutes, ReportsRoutes } from '@root/common/Routes';

const exemptRoutes = [
  `${ReportsRoutes.Root}/${ReportsRoutes.Periodic}`,
  `${ReportsRoutes.Root}/${ReportsRoutes.Domain}`,
];

const PrivateRoute = () => {
  const { isUserAuthenticated } = useAppContext();
  const location = useLocation();

  return isUserAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate
      to={LoginRoutes.Root}
      replace
      state={
        exemptRoutes.some((r) => location.pathname.includes(r))
          ? undefined
          : { from: location }
      }
    />
  );
};

export default PrivateRoute;
