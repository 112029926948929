import React from 'react';
import { Box, Toolbar, Typography } from '@mui/material';
import SavingIcon from '@root/common/Saving.gif';
import * as StyledComponents from '@root/assets/asset-types/AssetTypes.styles';

interface OwnProps {
  id?: string;
  isSaving?: boolean;
}
const ToolbarComponent = ({ id, isSaving }: OwnProps) => {
  return (
    <Toolbar>
      <Box display="flex" gap={42} flexGrow={1} mr={1}>
        <Box />
        {id && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            {isSaving ? (
              <>
                <img src={SavingIcon} alt="saving-icon" height="28" width="56"/>
                <Typography variant="body2" color="#31AD34">
                  Saving
                </Typography>
              </>
            ) : (
              <>
                <StyledComponents.AutoSaveStill fontSize="large" />
                <Typography variant="body2" color="#C9C8BF">
                Autosave
                </Typography>
               </>
            )}
           
          </Box>
        )}
      </Box>
    </Toolbar>
  );
};

export default ToolbarComponent;
