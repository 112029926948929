import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { LoginRoutes } from '@root/common/Routes';
import { Link as RLink } from 'react-router-dom';
import React, { useState } from 'react';
import {
  LoginContextProvider,
  useLoginContext,
  useLoginContextModel,
} from './Login.context';
import useLoginHook from './Login.hooks';
import * as StyledComponents from './Login.styles';
import Root from '../Root.component';
import validator from 'validator';

const Login = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const {
    email,
    password,
    onChangePassword,
    onChangeEmail,
    onChangeKeepMeLoggedIn,
    keepMeLoggedIn,
  } = useLoginContext();
  const { isLoginInProgress, onLogin, isError } = useLoginHook();
  const disableLogin =
    !validator.isEmail(email?.trim?.()) ||
    !password?.trim?.() ||
    isLoginInProgress;
  return (
    <Root>
      <StyledComponents.Root>
        <Box textAlign="center">
          <Typography variant="h6">Log in</Typography>
        </Box>
        <Box display="flex" gap={2} justifyContent="center">
          <Typography variant="subtitle2">New user?</Typography>
          <Link
            component={RLink}
            to={LoginRoutes.SignUp}
            underline="none"
            variant="subtitle2"
          >
            Create an account
          </Link>
        </Box>
        {isError && (
          <Box textAlign="left" ml={15} mr={15}>
            <Typography variant="caption" color="error">
              Wrong password or email
            </Typography>
          </Box>
        )}
        <Box textAlign="center" ml={15} mr={15}>
          <TextField
            placeholder="Work email"
            onChange={(evt) => onChangeEmail(evt?.target?.value)}
          />
        </Box>
        <Box textAlign="center" ml={15} mr={15}>
          <TextField
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={() => setShowPassword((old) => !old)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(evt) => onChangePassword(evt?.target?.value)}
            autoComplete=""
          />
        </Box>
        <Box textAlign="center" ml={15} mr={15}>
          <LoadingButton
            variant="contained"
            fullWidth
            size="medium"
            disabled={disableLogin}
            onClick={onLogin}
            loading={isLoginInProgress}
          >
            Log in
          </LoadingButton>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          ml={15}
          mr={15}
          alignItems="center"
        >
          <FormControlLabel
            control={<Checkbox />}
            label={
              <Typography variant="subtitle2">Keep me logged in</Typography>
            }
            disableTypography
            labelPlacement="end"
            value={keepMeLoggedIn}
            onChange={(_, checked) => onChangeKeepMeLoggedIn(checked)}
          />
          <Box>
            <Link
              component={RLink}
              to={LoginRoutes.ResetPassword}
              underline="none"
              variant="subtitle2"
            >
              Forgot password
            </Link>
          </Box>
        </Box>
      </StyledComponents.Root>
    </Root>
  );
};

const LoginWithContext = () => {
  const contextData = useLoginContextModel();
  return (
    <LoginContextProvider value={contextData}>
      <Login />
    </LoginContextProvider>
  );
};

export default LoginWithContext;
