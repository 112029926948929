import { Box } from '@mui/material';
import { Chart, TooltipItem, TooltipModel } from 'chart.js';
import chroma from 'chroma-js';
import { uniqueId } from 'lodash';
import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { externalTooltip } from './DomainReport.chart.helpers';
import * as StyledComponent from './DomainReport.styles';

const chromaScale = chroma.scale(['#006704', '#FFD700']);
const colorGenerator = (value: number) => chromaScale(value).hex();
const scaleDataForHeatMap = (data: number[], value: number) => {
  const max = Math.max(...data);
  const min = Math.min(...data);
  const scaledValue = (value - min) / (max - min);
  return scaledValue || 0;
};

const plugin = {
  id: `custom_canvas_background_color-${uniqueId()}`,
  beforeDraw: (chart: Chart) => {
    const ctx = chart.ctx;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = '#f8f9fb';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

const determineScale = (num: number) => {
  const numStr = `${num}`;
  return parseInt('1'.padEnd(numStr.length, '0'));
};

interface OwnProps {
  data?: {
    count: number;
    description?: string;
    label: string;
  }[];
  tooltip?: boolean;
}

const HorizontalBarChartComponent = (props: OwnProps) => {
  const labels = useMemo(
    () => props?.data?.map((d) => d.label) ?? [],
    [props?.data],
  );

  const newChartData = useMemo(
    () => props?.data?.map((d) => d.count) ?? [],
    [props?.data],
  );

  const sortedData = [...newChartData].sort((a, b) => b - a);

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: newChartData,
        fill: true,
        backgroundColor: [
          ...newChartData.map((a) =>
            colorGenerator(scaleDataForHeatMap(newChartData, a)),
          ),
        ],
        borderColor: '#02b844',
        barThickness: 20,
        minBarThickness: 20,
      },
    ],
  };

  const options = {
    indexAxis: 'y' as const,
    animation: {
      duration: 1,
      onProgress: function (this: Chart) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const chartInstance = this;
        const ctx = chartInstance.ctx;
        ctx.font = `${Chart.defaults.font.style} ${Chart.defaults.font.size} ${Chart.defaults.font.family}`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';

        chartInstance.data.datasets.forEach(function (dataset, i) {
          const meta = chartInstance.getDatasetMeta(i);
          meta.data.forEach((bar, index) => {
            const data = dataset.data[index];
            ctx.fillText(`${data}`, bar.x + 10, bar.y + 5);
          });
        });
      },
    },
    scales: {
      x: {
        max: Math.max(...data.datasets[0].data) + determineScale(sortedData[0]),
        grid: {
          drawOnChartArea: false,
          borderWidth: 1.5,
          borderColor: '#000000',
        },
        ticks: {
          display: false,
          beginAtZero: true,
        },
      },
      y: {
        grid: {
          drawOnChartArea: false,
          borderColor: '#000000',
        },
        ticks: {
          display: true,
          beginAtZero: true,
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: function (
          this: TooltipModel<'bar'>,
          args: { chart: Chart; tooltip: TooltipModel<'bar'> },
        ) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const tooltipModel = this;
          externalTooltip(tooltipModel, args, props.tooltip);
        },
        backgroundColor: '#fff',
        bodyColor: '#323232',
        borderColor: 'rgba(1, 1, 1, 0.1)',
        borderWidth: 1,
        intersect: true,
        displayColors: false,
        cornerRadius: 8,
        padding: 10,
        callbacks: {
          title: () => '',
          label: (context: TooltipItem<'bar'>) => {
            return [`${props?.data?.[context.dataIndex]?.description}`];
          },
        },
      },
    },
  };

  return (
    <Box>
      <StyledComponent.WrappedPaperBar
        elevation={0}
        height={newChartData.length * 25 + (newChartData.length - 1) * 5 + 20}
      >
        {!!props?.data?.length && (
          <Bar options={options} data={data} plugins={[plugin]} />
        )}
      </StyledComponent.WrappedPaperBar>
    </Box>
  );
};

export default HorizontalBarChartComponent;
