import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const MailIcon2: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M0 6.43084L10.3986 10.888L20.7973 6.43084V2.14089C20.7973 1.57309 20.5717 1.02855 20.1702 0.627053C19.7687 0.225558 19.2242 0 18.6564 0H2.14089C1.57309 0 1.02855 0.225558 0.627053 0.627053C0.225558 1.02855 0 1.57309 0 2.14089V6.43084ZM10.3986 9.55858L1.22337 5.62342V2.14089C1.22337 1.89755 1.32004 1.66417 1.49211 1.49211C1.66418 1.32004 1.89755 1.22337 2.14089 1.22337H18.6564C18.8997 1.22337 19.1331 1.32004 19.3052 1.49211C19.4772 1.66417 19.5739 1.89755 19.5739 2.14089V5.62342L10.3986 9.55858ZM0.627053 17.3735C1.02855 17.775 1.57309 18.0006 2.14089 18.0006H18.6564C19.2242 18.0006 19.7687 17.775 20.1702 17.3735C20.5717 16.972 20.7973 16.4275 20.7973 15.8597V8.82532C20.7973 8.66309 20.7328 8.5075 20.6181 8.39279C20.5034 8.27808 20.3478 8.21363 20.1856 8.21363C20.0233 8.21363 19.8678 8.27808 19.7531 8.39279C19.6383 8.5075 19.5739 8.66309 19.5739 8.82532V15.8597C19.5739 16.103 19.4772 16.3364 19.3052 16.5085C19.1331 16.6805 18.8997 16.7772 18.6564 16.7772H2.14089C1.89755 16.7772 1.66418 16.6805 1.49211 16.5085C1.32004 16.3364 1.22337 16.103 1.22337 15.8597V8.82532C1.22337 8.66309 1.15892 8.5075 1.04421 8.39279C0.929497 8.27808 0.773913 8.21363 0.611684 8.21363C0.449455 8.21363 0.293871 8.27808 0.179158 8.39279C0.064445 8.5075 0 8.66309 0 8.82532V15.8597C0 16.4275 0.225558 16.972 0.627053 17.3735Z" />
  </SvgIcon>
);

export default MailIcon2;
