import { Typography } from '@mui/material';
import React from 'react';
import { IChartData } from '../../dashboard';
import DomainChart from './DomainReport.chartWrapper.component';
import * as StyledComponents from './DomainReport.styles';
import { Detections } from '@fyeo-di-frontend/shared';

interface OwnProps {
  data?: Record<Detections, IChartData[]>;
  isLoading: boolean;
}
const DetectedTimeline = (props: OwnProps) => {
  return (
    <StyledComponents.EntryRoot>
      <Typography variant="h5" fontWeight={'bold'} mb={2}>
        Detected entries timeline
      </Typography>
      <DomainChart isLoading={props.isLoading} data={props.data} />
    </StyledComponents.EntryRoot>
  );
};

export default DetectedTimeline;
