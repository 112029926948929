import dayjs from 'dayjs';
import { IChartData } from './Dashboard.types';
import { SeverityWithoutNone } from '@fyeo-di-frontend/shared';

export const generateLabels = (
  format: string,
  data?: Record<SeverityWithoutNone, IChartData[]>,
) => {
  const labels: string[] = [];
  const lb = [
    ...Object.values(data ?? {})
      .slice(0, -1)
      .flat(),
  ]
    .sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf())
    .map((m) => dayjs(m.date).format(format));
  lb.forEach((c) => {
    if (!labels.includes(c)) {
      labels.push(c);
    }
  });
  return labels;
};

export const formatDataFromLabel = (
  data?: Record<SeverityWithoutNone, IChartData[]>,
) => {
  if (data) {
    const label = generateLabels('MMM,DD,YYYY', data);
    Object.entries(data)?.forEach(([key, value]) => {
      const k = key as SeverityWithoutNone;
      if (Array.isArray(value) && !!value?.length) {
        const newValue = [...value];
        const dates = value.map((v) => dayjs(v.date).format('MMM,DD,YYYY'));
        label.forEach((l) => {
          if (!dates.includes(l)) {
            newValue.push({ count: 0, date: new Date(l) });
          }
        });
        newValue.sort(
          (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf(),
        );
        data[k] = newValue;
      }
    });
    return data;
  }
};
