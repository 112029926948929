import {
  DetectedTimeline,
  DomainReportCardSummaryComponent,
  ListBreaches,
  ReportImage,
  TopPasswords,
  TopSources,
  TopUsers,
} from '@fyeo-di-frontend/shared';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import LogoUrl from '../../header/Logo.svg';
import {
  DomainContextProvider,
  useDomainContext,
  useDomainContextModel,
} from './DomainReport.context';
import useDomainReportHook from './DomainReport.hooks';
import * as StyledComponents from './DomainReport.styles';

const DomainReport = () => {
  const {
    dateRange,
    preparedFor,
    preparedBy,
    topSources,
    topUsers,
    topPasswords,
    listOfBreaches,
    summary,
    chartData,
  } = useDomainContext();

  const {
    fetchingTopSources,
    fetchingTopUsers,
    fetchingTopPasswords,
    fetchingBreaches,
  } = useDomainReportHook();

  return (
    <>
      <StyledComponents.Container>
        <StyledComponents.Header>
          <img src={LogoUrl} alt="Logo" />
          <StyledComponents.Background>
            <ReportImage />
          </StyledComponents.Background>
          <StyledComponents.ReportDetailsWrapper>
            <Typography variant="h4" mb={1}>
              Leaked credentials report
            </Typography>
            <Typography variant="subtitle1">
              Period: {dayjs(dateRange?.from).format('YYYY-MM-DD')} to{' '}
              {dayjs(dateRange?.to).format('YYYY-MM-DD')}
            </Typography>
            <Typography variant="subtitle1">
              Prepared for: {preparedFor}
            </Typography>
            <Typography variant="subtitle1" mb={2.75}>
              Prepared by: {preparedBy}
            </Typography>
          </StyledComponents.ReportDetailsWrapper>
        </StyledComponents.Header>
        <DomainReportCardSummaryComponent
          isLoading={fetchingTopSources}
          data={summary}
        />
        <DetectedTimeline isLoading={fetchingTopSources} data={chartData} />
        <TopSources isLoading={fetchingTopSources} data={topSources} />
        <TopUsers isLoading={fetchingTopUsers} data={topUsers} />
        <TopPasswords isLoading={fetchingTopPasswords} data={topPasswords} />
        <ListBreaches loading={fetchingBreaches} data={listOfBreaches} />
      </StyledComponents.Container>
    </>
  );
};

const DomainReportWithContext = () => {
  const contextData = useDomainContextModel();
  return (
    <DomainContextProvider value={contextData}>
      <DomainReport />
    </DomainContextProvider>
  );
};

export default DomainReportWithContext;
