export const autoMerge = <T, Key extends keyof T>(
  arr1: T[],
  arr2: T[],
  prop: Key,
): T[] => {
  return arr1
    .concat(arr2)
    .filter(
      (value, index, self) =>
        self.map((a) => a[prop]).indexOf(value[prop]) === index,
    );
};
