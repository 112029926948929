import * as StyledComponents from './Events.styles';
import { Box, Link, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import PlaceholderIcon from './PlaceholderImage.png';
import { Events } from './Events.types';
import { AssetTypeRender } from '../assets';
import { generateJSX, isValidUrl } from '../helpers';
import { eventsFormatCut } from './Events.cut.helpers';
import { SourceType } from '../incidents';

interface OwnProps<T, K> {
  event: Events<T, K>;
  incident?: { name: string; id: string };
  onRedirectUrl?: (url: string) => void;
  onDeepLink?: (
    incident?: { name: string; id: string },
    event?: Events<T, K>,
  ) => void;
}
function EventsDetailsComponent<T, K>({
  event,
  onRedirectUrl,
  onDeepLink,
  incident,
}: OwnProps<T, K>) {
  return (
    <StyledComponents.ListContainer
      elevation={0}
      data-cy={`event-item-${event.id}`}
    >
      <Box display="flex">
        <StyledComponents.ImageWrapper>
          {event?.pdfUrl ? (
            <Link href={event?.pdfUrl} target="_blank" rel="noreferrer">
              <img src={event?.image || PlaceholderIcon} alt="events" />
            </Link>
          ) : (
            <img src={event?.image || PlaceholderIcon} alt="events" />
          )}
        </StyledComponents.ImageWrapper>
        <StyledComponents.DetailContainer mt={0.5}>
          <Box
            display="flex"
            flexWrap="nowrap"
            gap={0.5}
            alignItems="flex-start"
            justifyContent="space-between"
            mb={1}
          >
            <StyledComponents.ListTitle variant="subtitle1" fontWeight="bold">
              {event.title}
              {onDeepLink && (
                <StyledComponents.StyledCloseIcon
                  color="primary"
                  fontSize="small"
                  onClick={() => onDeepLink(incident, event)}
                />
              )}
            </StyledComponents.ListTitle>
            <Typography variant="body2">
              {dayjs(new Date(event.createdAt!)).format('YYYY-MM-DD')}
            </Typography>
          </Box>
          {event?.sourceType === SourceType.Darknet && event?.pdfUrl ? (
            <Link
              typography={{
                variant: 'subtitle1',
              }}
              href={event?.pdfUrl}
              target="_blank"
              rel="noreferrer"
            >
              <StyledComponents.EllipsisText
                variant="subtitle1"
                fontSize={14}
                title={event.url}
              >
                {event?.url}
              </StyledComponents.EllipsisText>
            </Link>
          ) : isValidUrl(event?.url) &&
            event?.sourceType !== SourceType.Darknet ? (
            <Link
              typography={{
                variant: 'subtitle1',
              }}
              onClick={() => event?.url && onRedirectUrl?.(event?.url)}
            >
              <StyledComponents.EllipsisText
                variant="subtitle1"
                fontSize={14}
                title={event.url}
              >
                {event?.url}
              </StyledComponents.EllipsisText>
            </Link>
          ) : (
            <StyledComponents.EllipsisText
              variant="subtitle1"
              fontSize={14}
              color="primary"
              title={event.url}
            >
              {event?.url}
            </StyledComponents.EllipsisText>
          )}
          <Box display="flex" gap={3} alignItems="center" mt={0.5}>
            <Box display="flex" alignItems="center" gap={0.5} fontSize={25}>
              <AssetTypeRender
                assetType={event.asset?.assetType}
                size="inherit"
              />
              {/*<Link*/}
              {/*  typography={{*/}
              {/*    variant: 'subtitle1',*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <Typography variant="subtitle1" fontSize={14}>*/}
              {/*    {event.asset?.assetName}*/}
              {/*  </Typography>*/}
              {/*</Link>*/}
              <Typography variant="subtitle1" fontSize={14} color="primary">
                {event.asset?.assetName}
              </Typography>
            </Box>
            <Box display="flex" gap={0.7} alignItems="center">
              <Typography variant="subtitle1" fontSize={12}>
                Threat level:
              </Typography>
              <Box>
                {event.threatLevel != undefined &&
                  generateJSX(event.threatLevel)}
              </Box>
            </Box>
          </Box>
        </StyledComponents.DetailContainer>
      </Box>
      <StyledComponents.CutWrapper>
        <Typography variant="body2" bgcolor="white" padding={1}>
          {eventsFormatCut({
            cut: event.cut || '',
            matches: [
              ...(event?.mentions?.[0]?.matches?.map((m) => ({
                startPos: m.startPos || 0,
                endPos: m.endPos || 0,
              })) || [
                {
                  startPos: 0,
                  endPos: 0,
                },
              ]),
            ],
          })}
        </Typography>
      </StyledComponents.CutWrapper>
    </StyledComponents.ListContainer>
  );
}

export default EventsDetailsComponent;
