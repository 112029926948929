import useDashboardChartHook from './Dashboard.chart.hooks';
import useDashboardDetectionsHook from './Dashboard.detections.hooks';
import useDashboardHeatMapHook from './Dashboard.heatMap.hooks';
import useDashboardIncidentsHook from './Dashboard.incidents.hooks';
import useDashboardIncidentTypesHook from './Dashboard.incidentTypes.hooks';
import useDashboardSeverityHook from './Dashboard.severity.hooks';
import { useCallback } from 'react';
import { AssetType, Severity, Status } from '@fyeo-di-frontend/shared';
import { useNavigate } from 'react-router-dom';
import { RIncidentsByIncidentType } from '@root/dashboard/Dashboard.types';
import dayjs from 'dayjs';
import { FilterStorageKey } from '@root/incidents';
import startCase from 'lodash/startCase';

const defaultIncidentFilterState = {
  [FilterStorageKey.Statuses]: undefined,
  [FilterStorageKey.IncidentsTypes]: [],
  [FilterStorageKey.Reported]: [],
  [FilterStorageKey.Severity]: [],
  [FilterStorageKey.IncidentSearchText]: '',
  [FilterStorageKey.DateRange]: {
    from: dayjs().subtract(1, 'month').toDate(),
    to: dayjs().toDate(),
  },
};

const useDashboardHook = () => {
  const { fetchingIncidentsHistoryByDate } = useDashboardChartHook();
  const { fetchingIncidentsByAssetType } = useDashboardDetectionsHook();
  const { fetchingIncidentsByCaseAndType } = useDashboardHeatMapHook();
  const { fetchingIncidentsByDate } = useDashboardIncidentsHook();
  const { fetchingIncidentsByIncidentType } = useDashboardIncidentTypesHook();
  const { fetchingIncidentsBySeverity } = useDashboardSeverityHook();
  const push = useNavigate();

  const onCardClick = useCallback(
    (val: 'open' | 'close' | 'new') => {
      let statuses: Status | undefined;
      switch (val) {
        case 'close':
          statuses = Status.Closed;
          break;
        case 'open':
          statuses = Status.Open;
          break;
        case 'new':
          statuses = undefined;
          break;
      }

      push('/incidents', {
        state: {
          ...defaultIncidentFilterState,
          [FilterStorageKey.Statuses]: statuses,
        },
      });
    },
    [push],
  );

  const onBarClick = useCallback(
    (severity: Severity) => {
      push('/incidents', {
        state: {
          ...defaultIncidentFilterState,
          [FilterStorageKey.Severity]: [
            { name: Severity[severity], id: severity },
          ],
        },
      });
    },
    [push],
  );

  const onTypeClick = useCallback(
    (incidentType: RIncidentsByIncidentType) => {
      push('/incidents', {
        state: {
          ...defaultIncidentFilterState,
          [FilterStorageKey.IncidentsTypes]: [
            {
              name: incidentType.incidentType,
              id: incidentType.id,
            },
          ],
        },
      });
    },
    [push],
  );

  const onAssetTypeClick = useCallback(
    (assetType: AssetType) => {
      push('/incidents', {
        state: {
          ...defaultIncidentFilterState,
          [FilterStorageKey.AssetTypes]: [
            { name: startCase(assetType), id: assetType },
          ],
        },
      });
    },
    [push],
  );

  return {
    fetchingIncidentsByDate,
    fetchingIncidentsBySeverity,
    fetchingIncidentsByAssetType,
    fetchingIncidentsHistoryByDate,
    fetchingIncidentsByIncidentType,
    fetchingIncidentsByCaseAndType,
    onCardClick,
    onBarClick,
    onTypeClick,
    onAssetTypeClick,
  };
};

export default useDashboardHook;
