import { Box, Grid, Paper, Typography } from '@mui/material';
import styled from 'styled-components';

export const Root = styled.div({
  height: 'calc(100vh - 70px)',
  backgroundColor: '#f8f9fb',
  paddingTop: 30,
  overflowY: 'auto',
});

export const HeatMapHeaderText = styled(Typography)`
  white-space: nowrap;
  position: relative;
  top: 75%;
  left: 30%;
  ${(props) => props.theme.breakpoints.down(1920)} {
    top: 70%;
    left: 10%;
  }
  ${(props) => props.theme.breakpoints.up(2600)} {
    top: 50%;
    left: 30%;
  }
`;

export const TextGrid = styled(Grid)({
  transform: 'rotate(-45deg)',
});

export const TabRoot = styled.div({
  '& .MuiTabs-root': {
    minHeight: 'inherit',
    marginBottom: 15,
  },
  '& .MuiTabs-flexContainer': {
    gap: 23,
  },
  '& .MuiTab-root': {
    minWidth: 'inherit',
    padding: 0,
    minHeight: 'inherit',
    paddingBottom: 3,
  },
  '& .MuiTabs-indicator': {
    height: 2,
  },
});

export const LegendLine = styled(Box)<{ strokeStyle?: string }>`
  width: 20px;
  border: 1px solid ${({ strokeStyle }) => `${strokeStyle}`};
  background-color: ${({ strokeStyle }) => `${strokeStyle}`};
  border-radius: 9999px;
  color: #323232;
`;

export const CursorBox = styled(Box)({
  '&.active-cursor': {
    cursor: 'pointer',
  },
});

export const PaperWithBG = styled(Paper)({
  backgroundColor: '#f8f9fb',
});

export const WrappedPaper = styled(Paper)<{ bg?: boolean }>((props) => ({
  backgroundColor: props.bg ? '#f8f9fb' : undefined,
}));

export const MultiLineTruncText = styled(Typography)({
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
});
