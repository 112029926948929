import React from 'react';
import * as StyledComponent from './Root.styles';
import BgImageUrl from './Bg.svg';

const Root = (props: { children?: React.ReactNode }) => {
  return (
    <StyledComponent.RootStyled>
      <StyledComponent.RootTopStyledBg src={BgImageUrl} />
      <StyledComponent.ContainerStyled maxWidth="sm">
        {props.children}
      </StyledComponent.ContainerStyled>
    </StyledComponent.RootStyled>
  );
};

export default Root;
