import { HorizontalFullHeightLoader } from '@fyeo-di-frontend/shared';
import { Box, Grid, Typography } from '@mui/material';
import React, { memo } from 'react';
import * as StyledComponents from './Dashboard.styles';

interface OwnProps {
  newIncidents?: number;
  openIncidents?: number;
  closedIncidents?: number;
  isLoading: boolean;
  bg?: boolean;
  monthly?: boolean;
  onCardClick?: (val: 'open' | 'close' | 'new') => void;
}

const Card = ({
  count,
  title,
  isLoading,
  bg,
  onCardClick,
}: {
  count?: number;
  title?: string;
  isLoading?: boolean;
  bg?: boolean;
  onCardClick?: () => void;
}) => (
  <StyledComponents.CardPaper
    elevation={0}
    bg={bg}
    onClick={onCardClick}
    className={onCardClick && 'active-cursor'}
  >
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height={100}
    >
      {isLoading ? (
        <HorizontalFullHeightLoader />
      ) : (
        <>
          <Typography variant="h4">{count}</Typography>
          <Typography variant="body1">{title}</Typography>
        </>
      )}
    </Box>
  </StyledComponents.CardPaper>
);

const DashboardIncidentsComponent = (props: OwnProps) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h6">Incidents</Typography>
      <Box mt={2}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Card
              count={props.newIncidents}
              title={`New Incidents${props.monthly ? '' : ' (30 days)'}`}
              isLoading={props.isLoading}
              bg={props.bg}
              onCardClick={
                props.onCardClick
                  ? () => props?.onCardClick?.('new')
                  : undefined
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Card
              count={props.openIncidents}
              title="Total Open Incidents"
              isLoading={props.isLoading}
              bg={props.bg}
              onCardClick={
                props.onCardClick
                  ? () => props?.onCardClick?.('open')
                  : undefined
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Card
              count={props.closedIncidents}
              title={`Closed Incidents${props.monthly ? '' : ' (30 days)'}`}
              isLoading={props.isLoading}
              bg={props.bg}
              onCardClick={
                props.onCardClick
                  ? () => props?.onCardClick?.('close')
                  : undefined
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default memo(DashboardIncidentsComponent);
