import React from 'react';
import { Wallet, WalletType } from '../Assets.types';
import { WalletIcon } from '@fyeo-di-frontend/shared';
import * as StyledComponent from './AssetTypes.styles';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import startCase from 'lodash/startCase';

interface OwnProps {
  isThreatActor?: boolean;
  data?: Wallet[];
  onChange?: (data: Wallet) => void;
  onAdd?: () => void;
  onDelete?: (data: Wallet) => void;
  disabled?: boolean;
  readOnly?: boolean;
}

const WalletRender: React.FC<OwnProps> = (props) => {
  const onChangeType =
    (item: Wallet) => (evt: SelectChangeEvent<WalletType>) => {
      props.onChange?.({ ...item, tag: evt.target.value as WalletType });
    };

  const onChangeValue =
    (item: Wallet) => (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange?.({ ...item, value: event.target.value });
    };

  const onDelete = (item: Wallet) => () => {
    props.onDelete?.(item);
  };

  const color = props.isThreatActor ? 'secondary' : 'primary';
  return (
    <>
      <Box display="flex" width="100%" mt={2}>
        <Box mt={-1} ml={-1} mb={-1}>
          <StyledComponent.CursorBox
            onClick={!props.readOnly ? props.onAdd : undefined}
            fontSize={40}
          >
            <WalletIcon fontSize="inherit" color={color} />
          </StyledComponent.CursorBox>
        </Box>
      </Box>
      {props?.data?.map?.((data) => (
        <Grid container spacing={1} key={data?._id}>
          <Grid item xs={4}>
            <Select<WalletType>
              onChange={onChangeType(data)}
              size="small"
              fullWidth
              value={data?.tag}
              color={color}
              disabled={props.disabled}
              readOnly={props.readOnly}
            >
              <MenuItem value={WalletType.Etherium} dense>
                {startCase(WalletType.Etherium)}
              </MenuItem>
              <MenuItem value={WalletType.Bitcoin} dense disabled>
                {startCase(WalletType.Bitcoin)}
              </MenuItem>
              <MenuItem value={WalletType.Solana} dense disabled>
                {startCase(WalletType.Solana)}
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={8}>
            <TextField
              disabled={props.disabled}
              value={data?.value || ''}
              onChange={onChangeValue(data)}
              color={color}
              InputProps={{
                readOnly: props.readOnly,
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={onDelete?.(data)}>
                      <Close fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default WalletRender;
