import React from 'react';
import Incidents from './Incidents.container';
import {
  IncidentsContextProvider,
  useIncidentsContextModel,
} from './Incidents.context';
export { default as IncidentsDetailsDialog } from './Incidents.detailsWrapper.container';
export { IncidentDetailsComponent, IncidentDetailsReport } from './details';
export { enumToDescriptedArray } from './Incidents.helpers';
export { default as MultiSelectLazyList } from './Incidents.multiSelectLazyList.component';
export * from './Incidents.types';
export { transformDataToCSVDetails, transformDataToCSVEvents } from './details';

const IncidentsRoot = () => {
  const contextData = useIncidentsContextModel();
  return (
    <IncidentsContextProvider value={contextData}>
      <Incidents />
    </IncidentsContextProvider>
  );
};

export default IncidentsRoot;
