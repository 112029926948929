import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const CheckIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    {...props}
  >
    <circle
      cx="52.2495"
      cy="51.6523"
      r="50"
      fill="#31AD34"
      fillOpacity="0.09"
      stroke="#31AD34"
      strokeWidth="3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77.6252 33.0669C78.519 33.9608 78.519 35.41 77.6252 36.3039L43.6865 70.2426C42.7926 71.1364 41.3434 71.1364 40.4495 70.2426L26.874 56.6671C25.9801 55.7732 25.9801 54.324 26.874 53.4301C27.7679 52.5362 29.2171 52.5362 30.111 53.4301L42.068 65.3871L74.3882 33.0669C75.2821 32.173 76.7313 32.173 77.6252 33.0669Z"
      fill="#32AE34"
    />
  </SvgIcon>
);

export default CheckIcon;
