export enum FilterStorageKey {
  DateRange = 'dateRange',
  IncidentSearchText = 'incidentSearchText',
  IncidentsTypes = 'incidentsTypes',
  Reported = 'reported',
  Severity = 'severity',
  Statuses = 'statuses',
  Assets = 'assets',
  AssetTypes = 'assetTypes',
}

export type Descripted<T> = {
  [K in keyof T]: {
    readonly id: T[K];
    readonly name: string;
  };
}[keyof T];
