import {
  Box,
  ListItemIcon,
  Paper,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import styled from 'styled-components';

export const Root = styled.div({
  marginLeft: 'auto',
  marginTop: 10,
  marginBottom: 20,
  marginRight: 'auto',
  display: 'flex',
});

export const NoBreakTitle = styled(Typography)({
  pageBreakAfter: 'avoid',
});

export const EntryRoot = styled.div({
  marginLeft: 'auto',
  marginBottom: 20,
  marginRight: 'auto',
  width: 921,
  'page-break-inside': 'avoid',
  position: 'relative',
  top: 20,
});

export const Container = styled.div`
  overflow-y: scroll;
  height: 100vh;
`;

export const Header = styled(Box)({
  minHeight: 468,
  position: 'relative',
  paddingTop: 30,
  paddingLeft: 36,
  paddingBottom: 30,
  width: '100%',
  background: 'linear-gradient(89.78deg, #32AE34 0%, #FAC705 100%);',
});

export const ReportDetailsWrapper = styled(Box)({
  display: 'flex',
  paddingLeft: 364,
  paddingRight: 400,
  paddingTop: 80,
  color: 'white',
  flexDirection: 'column',
  '.MuiTypography-subtitle1': {
    lineHeight: 1.3,
  },
});

export const IncidentsWrapper = styled(Box)({
  background: '#F8F9FB',
  borderRadius: 7,
  padding: 16,
  width: '100%',
});

export const ReportedItemIcon = styled(ListItemIcon)({
  minWidth: 'auto',
  marginRight: '12px',
});

export const CursorBox = styled(Box)({
  cursor: 'pointer',
});

export const IncidentTextGroup = styled(Box)({
  '.MuiTypography-subtitle1': {
    lineHeight: 1.3,
  },
});

export const LegendLine = styled(Box)<{ strokeStyle?: string }>`
  width: 20px;
  border: 1px solid ${({ strokeStyle }) => `${strokeStyle}`};
  background-color: ${({ strokeStyle }) => `${strokeStyle}`};
  border-radius: 9999px;
  color: #323232;
`;

export const CardPaper = styled(Paper)({
  backgroundColor: '#f8f9fb',
});

export const ChartPaper = styled(CardPaper)({
  padding: 10,
});

export const TableHeaderCell = styled(TableCell)({
  top: 60,
  height: 50,
  cursor: 'pointer',
});

export const TableBodyRow = styled(TableRow)({
  backgroundColor: '#fff',
  'page-break-inside': 'avoid',
});

export const TableHeaderStyled = styled(TableHead)({
  paddingTop: 60,
});

export const NoResultContainer = styled(Paper)({
  marginTop: 10,
  paddingTop: 40,
  paddingBottom: 62,
});

export const NoResultIcon = styled.img({
  width: 103,
});

export const WrappedPaper = styled(Paper)<{ bg?: boolean }>((props) => ({
  backgroundColor: props.bg ? '#f8f9fb' : undefined,
}));

export const WrappedPaperBar = styled(WrappedPaper)<{ height?: number }>(
  (props) => ({
    position: 'relative',
    height: props.height ? props.height : 200,
  }),
);
