import { TrendItem } from '@fyeo-di-frontend/shared';
import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import useEditTrendsHook from './EditTrends.hooks';

export type TEditTrendsContextModel = Partial<
  ReturnType<typeof useEditTrendsContextModel>
>;

const EditTrendsContext = createContext<TEditTrendsContextModel>({});
const { Provider } = EditTrendsContext;

export const useEditTrendsContextModel = () => {
  const [value, setValue] = useState<string | undefined>('');
  const [id, setId] = useState<string | undefined>('');
  const [published, setPublished] = useState<boolean>(false);
  const [titleValue, setTitleValue] = useState<string | undefined>('');
  const listingRef = useRef<HTMLDivElement>(null);
  const [trends, setTrends] = useState<TrendItem[]>([]);
  const [page, setPage] = useState<number>(1);
  const [shouldResetData, setShouldResetData] = useState<boolean>(true);

  const isPublished = useCallback(() => {
    return !id || (!!id && published);
  }, [published, id]);

  const commonCopy = useCallback((trend: TrendItem) => {
    setTitleValue(trend.title);
    setPublished(trend.published);
    setValue(trend.content);
  }, []);

  const resetState = useCallback(() => {
    setTitleValue('');
    setPublished(false);
    setValue('');
    setId('');
  }, []);

  const onCopyTrend = useCallback(
    (trend: TrendItem) => {
      setId('');
      commonCopy(trend);
    },
    [commonCopy],
  );

  const onTrendClick = useCallback(
    (trend: TrendItem) => {
      setId(trend.id);
      commonCopy(trend);
    },
    [commonCopy],
  );

  const resetDataAndPage = useCallback(() => {
    setPage(() => 1);
  }, [setPage]);

  const {
    isLoading,
    handleLoadMore,
    refetch,
    onSave,
    isSaving,
    isPublishing,
    onPublish,
  } = useEditTrendsHook({
    setTrends,
    page,
    setPage,
    resetDataAndPage,
    shouldResetData,
    setShouldResetData,
    listingRef,
    id,
    resetState,
    value,
    titleValue,
  });

  return {
    trends,
    setTrends,
    page,
    setPage,
    resetDataAndPage,
    isLoading,
    handleLoadMore,
    refetch,
    listingRef,
    value,
    setValue,
    titleValue,
    setTitleValue,
    id,
    setId,
    isPublished,
    onTrendClick,
    onCopyTrend,
    onSave,
    isSaving,
    isPublishing,
    onPublish,
  };
};

export const useEditTrendsContext = () => useContext(EditTrendsContext);

export const EditTrendsContextProvider = Provider;
