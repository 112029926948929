import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  Divider,
  ListItemIcon,
  Paper,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import React from 'react';

export const Root = styled.div({
  backgroundColor: '#f8f9fb',
  paddingTop: 30,
});

export const NoPaddingDialog = styled(DialogContent)({
  padding: 0,
});

export const Container = styled.div({
  display: 'flex',
  gap: 5,
  paddingRight: 16,
  paddingLeft: 16,
});

export const FilterOptionsButton = styled(Button)({
  textTransform: 'initial',
  marginLeft: 11,
});

export const ListView = styled(Box)({
  height: 'calc(100vh - 70px)',
  width: '55%',
  overflowY: 'auto',
  paddingBottom: 500,
});

export const DetailsPaper = styled(Paper)({
  backgroundColor: 'white',
  width: '45%',
  padding: '15px 11px',
});

export const DividerStyled = styled(Divider)({
  height: 2,
  backgroundColor: '#000',
});

export const Details = styled.div({
  height: 'calc(100vh - 200px)',
  overflowY: 'scroll',
  paddingBottom: 500,
});

export const ImageWrapper = styled(Box)({
  width: 73,
  height: 104,
  background: '#F8F9FB',
  float: 'left',
  marginRight: 8,
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

export const DetailImageWrapper = styled(Box)({
  width: 181,
  height: 258,
  background: '#F8F9FB',
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

export const ListContainer = styled(Paper)({
  backgroundColor: 'white',
  padding: 10,
});
export const HorizontalLine = styled.hr({
  height: 0,
  margin: 0,
  border: 'none',
  borderBottom: '1px solid #D5D8DF',
});

export const ListTitle = styled(Typography)({
  wordWrap: 'break-word',
  width: '80%',
  lineHeight: 1.5,
});

export const BreakWord = styled(Typography)({
  wordWrap: 'break-word',
  fontSize: 14,
});

export const DetailTitle = styled(BreakWord)({
  lineHeight: 1.5,
  fontSize: 18,
});

export const ListBox = styled.div({
  width: 20,
  height: 20,
  backgroundColor: '#C4C4C4',
});

export const AddButton = styled(LoadingButton)({
  width: 27,
  minWidth: 27,
  height: 39,
});

export const Indicator = styled(Box)({
  width: 8,
  height: 8,
  borderRadius: '50%',
});

export const ReportedItemIcon = styled(ListItemIcon)({
  minWidth: 'auto',
});

export const NoResultContainer = styled(Paper)({
  paddingTop: 40,
  paddingBottom: 62,
});

export const NoResultIcon = styled.img({
  width: 103,
});

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    color: '#323232',
    boxShadow: '0px 0px 4.22142px 2px rgba(0, 0, 0, 0.1)',
    fontSize: 11,
    borderRadius: 3.16606,
    maxWidth: 500,
  },
  [`& .${tooltipClasses.tooltipPlacementRight}`]: {
    marginLeft: '2px !important',
  },
}));
