import {
  AssetType,
  AssetTypeRender,
  HorizontalFullHeightLoader,
  StyledComponentsShared,
} from '@fyeo-di-frontend/shared';
import { Box, Typography } from '@mui/material';
import startCase from 'lodash/startCase';
import React from 'react';
import * as StyledComponents from './Dashboard.styles';

interface OwnProps {
  personCount?: number;
  domainCount?: number;
  locationCount?: number;
  organizationCount?: number;
  agentCount?: number;
  isLoading: boolean;
  bg?: boolean;
  onClick?: (assetType: AssetType) => void;
}

const Bar = (props: {
  count?: number;
  assetType?: AssetType;
  onClick?: (assetType: AssetType) => void;
}) => (
  <StyledComponents.AssetTypeWrapper
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
    textAlign="center"
    alignItems="center"
    gap={2}
    width="100%"
    onClick={() => props.assetType && props.onClick?.(props.assetType)}
  >
    <StyledComponentsShared.CustomTooltip
      disableFocusListener
      disableTouchListener
      title={
        props?.assetType === AssetType.Person
          ? 'Executives'
          : startCase(props?.assetType)
      }
      placement="right-start"
    >
      <Box fontSize={40} height={40}>
        <AssetTypeRender assetType={props?.assetType} size="inherit" />
      </Box>
    </StyledComponentsShared.CustomTooltip>
    <Typography variant="h5">{props?.count}</Typography>
  </StyledComponents.AssetTypeWrapper>
);

const DashboardDetections = (props: OwnProps) => {
  return (
    <StyledComponents.PaperWithFullHeight elevation={0} bg={props.bg}>
      {props.isLoading ? (
        <Box height={200}>
          <HorizontalFullHeightLoader />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" p={1} gap={3}>
          <Typography variant="subtitle1">Detections by asset type</Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Bar
              count={props?.organizationCount}
              assetType={AssetType.Organization}
              onClick={props.onClick}
            />
            <Bar
              count={props?.locationCount}
              assetType={AssetType.Location}
              onClick={props.onClick}
            />
            <Bar
              count={props?.domainCount}
              assetType={AssetType.Domain}
              onClick={props.onClick}
            />
            <Bar
              count={props?.personCount}
              assetType={AssetType.Person}
              onClick={props.onClick}
            />
            <Bar
              count={props?.agentCount}
              assetType={AssetType.Agent}
              onClick={props.onClick}
            />
          </Box>
        </Box>
      )}
    </StyledComponents.PaperWithFullHeight>
  );
};

export default DashboardDetections;
