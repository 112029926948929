import { CloseIcon } from '@fyeo-di-frontend/shared';
import { Box, ListItemIcon, Typography } from '@mui/material';
import styled from 'styled-components';
import MDEditor from '@uiw/react-md-editor';

export const Root = styled.div({
  paddingTop: 70,
  // paddingLeft: 400,
  // paddingRight: 400,
  width: 900,
  margin: '0 auto',
  display: 'flex',
  marginBottom: 20,
});

export const EntryRoot = styled.div({
  width: 900,
  margin: '0 auto',
  marginTop: 10,
  marginBottom: 20,
  '.wmde-markdown p': {
    margin: 0,
  },
});

export const Container = styled.div`
  overflow-y: scroll;
  height: 100vh;
`;

export const Header = styled(Box)({
  minHeight: 468,
  position: 'relative',
  paddingTop: 30,
  paddingLeft: 36,
  paddingBottom: 30,
  width: '100%',
  background: 'linear-gradient(89.78deg, #32AE34 0%, #FAC705 100%);',
});

export const Background = styled(Box)({
  position: 'absolute',
  bottom: -10,
  right: 0,
  img: {
    width: 426,
    height: 394,
  },
});

export const ReportDetailsWrapper = styled(Box)({
  display: 'flex',
  width: 936,
  margin: '0 auto',
  paddingTop: 80,
  color: 'white',
  flexDirection: 'column',
  '.MuiTypography-subtitle1': {
    lineHeight: 1.3,
  },
});

export const IncidentsWrapper = styled(Box)({
  background: '#F8F9FB',
  borderRadius: 7,
  padding: 16,
  width: '100%',
});

export const ReportedItemIcon = styled(ListItemIcon)({
  minWidth: 'auto',
  marginRight: '12px',
});

export const CursorBox = styled(Box)({
  cursor: 'pointer',
  display: 'inline-flex',
  marginLeft: 8,
});

export const IncidentTextGroup = styled(Box)({
  '.MuiTypography-subtitle1': {
    lineHeight: 1.3,
  },
});

export const SeverityBox = styled(Box)<{
  severityColor: { backgroundColor: string; color: string };
}>((props) => ({
  border: `1px solid ${props.severityColor.color}`,
  boxSizing: 'border-box',
  borderRadius: 2,
  color: `${props.severityColor.color}`,
  backgroundColor: props.severityColor.backgroundColor,
  paddingRight: 5,
  paddingLeft: 5,
}));

export const StyledCloseIcon = styled(CloseIcon)({
  display: 'inline-flex',
  textAlign: 'center',
  marginLeft: 5,
  height: '100%',
});

export const WordBreak = styled(Typography)({
  wordBreak: 'break-word',
});

export const Markdown = styled(MDEditor.Markdown)({
  '&.wmde-markdown': {
    fontFamily: 'Circular Pro Book,Noto Sans',
  },
});

export const Body = styled(Typography)({
  '.wmde-markdown': {
    fontSize: '0.9rem',
  },
});
