import React from 'react';
import {
  DashboardDetections,
  DashboardIncidents,
  DashboardSeverityChart,
} from '@fyeo-di-frontend/shared';
import { Box, Grid } from '@mui/material';
import DashboardChart from './Dashboard.chartWrapper.component';
import {
  DashboardContextProvider,
  useDashboardContext,
  useDashboardContextModel,
} from './Dashboard.context';
import DashboardHeatMap from './Dashboard.heatMap.component';
import useDashboardHook from './Dashboard.hooks';
import DashboardIncidentTypes from './Dashboard.incidentTypes.component';
import * as StyledComponents from './Dashboard.styles';

const Dashboard = () => {
  const {
    incidents,
    severity,
    detections,
    newOpenClosed,
    incidentTypes,
    columns,
    data,
  } = useDashboardContext();
  const {
    fetchingIncidentsByDate,
    fetchingIncidentsBySeverity,
    fetchingIncidentsByAssetType,
    fetchingIncidentsHistoryByDate,
    fetchingIncidentsByIncidentType,
    fetchingIncidentsByCaseAndType,
    onCardClick,
    onBarClick,
    onTypeClick,
    onAssetTypeClick,
  } = useDashboardHook();

  return (
    <StyledComponents.Root>
      <Box ml={2} mr={2} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" gap={1}>
              <DashboardIncidents
                newIncidents={incidents?.newIncidents}
                openIncidents={incidents?.openIncidents}
                closedIncidents={incidents?.closedIncidents}
                isLoading={fetchingIncidentsByDate}
                onCardClick={onCardClick}
              />
              <DashboardChart
                {...newOpenClosed}
                isLoading={fetchingIncidentsHistoryByDate}
              />
              <Grid container spacing={1}>
                <Grid item xs={5.5}>
                  <DashboardSeverityChart
                    {...severity}
                    isLoading={fetchingIncidentsBySeverity}
                    onBarClick={onBarClick}
                  />
                </Grid>
                <Grid item xs={6.5}>
                  <DashboardDetections
                    {...detections}
                    isLoading={fetchingIncidentsByAssetType}
                    onClick={onAssetTypeClick}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <DashboardIncidentTypes
              data={incidentTypes}
              isLoading={fetchingIncidentsByIncidentType}
              onTypeClick={onTypeClick}
            />
          </Grid>
          <Grid item xs={12}>
            <DashboardHeatMap
              data={data}
              columns={columns}
              isLoading={fetchingIncidentsByCaseAndType}
            />
          </Grid>
        </Grid>
      </Box>
    </StyledComponents.Root>
  );
};

const DashboardWithContext = () => {
  const contextData = useDashboardContextModel();
  return (
    <DashboardContextProvider value={contextData}>
      <Dashboard />
    </DashboardContextProvider>
  );
};

export default DashboardWithContext;
