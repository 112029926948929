import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const MailIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M17,11.26v1.29h2.24v2.33h1.32V12.55h2.26V11.26H20.58V9H19.26v2.31Z" />
    <path d="M2.11,10.67,8,13.21,14,10.67V8.22a1.21,1.21,0,0,0-.35-.86A1.23,1.23,0,0,0,12.74,7H3.33a1.19,1.19,0,0,0-.86.36,1.22,1.22,0,0,0-.36.86ZM8,12.45,2.81,10.21v-2a.51.51,0,0,1,.52-.52h9.41a.5.5,0,0,1,.37.15.52.52,0,0,1,.16.37v2ZM2.47,16.9a1.19,1.19,0,0,0,.86.36h9.41a1.23,1.23,0,0,0,.87-.36A1.18,1.18,0,0,0,14,16V12a.34.34,0,0,0-.34-.35.36.36,0,0,0-.25.1.35.35,0,0,0-.1.25v4a.52.52,0,0,1-.16.37.5.5,0,0,1-.37.15H3.33A.51.51,0,0,1,2.81,16V12a.36.36,0,0,0-.11-.25.35.35,0,0,0-.24-.1.37.37,0,0,0-.25.1.35.35,0,0,0-.1.25v4A1.19,1.19,0,0,0,2.47,16.9Z" />
  </SvgIcon>
);

export default MailIcon;
