import EditIcon from '@mui/icons-material/Edit';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Drawer,
  ListItemButton,
} from '@mui/material';
import styled from 'styled-components';

export const ListItemButtonStyled = styled(ListItemButton)({
  width: '100%',
  '&.Mui-selected': {
    backgroundColor: '#F8F9FB',
  },
  paddingLeft: 0,
  paddingRight: 1,
});

export const AccordionStyled = styled(Accordion)({
  '&:before': {
    backgroundColor: 'transparent',
  },
  marginRight: 2,
  '.MuiCheckbox-root': {
    padding: 0,
  },
});

export const CustomAccordionDetails = styled(AccordionDetails)({
  paddingRight: 0,
  paddingLeft: 0,
  marginLeft: 3,
});

export const AccordianSummaryStyled = styled(AccordionSummary)({
  height: 25,
  minHeight: 'initial',
  padding: 0,
  width: 'fit-content',
});

export const DrawerStyled = styled(Drawer)({
  '> .MuiPaper-root': {
    borderRight: 'none',
    marginTop: 64,
    paddingTop: 30,
    paddingBottom: 64,
    width: 176,
  },
});

export const EditIconWrapper = styled(EditIcon)({
  color: '#808080',
  ':hover': {
    color: '#31AD34',
  },
});
