import { IChartData, Detections } from '@fyeo-di-frontend/shared';
import { Chart, TooltipModel } from 'chart.js';
import dayjs from 'dayjs';

export const generateLabels = (
  format: string,
  data?: Record<Detections, IChartData[]>,
) => {
  return [...(data?.[Detections.New] || [])]
    .sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf())
    .map((m) => dayjs(m.date).format(format));
};

export const computeTotalDetections = (
  data?: Record<Detections, IChartData[]>,
) => {
  if (data) {
    const newDetection = [...(data[Detections.New] || [])];
    newDetection.sort(
      (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf(),
    );
    const totalDetection = [
      ...newDetection.reduce((pre: IChartData[], curr) => {
        return [
          ...pre,
          {
            count: (pre?.[pre.length - 1]?.count || 0) + curr.count,
            date: curr.date,
          },
        ];
      }, []),
    ];

    return {
      [Detections.New]: newDetection,
      [Detections.Total]: totalDetection,
    } as Record<Detections, IChartData[]>;
  }
};

export function externalTooltip(
  tooltipModel: TooltipModel<'bar'>,
  args: { chart: Chart; tooltip: TooltipModel<'bar'> },
  tooltip?: boolean,
) {
  // Tooltip Element
  let tooltipEl = document.getElementById('chartjs-tooltip');

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<table></table>';
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltipModel?.opacity === 0 || !tooltip) {
    tooltipEl.style.opacity = '0';
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel?.yAlign) {
    tooltipEl.classList.add(tooltipModel?.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem: {
    before: string[];
    lines: string[];
    after: string[];
  }) {
    return bodyItem.lines;
  }

  // Set Text
  if (tooltipModel?.body) {
    const titleLines = tooltipModel.title || [];
    const bodyLines = tooltipModel.body.map(getBody);

    let innerHtml = '<thead>';

    titleLines.forEach(function (title) {
      innerHtml += '<tr><th>' + title + '</th></tr>';
    });
    innerHtml += `</thead><tbody style='background-color: white'>`;

    bodyLines.forEach(function (body) {
      if (body.join(' ') === 'null') {
        return;
      }
      const span = '<span>' + body.join(' ') + '</span>';
      innerHtml +=
        '<tr><td width="400px" style="padding: 10px; border-radius: 3px; box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);">' +
        span +
        '</td></tr>';
    });
    innerHtml += '</tbody>';

    const tableRoot = tooltipEl.querySelector('table');
    if (tableRoot) {
      tableRoot.innerHTML = innerHtml;
    }
  }

  const position = args.chart.canvas.getBoundingClientRect();

  // Display, position
  tooltipEl.style.opacity = '1';
  tooltipEl.style.position = 'absolute';
  if (tooltipModel) {
    tooltipEl.style.left =
      window.scrollX + tooltipModel.caretX - position.left / 2 + 'px';
    tooltipEl.style.top =
      position.top + window.scrollY + tooltipModel.caretY + 'px';
    tooltipEl.style.pointerEvents = 'none';
  }
}
