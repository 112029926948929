import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const Safe: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 59 23" {...props}>
    <rect
      x="0.5"
      y="0.5"
      width="58"
      height="22"
      rx="1.5"
      fill="#E2E2E2"
      stroke="#767676"
    />
    <path
      d="M23.9395 9.484C23.7475 8.536 22.9675 7.312 20.9635 7.312C19.3795 7.312 18.0715 8.488 18.0715 9.916C18.0715 11.188 18.9355 12.04 20.2315 12.304L21.4315 12.556C22.1635 12.712 22.5715 13.156 22.5715 13.708C22.5715 14.368 22.0435 14.884 21.0235 14.884C19.7995 14.884 19.1635 14.068 19.0795 13.18L17.7355 13.564C17.8675 14.788 18.8635 16.18 21.0235 16.18C22.9675 16.18 24.0355 14.92 24.0355 13.6C24.0355 12.4 23.2315 11.452 21.7315 11.152L20.5075 10.9C19.8475 10.756 19.5115 10.348 19.5115 9.808C19.5115 9.16 20.1115 8.584 20.9755 8.584C22.1155 8.584 22.5595 9.364 22.6435 9.892L23.9395 9.484ZM25.088 14.416C25.088 15.34 25.844 16.18 27.068 16.18C28.016 16.18 28.592 15.7 28.868 15.256C28.868 15.724 28.916 15.964 28.928 16H30.224C30.212 15.94 30.152 15.592 30.152 15.064V12.16C30.152 10.996 29.468 9.976 27.668 9.976C26.228 9.976 25.316 10.876 25.208 11.884L26.48 12.172C26.54 11.584 26.948 11.116 27.68 11.116C28.448 11.116 28.784 11.512 28.784 12.004C28.784 12.208 28.688 12.376 28.352 12.424L26.852 12.652C25.856 12.796 25.088 13.372 25.088 14.416ZM27.332 15.088C26.78 15.088 26.48 14.728 26.48 14.332C26.48 13.852 26.828 13.612 27.26 13.54L28.784 13.312V13.576C28.784 14.716 28.112 15.088 27.332 15.088ZM34.4987 8.5C34.7627 8.5 34.9187 8.536 35.0027 8.56V7.372C34.9067 7.324 34.6187 7.252 34.2707 7.252C33.0707 7.252 32.2307 8.044 32.2307 9.352V10.156H31.2107V11.368H32.2307V16H33.6347V11.368H34.9907V10.156H33.6347V9.388C33.6347 8.668 34.1267 8.5 34.4987 8.5ZM36.9702 12.436C37.0182 11.812 37.5222 11.14 38.3982 11.14C39.3582 11.14 39.8142 11.752 39.8382 12.436H36.9702ZM39.9942 13.96C39.8022 14.536 39.3702 14.98 38.5302 14.98C37.6662 14.98 36.9702 14.344 36.9342 13.456H41.2062C41.2182 13.432 41.2422 13.24 41.2422 13.012C41.2422 11.164 40.1862 9.976 38.3862 9.976C36.9102 9.976 35.5422 11.2 35.5422 13.048C35.5422 15.028 36.9462 16.18 38.5302 16.18C39.9342 16.18 40.8582 15.352 41.1702 14.332L39.9942 13.96Z"
      fill="#767676"
    />
  </SvgIcon>
);


export default Safe;
