import { Box } from '@mui/material';
import React, { useState } from 'react';
import IncidentDetailsComponent from './IncidentsDetails.details.component';
import IncidentsDetailsDetailsLoaderComponent from './IncidentsDetails.detailsLoader.component';
import useIncidentsDetailsHooks from './IncidentsDetails.hooks';
import IncidentDetailsReport from './IncidentsDetails.report.component';
import { EventsRedirectDialog } from '@root/events';
import { useAppContext } from '@root/App.context';
import { tokenToJson } from '@root/common/Jwt';

const IncidentsDetails = () => {
  const [redirectUrl, setRedirectUrl] = useState<string>();
  const {
    details,
    onChangeData,
    isLoading,
    isSaving,
    onSave,
    cases,
    assets,
    threatActors,
    events,
    history,
    isLoadingHistory,
    onGenereate,
    onDownloadCsv,
    isGenerating,
    isSending,
    onSendEmail,
    isChanged,
    editorState,
    setEditorState,
    onComment,
    onEdit,
    onDelete,
    updatingComments,
    onEventClick,
    incidentTypeOptions,
  } = useIncidentsDetailsHooks();
  const { idToken } = useAppContext();

  return (
    <Box
      pt={2}
      pb={5}
      pl={20}
      pr={20}
      display="flex"
      flexDirection="column"
      gap={4}
    >
      {isLoading && <IncidentsDetailsDetailsLoaderComponent />}
      {!isLoading && (
        <IncidentDetailsComponent
          data={details}
          onChangeData={onChangeData}
          onSave={onSave}
          isSaving={isSaving}
          cases={cases}
          isChanged={isChanged}
          incidentTypeOptions={incidentTypeOptions}
        />
      )}
      <IncidentDetailsReport
        user={(idToken && tokenToJson(idToken)?.email) || undefined}
        assets={assets}
        events={events}
        threatActors={threatActors}
        isLoading={isLoading}
        history={history}
        isLoadingHistory={isLoadingHistory}
        export={{
          isExportingPdf: isGenerating,
          onExportPdf: onGenereate,
          onExportCSV: onDownloadCsv,
          isExportingEmail: isSending,
          onExportEmail: onSendEmail,
        }}
        onRedirectUrl={(url) => setRedirectUrl(url)}
        editorState={editorState}
        setEditorState={setEditorState}
        id={details?.id}
        onComment={onComment}
        isCommenting={isSaving}
        updatingComments={updatingComments}
        onEdit={onEdit}
        onDelete={onDelete}
        onEventDeepLink={onEventClick}
        incident={
          details?.details && details?.id
            ? { name: details?.details, id: details.id }
            : undefined
        }
      />
      {redirectUrl && (
        <EventsRedirectDialog
          redirectUrl={redirectUrl}
          setRedirectUrl={setRedirectUrl}
        />
      )}
    </Box>
  );
};

export default IncidentsDetails;
