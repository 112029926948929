import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import React, { memo, useState } from 'react';
import { useIncidentsContext } from './Incidents.context';
import FiltersContainer from './Incidents.filters.container';
import useIncidentsSortsHook from './Incidents.sorts.hooks';
import * as StyledComponents from './Incidents.styles';
import IncidentTableData from './Incidents.tableData.component';
import { Outlet, useNavigate } from 'react-router-dom';
import { DownloadIcon, FilterButton } from '@fyeo-di-frontend/shared';
import { Close } from '@mui/icons-material';
import PillsContainer from '@root/incidents/Incidents.pills.container';
import useFilterRouterState from './Incidents.filterRouterState.hooks';
import useDownloadCSVHook from '@root/incidents/Incidents.downloadCSV.hooks';

const Incidents = () => {
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const { incidents, isLoading, handleLoadMore, onEventClick, listingRef } =
    useIncidentsContext();
  const { onChangeSort } = useIncidentsSortsHook();
  useFilterRouterState();
  const push = useNavigate();
  const { isDownloading, beginDownloadSequence } = useDownloadCSVHook();

  return (
    <>
      <StyledComponents.Root ref={listingRef}>
        <Box
          ml={1}
          mr={1}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mt={3.75}
        >
          <Box display="flex" alignItems="center" gap={2}>
            <FilterButton onClick={() => setShowFilters((old) => !old)} />
            <PillsContainer clickAction={() => setShowFilters((old) => !old)} />
          </Box>
          <StyledComponents.DownloadIcon
            variant="outlined"
            color="primary"
            onClick={beginDownloadSequence}
            loading={isDownloading}
            data-cy="download-csv"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={1}
            >
              {!isDownloading && (
                <DownloadIcon fontSize="large" data-cy="csv-download-icon" />
              )}
              <Typography variant="subtitle1">CSV</Typography>
            </Box>
          </StyledComponents.DownloadIcon>
        </Box>
        <Box ml={1} mr={1}>
          <IncidentTableData
            loading={isLoading}
            data={incidents}
            onLoadMore={handleLoadMore}
            onSort={onChangeSort}
            onRowClick={(row) => push(`${row.id}`)}
            onEventClick={onEventClick}
          />
        </Box>
      </StyledComponents.Root>
      <Outlet />
      {showFilters && (
        <Dialog
          open
          onClose={() => setShowFilters(() => false)}
          scroll="body"
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            <Box position="absolute" right={8} top={8} color="black">
              <IconButton
                onClick={() => setShowFilters(() => false)}
                color="inherit"
                data-cy="close-filter"
              >
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <FiltersContainer />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default memo(Incidents);
