import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const TickIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <rect width="24" height="24" rx="2" fill="#F1F3F6" />
    <path fillRule="evenodd" clipRule="evenodd" d="M18.1107 7.76753C18.5943 8.17664 18.6607 8.90761 18.2591 9.40021L11.6412 17.5161C11.4215 17.7855 11.0941 17.9394 10.7502 17.9347C10.4064 17.93 10.0831 17.7673 9.87063 17.492L6.24352 12.7933C5.85494 12.2899 5.94058 11.561 6.4348 11.1652C6.92902 10.7695 7.64467 10.8567 8.03325 11.3601L10.7897 14.9309L16.5077 7.91869C16.9093 7.42609 17.627 7.35842 18.1107 7.76753Z" />
  </SvgIcon>
);

export default TickIcon;
