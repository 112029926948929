import { AssetType, Severity } from '@fyeo-di-frontend/shared';
import dayjs from 'dayjs';

export enum Status {
  Open,
  Closed,
}

export enum ControlValues {
  Bold = 'bold',
  Underline = 'underline',
  Italic = 'italic',
  Ordered = 'ordered-list-item',
  Unordered = 'unordered-list-item',
}

export interface Incident {
  id: string;
  date: Date;
  details: string;
  case: string;
  caseId: string;
  severity: Severity;
  eventCount: number;
  incidentType: string;
  reported: boolean;
  status: Status;
}

export interface PeriodicIncident<T> {
  id: string;
  date: Date;
  case: string;
  status: Status;
  title: string;
  description: string;
  severity: Severity;
  target: T[];
  source: T[];
}

export interface IncidentDetails extends Incident {
  recommendation: string;
  source: string;
  sourceType: SourceType;
  summary: string;
  comment: {
    createdAt: Date;
    content: string;
    commentator: string;
  }[];
}

export enum SourceType {
  None = 'none',
  'Social Media' = 'social-media',
  Public = 'clear-net',
  Darknet = 'dark-net',
  Private = 'private',
}

export enum SourceTypeWithOutNone {
  'Social Media' = 'social-media',
  Public = 'clear-net',
  Darknet = 'dark-net',
  Private = 'private',
}

export interface IThreatActor<T> {
  assetType: T;
  name?: string;
  domainName?: string;
}

export interface IAssets<T> {
  assetType: T;
  assetName: string;
}

export interface IEvents {
  icon: string;
  link: string;
  linkToEvidenceFile: string;
  date: Date;
  data: string;
}

export interface AssetResponse {
  assetType: AssetType;
  name: { common: string; first: string; last: string };
}

export interface EventsResponse {
  title: string;
  url: string;
  createdAt: string | number | Date | dayjs.Dayjs | null | undefined;
  cuts: JSON;
  imageIconUrl: string;
  pdfUrl?: string;
}

export interface CommentType {
  commentator: string;
  content: string;
}

export interface History {
  changedKey: keyof IncidentDetails;
  oldValue: string | CommentType;
  newValue: string | CommentType;
  createdAt: Date;
  updatedBy: string;
  _id: string;
  incident?: Incident;
}
