import { useAppContext } from '@root/App.context';
import dayjs from 'dayjs';
import qs from 'qs';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { tokenToJson } from '../common/Jwt';
import { ReportsRoutes } from '../common/Routes';
import { Domain, ReportsTypes } from './Reports.types';

export type TReportsContextModel = ReturnType<typeof useReportsContextModel>;

const ReportsContext = createContext<Partial<TReportsContextModel>>({});
const { Provider } = ReportsContext;

export const useReportsContextModel = () => {
  const { selectedCases, idToken } = useAppContext();

  const preparedBy = useMemo(() => {
    return idToken ? tokenToJson(idToken).email : '';
  }, [idToken]);

  const cases = useMemo(() => {
    return selectedCases?.map((c) => ({
      id: c.id,
      name: c.name,
      group: c.group,
    }));
  }, [selectedCases]);

  const preparedFor = cases[0]?.group;

  const [domain, setDomain] = useState<Domain | undefined>();
  const [reportType, setReportType] = useState<ReportsTypes>(ReportsTypes.None);
  const [dateRange, setDateRange] = useState<{ from?: Date; to?: Date }>({
    from: dayjs().subtract(1, 'month').toDate(),
    to: dayjs().toDate(),
  });
  const [dateError, setDateError] = useState(false);

  const computeButtonDisability = useCallback(() => {
    if (!selectedCases?.length) return true;
    switch (reportType) {
      case ReportsTypes.None:
        return true;
      case ReportsTypes.Domain:
        return !domain || !dateRange.from || !dateRange.to || dateError;
      case ReportsTypes.Periodic:
        return !dateRange.from || !dateRange.to || dateError;
    }
  }, [reportType, dateRange, domain, dateError, selectedCases]);

  const url = useMemo(() => {
    switch (reportType) {
      case ReportsTypes.Domain: {
        const domainParams = qs.stringify({
          preparedBy: JSON.stringify(preparedBy),
          preparedFor: JSON.stringify(domain?.name),
          caseIds: JSON.stringify(cases),
          from: JSON.stringify(dateRange?.from),
          to: JSON.stringify(dateRange?.to),
        });
        return `${ReportsRoutes.Root}/${ReportsRoutes.Domain}?${domainParams}`;
      }
      case ReportsTypes.Periodic: {
        const periodicParams = qs.stringify({
          preparedBy: JSON.stringify(preparedBy),
          preparedFor: JSON.stringify(preparedFor),
          caseIds: JSON.stringify(cases),
          from: JSON.stringify(dateRange?.from),
          to: JSON.stringify(dateRange?.to),
        });
        return `${ReportsRoutes.Root}/${ReportsRoutes.Periodic}?${periodicParams}`;
      }
      default:
        return '#';
    }
  }, [dateRange, cases, reportType, preparedBy, domain, preparedFor]);

  return {
    setDateRange,
    dateRange,
    reportType,
    setReportType,
    domain,
    setDomain,
    computeButtonDisability,
    cases,
    url,
    setDateError,
  };
};

export const useReportsContext = () => useContext(ReportsContext);

export const ReportsContextProvider = Provider;
