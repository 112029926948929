import { useEffect, useState } from 'react';
import { Severity } from '@fyeo-di-frontend/shared';
import { Option } from '@root/common/Common.types';

const useEventsThreatLevel = () => {
  const [threatLevel, setThreatLevel] = useState<Option[]>([]);

  useEffect(() => {
    const threatModelList: {
      name: string;
      id: string;
    }[] = Object.entries(Severity).map(([key, value]) => ({
      name: value as string,
      id: key,
    }));

    setThreatLevel(() =>
      threatModelList.slice(0, threatModelList.length / 2 - 1),
    );
  }, []);

  return {
    isLoading: false,
    threatLevel,
  };
};

export default useEventsThreatLevel;
