import * as StyledComponents from './Account.styles';
import { Box, Grid, Link, Typography } from '@mui/material';
import { AvatarIcon } from '@fyeo-di-frontend/shared';
import React from 'react';
import CallToAction from '@root/account/Account.callToAction.component';
import { useNavigate } from 'react-router-dom';
import { SettingsRoutes } from '@root/common/Routes';
import useAccount from '@root/account/Account.hooks';

const Account = () => {
  const push = useNavigate();
  const { details } = useAccount();
  return (
    <StyledComponents.Root>
      <StyledComponents.Wrapper
        ml={2}
        display="flex"
        flexDirection="column"
        p={2.5}
      >
        <Box fontSize={63} display="flex" gap={2} alignItems="center">
          <AvatarIcon fontSize="inherit" />
          <Box>
            <Typography
              variant="subtitle1"
              lineHeight={1.5}
              data-cy="full-name"
            >
              {details.firstName} {details.lastName}
            </Typography>
            <Typography variant="subtitle1" lineHeight={1.5}>
              {details.email}
            </Typography>
          </Box>
        </Box>
        <StyledComponents.HorizontalRule />
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <CallToAction
              title="Password"
              buttonText="Change password"
              buttonAction={() => {
                push(SettingsRoutes.ResetPassword);
              }}
            >
              <Typography variant="subtitle2">
                Remember to use a complex password. We recommend using the{' '}
                <Link
                  href="https://www.gofyeo.com/fyeo-identity"
                  target="_blank"
                  referrerPolicy="no-referrer"
                >
                  FYEO ID
                </Link>{' '}
                password manager to manage and create secure credentials.
              </Typography>
            </CallToAction>
          </Grid>
          <Grid item xs={6} />
        </Grid>
      </StyledComponents.Wrapper>
    </StyledComponents.Root>
  );
};

export default Account;
