import { Breaches } from '@fyeo-di-frontend/shared';
import React from 'react';
import * as StyledComponents from './DomainReport.styles';
import DomainReportTableData from './DomainReport.tableData.component';

interface Props {
  loading?: boolean;
  data?: Breaches[];
}

const ListBreaches = (props: Props) => {
  return (
    <StyledComponents.EntryRoot>
      <StyledComponents.NoBreakTitle variant="h5" fontWeight={'bold'} mb={2}>
        List of breaches
      </StyledComponents.NoBreakTitle>
      <DomainReportTableData loading={props.loading} data={props.data} />
    </StyledComponents.EntryRoot>
  );
};

export default ListBreaches;
