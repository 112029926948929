import styled from 'styled-components';
import { Box, Container } from '@mui/material';

export const Root = styled.div({
  height: 'calc(100vh - 70px)',
  backgroundColor: '#f8f9fb',
  paddingTop: 55,
  overflowY: 'auto',
  overflowX: 'hidden',
});

export const Wrapper = styled(Box)({
  borderRadius: '4px 4px 0px 0px',
  marginTop: 13,
  marginRight: 27,
  backgroundColor: '#fff',
  height: 'calc(100% - 70px)',
});

export const HorizontalRule = styled.hr({
  border: '1px solid #D5D8DF',
  borderTop: 'none',
  width: '100%',
  marginTop: 20,
  marginBottom: 30,
});

export const BorderBox = styled(Box)({
  border: '1px solid #D5D8DF',
  borderRadius: 4,
  height: 158,
});

export const RootStyled = styled.div({
  backgroundColor: '#f8f9fb',
  height: 'calc(100vh - 64px)',
});

export const ContainerStyled = styled(Container)({
  background: 'transparent',
  position: 'relative',
  top: 100,
});

export const HighMeterBar = styled.div({
  width: 'calc(20% - 3px)',
  background: '#31AD34',
  borderRadius: 6,
  height: 3,
  opacity: 0.2,
});
