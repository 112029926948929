import { useAppContext } from '@root/App.context';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { DashboardRoutes } from '@root/common/Routes';

const LoggedOutRoute = () => {
  const { isUserAuthenticated } = useAppContext();
  return !isUserAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={DashboardRoutes.Root} replace />
  );
};

export default LoggedOutRoute;
