import {
  autoMerge,
  Case,
  retrieveSelectedCases,
  storeSelectedCases,
} from '@fyeo-di-frontend/shared';
import { useAppContext } from '@root/App.context';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import useCasesHook from './Cases.hooks';

export type TCasesContextModel = ReturnType<typeof useCasesContextModel>;

const CasesContext = createContext<TCasesContextModel>({
  groups: [],
  isLoading: false,
  onChangeSelectedCases: () => {},
  refreshCases: () => {},
  onGroupCheck: () => {},
  setUser: () => {},
  user: {},
  openNoGroup: false,
});

const { Provider } = CasesContext;

export const useCasesContextModel = () => {
  const { setSelectedCases, setAllCases, allCases: cases } = useAppContext();
  const [groups, setGroups] = useState<string[]>([]);

  const { isLoading, data, refreshCases, user, setUser, openNoGroup } =
    useCasesHook();

  const onChangeSelectedCases = useCallback(
    (aCase: Case, isDelete?: boolean) => {
      setSelectedCases((oldCases) => {
        if (isDelete) {
          const index = oldCases.findIndex((o) => o.id === aCase.id);
          if (index >= 0) {
            oldCases.splice(index, 1);
            storeSelectedCases([...oldCases]);
            return [...oldCases];
          }
          storeSelectedCases(oldCases);
          return oldCases;
        } else {
          storeSelectedCases([...oldCases, aCase]);
          return [...oldCases, aCase];
        }
      });
    },
    [setSelectedCases],
  );

  const onGroupCheck = useCallback(
    (group: string, checked: boolean) => {
      setSelectedCases((oldCases) => {
        if (checked) {
          const addedSelectedCases = cases?.filter((ca) => ca.group === group);
          const newArr = autoMerge(oldCases || [], addedSelectedCases, 'id');
          storeSelectedCases(newArr);
          return newArr;
        } else {
          const newArr = oldCases?.filter((ca) => ca.group !== group);
          storeSelectedCases(newArr);
          return newArr;
        }
      });
    },
    [setSelectedCases, cases],
  );

  useEffect(() => {
    if (data.length) {
      setAllCases((oldCases) => data || oldCases);
      setGroups(() => Array.from(new Set(data?.map((m) => m.group))));
    }
  }, [data]);

  useEffect(() => {
    if (data.length) {
      const allGroups = Array.from(new Set(data?.map((m) => m.group)));
      const retrievedSelectedCases = retrieveSelectedCases();
      let selCases;
      retrievedSelectedCases
        ? (selCases = data?.filter((c) =>
            retrievedSelectedCases.map((r) => r.id).includes(c.id),
          ))
        : (selCases = data?.filter((c) => c.group === allGroups[0]));
      setSelectedCases(selCases);
      storeSelectedCases(selCases);
    }
  }, [data]);

  return {
    onChangeSelectedCases,
    isLoading,
    groups,
    refreshCases,
    onGroupCheck,
    user,
    setUser,
    openNoGroup,
  };
};

export const useCasesContext = () => useContext(CasesContext);

export const CasesContextProvider = Provider;
