import styled from 'styled-components';
import { Button, Container, Paper } from '@mui/material';

export const Root = styled.div({
  height: 'calc(100vh - 70px)',
  backgroundColor: '#f8f9fb',
  paddingTop: 110,
  overflowY: 'auto',
  display: 'flex',
});

export const ContainerWrapper = styled(Container)({
  background: 'transparent',
});

export const PaperRoot = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  padding: 30,
  alignItems: 'center',
});

export const NoResultIcon = styled.img({
  width: 103,
});

export const NoWrapButtonText = styled(Button)({
  whiteSpace: 'nowrap',
});
