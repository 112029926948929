import {
  Button,
  Divider,
  Paper,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from '@mui/material';
import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';

export const Root = styled.div({
  height: 'calc(100vh - 70px)',
  backgroundColor: '#f8f9fb',
  overflowY: 'auto',
});

export const ButtonWrapper = styled(Button)({
  width: 45,
  height: 35,
});
export const FilterOptionsButton = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 4,
  div: {
    height: 3,
    backgroundColor: 'white',
    borderRadius: 50,
    border: 'none',
  },
  '.first': {
    width: 19,
  },
  '.second': {
    width: 14,
  },
  '.last': {
    width: 8,
  },
});

export const TableHeaderCell = styled(TableCell)({
  top: 60,
  height: 50,
  cursor: 'pointer',
});

export const TableBodyRow = styled(TableRow)({
  backgroundColor: '#fff',
  cursor: 'pointer',
});

export const TableBodyCenteredCell = styled(TableCell)({
  textAlign: 'center',
});

export const TableHeaderStyled = styled(TableHead)({
  paddingTop: 60,
  position: 'sticky',
  top: 0,
  backgroundColor: '#f8f9fb',
});

export const DarkDivider = styled(Divider)({
  backgroundColor: '#323232',
  height: '100%',
});

export const DateRangePickerRoot = styled.div({
  '.MuiPaper-root': {
    '> div > div': {
      height: 350,
    },
    backgroundColor: '#E5E7EA',
    borderRadius: 16,
    '.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
      backgroundColor: 'rgb(212, 233, 217)',
    },
  },
});

export const NoResultContainer = styled(Paper)({
  marginTop: 10,
  paddingTop: 40,
  paddingBottom: 62,
});

export const NoResultIcon = styled.img({
  width: 103,
});

export const DownloadIcon = styled(LoadingButton)({
  paddingTop: 2.4,
  paddingBottom: 2.4,
  borderWidth: 1.5,
  minWidth: 103,
  minHeight: 37,
  ':hover': {
    borderWidth: 1.5,
  },
});
